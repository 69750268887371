import omniSelectReducer from 'containers/OmniSelect/reducer';
import tableReducer from 'containers/Table/reducer';
import formReducer from 'global/formWrapper/reducer';
import { combineReducers } from 'redux-immutable';
import {
  SAVE_TABLE_STATE,
  SET_USERS,
  TABLE_STATE_REDUCER,
  USERS_REDUCER,
} from 'routes/OrgContainer/constants';
import { createOrgContainerReducer } from 'routes/OrgContainer/reducer';
import { createNamedWrapperReducer } from 'utils/reducers';
import { PAGE, FORM, USERS_OMNI, TABLE } from './constants';

const reducers = {
  [FORM]: createNamedWrapperReducer(formReducer, PAGE, FORM),
  [USERS_OMNI]: createNamedWrapperReducer(omniSelectReducer, PAGE, USERS_OMNI),
  [USERS_REDUCER]: createNamedWrapperReducer(
    createOrgContainerReducer([], SET_USERS),
    PAGE,
  ),
  [TABLE]: createNamedWrapperReducer(tableReducer, PAGE),
  [TABLE_STATE_REDUCER]: createNamedWrapperReducer(
    createOrgContainerReducer({}, SAVE_TABLE_STATE),
    PAGE,
  ),
};

export default combineReducers(reducers);
