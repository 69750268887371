import {
  REQUEST_GET_INITIAL_DATA,
  REQUEST_GET_IDENTITY_PROVIDERS,
  SET_IDENTITY_PROVIDER_TYPES,
  SET_IDENTITY_PROVIDERS,
  REQUEST_SYNC_IDENTITY_PROVIDER,
  REQUEST_UPSERT_IDENTITY_PROVIDER,
  OAUTH_SET_AUTH_URL,
  OAUTH_REQUEST_CALLBACK,
  REQUEST_GET_GROUPS,
  REQUEST_GET_GROUP_RELATIONS,
  SET_GROUP_RELATIONS,
  REQUEST_GET_IDP_GROUPS,
  SET_IDENTITY_PROVIDER_GROUPS,
  INACTIVATE_IDP,
  REQUEST_CREATE_VIDEO_PROVIDER,
  REQUEST_UPDATE_VIDEO_PROVIDER,
  REQUEST_IDP_REAUTH,
} from './constants';

export function setIdentityProviderGroups(code, data) {
  return {
    type: SET_IDENTITY_PROVIDER_GROUPS,
    code,
    data,
  };
}
export function requestGetIdentityProviderGroups(idpId, code, status, query) {
  return {
    type: REQUEST_GET_IDP_GROUPS,
    idpId,
    code,
    status,
    query,
  };
}

export function requestGetInitialData() {
  return {
    type: REQUEST_GET_INITIAL_DATA,
  };
}
// export function requestGetInitialData(directorySyncFeatureAvailable) {
//   return {
//     type: REQUEST_GET_INITIAL_DATA,
//     directorySyncFeatureAvailable,
//   }
// }

// @TODO - this may not be necessary any more
export function requestGetIdentityProviders() {
  return {
    type: REQUEST_GET_IDENTITY_PROVIDERS,
  };
}

export function setIdentityProviderTypes(identityProviderTypes) {
  return {
    type: SET_IDENTITY_PROVIDER_TYPES,
    data: identityProviderTypes,
  };
}

export function setIdentityProviders(identityProviders) {
  return {
    type: SET_IDENTITY_PROVIDERS,
    data: identityProviders,
  };
}

export function requestSyncIdentityProvider(identityProviderId) {
  return {
    type: REQUEST_SYNC_IDENTITY_PROVIDER,
    id: identityProviderId,
  };
}

export function requestUpsertIdentityProvider({ idpt, idpPageItems }) {
  return {
    type: REQUEST_UPSERT_IDENTITY_PROVIDER,
    idpt,
    idpPageItems,
  };
}

export function setOAuthRedirectUrl(url) {
  return {
    type: OAUTH_SET_AUTH_URL,
    url,
  };
}

export function requestPostOAuthCallback(authCode, authState, siteCode) {
  return {
    type: OAUTH_REQUEST_CALLBACK,
    authCode,
    authState,
    siteCode,
  };
}

export function requestIdpReauth(orgId, idpId) {
  return {
    type: REQUEST_IDP_REAUTH,
    orgId,
    idpId,
  };
}

export function requestGetGroups() {
  return {
    type: REQUEST_GET_GROUPS,
  };
}

export function requestGetGroupRelations(identityProviderId) {
  return {
    type: REQUEST_GET_GROUP_RELATIONS,
    id: identityProviderId,
  };
}

export function setGroupRelations(identityProviderCode, groupRelations) {
  return {
    type: SET_GROUP_RELATIONS,
    data: groupRelations,
    code: identityProviderCode,
  };
}

export function inactivateIdp(identityProviderId) {
  return {
    type: INACTIVATE_IDP,
    identityProviderId,
  };
}

// This shouldn't live here
export function requestCreateVideoProvider({
  videoProviderTypeId,
  apiKey,
  authState,
  name,
  supportsGroups,
}) {
  return {
    type: REQUEST_CREATE_VIDEO_PROVIDER,
    videoProviderTypeId,
    apiKey,
    authState,
    name,
    supportsGroups,
  };
}

// This shouldn't live here
export function requestUpdateVideoProvider(
  videoProviderId,
  { apiKey, authState, name, supportsGroups },
) {
  return {
    type: REQUEST_UPDATE_VIDEO_PROVIDER,
    videoProviderId,
    apiKey,
    authState,
    name,
    supportsGroups,
  };
}
