import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { InitOptions } from 'i18next';
import i18n, { i18nInitOptions, i18nPostInit } from 'i18n/i18n';
import { I18nextProvider } from 'react-i18next';
import ConfigProvider from 'antd/es/config-provider';
import { THEME } from 'op-themes';
import { configureStore } from 'store/index';
import appReducer from 'routes/AppContainer/reducer';
import { App } from './App';

import 'shims';

// Currently using this but if more need added - make a new one and keep this one only for signin.
import 'importMinimalAssets';

import 'assets/scss/main.scss';

i18n.init(i18nInitOptions as InitOptions);

i18nPostInit(i18n);

// We need to inject the app reducer for now to have uniform alerts
const store = configureStore({}, { app: appReducer }) as Store;

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <I18nextProvider defaultNS="translation" i18n={i18n}>
      <ConfigProvider theme={THEME.dark}>
        {/* TODO THEME based on user profile */}
        <Provider store={store}>
          <App />
        </Provider>
      </ConfigProvider>
    </I18nextProvider>,
  );
}
