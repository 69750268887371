import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const CheckSvg = ({ ...svgProps }) => (
  <SvgWrapper width="12" height="12" viewBox="0 0 12 12" {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.14625 9.35425L1.14625 6.35425C0.95125 6.15825 0.95125 5.84225 1.14625 5.64625C1.34225 5.45125 1.65825 5.45125 1.85425 5.64625L4.50025 8.29325L10.1462 2.64625C10.3422 2.45125 10.6582 2.45125 10.8542 2.64625C11.0492 2.84225 11.0492 3.15825 10.8542 3.35425L4.85425 9.35425C4.75625 9.45125 4.62825 9.50025 4.50025 9.50025C4.37225 9.50025 4.24425 9.45125 4.14625 9.35425Z"
    />
  </SvgWrapper>
);
