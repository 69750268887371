import { OpInfoTooltip } from 'new-components/DLS/OpInfoTooltip/OpInfoTooltip';
import {
  ColumnHeaderFilter,
  ColumnHeaderFilterProps,
} from './ColumnHeaderFilter';
import { OpTableRawColumnType } from '../OpTableCore';

import './ColumnHeader.scss';

interface ColumnHeaderProps
  extends Pick<
      OpTableRawColumnType,
      'label' | 'tooltip' | 'defaultFilteredValue'
    >,
    ColumnHeaderFilterProps {
  hasHeaderFilter?: boolean;
}

export const ColumnHeader = ({
  label,
  tooltip,
  hasHeaderFilter,
  gtm,
  ...columnHeaderFilterProps
}: ColumnHeaderProps) => {
  return (
    <div
      className="column-header"
      /** Prevents click on this div (and its children) from firing
       * a click event on the header as that causes sorting to occur.
       * Need to add here as disabled elements do not fire the onClick
       * and we are using a disabled filter input at times */
      onClick={(e) => e?.stopPropagation?.()}
      aria-hidden
    >
      <div className="column-header__title">
        {label} {tooltip && <OpInfoTooltip title={tooltip} />}
      </div>
      {hasHeaderFilter && (
        <ColumnHeaderFilter
          gtm={gtm && `${gtm}-${label}`}
          testId={`op-filter-${label}`}
          {...columnHeaderFilterProps}
        />
      )}
    </div>
  );
};
