/* eslint-disable @typescript-eslint/no-unused-vars */
import { OnSubmitArgs } from 'new-components/DLS/OpForm/OpForm';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useTranslation } from 'react-i18next';

interface MailroomFormProps {
  orgId: number;
  mailroomId?: number;
}

export const useMailroomForm = ({ orgId, mailroomId }: MailroomFormProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const createMailroomMutation = useOpMutation({
    apiEndpointName: 'createMailroom',
    onSuccessMessage: t('Successfully created mailroom'),
    queryKeysToInvalidate: [['listMailrooms'], ['describeMailroom']],
    onSuccessCallback: () => {
      setSearchParams({});
    },
  });

  const updateMailroomMutation = useOpMutation({
    apiEndpointName: 'updateMailroom',
    onSuccessMessage: t('Successfully updated mailroom'),
    queryKeysToInvalidate: [['listMailrooms'], ['describeMailroom']],
    onSuccessCallback: () => {
      setSearchParams({});
    },
  });

  const {
    data: describeMailroomHeliumResponse,
    isError: describeMailroomError,
  } = useOpQuery({
    apiEndpointName: 'describeMailroom',
    parameters: [orgId, mailroomId!],
  });

  const {
    name,
    site,
    address,
    address2,
    city,
    state,
    country,
    zip,
    phone,
    description,
  } = describeMailroomHeliumResponse?.json.data || {};

  const mailroomFormData = {
    name: name || '',
    siteId: site?.id,
    address: address || '',
    address2: address2 || '',
    city: city || '',
    state: state || '',
    country: country || '',
    zip: zip || '',
    phone: phone || '',
    description: description || '',
  };

  const handleSubmit = async ({ touchedValues }: OnSubmitArgs) => {
    if (mailroomId) {
      updateMailroomMutation.mutate({
        apiEndpointRequirements: [orgId, mailroomId],
        payload: touchedValues as Api.Payload['updateMailroom'],
      });
    } else {
      createMailroomMutation.mutate({
        apiEndpointRequirements: [orgId],
        payload: touchedValues as Api.Payload['createMailroom'],
      });
    }
  };

  return {
    mailroomFormData,
    handleSubmit,
    hasFormLoadError: Boolean(describeMailroomError),
  };
};
