import { ButtonProps } from 'antd/es/button';
import { PlusSvg } from 'components/svgs/PlusSvg';
import { OpTooltip } from 'new-components/DLS/OpTooltip/OpTooltip';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface AddButtonProps {
  onClick?: ButtonProps['onClick'];
  itemName?: string;
  disabled?: boolean;
  tooltip?: ReactNode;
  gtm?: string;
}

export const AddButton = ({
  onClick,
  itemName = 'item',
  disabled = false,
  tooltip,
  gtm,
}: AddButtonProps) => {
  const { t } = useTranslation();
  return (
    <OpTooltip
      placement="topRight"
      title={tooltip || t('Add {{itemName}}', { itemName })}
    >
      <OpButton
        gtm={gtm && `${gtm}-add`}
        data-testid={`add-${itemName.toLowerCase().split(' ').join('-')}`}
        size="large"
        shape="circle"
        icon={<PlusSvg />}
        onClick={onClick}
        disabled={disabled}
      />
    </OpTooltip>
  );
};
