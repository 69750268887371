import { t } from 'i18next';
import { OpTableRawColumnType } from 'new-components/DLS/OpTableCore/OpTableCore';
import { formatMailroomAddress } from './formatMailroomAddress';

export const getMailroomsPageColumns = () => {
  const columns: OpTableRawColumnType[] = [
    {
      label: t('Mailroom'),
      dataIndex: ['name'],
      ellipsis: true,
      sorter: true,
      filter: { type: 'input' },
    },
    {
      label: t('Site'),
      dataIndex: ['site', 'name'],
      sorter: true,
      filter: { type: 'input' },
    },
    {
      label: t('Address'),
      dataIndex: ['address'],
      ellipsis: false,
      render: (_, record) =>
        formatMailroomAddress(record as Api.Response['describeMailroom']),
    },
  ];

  return columns;
};
