import { ComponentProps } from 'react';
import { OpForm } from '../../OpForm';

import './OpFormHiddenItemForDataSetting.scss';

/**
 * The way antd creates a form values object is by taking all the
 * names/paths that are rendered and creates node from those points.
 * In order for keys to be included, they must have a name/path that
 * points to that point or before in the chain. In cases where we
 * don't want a UI field shown, but need a pointer that is at or
 * before data we need in the object, we need to add a hidden field
 * with the appropriate name/path.
 *
 * E.g. We have a version at subscription.hookAction.config.version,
 * but the first name/path of a field we ever show in the UI points
 * to subscription.hookAction.config.trigger.properties.data.allOf.
 * Therefore, in order for version to be included in the form values
 * object, we add a hidden field with the name
 * subscription.hookAction.config (could be subscription.hookAction
 * or just subscription as well) so that version and other values
 * nested inside config are included in the form values object.
 */
export const OpFormHiddenItemForDataSetting = ({
  name,
}: Pick<ComponentProps<typeof OpForm.Item>, 'name'>) => (
  <OpForm.Item className="op-form-hidden-item-for-data-setting" name={name}>
    <span />
  </OpForm.Item>
);
