import { useTranslation } from 'react-i18next';
import { useUrlSearchParams } from 'utils/customHooks/useUrlSearchParams';
import { OpContent } from 'new-components/OpContent/OpContent';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { Footer } from 'antd/es/layout/layout';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import footerLogo from './assets/Footer-Logo.png';
import backgroundImg from './assets/background.png';

import './Packages.scss';

const APP_REDIRECT_PATH = '';
const STORE_REDIRECT_PATH = '';

const Packages = () => {
  const { t } = useTranslation();
  const { mailroom, user } = useUrlSearchParams();

  const redirect = () => {
    // if redirect doesn't happen within 25 milliseconds, redirect to app store
    setTimeout(() => {
      window.location.href = STORE_REDIRECT_PATH;
    }, 25);
    window.location.href = `${APP_REDIRECT_PATH}?user=${user}&mailroom=${mailroom}`;
  };

  return (
    <div className="packages-page ant-layout css-dev-only-do-not-override-dfixay css-var-r1">
      <OpContent className="packages-page__content">
        <OpSpace className="packages-page__space">
          <img src={backgroundImg} alt="Package QR Codes" />
        </OpSpace>
        <OpText className="packages-page__text">
          {t('This QR code is for Mailroom Managers')}
        </OpText>
        <OpButton type="primary" data-testid="go-to-mobile" onClick={redirect}>
          {t('Open')}
        </OpButton>
      </OpContent>
      <Footer className="packages-page__footer">
        <img src={footerLogo} alt="Avinon Alta" />
      </Footer>
    </div>
  );
};
export default Packages;
