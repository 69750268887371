import { getAccessToken } from 'utils/accessToken';
import { getOpParams } from 'utils/getOpParams';
import { useOpQuery } from './useOpQuery';

/**
 * Hook to get the current logged in identity and user
 * @returns {object} -
 *   user - data returned from describeUser
 *   identity - data returned from describeIdentity
 *   isPending - boolean if any call is still pending
 *   isFetching - boolean if any call is still fetching
 */
export const useIdentityAndUser = () => {
  const { orgId } = getOpParams();
  const accessToken = getAccessToken();

  const {
    data: tokenData,
    isLoading: isLoadingToken,
    isPending: isPendingToken,
    isFetching: isFetchingToken,
  } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    select: (data) => data?.json?.data,
  });

  const {
    data: identityData,
    isLoading: isLoadingIdentity,
    isPending: isPendingIdentity,
    isFetching: isFetchingIdentity,
  } = useOpQuery({
    apiEndpointName: 'describeIdentity',
    parameters: [tokenData?.identity?.id!],
    select: (data) => data?.json?.data,
  });

  const currentUserId = tokenData?.tokenScopeList?.find(
    (tokenScope) => tokenScope.org?.id === orgId,
  )?.user?.id;

  const {
    data: userData,
    isLoading: isLoadingUser,
    isPending: isPendingUser,
    isFetching: isFetchingUser,
  } = useOpQuery({
    apiEndpointName: 'describeUser',
    parameters: [orgId, currentUserId!],
    select: (data) => data?.json?.data,
  });

  return {
    identity: identityData,
    user: userData,
    token: tokenData,
    identityId: tokenData?.identity?.id,
    userId: tokenData?.tokenScopeList?.find(
      (tokenScope) => tokenScope.org?.id === orgId,
    )?.user?.id,
    isLoading: isLoadingToken || isLoadingIdentity || isLoadingUser,
    isPending: isPendingToken || isPendingIdentity || isPendingUser,
    isFetching: isFetchingToken || isFetchingIdentity || isFetchingUser,
  };
};
