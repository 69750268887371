import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const MailroomSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 14 19" {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.5C10.584 0.5 13.5 3.416 13.5 7C13.5 13.163 7.572 18.174 7.32 18.384C7.228 18.461 7.114 18.5 7 18.5C6.886 18.5 6.772 18.461 6.68 18.384C6.428 18.174 0.5 13.163 0.5 7C0.5 3.416 3.416 0.5 7 0.5ZM7 1.5C3.967 1.5 1.5 3.967 1.5 7C1.5 11.913 5.78 16.207 7 17.331C8.219 16.206 12.5 11.905 12.5 7C12.5 3.967 10.033 1.5 7 1.5ZM7 3.5C8.93 3.5 10.5 5.07 10.5 7C10.5 8.93 8.93 10.5 7 10.5C5.07 10.5 3.5 8.93 3.5 7C3.5 5.07 5.07 3.5 7 3.5ZM7 4.5C5.622 4.5 4.5 5.622 4.5 7C4.5 8.378 5.622 9.5 7 9.5C8.378 9.5 9.5 8.378 9.5 7C9.5 5.622 8.378 4.5 7 4.5Z"
    />
  </SvgWrapper>
);
