import React from 'react';
import PropTypes from 'prop-types';
import SemanticSegment from 'semantic-ui-react/dist/es/elements/Segment/Segment';
import Label from 'semantic-ui-react/dist/es/elements/Label/Label';
import Header from 'semantic-ui-react/dist/es/elements/Header/Header';
import { Ref } from '@fluentui/react-component-ref/dist/es/Ref';
import { InfoButton } from 'components/InfoButton';

import './Segment.scss';

const styles = {
  ribbonLabel: {
    zIndex: 3, // Above feature gate overlay so that label is clearly visible
  },
};

export const Segment = ({
  children,
  label,
  ribbonLabel,
  infoButtonContent,
  id,
  headerRightContent,
  innerRef,
  labelContainerStyle = {},
  className = '',
  ...props
}) => {
  let content = (
    <SemanticSegment
      className={`op-segment ${className}`.trim()}
      id={id}
      aria-label={label || ribbonLabel}
      {...props}
      {...(props.disabled && {
        style: { pointerEvents: 'none', ...props.style },
      })}
    >
      {(label || ribbonLabel || headerRightContent) && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ...(ribbonLabel && { marginBottom: 11 }),
            ...labelContainerStyle,
          }}
        >
          {/* Ribbon label */}
          {ribbonLabel && (
            <Label ribbon style={styles.ribbonLabel}>
              <Header
                as="h4"
                style={{ display: 'flex', alignItems: 'center' }}
                className="op-segment__header"
              >
                {ribbonLabel}
                {infoButtonContent && (
                  <InfoButton
                    styles={{
                      icon: {
                        marginRight: 0,
                      },
                    }}
                    tooltipOrientation="right center"
                    tooltipContent={infoButtonContent}
                  />
                )}
              </Header>
            </Label>
          )}

          {/* Header right content */}
          {headerRightContent || null}
        </div>
      )}

      {children}
    </SemanticSegment>
  );

  if (label) {
    content = (
      <div className="op-segment">
        <Header as="h4" className="op-segment__header">
          {label}
          {infoButtonContent && (
            <InfoButton
              styles={{
                icon: {
                  marginRight: 0,
                },
              }}
              tooltipOrientation="right center"
              tooltipContent={infoButtonContent}
            />
          )}
        </Header>
        {content}
      </div>
    );
  }

  return innerRef ? <Ref innerRef={innerRef}>{content}</Ref> : content;
};

Segment.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  ribbonLabel: PropTypes.string,
  infoButtonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerRightContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  style: PropTypes.object,
  labelContainerStyle: PropTypes.object,
};
