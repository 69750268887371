import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Message from 'semantic-ui-react/dist/es/collections/Message/Message';
import MessageHeader from 'semantic-ui-react/dist/es/collections/Message/MessageHeader';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';

import {
  selectActiveScopes,
  selectCurrentOrgId,
} from 'global/accessToken/selectors';
import { addSlideOut } from 'global/slideOuts/actions';

import alarmEditRouteConfig from 'routes/AlarmDashboardPage/AlarmEditPage/route';

import { verifyScope } from 'utils/redirects';
import { useSelectorJs } from 'utils/customHooks';

import './AlertBox.scss';

export const AlertBox = ({
  type = 'info',
  header = type,
  buttons,
  children,
  onClose,
  icon,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasButtons = useMemo(() => !!buttons?.length, [buttons]);

  const activeScopes = useSelectorJs(selectActiveScopes());
  const orgId = useSelectorJs(selectCurrentOrgId);

  const canEditAlarms = useMemo(
    () => verifyScope(activeScopes, alarmEditRouteConfig.scope, orgId),
    [activeScopes, orgId],
  );

  return (
    <Message
      className={`op-alert-box op-alert-box--type-${type}${
        hasButtons ? ' op-alert-box--with-buttons' : ''
      }`}
      icon={icon}
      error={type === 'error'}
      warning={type === 'warning'}
      info={type === 'info'}
      success={type === 'success'}
      header={hasButtons ? null : String(header).toUpperCase()}
      content={
        hasButtons ? (
          <>
            <div className="op-alert-box__content">
              <MessageHeader>
                {t('{{header}}', { header: String(header) }).toUpperCase()}
              </MessageHeader>
              {children}
            </div>
            <div className="op-alert-box__buttons">
              {buttons?.map((button, index) => {
                // Note: If adding a new open-anywhere slideout here, also add it to AuthenticatedContainer!
                switch (button.target?.resourceType) {
                  case 'alarm':
                    return button.target.resourceUid && canEditAlarms ? (
                      <Button
                        key={index}
                        size="mini"
                        content={t('View Alarm')}
                        onClick={() => {
                          onClose();
                          dispatch(
                            addSlideOut({
                              routeConfig: {
                                ...alarmEditRouteConfig,
                                extraData: {
                                  alarmIdExt: button.target.resourceUid,
                                },
                              },
                              slideOutProps: {
                                requiredExtraData: ['alarmIdExt'],
                                pathname: null,
                                debug:
                                  window.sessionStorage.getItem(
                                    'op-history-debug',
                                  ),
                              },
                            }),
                          );
                        }}
                      />
                    ) : null;
                  case 'intercomNotification':
                    return (
                      <Button
                        key={index}
                        size="mini"
                        content={t('View call')}
                        onClick={() => button.onClick(onClose)}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </>
        ) : (
          children
        )
      }
      onDismiss={onClose}
    />
  );
};

AlertBox.propTypes = {
  type: PropTypes.oneOf([
    'error',
    'warning',
    'info',
    'success',
    'intercom',
    'alarmHigh',
    'alarmMedium',
    'alarmLow',
  ]),
  header: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.shape({
        resourceType: PropTypes.string,
        resourceUid: PropTypes.string,
      }),
      onClick: PropTypes.func,
    }),
  ),
};
