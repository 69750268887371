import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { t } from 'i18next';
import { OpModal } from 'new-components/DLS/OpModal/OpModal';
import { OnSubmitArgs, OpForm } from 'new-components/DLS/OpForm/OpForm';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { OpInfo } from 'new-components/DLS/OpInfo/OpInfo';
import { OpText } from 'new-components/DLS/OpText/OpText';

interface ParcelMailroomModalProps {
  isOpen: boolean;
  onClose(): void;
  onUpdate(): void;
  selectedIds: string[];
  orgId: number;
  extraInfo: string[];
}

/**
 * @component
 * @param {Boolen} isOpen - is the modal open or closed
 * @param {Function} onClose - callback to close the modal
 * @param {Function} onUpdate - callback to handle update success
 * @param {string[]} selectedIds - ids of the selected parcels
 * @param {string[]} emails - recipients emails for the selected parcels
 * @param {number} orgId - orgId number
 * @returns {JSX.Element} - modal containing a form to send emails to the recipients for the selected parcels
 */
export const ParcelMessageModal = ({
  isOpen,
  onClose,
  onUpdate,
  selectedIds,
  extraInfo,
  orgId,
}: ParcelMailroomModalProps) => {
  const [form] = OpForm.useForm();

  const hasRecipients = extraInfo.find((el) => el);

  const hasEmptyRecipients = extraInfo.includes('');

  const sendMessageMutation = useOpMutation({
    apiEndpointName: 'sendCustomParcelEmail',
    onSuccessMessage: t('Successfully messaged {{count}} recipients!', {
      count: selectedIds.length,
    }),
  });

  const handleSubmit = async ({ values }: OnSubmitArgs) => {
    const { subject, body } = values;

    const payload = {
      parcelIdExts: selectedIds,
      subject,
      body,
    };

    try {
      await sendMessageMutation.mutateAsync({
        apiEndpointRequirements: [orgId],
        payload,
      });

      onUpdate();
    } catch {
      onClose();
    }
  };

  const initialValues = {
    recipients: extraInfo.filter((element) => Boolean(element)),
    subject: t('Package reminder'),
    body: t('Please come pick up your package.'),
  };

  return (
    <OpModal
      onCancel={onClose}
      open={isOpen}
      width={750}
      title={
        extraInfo?.length > 1 ? t('Message recipients') : t('Message recipient')
      }
      okText={hasRecipients ? t('Send') : t('Ok')}
      onOk={hasRecipients ? form.submit : onUpdate}
      cancelButtonProps={!hasRecipients ? { style: { display: 'none' } } : {}}
      closeIcon={
        <CloseOutlined data-gtm={'parcel-mailroom-modal-close-icon'} />
      }
    >
      {hasRecipients ? (
        <OpForm
          className="parcel-modal-form"
          form={form}
          initialValues={initialValues}
          testId="modal-parcel-message-form"
          defaultButtons={false}
          hasError={false}
          onSubmit={handleSubmit}
        >
          <OpForm.TagInput
            label={t('Recipients')}
            name="recipients"
            testId="modal-parcel-recipients"
            maxTagCount={10}
            required
            disabled
          />
          {hasEmptyRecipients ? (
            <OpInfo
              message={
                <OpText inline>
                  {t(
                    'Some selected packages have no recipients associated to them. No message will be sent to them.',
                  )}
                </OpText>
              }
            />
          ) : (
            ''
          )}
          <OpForm.Input
            label={t('Subject')}
            name="subject"
            placeholder={t('Enter your subject here')}
            rules={[
              {
                required: true,
                message: t('Message subject is required'),
              },
            ]}
            required
          />
          <OpForm.TextAreaInput
            label={t('Body')}
            name="body"
            placeholder={t('Enter your message here')}
            rules={[
              {
                required: true,
                message: t('Message body is required'),
              },
            ]}
            required
          />
        </OpForm>
      ) : (
        <OpText inline>
          {t(
            'The selected packages have no recipients. Please select other packages to send messages to them.',
          )}
        </OpText>
      )}
    </OpModal>
  );
};
