import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'antd/es/alert';
import { useActivateMfaCredential } from 'utils/customHooks/api/useActivateMfaCredential';
import { FormOnSubmitType, OpForm } from 'new-components/DLS/OpForm/OpForm';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { OpImage } from 'new-components/DLS/OpImage/OpImage';
import { OpInput } from 'new-components/DLS/OpInput/OpInput';
import { OpModal } from 'new-components/DLS/OpModal/OpModal';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { OpDivider } from 'new-components/DLS/OpDivider/OpDivider';
import { useLogout } from 'utils/customHooks/useLogout';

import './MfaCredentialActivate.scss';

interface MfaCredentialActivateProps {
  currentMfaCredentialData?: Api.Response['createMfaCredential'];
  resetProfileSecurityTab(): void;
  identityId: number;
}

const MfaCredentialActivate = ({
  currentMfaCredentialData,
  resetProfileSecurityTab,
  identityId,
}: MfaCredentialActivateProps) => {
  const { t } = useTranslation();

  const { logout } = useLogout();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Check if the user has an mfa credential
  const { data: listMfaCredentialsResponse } = useOpQuery({
    apiEndpointName: 'listMfaCredentials',
    parameters: [identityId],
  });

  const hasNoActiveMfaCredentials =
    listMfaCredentialsResponse?.json?.data?.filter(
      ({ status }) => status === 'A',
    )?.length === 0;

  const activateMfaCredentialMutation = useActivateMfaCredential();

  if (!currentMfaCredentialData) return null;

  const { id: mfaCredentialId, totpSoftDevice } = currentMfaCredentialData;
  const { qrCodeDataUrl, secret } = totpSoftDevice!;

  const onSubmit: FormOnSubmitType = ({ values: { totpCode } }) => {
    activateMfaCredentialMutation.mutate(
      {
        apiEndpointRequirements: [identityId, mfaCredentialId],
        payload: {
          mfa: {
            totpCode,
          },
        },
      },
      {
        onSuccess: () => {
          if (hasNoActiveMfaCredentials) {
            // If this is the first created MFA credential open modal
            setModalIsOpen(true);
          } else {
            // If already have an MFA credential just reset
            resetProfileSecurityTab();
          }
        },
      },
    );
  };

  return (
    <div className="mfa-credential-activate">
      <OpSpace direction="vertical">
        <Alert
          message="This is the last time you'll see the QR code or secret key!"
          banner
        />

        <OpText className="mfa-credential-activate__text">
          {t(
            'Configure your virtual MFA application using either the QR code or secret key',
          )}
        </OpText>
        <div className="mfa-credential-activate__image-container">
          <OpImage width={200} src={qrCodeDataUrl!} preview={false} />
        </div>
        <OpInput.Password value={secret!} addonBefore="Secret key" readOnly />
      </OpSpace>

      <OpDivider />

      <OpSpace direction="vertical" size="middle">
        <OpText>
          {t(
            `After your application is configured, enter an authentication code in the box below and choose "Activate"`,
          )}
        </OpText>
        <OpForm
          initialValues={{ totpCode: '' }}
          onSubmit={onSubmit}
          hasError={false}
        >
          <OpForm.Input
            autoFocus
            name="totpCode"
            label={t('Enter 2FA Code')}
            rules={[{ required: true, min: 3 }]}
          />
        </OpForm>
      </OpSpace>

      {/** Confirmation modal for logout */}
      <OpModal
        title={t('Notice')}
        open={modalIsOpen}
        closable={false}
        okText={t('Got it!')}
        onOk={() => {
          // Sign user out
          logout();
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {t(
          'You will now be signed out as the first MFA device was activated and it must be used to sign in.',
        )}
      </OpModal>
    </div>
  );
};

export default MfaCredentialActivate;
