import { appColorThemeManager } from 'utils/appColorThemeManager';

export const getAppColorTheme = () => {
  const colorTheme = (localStorage.getItem('colorTheme') || "'light'") as
    | "'light'"
    | "'dark'"
    | "'system'";

  try {
    return appColorThemeManager.getColorThemeForProvider(
      JSON.parse(colorTheme),
    );
  } catch {
    return 'light';
  }
};
