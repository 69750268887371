import {
  LIST,
  OPTIONS,
  SET_OMNISELECT_LOADING,
  REQUEST_OMNISELECT_DATA,
  SET_OMNISELECT_LIST,
  SET_OMNISELECT_OPTIONS,
  ADD_OMNISELECT_ADD,
  ADD_OMNISELECT_REMOVE,
  REMOVE_OMNISELECT_REMOVE,
  REMOVE_OMNISELECT_ADD,
  RESET_OMNISELECT_STATE,
  SET_OMNISELECT_COUNT,
} from './constants';

export function setOmniSelectCount({ page, omniId = null, count }) {
  return {
    type: SET_OMNISELECT_COUNT,
    page,
    omniId,
    count,
  };
}

export function resetOmniSelectState({ page, omniId = null }) {
  return {
    type: RESET_OMNISELECT_STATE,
    page,
    omniId,
  };
}

export function addOmniSelectAdd({ page, omniId = null, add }) {
  return {
    type: ADD_OMNISELECT_ADD,
    page,
    omniId,
    add,
  };
}

export function removeOmniSelectAdd({ page, omniId = null, add }) {
  return {
    type: REMOVE_OMNISELECT_ADD,
    page,
    omniId,
    add,
  };
}

export function removeOmniSelectRemove({ page, omniId = null, remove }) {
  return {
    type: REMOVE_OMNISELECT_REMOVE,
    page,
    omniId,
    remove,
  };
}

export function addOmniSelectRemove({ page, omniId = null, remove }) {
  return {
    type: ADD_OMNISELECT_REMOVE,
    page,
    omniId,
    remove,
  };
}

export function setOmniSelectLoading({ page, omniId = null, loading }) {
  return {
    type: SET_OMNISELECT_LOADING,
    page,
    omniId,
    loading,
  };
}

export function requestOmniSelectData({
  page,
  omniId = null,
  filter = null,
  apiData,
}) {
  return {
    type: REQUEST_OMNISELECT_DATA,
    page,
    omniId,
    filter,
    apiData,
  };
}

export function setOmniSelectList({ page, omniId = null, data }) {
  return {
    type: SET_OMNISELECT_LIST,
    page,
    omniId,
    [LIST]: data,
  };
}

export function setOmniSelectOptions({ page, omniId = null, data }) {
  return {
    type: SET_OMNISELECT_OPTIONS,
    page,
    omniId,
    [OPTIONS]: data,
  };
}
