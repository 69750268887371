import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const UpperControls = memo(({ content = {}, isHovering }) => {
  const { left, center, right } = content;

  const styles = useMemo(
    () => ({
      upperControls: {
        display: 'flex',
        width: '100%',
        position: 'absolute',
        top: 0,
        paddingBottom: 52.5,
        color: 'var(--colorWhite)',
        zIndex: 1, // Allows for webRtcButton and entryUnlockButton to be visible/usable
        background: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0))',
        opacity: 0,
        transition: 'opacity 0.3s',
        padding: '0 11px',

        // Only show background on hover or if something is persisted
        // We use opacity so we can fade in/out
        ...((isHovering ||
          left?.isPersisted ||
          center?.isPersisted ||
          right?.isPersisted) && {
          opacity: 1,
        }),
      },
    }),
    [center?.isPersisted, isHovering, left?.isPersisted, right?.isPersisted],
  );

  // Return null if no content to display
  if (!left?.content && !center?.content && !right?.content) {
    return null;
  }

  const fadeInStyles = {
    opacity: isHovering ? 1 : 0,
    transition: 'opacity 0.3s',
  };

  return (
    <div style={styles.upperControls}>
      <div
        className="op-flex-1 op-justify-content-flex-start"
        style={{ ...left?.style, ...(!left?.isPersisted && fadeInStyles) }}
      >
        {left?.content}
      </div>
      {center && (
        <div
          className="op-flex-1 op-justify-content-center"
          style={{
            ...center?.style,
            ...(!center?.isPersisted && fadeInStyles),
          }}
        >
          {center?.content}
        </div>
      )}
      <div
        className="op-flex-1 op-justify-content-flex-end"
        style={{ ...right?.style, ...(!right?.isPersisted && fadeInStyles) }}
      >
        {right?.content}
      </div>
    </div>
  );
});

UpperControls.displayName = 'UpperControls';

UpperControls.propTypes = {
  content: PropTypes.shape({
    left: PropTypes.shape({
      isPersisted: PropTypes.bool,
      content: PropTypes.any,
      style: PropTypes.object,
    }),
    center: PropTypes.shape({
      isPersisted: PropTypes.bool,
      content: PropTypes.any,
      style: PropTypes.object,
    }),
    right: PropTypes.shape({
      isPersisted: PropTypes.bool,
      content: PropTypes.any,
      style: PropTypes.object,
    }),
  }),
  isHovering: PropTypes.bool,
};

export default UpperControls;
