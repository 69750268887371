import { AvigilonLogoMarkSvg } from 'components/svgs/AvigilonLogoMarkSvg/AvigilonLogoMarkSvg';

import './ProductBranding.scss';

export const ProductBranding = ({
  label,
  testId,
}: {
  label: string;
  testId: string;
}) => {
  return (
    <div className="product-branding" data-testid={testId}>
      <AvigilonLogoMarkSvg />
      <div className="product-branding__text">{label}</div>
    </div>
  );
};
