import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'semantic-ui-react/dist/es/elements/Icon/Icon';

const styles = {
  liveText: {
    textShadow: '0px 0px 1px #000000',
  },
};

const LiveButton = memo(({ duration, currentTime }) => (
  <div className="live-button">
    <Icon
      className="live-button-icon"
      size="tiny"
      color={
        duration === Number.POSITIVE_INFINITY || duration - currentTime < 2
          ? 'red'
          : 'grey'
      }
      name="circle"
    />
    <div style={styles.liveText}>Live</div>
  </div>
));

LiveButton.displayName = 'LiveButton';

LiveButton.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};

export default LiveButton;
