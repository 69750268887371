import { useLocation } from 'react-router-dom-v5-compat';
import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { getOpParams } from 'utils/getOpParams';
import { sharedRoutes } from 'new-components/OpAppScaffold/constants';
import { findNavDefaultKeys } from 'new-components/OpNav/helpers/findNavDefaultKeys';

export const useCreateAppDefaultKeys = ({
  rawMenuItems,
}: {
  rawMenuItems: OpRawMenuItemType[];
}) => {
  const { pathname } = useLocation();
  const { orgId } = getOpParams();
  let pathToMatch = '';

  if (orgId) {
    // Extract everything after '/o/{number}/' but before query parameters
    const match = pathname.match(/\/o\/\d+\/([^?]+)/);
    pathToMatch = match ? match[1] : '';
  }

  return findNavDefaultKeys({
    rawMenuItems,
    pathToMatch,
    notFoundPrimaryKey: sharedRoutes.pageNotFound,
  });
};
