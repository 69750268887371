import gsuiteLogo from 'assets/img/g-suite-white-66.png';
import msazureadLogo from 'assets/img/azure-active-directory-white-66.png';
import oktaLogo from 'assets/img/okta-white-66.png';
import oneloginLogo from 'assets/img/onelogin-white-66.png';
import workdayLogo from 'assets/img/workday-logo.svg';

export const PAGE = 'integrationsPage';

export const REQUEST_GET_INITIAL_DATA = `app/${PAGE}/REQUEST_GET_INITIAL_DATA`;
export const SET_IDENTITY_PROVIDER_TYPES = `app/${PAGE}/SET_IDENTITY_PROVIDER_TYPES`;
export const SET_IDENTITY_PROVIDERS = `app/${PAGE}/SET_IDENTITY_PROVIDERS`;
export const REQUEST_GET_IDENTITY_PROVIDERS = `app/${PAGE}/REQUEST_GET_IDENTITY_PROVIDERS`;
export const REQUEST_GET_IDENTITY_PROVIDER_BY_ID = `app/${PAGE}/REQUEST_GET_IDENTITY_PROVIDER_BY_ID`;
export const REQUEST_SYNC_IDENTITY_PROVIDER = `app/${PAGE}/REQUEST_SYNC_IDENTITY_PROVIDER`;
export const REQUEST_UPSERT_IDENTITY_PROVIDER = `app/${PAGE}/REQUEST_UPSERT_IDENTITY_PROVIDER`;
export const REQUEST_IDP_REAUTH = `app/${PAGE}/REQUEST_IDP_REAUTH`;
export const OAUTH_REQUEST_CALLBACK = `app/${PAGE}/OAUTH_REQUEST_CALLBACK`;
export const OAUTH_SET_AUTH_URL = `app/${PAGE}/OAUTH_SET_AUTH_URL`;
export const REQUEST_GET_GROUPS = `app/${PAGE}/REQUEST_GET_GROUPS`;
export const REQUEST_GET_GROUP_RELATIONS = `app/${PAGE}/REQUEST_GET_GROUP_RELATIONS`;
export const REQUEST_GET_IDP_GROUPS = `app/${PAGE}/REQUEST_GET_IDP_GROUPS`;
export const SET_IDENTITY_PROVIDER_GROUPS = `app/${PAGE}/SET_IDENTITY_PROVIDER_GROUPS`;
export const SET_GROUP_RELATIONS = `app/${PAGE}/SET_GROUP_RELATIONS`;
export const INACTIVATE_IDP = `app/${PAGE}/INACTIVATE_IDP`;
export const REQUEST_CREATE_VIDEO_PROVIDER = `app/${PAGE}/REQUEST_CREATE_VIDEO_PROVIDER`;
export const REQUEST_UPDATE_VIDEO_PROVIDER = `app/${PAGE}/REQUEST_UPDATE_VIDEO_PROVIDER`;

export const logos = {
  gsuite: { src: gsuiteLogo, backgroundColor: '#767676', height: 22 },
  msazuread: { src: msazureadLogo, backgroundColor: '#00ABF5', height: 33 },
  okta: { src: oktaLogo, backgroundColor: '#007DC1', height: 22 },
  // see https://www.onelogin.com/press-center/press-kit - bg color is "OneLogin Black"
  onelogin: { src: oneloginLogo, backgroundColor: '#1C1F2A', height: 33 },
  workday: {
    src: workdayLogo,
    backgroundColor: '#005BB8',
    containerStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    style: {
      padding: '10px 0',
      position: 'relative',
      left: '18px',
    },
  },
};
