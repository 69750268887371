import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormOnSubmitType, OpForm } from 'new-components/DLS/OpForm/OpForm';
import { useUpdateIdentity } from 'utils/customHooks/api/useUpdateIdentity';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';

export default () => {
  const { t } = useTranslation();

  const { identityId } = useIdentityAndUser();

  const updateIdentityMutation = useUpdateIdentity();

  const onSubmit: FormOnSubmitType = ({ values }) => {
    updateIdentityMutation.mutate({
      apiEndpointRequirements: [identityId!],
      payload: {
        oldPassword: values.oldPassword,
        password: values.newPassword,
      },
    });
  };

  return (
    <OpForm
      hasError={false}
      initialValues={{ oldPassword: '', newPassword: '' }}
      onSubmit={onSubmit}
      testId="profile-password-form"
    >
      <OpForm.PasswordInput
        autoFocus
        name="oldPassword"
        label={t('Old Password')}
        rules={[
          {
            required: true,
          },
        ]}
      />
      <OpForm.PasswordInput
        enforcePolicy
        name="newPassword"
        label={t('New Password')}
        rules={[
          {
            required: true,
          },
        ]}
      />
    </OpForm>
  );
};
