import { ComponentProps } from 'react';
import Icon from '@ant-design/icons/es/components/Icon';
import { OpDeviceStatusIcon } from './icons/OpDeviceStatusIcon/OpDeviceStatusIcon';
import { OpBatteryIcon } from './icons/OpBatteryIcon/OpBatteryIcon';
import { OpCircleIcon } from './icons/OpCircleIcon/OpCircleIcon';
import { OpDonutIcon } from './icons/OpDonutIcon/OpDonutIcon';
import { OpExclamationIcon } from './icons/OpExclamationIcon/OpExclamationIcon';
import { OpXMarkIcon } from './icons/OpXMarkIcon/OpXMarkIcon';
import { OpSignalQualityIcon } from './icons/OpSignalQualityIcon/OpSignalQualityIcon';

export type CustomIconComponentProps = ComponentProps<typeof Icon>;

export const OpIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon {...props} />;
};

OpIcon.Battery = OpBatteryIcon;
OpIcon.Circle = OpCircleIcon;
OpIcon.DeviceStatus = OpDeviceStatusIcon;
OpIcon.Donut = OpDonutIcon;
OpIcon.Exclamation = OpExclamationIcon;
OpIcon.SignalQuality = OpSignalQualityIcon;
OpIcon.XMark = OpXMarkIcon;
