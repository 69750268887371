export const SET_ORG = 'app/Billing/SET_ORG';
export const SET_SAVING_BILLING_SUBSCRIPTION_INFO =
  'app/Billing/SET_SAVING_BILLING_SUBSCRIPTION_INFO';
export const REQUEST_LOAD_BILLING_FORM_VALUES =
  'app/Billing/REQUEST_LOAD_BILLING_FORM_VALUES';
export const REQUEST_EDIT_BILLING_SUBSCRIPTION =
  'app/Billing/REQUEST_EDIT_BILLING_SUBSCRIPTION';
export const SET_TOS_INFORMATION = 'app/Billing/SET_TOS_INFORMATION';
export const REQUEST_SIGN_TERMS_OF_SERVICE =
  'app/Billing/REQUEST_SIGN_TERMS_OF_SERVICE';
export const REQUEST_GET_BILLING_INFORMATION =
  'app/Billing/REQUEST_GET_BILLING_INFORMATION';
export const REQUEST_SAML_APP_LOGIN = 'app/Billing/REQUEST_SAML_APP_LOGIN';
export const REQUEST_ERP_SAML_APP_LOGIN =
  'app/Billing/REQUEST_ERP_SAML_APP_LOGIN';
export const REQUEST_UPDATE_BILLABLE_FEATURES =
  'app/Billing/REQUEST_UPDATE_BILLABLE_FEATURES';
export const INVOICES_TABLE = 'invoicesTable';
export const INVOICES_TABLE_PAGE_LENGTH = 25; // 2 years * 12 months/year + 1 extra

export const REQUEST_ADD_QUANTITY_FORM =
  'app/Billing/REQUEST_ADD_QUANTITY_FORM';
export const GO_TO_STORE_FORM = 'app/Billing/GO_TO_STORE_FORM';

// license helpers
export const REQUEST_ADD_USER_LICENSES =
  'app/Billing/REQUEST_ADD_USER_LICENSES';
