import { OpText } from 'new-components/DLS/OpText/OpText';
import { useTranslation } from 'react-i18next';
import avigilonAltaLogo from 'assets/img/avigilon-alta-logo-light.svg';

import './OpPageNotFound.scss';

/**
 * @component Page not Found component will redirect to the 404
 * route if using this component on that route
 * @returns 404 page
 */
export const OpPageNotFound = () => {
  const { t } = useTranslation();

  const error = t('404');
  const heading = t('You seem to have veered off course');
  const subheading = t(
    "The page you are looking for doesn't\nexist or you do not have access.",
  );
  return (
    <div className="error-container" data-testid="op-page-not-found">
      <OpText size="large" fontWeight="700" className="error-container__text">
        {error}
      </OpText>
      <OpText fontWeight="500" className="error-container__heading">
        {heading}
      </OpText>
      <OpText className="error-container__subheading">{subheading}</OpText>
      <img
        className="error-container__alta-logo"
        src={avigilonAltaLogo}
        alt="Logo"
      />
    </div>
  );
};
