/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  memo,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useMicrophonePermission, useSelectorJs } from 'utils/customHooks';
import { heliumReceive } from 'utils/helpers';
import {
  selectCurrentOrgId,
  selectCurrentUserId,
} from 'global/accessToken/selectors';
import { EntryUnlockButton } from 'containers/EntryUnlockButton';
import { useTranslation } from 'react-i18next';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { opMoment } from 'utils/dates';
import LiveKitVideoPlayer from 'new-components/LiveKitVideoPlayer/LiveKitVideoPlayer';
import {
  useVideoState,
  VideoStateProvider,
  VideoEvents,
  VideoStates,
} from '@openpathsec/opvideo-state-machine';
import { setAlert } from 'routes/AppContainer/actions';
import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import {
  VideoPlayer,
  WebRtcButton,
  MicrophoneToggleButton,
} from './components';
import { useHls } from './helpers/useHls';
import { callStates, connectionStates } from './constants';
import VideoDownloadButton from './components/VideoDownloadButton';

const CLIP_NOT_FOUND_MSG = 'No clip found';
const HELP_MSG =
  'Most likely this means the event was deleted due to the retention period elapsing';

const clipPlaybackError = (t) => (
  <div className="op-flex-direction-column op-fully-centered-content">
    <div key="1" className="op-margin-bottom-22">
      {t(CLIP_NOT_FOUND_MSG)}
    </div>
    <div key="2">{t(HELP_MSG)}</div>
  </div>
);

// VideoState context should be set to OpVideoPlayer and accessible both to OpVideoPlayer and its children.
const WrappedOpVideoPlayer = (props) => {
  return (
    <VideoStateProvider>
      <OpVideoPlayer {...props} />
    </VideoStateProvider>
  );
};

const OpVideoPlayer = ({
  opvideoDeviceId,
  autoPlay = true,
  controls = true,
  isLive = false,
  isIncomingCall = false,
  startAt = '', // Date string (e.g. 2022-03-30T18:26:23.000Z)
  endAt = '', // Date string (e.g. 2022-03-30T18:26:34.968Z)
  unixTimestamp = 0, // Timestamp in seconds
  isClip = false,
  livekitAuth = null,
  livekitRoom = null,
  // Passed through to <video>
  ...videoPlayerProps
}) => {
  const { t } = useTranslation();

  /*
    LiveKit:
    Both preview and call run under the same LiveKit call.
    When you hit the "call" button, the mic is enabled
  */
  const [connectionState, setConnectionState] = useState(
    connectionStates.DISCONNECTED,
  );
  const [callState, setCallState] = useState(callStates.PREVIEW);

  const [errorProps, setErrorProps] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [playerValues, setPlayerValues] = useState({});
  const [initialVolume, setInitialVolume] = useState(isLive ? 0 : 1);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(false);
  const [shouldStopVideoCall, setShouldStopVideoCall] = useState(false);
  const [hasShownMicAlert, sethasShownMicAlert] = useState(false);

  const playerRef = useRef(null);
  const dispatch = useDispatch();
  const micPermission = useMicrophonePermission();
  const orgId = useSelectorJs(selectCurrentOrgId());
  const currentUserId = useSelectorJs(selectCurrentUserId());
  const currentIdentityLanguage = useSelectorJs(
    selectCurrentIdentityLanguage(),
  );
  const isVideoStateMachineEnabled = useSelectorJs(
    selectFeatureFlag('IS_VIDEO_STATE_MACHINE_ENABLED'),
  );

  const { opvideoDevice, cameraStaleSnapshot } = playerValues || {};

  const { videoState, sendEvent } = useVideoState();
  const { value: currentVideoState, context: videoStateContext } = videoState;
  const micPermissionStatus = useMicrophonePermission();

  const handleMicState = useCallback(() => {
    if (isVideoStateMachineEnabled) {
      sendEvent({
        type: videoStateContext.micEnabled
          ? VideoEvents.MicDisabled
          : VideoEvents.MicEnabled,
      });
    }
    setIsMicrophoneEnabled((prevState) => !prevState);
  }, [isVideoStateMachineEnabled, sendEvent, videoStateContext.micEnabled]);

  const isMicEnabled = useCallback(() => {
    return isVideoStateMachineEnabled
      ? videoStateContext.micEnabled
      : isMicrophoneEnabled;
  }, [
    isVideoStateMachineEnabled,
    videoStateContext.micEnabled,
    isMicrophoneEnabled,
  ]);

  // @ws TODO: drop opvideoDeviceStreamingSubscriberToken scope checks littered throughout codebase and make them camera-specific not opvideoDevice-specific 🙃

  /*
    Destructuring here to prevent issues when items are null
    (as default prop only works when value is undefined)
  */
  const {
    cameraId,
    opvideoIntercom,
    recordPreEventSeconds,
    recordPostEventSeconds,
  } = opvideoDevice || {};
  const { id: opvideoIntercomId } = opvideoIntercom || {};
  const { blurredThumbnailUrl } = cameraStaleSnapshot || {};

  /*
    Create startAt and endAt values
    As this is only used for entry unlock events we are not taking clip length
    into consideration and simply using the pre/post recording buffer to create
    a consistent length clip
  */
  const startDate =
    startAt ||
    opMoment.unix(unixTimestamp - recordPreEventSeconds).toISOString();
  const endDate =
    endAt ||
    opMoment.unix(unixTimestamp + recordPostEventSeconds).toISOString();

  useHls({
    playerRef,
    autoPlay,
    startDate,
    endDate,
    errorProps,
    setErrorProps,
    setIsLoading,
    orgId,
    opvideoDeviceId,
    shouldRun:
      !isLive &&
      playerRef.current &&
      !errorProps &&
      recordPreEventSeconds &&
      recordPostEventSeconds,
  });

  // Load data needed for player once on mount
  useEffect(() => {
    if (isVideoStateMachineEnabled) {
      sendEvent({ type: VideoEvents.ConnectionStart });
    }
    // We don't run setup if we have bad data
    if (!isLive && !((startAt && endAt) || unixTimestamp)) {
      /*
        If a unixTimestamp doesn't exist this means we most likely got an
        invalid startTime from the URL param. This most likely means the event
        no longer exists due to the event being outside the retention period.
      */
      setErrorProps({
        errorMessage: clipPlaybackError(t),
        handleRetryClick: undefined,
      });

      console.error(
        'Neither startDate, endDate, or unixTimestamp were provided. Most likely this means that an invalid URL param `startTime` was provided (normally due the event being deleted as it is not within the retention period).',
      );

      if (isVideoStateMachineEnabled) {
        sendEvent({ type: VideoEvents.ConnectionFailed });
      }

      return;
    }

    // Do nothing if we don't have the orgId
    if (!orgId) {
      return;
    }

    // Player setup
    (async () => {
      const {
        data: opvideoDevice,
        localizedErrorMessage: describeOpvideoDeviceErrorMessage,
      } = await heliumReceive(
        'describeOpvideoDevice',
        [orgId, opvideoDeviceId],
        {
          headers: {
            'Accept-Language': currentIdentityLanguage,
          },
        },
      );

      if (describeOpvideoDeviceErrorMessage) {
        setErrorProps({ errorMessage: describeOpvideoDeviceErrorMessage });
        if (isVideoStateMachineEnabled) {
          sendEvent({ type: VideoEvents.ConnectionFailed });
        }
        return;
      }

      const { acuPort, cameraId } = opvideoDevice;
      const acuId = acuPort?.acu?.id;

      /*
        If we don't have an acuId something went wrong and we fail fast
        We don't need to show a player error here as this doesn't block playback/stream
      */
      if (!acuId || !cameraId) {
        console.error(
          'Something wrong with the opvideoDevice response object. No acuId or cameraId',
        );
        if (isVideoStateMachineEnabled) {
          sendEvent({ type: VideoEvents.ConnectionFailed });
        }
        return;
      }

      const [{ data: cameraStaleSnapshot }] = await Promise.all([
        heliumReceive('cameraStaleSnapshot', [orgId, cameraId], {
          headers: {
            'Accept-Language': currentIdentityLanguage,
          },
        }),
      ]);

      setPlayerValues((prevPlayerValues) => ({
        ...prevPlayerValues,
        opvideoDevice,
        cameraStaleSnapshot,
      }));

      if (isVideoStateMachineEnabled) {
        sendEvent({ type: VideoEvents.ConnectionSuccessful });
        if (isIncomingCall) {
          sendEvent({ type: VideoEvents.IncomingCall });
        }
      }
    })();
  }, [
    currentIdentityLanguage,
    endAt,
    isVideoStateMachineEnabled,
    isLive,
    opvideoDeviceId,
    orgId,
    sendEvent,
    startAt,
    t,
    unixTimestamp,
    isIncomingCall,
  ]);

  // Destroy the player on unmount
  useEffect(
    () => () => {
      // Destroy playerRef
      if (playerRef.current) {
        playerRef.current = null;
      }
    },
    [],
  );

  useEffect(() => {
    if (isVideoStateMachineEnabled) {
      sendEvent({
        type:
          micPermissionStatus === 'granted'
            ? VideoEvents.MicPermissionEnabled
            : VideoEvents.MicPermissionDisabled,
      });
    }
  }, [micPermissionStatus, isVideoStateMachineEnabled, sendEvent]);

  // Intercom button click handler
  const intercomCallButtonClick = useCallback(() => {
    switch (connectionState) {
      case connectionStates.CONNECTED:
        if (callState === callStates.PREVIEW) {
          setCallState(callStates.CALL);
          handleMicState();
        }
        if (callState === callStates.CALL) {
          setCallState(callStates.PREVIEW);
        }
        break;
      case connectionStates.DISCONNECTED:
        setCallState(callStates.DISCONNECTED);
        break;
      case connectionStates.CONNECTING || connectionStates.CONNECTION_ERROR:
        setConnectionState(connectionStates.DISCONNECTED);
        break;
      default:
    }

    if (isVideoStateMachineEnabled) {
      switch (currentVideoState) {
        case VideoStates.SURVEILLANCE:
          sendEvent({ type: VideoEvents.StartCall });
          break;
        case VideoStates.PREVIEW:
          sendEvent({ type: VideoEvents.JoinCall });
          break;
        case VideoStates.CALL:
          sendEvent({ type: VideoEvents.VideoDetails });
          break;
        default:
      }
    }
  }, [
    callState,
    connectionState,
    currentVideoState,
    isVideoStateMachineEnabled,
    sendEvent,
    handleMicState,
  ]);

  // Manage video volume
  useEffect(() => {
    if (connectionState === connectionStates.CONNECTED) {
      if (callState === callStates.PREVIEW) {
        setInitialVolume(0);
      }
      if (callState === callStates.CALL) {
        setInitialVolume(1);
      }
    }
  }, [callState, connectionState]);

  useEffect(() => {
    if (
      !isClip &&
      connectionStates.CONNECTED &&
      callState === callStates.PREVIEW &&
      micPermission === 'denied' &&
      !hasShownMicAlert
    ) {
      dispatch(setAlert('warning', t(`Browser microphone permission denied.`)));
      sethasShownMicAlert(true);
    }
  }, [callState, dispatch, hasShownMicAlert, isClip, micPermission, t]);

  const upperControlsContent = useMemo(() => {
    /*
      We are linking upper and lower controls together so we don't want
      to show the upper controls if controls is set to false
    */
    if (!controls) return null;

    return {
      ...(!!opvideoIntercomId &&
        (connectionState === connectionStates.CONNECTED ||
          callState === callStates.PREVIEW) &&
        !isClip && {
          center: {
            isPersisted: true,
            content: (
              <WebRtcButton
                onClick={intercomCallButtonClick}
                connectionState={connectionState}
                callState={callState}
                micPermission={micPermission}
              />
            ),
            style: { flex: 0, padding: '0 11px' },
          },
        }),

      ...((connectionState === connectionStates.CONNECTED ||
        (isLive && !!cameraId) ||
        !isLive) && {
        right: {
          isPersisted: isLive,
          content: (
            <>
              {connectionState === connectionStates.CONNECTED &&
                callState === callStates.CALL && (
                  <MicrophoneToggleButton
                    toggleHandler={handleMicState}
                    initState={isMicEnabled()}
                  />
                )}
              {isLive && Boolean(cameraId) && (
                <EntryUnlockButton cameraId={cameraId} />
              )}
              {!isLive && startDate && endDate && (
                <VideoDownloadButton
                  orgId={orgId}
                  opvideoDeviceId={opvideoDeviceId}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </>
          ),
        },
      }),
    };
  }, [
    controls,
    isClip,
    callState,
    micPermission,
    opvideoIntercomId,
    connectionState,
    intercomCallButtonClick,
    isLive,
    cameraId,
    startDate,
    endDate,
    orgId,
    opvideoDeviceId,
    handleMicState,
    isMicEnabled,
  ]);

  const preventedHotkeys = useMemo(
    () => (isLive ? [' ', 'ArrowLeft', 'ArrowRight'] : []),
    [isLive],
  );

  const hiddenElements = useMemo(() => {
    const elementsToHide = [];

    // Hide items when live streaming
    if (isLive) {
      elementsToHide.push(
        'BigPlayButton',
        'PlayButton',
        'SeekBar',
        'TimeDisplay',
      );
    } else {
      elementsToHide.push('LiveButton');
    }

    // Hide volume button if not an intercom or if the mic is not enabled
    if (
      !opvideoIntercomId ||
      !opvideoDevice?.opvideoIntercom?.isMicrophoneEnabled
    ) {
      elementsToHide.push('VolumeButton');
    }

    return elementsToHide;
  }, [
    isLive,
    opvideoIntercomId,
    opvideoDevice?.opvideoIntercom?.isMicrophoneEnabled,
  ]);

  const videoPlayerErrorProps = useMemo(
    () => ({
      handleRetryClick: () => {
        setErrorProps(null);
        setIsLoading(true);
      },
      ...errorProps,
    }),
    [errorProps],
  );

  // Stop loader if there is an error
  useEffect(() => {
    if (errorProps) {
      setIsLoading(false);
    }
  }, [errorProps]);

  // Updates on callState changes
  useEffect(() => {
    if (
      shouldStopVideoCall &&
      connectionState === connectionStates.DISCONNECTED &&
      opvideoIntercomId
    ) {
      setShouldStopVideoCall(false);
      setInitialVolume(0);
    }
    // Clear any errors so we can view intercom call unobstructed
    if (connectionState === connectionStates.CONNECTED) {
      setErrorProps(null);
      if (isVideoStateMachineEnabled) {
        sendEvent({ type: VideoEvents.VideoDetails });
      }
    }
  }, [
    connectionState,
    currentIdentityLanguage,
    currentUserId,
    isVideoStateMachineEnabled,
    opvideoIntercomId,
    orgId,
    sendEvent,
    shouldStopVideoCall,
  ]);

  // (I am so sorry for tacking on another useEffect instead of fundamentally solving this, I tried and just about lost my mind)
  useEffect(() => {
    if (!playerRef?.current) return;
    const videoElement = playerRef.current;

    const endLoading = () => {
      setIsLoading(false);
    };

    videoElement.addEventListener('canplay', endLoading);
    videoElement.addEventListener('timeupdate', endLoading);

    // cleanup
    return () => {
      videoElement.removeEventListener('canplay', endLoading);
      videoElement.removeEventListener('timeupdate', endLoading);
    };
  }, []);

  return (
    <div className="op-line-height-normal" data-testid="opvideoplayer">
      {' '}
      {/** Prevents alignment issues caused by .ui.dimmer */}
      <VideoPlayer
        ref={playerRef}
        isLoading={isLoading}
        muted // Must mute when possible so that autoplay is most consistent
        playsInline
        preventVideoClick
        poster={blurredThumbnailUrl}
        {...{
          autoPlay,
          controls,
          upperControlsContent,
          preventedHotkeys,
          hiddenElements,
          initialVolume,
          ...(errorProps && {
            errorProps: videoPlayerErrorProps,
          }),
          // Everything else gets passed through
          ...videoPlayerProps,
        }}
      />
      {!isClip && (
        <LiveKitVideoPlayer
          ref={playerRef}
          {...{
            room: livekitRoom,
            token: livekitAuth?.token,
            wsUrl: livekitAuth?.wsUrl,
            callState,
            connectionState,
            isMicrophoneEnabled: isMicEnabled(),
            setCallState,
            setConnectionState,
            setErrorProps,
            setIsLoading,
            orgId,
            opvideoDeviceId,
            currentUserId,
            currentIdentityLanguage,
          }}
        />
      )}
    </div>
  );
};

OpVideoPlayer.propTypes = {
  opvideoDeviceId: PropTypes.number.isRequired,
  // this is getting spread....
  // eslint-disable-next-line react/no-unused-prop-types
  poster: PropTypes.string,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  // this is getting spread....
  // eslint-disable-next-line react/no-unused-prop-types
  aspectRatio: PropTypes.number,
  isLive: PropTypes.bool,
  isClip: PropTypes.bool,
  isIncomingCall: PropTypes.bool,
  startAt: PropTypes.string, // Date/time string
  endAt: PropTypes.string, // Date/time string
  unixTimestamp: PropTypes.number, // Seconds since epoch
  livekitAuth: PropTypes.any,
  livekitRoom: PropTypes.any,
};

export default memo(WrappedOpVideoPlayer);
