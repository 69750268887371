import { TableState } from 'new-components/DLS/OpTable/OpTable';
import { OpTableColumn } from 'new-components/DLS/OpTableCore/OpTableCore';

type SemanticUiTableFilter = {
  accessor?: string;
  id?: string;
  value: string | number | null;
};

export const removeHiddenColumnsFromFilters = (
  columns: OpTableColumn[],
  filters: TableState['filters'],
) => {
  // remove filters for hidden columns
  const newFilters = { ...(filters ?? {}) };
  const filterKeys = Object.keys({ ...newFilters });

  filterKeys.forEach((filterKey) => {
    // check if column is hidden
    const matchingCol = columns.find((col) => col.key === filterKey);
    if (matchingCol?.hidden) {
      newFilters[filterKey] = null;
    }
  });

  return newFilters;
};

export const removeHiddenColumnsFromFiltersOldTable = (
  column: string,
  filters: SemanticUiTableFilter[] = [],
) => {
  // remove filters for hidden columns
  const newFilters = [...filters];
  const filterIdx = newFilters.findIndex(
    (filter) => filter.id === column || filter.accessor === column,
  );

  if (filterIdx > -1) {
    newFilters.splice(filterIdx, 1);
  }

  return newFilters;
};
