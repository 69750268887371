import { useCallback, useMemo } from 'react';

import { useOxygenSocket } from 'utils/customHooks';

// only subscribe to these events
const UI_NOTIFICATION_EVENT = 'ui.notification';
const OXYGEN_CLIENT_ID = 'ui-notifications';

// note this does not connect on load, you have to use your own useEffect() to do initial connect

/**
 * @param {number} args.userId [Required] The current user's ID
 * @param {string} args.token [Required] The current user's Oxygen token
 * @param {function(titleText: string, subtitleText: string, type: string, icon: string, buttons, recipientUserIds, recipientRoleIds)} args.displayUiNotification Called every time we want to visually display a notification; natural refactor point
 */
const useUiNotifications = ({
  orgId,
  userId,
  token,
  displayUiNotification,
}) => {
  /* eslint-disable no-console */

  const subscriptionData = useMemo(
    () => [
      {
        preFilter: {
          hookEventType: 'ui.notification',
          userId,
        },
        trigger: {
          properties: {
            required: ['data'],
            data: {
              properties: {
                originUserId: {
                  not: {
                    enum: [userId],
                  },
                },
              },
            },
          },
        },
        clientData: {},
      },
    ],
    [userId],
  );

  // this will get called once for each hookEvent
  const handleEvent = useCallback(
    ({
      event: eventName,
      data: { titleText, subtitleText, type, icon, buttons },
    }) => {
      // filter down to just ui notifications we care about
      // technically we should never hit this because we're prefiltering, but it's not impossible
      if (eventName !== UI_NOTIFICATION_EVENT) {
        // console.log('ui notifications got unhandled event', eventName, data)
        return;
      }

      displayUiNotification(titleText, subtitleText, type, icon, buttons);
    },
    [displayUiNotification],
  );

  // this gets called every time we get an Oxygen message
  const onMessage = useCallback(
    (event) => {
      if (!event || !event.data) return;

      try {
        const parsed = JSON.parse(event.data);
        const { type, data } = parsed;

        // console.log('ui notifications got event', event?.data)

        // grab event batches from the Oxygen message
        // pass them each to handleEvent
        if (type === 'events' && data && Array.isArray(data)) {
          data.forEach(handleEvent);
        }
      } catch (e) {
        console.error('got unhandled error, probably invalid json', e, event);
      }
    },
    [handleEvent],
  );

  // when Oxygen successfully connects, call this function
  // this function binds the onMessage function to each socket message we get
  const onConnectionHandler = useCallback(
    (socket) => {
      // console.log('connected to oxygen for ui notifications')
      return socket.addEventListener('message', onMessage);
    },
    [onMessage],
  );

  const {
    isConnected,
    connect: oxyConnect,
    disconnect,
  } = useOxygenSocket({
    orgId,
    onConnectionHandler,
    subscriptionData,
    clientRequestId: OXYGEN_CLIENT_ID,
    token,
  });

  // wrap the underlying Oxygen connect() method to disconnect other tabs
  const connect = useCallback(() => {
    if (userId) {
      // Connect this tab to Oxygen
      oxyConnect();
    } else {
      console.debug('Not connecting to Oxygen, userId param is required');
    }
  }, [oxyConnect, userId]);

  /* eslint-enable no-console */

  return { connect, disconnect, isConnected };
};

export default useUiNotifications;
