import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { OpDivider } from 'new-components/DLS/OpDivider/OpDivider';
import { useLogout } from 'utils/customHooks/useLogout';
import { UserMenuDropdownContentHeader } from '.';

interface UserMenuDropdownContentProps {
  setDropdownIsOpen(isOpen: boolean): void;
  setDrawerIsOpen(isOpen: boolean): void;
}

const UserMenuDropdownContent = forwardRef(
  ({ setDropdownIsOpen, setDrawerIsOpen }: UserMenuDropdownContentProps, _) => {
    const { t } = useTranslation();

    const { logout } = useLogout();

    const onProfileButtonClick = () => {
      setDropdownIsOpen(false);
      setDrawerIsOpen(true);
    };

    const onLogoutClick = () => {
      setDropdownIsOpen(false);
      logout();
    };

    return (
      <div className="user-menu__dropdown-content op-content-container">
        <OpSpace direction="vertical">
          <UserMenuDropdownContentHeader />
          <OpDivider className="user-menu__dropdown-content-divider" />
          <OpButton type="primary" block onClick={onProfileButtonClick}>
            {t('Profile')}
          </OpButton>
          <OpButton danger block onClick={onLogoutClick}>
            {t('Sign out')}
          </OpButton>
        </OpSpace>
      </div>
    );
  },
);

export default UserMenuDropdownContent;
