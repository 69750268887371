import React, { useCallback, useEffect, useRef, useMemo, memo } from 'react';
import { useDispatch } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import { clearAlert } from 'routes/AppContainer/actions';
import { selectAlertStack } from 'routes/AppContainer/selectors';
import { Alert } from 'components/Alert';
import { useSelectorJs } from 'utils/customHooks';
import { useTranslation } from 'react-i18next';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';

const faviconEl = document.querySelector('link[rel="shortcut icon"]');
const favicon16El = document.querySelector('link[rel="icon"][sizes="16x16"]');
const favicon32El = document.querySelector('link[rel="icon"][sizes="32x32"]');

const favicon = faviconEl.href;
const favicon16 = favicon16El.href;
const favicon32 = favicon32El.href;

const { faviconAlert, faviconAlert16, faviconAlert32 } = document.body.dataset;

const AlertWrapperWithSuir = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const alertStack = useSelectorJs(selectAlertStack());

  const hasAlerts = useMemo(() => alertStack.length > 0, [alertStack]);
  useEffect(() => {
    if (hasAlerts) {
      faviconEl.href = faviconAlert;
      favicon16El.href = faviconAlert16;
      favicon32El.href = faviconAlert32;
    } else {
      faviconEl.href = favicon;
      favicon16El.href = favicon16;
      favicon32El.href = favicon32;
    }
  }, [hasAlerts]);

  const clearOldAlertsTimeout = useRef(null);
  const alertsWithTimeout = useRef(null);

  const checkForTimeout = useCallback(() => {
    if (clearOldAlertsTimeout.current) {
      clearTimeout(clearOldAlertsTimeout.current);
      clearOldAlertsTimeout.current = null;
    }

    alertsWithTimeout.current = alertStack.filter(({ timeout }) => !!timeout);

    if (alertsWithTimeout.current.length) {
      clearOldAlertsTimeout.current = setTimeout(() => {
        alertsWithTimeout.current.forEach(({ alertTime, alertId, timeout }) => {
          if (Date.now() - alertTime > timeout) {
            dispatch(clearAlert(alertId));
          }
        });

        if (alertsWithTimeout.current.length) {
          checkForTimeout();
        } else {
          clearTimeout(clearOldAlertsTimeout.current);
          clearOldAlertsTimeout.current = null;
        }
      }, 2000);
    }
  }, [dispatch, alertStack]);

  useEffect(() => {
    checkForTimeout();

    return () => {
      if (clearOldAlertsTimeout.current) {
        clearTimeout(clearOldAlertsTimeout.current);
        clearOldAlertsTimeout.current = null;
      }
    };
  }, [checkForTimeout]);

  const handleCloseAlerts = useCallback(() => {
    dispatch(clearAlert(null, null, true));
  }, [dispatch]);

  const handleAlertClosed = useCallback(
    (alertId) => {
      dispatch(clearAlert(alertId));
    },
    [dispatch],
  );

  const transitionProps = useMemo(
    () => ({
      keys: alertStack.map((alert) => `${alert.alertTime}-${alert.alertId}-0`),
      from: {
        opacity: 0,
        right: -400,
        position: 'relative',
        width: 'fit-content',
        marginBottom: -8,
      },
      enter: { opacity: 1, right: 0, position: 'relative' },
      leave: { opacity: 0, right: -400, position: 'relative' },
    }),
    [alertStack],
  );

  const transition = useTransition(alertStack, transitionProps);

  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        bottom: 50,
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-end',
        lineHeight: 1.4,
        zIndex: 9999, // Alerts should be topmost item on page
        padding: 11,
        pointerEvents: 'none', // Allows clicking between alerts
        maxWidth: '450px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          pointerEvents: 'all',
        }}
      >
        {transition((styles, alert) => {
          if (!alert) {
            return null;
          }

          return (
            <animated.div style={styles}>
              <Alert
                key={`${alert.alertTime}-${alert.alertId}`}
                alertType={alert.alertType}
                alertIcon={alert.alertIcon}
                alertButtons={alert.alertButtons}
                onAlertClosed={handleAlertClosed}
                id={`${alert.alertId}`}
                header={alert.alertHeader}
              >
                {typeof alert.alertMessage === 'function'
                  ? // Allow the close handler to be pass to JSX
                    alert.alertMessage(handleAlertClosed)
                  : alert.alertMessage}
              </Alert>
            </animated.div>
          );
        })}
      </div>
      {alertStack.length > 1 && (
        <OpButton
          style={{ pointerEvents: 'auto', margin: '11px' }}
          size="small"
          onClick={handleCloseAlerts}
        >
          {t('Close all alerts')}
        </OpButton>
      )}
    </div>
  );
};

export default memo(AlertWrapperWithSuir);
