import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpCodeEditor } from 'new-components/DLS/OpCodeEditor/OpCodeEditor';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

interface OpFormCodeEditor
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      | 'name'
      | 'label'
      | 'rules'
      | 'tooltip'
      | 'validateStatus' // Temp: For SUIR wrapped version
      | 'help' // Temp: For SUIR wrapped version
      | 'required' // Temp: For SUIR wrapped version
    >,
    Omit<ComponentProps<typeof OpCodeEditor>, 'name' | 'tooltip'> {
  formItemClassName?: string;
  isLoading?: boolean;
  showAnnotation?: boolean;
  disabled?: boolean;
}

export const OpFormCodeEditor = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,
  rules = [],
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation

  // For skeleton loading state
  isLoading,
  testId = 'op-form-code-editor',

  // Wrapped element props
  ...elementProps
}: OpFormCodeEditor) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx('op-form-checkbox', formItemClassName)}
      name={name}
      label={label}
      tooltip={tooltip}
      validateTrigger="onBlur"
      data-testid={testId}
      rules={[
        ...rules,
        {
          validator: async (_: any, json: string) => {
            try {
              JSON.parse(json);
            } catch (e) {
              if (e instanceof SyntaxError) {
                throw new Error(e.message);
              }
            }
          },
        },
      ]}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Input active block />
      ) : (
        <OpCodeEditor
          disabled={isReadOnly || elementProps.disabled}
          {...elementProps}
        />
      )}
    </OpForm.Item>
  );
};
