import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { useDebouncedState } from 'utils/customHooks/useDebouncedState';
import { OpDataFetchSelectProps } from './types';
import { OpSelect } from '../OpSelect/OpSelect';
import { useCreateOptions } from './helpers';

export const OpDataFetchSelect = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  className = '',
  testId = 'op-data-fetch-select',
  gtm,
  onChange,
  value: valueProp,
  numerifyOptionValues = false, // TEMP - until we can correctly type Selects

  // Props for fetching data and creating options
  optionRender,
  createDisabledOption,
  createOptionLabel,
  queryOptions,
  pathToData = 'json.data',
  pathToLabel = 'name',
  pathToValue = 'id',
  fetchAllInitialValues = false,

  // Props passed through to OpSelect
  ...opSelectProps
}: OpDataFetchSelectProps<T, SelectData>) => {
  const [debouncedQuery, setQuery] = useDebouncedState('');

  const { isLoading, isFetching, options, optionChildren } = useCreateOptions({
    numerifyOptionValues,
    optionRender,
    createDisabledOption,
    createOptionLabel,
    queryOptions,
    pathToData,
    pathToLabel,
    pathToValue,
    fetchAllInitialValues,
    valueProp,
    debouncedQuery,
  });

  /** Manipulating the value so that we make sure we don't have issues in non-ts
   * files. Doing this as Select will display the value if there is no option value
   * that matches (e.g. a number value passed will not match any of the coerced
   * option values [see code above]) */
  const finalValue = !valueProp
    ? valueProp
    : Array.isArray(valueProp)
      ? valueProp.map((v) =>
          numerifyOptionValues ? Number(v) || v : String(v),
        )
      : numerifyOptionValues
        ? Number(valueProp) || valueProp
        : String(valueProp);

  /**
   * If the Select mode is single (default mode), and the option for the
   * value is disabled, we should disable the Select so that the value
   * can't be changed.
   */
  const firstDisabledOption = options?.find(({ disabled }) => disabled);
  const isOptionForSingleModeValueDisabled = opSelectProps.mode
    ? false
    : Boolean(firstDisabledOption && firstDisabledOption.value === valueProp);

  return (
    <OpSelect
      className={`${className}`.trim()}
      testId={testId}
      gtm={gtm}
      showSearch
      filterOption={false} // Needed so options menu doesn't close on change
      optionFilterProp="label" // Defaulting to label as Select should always have options and antd docs recommend using label
      notFoundContent={isFetching ? <LoadingOutlined /> : undefined} // Pass undefined to trigger default behavior
      options={options}
      // @ts-expect-error - OpSelect can actually handle numbers just fine, but we need to retype it
      value={finalValue}
      onChange={(value, option) => {
        // Update the value if an onChange prop was passed
        onChange?.(value, option);

        // Reset the query so we see all options again
        setQuery('');
      }}
      onSearch={(value) => {
        setQuery(value);
      }}
      {...opSelectProps}
      loading={isFetching}
      disabled={
        opSelectProps.disabled ||
        (isLoading && !debouncedQuery.length) || // Can't disable if there is a query as Select loses focus and typing is interrupted
        isOptionForSingleModeValueDisabled
      }
      {...(firstDisabledOption && { allowClear: false })} // If an option is disabled, we don't want to allow clearing
    >
      {optionChildren}
    </OpSelect>
  );
};
