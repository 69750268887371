import { OpPage } from 'new-components/OpPage/OpPage';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import { getOpParams } from 'utils/getOpParams';
import { opTime } from 'utils/dates';
import { DATE_TIME_FORMAT } from 'constants/Dates';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { OpTableRecordType } from 'new-components/DLS/OpTableCore/OpTableCore';
import { OpFormDrawer } from 'new-components/DLS/OpFormDrawer/OpFormDrawer';
import { OpSelect } from 'new-components/DLS/OpSelect/OpSelect';
import { OpText } from 'new-components/DLS/OpText/OpText';
import {
  OpDataFetchTable,
  OpDataFetchTableRefType,
} from 'new-components/DLS/OpDataFetchTable/OpDataFetchTable';
import { useActiveParcels } from './hooks/useActiveParcels';
import { getActiveParcelColumns } from './helpers/getActiveParcelColumns';
import { ParcelEditForm } from './ParcelEditForm';
import {
  ALL_MAILROOMS_VALUE,
  useMailroomSelect,
} from './hooks/useMailroomSelect';
import { ParcelStatusModal } from './ParcelStatusModal';
import { ParcelMailroomModal } from './ParcelMailroomModal';
import { ParcelMessageModal } from './ParcelMessageModal';

import './Dashboard.scss';

const batchActionModals = {
  changeParcelStatus: 'changeParcelStatus',
  changeMailroom: 'changeMailroom',
  messageRecipients: 'messageRecipients',
};

export const Dashboard = () => {
  const { t } = useTranslation();
  const { orgId } = getOpParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const parcelIdExt = searchParams.get('parcel');
  const tableRef = useRef<OpDataFetchTableRefType>(null);

  const [openModal, setOpenModal] = useState<{
    modal: null | string;
    rowIds: string[];
    extraInfo?: string[];
  }>({
    modal: null,
    rowIds: [],
    extraInfo: [],
  });

  const { mailroomOptions, selectedMailroom, handleMailroomChange } =
    useMailroomSelect({
      onMailroomChange: () => {
        tableRef.current?.resetRowSelections();
      },
      orgId,
    });

  const { parcelData } = useActiveParcels({
    orgId: orgId ? Number(orgId) : undefined,
    mailroomId:
      selectedMailroom !== ALL_MAILROOMS_VALUE ? selectedMailroom : undefined,
  });

  const columns = useMemo(() => getActiveParcelColumns(), []);

  // Open the parcel drawer
  const handleRowEdit = useCallback(
    (record: OpTableRecordType) => {
      setSearchParams({ parcel: record.idExt });
    },
    [setSearchParams],
  );

  // Close the parcel drawer
  const handleDrawerClose = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const currentParcel = parcelData?.find(
    (parcel) => parcel.idExt === parcelIdExt,
  );

  const batchActionMenuItems = useMemo(
    () => [
      {
        key: 'parcelStatus',
        label: t('Change status'),
        onClick: (rowIds: string[]) => {
          setOpenModal({ modal: batchActionModals.changeParcelStatus, rowIds });
        },
      },
      {
        key: 'mailroom',
        label: t('Change mailroom'),
        onClick: (rowIds: string[]) => {
          setOpenModal({ modal: batchActionModals.changeMailroom, rowIds });
        },
      },
      {
        key: 'messageRecipients',
        label: t('Message recipients'),
        onClick: (rowIds: string[], selectedRows: OpTableRecordType[]) => {
          setOpenModal({
            modal: batchActionModals.messageRecipients,
            rowIds: selectedRows
              .filter(
                (element: OpTableRecordType) =>
                  element.recipient?.identity.email,
              )
              .map((element) => element.idExt),
            extraInfo: selectedRows.map(
              (element: OpTableRecordType) =>
                element.recipient?.identity.email || '',
            ),
          });
        },
      },
    ],
    [t],
  );

  const handleModalUpdateSuccess = () => {
    tableRef.current?.resetRowSelections();
    setOpenModal({ modal: null, rowIds: [], extraInfo: [] });
  };

  return (
    <OpPage
      className="active-parcels-dashboard"
      title={t('Active packages dashboard')}
    >
      <div className="active-parcels-dashboard__mailroom-row">
        <OpSelect
          className="active-parcels-dashboard__mailroom-select"
          testId="parcel-dashboard-mailroom-select"
          options={mailroomOptions}
          value={selectedMailroom}
          onChange={handleMailroomChange}
        />
        <OpText
          className="active-parcels-dashboard__parcel-count"
          fontWeight="500"
          size="large"
        >
          {t('Packages in mailroom: {{parcelCount}}', {
            parcelCount: parcelData?.length ?? 0,
          })}
        </OpText>
      </div>
      <OpDataFetchTable
        tableRef={tableRef}
        height={600}
        uiStateKey="ActiveParcelsTable"
        data-testid="active-parcels-table"
        gtm="active-parcels-table"
        columns={columns}
        rowActions={{ onEditClick: handleRowEdit }}
        rowKey={(record) => record.idExt}
        batchActionMenuItems={batchActionMenuItems}
        opQueryProps={{
          apiEndpointName: 'listParcels',
          parameters: [
            orgId,
            {
              ...(selectedMailroom !== ALL_MAILROOMS_VALUE && {
                preFilter: `mailroom.id:(=${selectedMailroom})`,
              }),
            },
          ],
        }}
        allowExport={{
          dataMapCallback: (record) => ({
            ...record,
            updatedAt: opTime(record.updatedAt)
              .format(DATE_TIME_FORMAT)
              .toLowerCase()
              .trim(),
          }),
        }}
      />
      <ParcelStatusModal
        isOpen={openModal.modal === batchActionModals.changeParcelStatus}
        onClose={() => setOpenModal({ modal: null, rowIds: [] })}
        onUpdate={handleModalUpdateSuccess}
        orgId={orgId}
        selectedIds={openModal.rowIds}
      />
      <ParcelMailroomModal
        isOpen={openModal.modal === batchActionModals.changeMailroom}
        onClose={() => setOpenModal({ modal: null, rowIds: [] })}
        onUpdate={handleModalUpdateSuccess}
        orgId={orgId}
        selectedIds={openModal.rowIds}
      />
      <ParcelMessageModal
        isOpen={openModal.modal === batchActionModals.messageRecipients}
        onClose={() => setOpenModal({ modal: null, rowIds: [], extraInfo: [] })}
        onUpdate={handleModalUpdateSuccess}
        orgId={orgId}
        selectedIds={openModal.rowIds}
        extraInfo={openModal.extraInfo || []}
      />
      <OpFormDrawer
        title={`Package details (${currentParcel?.recipient?.identity.fullName})`}
        onClose={handleDrawerClose}
        open={Boolean(parcelIdExt)}
        width={1000}
        formComponent={
          <ParcelEditForm orgId={orgId} parcelIdExt={parcelIdExt!} />
        }
      />
    </OpPage>
  );
};
