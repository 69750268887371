import { useEffect, useState, useCallback } from 'react';
import useInterval from 'react-useinterval';
import { requestHeliumDataGeneric } from 'routes/OrgContainer/actions';

import { opMoment } from 'utils/dates';

import { useDispatch } from 'react-redux';

import { setAlert } from 'routes/AppContainer/actions';

const POLLING_DELAY_TIMER = 2500;
const POLLING_TIMEOUT = 1000 * 15; // 15 seconds

// takes hlsPlaybackUrl
// returns
// - isPolling: boolean
// - isErrorState: boolean
// -
export default function useVideoClipPolling({
  page,
  currentOrgId,
  cameraId,
  timestamp,
  shouldRun = false,
  clips = [],
  timeoutCallback = () => null,
}) {
  const dispatch = useDispatch();

  const [pollingDelay, setPollingDelay] = useState(null);
  const [pollingStart, setPollingStart] = useState(null);
  const [videoClip, setVideoClip] = useState(null);
  const [errorState, setErrorState] = useState(false);

  const startDate = timestamp
    ? opMoment(new Date(timestamp * 1000).toISOString())
        .subtract(10, 'seconds')
        .toISOString()
    : null;
  const endDate = timestamp
    ? opMoment(new Date(timestamp * 1000).toISOString())
        .add(10, 'seconds')
        .toISOString()
    : null;

  const intervalCheck = useCallback(() => {
    // eslint-disable-next-line no-console
    console.debug(':: intervalcheck ' + Date.now() + ` ${pollingStart}`);
    if (errorState || !shouldRun || videoClip || clips.length) {
      // eslint-disable-next-line no-console
      console.debug(
        ':: POLLING STOPPING (useVideoClipPolling)... ' + Date.now(),
      );
      setPollingDelay(null); // stop the interval!
      setPollingStart(null);
    } else if (pollingStart && Date.now() - pollingStart > POLLING_TIMEOUT) {
      // eslint-disable-next-line no-console
      console.debug(
        `:: POLLING TIMEOUT (useVideoClipPolling)... Diff: ${
          Date.now() - pollingStart
        }`,
      );
      setPollingStart(null);
      setPollingDelay(null); // stop the interval!
      setErrorState(true);
      dispatch(
        setAlert('error', 'Clip not ready! Please try again', 'Failed', {
          timeout: 5000,
        }),
      );
      timeoutCallback();
    } else {
      // eslint-disable-next-line no-console
      console.debug(
        ':: POLLING FOR CLIPS  (useVideoClipPolling) ' + Date.now(),
      );
      dispatch(
        requestHeliumDataGeneric(page, {
          key: 'opvideoDeviceListClips',
          requisites: [currentOrgId, cameraId],
          defaultQueryParams: { startDate, endDate },
        }),
      );
    }
  }, [
    cameraId,
    videoClip,
    clips.length,
    currentOrgId,
    dispatch,
    errorState,
    page,
    pollingStart,
    shouldRun,
    startDate,
    endDate,
    timeoutCallback,
  ]);

  useInterval(intervalCheck, pollingDelay); // null will stop interval

  // ** do one dispatch on mount to make sure if we have a clip we don't wait for the interval
  useEffect(intervalCheck, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if we're in an error, we aren't going to do anything
    if (errorState || !shouldRun) return;

    if (clips.length) {
      const clip = clips[0];

      if (clip) {
        setVideoClip(clip);
      }
    }

    setPollingStart(Date.now());
    setPollingDelay(POLLING_DELAY_TIMER);
    intervalCheck(); // ---> fire once so we dont have to wait
  }, [errorState, shouldRun, cameraId, clips.length, dispatch, page]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    errorState,
    videoClip,
  };
}
