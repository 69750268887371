import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { useEffect, useState } from 'react';
import { getAccessToken } from 'utils/accessToken';
import { findScopeWithPrefix } from 'utils/findScopeWithPrefix';

/**
 * @description Finds the user's default org when enabled and returns it.
 * @param scopePrefix - prefix for the scopes that are valid for the current app. NOTE this may change as
 * scopes for apps are figured out.
 * @param enabled - Turns this hook on/off
 * @returns {object}
 *  isLoading {boolean} - will stay true while searching for the org
 *  orgId {number | null | undefined} - the orgId if a valid org is found
 */
export const useDefaultAppSpecificOrg = ({
  scopePrefix,
  enabled = true,
}: {
  scopePrefix: string;
  enabled?: boolean;
}) => {
  const accessToken = getAccessToken();
  const [orgId, setOrgId] = useState<number | undefined | null>();
  const [isLoading, setIsLoading] = useState(Boolean(accessToken) && enabled);

  // Fetch the token
  const { data: tokenData, isError } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    enabled: Boolean(accessToken) && enabled,
    select: (data) => data.json?.data,
  });

  // When there is an error loading the token - return a null orgId
  useEffect(() => {
    if (isError) {
      setOrgId(null);
      setIsLoading(false);
    }
  }, [isError]);

  // Check if access token and org are valid
  useEffect(() => {
    // When hook is disabled or no tokenData is found, do not run this.
    if (!enabled || !tokenData) return;

    // Default to the 1st orgId in their token with the app's scopes
    const defaultOrg = tokenData.tokenScopeList?.find((tokenScope) => {
      // find a scope with the app's prefix
      const matchingScope = findScopeWithPrefix(scopePrefix, tokenScope.scope);
      return Boolean(matchingScope);
    });

    setOrgId(defaultOrg?.org?.id);

    setIsLoading(false);
  }, [enabled, orgId, scopePrefix, tokenData]);

  return {
    isLoading,
    orgId,
  };
};
