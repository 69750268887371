import {
  parsePhoneNumber,
  isValidPhoneNumber,
  ParseError,
} from 'libphonenumber-js';
import Joi from 'joi';
import { t } from 'i18next';

// Helper function for phoneNumber
const parseAndValidatePhoneNumber = (
  pn: string,
  options: { onlyUsNumbers: boolean; multiple: boolean },
) => {
  let message = null;

  /** note this will parse *all* numbers from *all* countries, it's just
   * telling the parser to *default* to US if no country code is included */
  const parsed = parsePhoneNumber(pn, {
    defaultCountry: 'US',
  });

  if (options.onlyUsNumbers && parsed.country !== 'US') {
    message = t('Only US phone numbers are supported.');
  }

  // Convert to E.164 format
  const parsedNumber = parsed.format('E.164');

  if (!isValidPhoneNumber(parsedNumber)) {
    message = t('Invalid phone number: `{{parsedNumber}}', { parsedNumber });
  }

  return message;
};

/** Take a phone number (of generally any format), validate it, and convert it
 * into an E.164 our DB/code expects */
const phoneNumber =
  (options = { onlyUsNumbers: false, multiple: false }) =>
  (
    value: string,
    helper: {
      message: (arg0: { custom: string }) => any;
      error: (arg0: string) => any;
    },
  ) => {
    let message = null;

    try {
      // When multiple is true, we expect a comma-separated list of phone numbers
      if (options.multiple) {
        const trimmedPhoneNumbers = value
          .split(',') // split by comma
          .map((pn) => pn.trim()); // remove any white space in front or back of number so spaces before and after comma are ok

        for (let i = 0; i < trimmedPhoneNumbers.length; i++) {
          message = parseAndValidatePhoneNumber(
            trimmedPhoneNumbers[i],
            options,
          );

          // Stop on the first error
          if (message) {
            return helper.message({ custom: message });
          }
        }

        // return back the E.164 formatted phone numbers as a comma-separated string
        return trimmedPhoneNumbers
          .map((pn) =>
            parsePhoneNumber(pn, { defaultCountry: 'US' }).format('E.164'),
          )
          .join(', ');
      }

      message = parseAndValidatePhoneNumber(value, options);

      return message
        ? helper.message({ custom: message })
        : parsePhoneNumber(value, { defaultCountry: 'US' }).format('E.164');
    } catch (e) {
      return e instanceof ParseError
        ? helper.message({
            custom: options.multiple
              ? t(
                  'One or more phone numbers are formatted incorrectly. Please provide a comma-separated list of valid E.164 formatted phone numbers.',
                )
              : t('Invalid phone number format {{errorMessage}}', {
                  errorMessage: e.message,
                }),
          })
        : helper.error('any.invalid');
    }
  };

export const isPhoneNumber = (
  value?: string,
  options = { onlyUsNumbers: false, multiple: false },
) => !Joi.string().custom(phoneNumber(options)).validate(value).error;

export const isEmail = (value?: string) =>
  !Joi.string()
    .email({
      multiple: true,
      allowUnicode: false,
      tlds: { allow: false }, // Must add this in the browser or else get `Built-in TLD list disabled` error
    })
    .trim()
    .min(1)
    .max(1000)
    .allow(null)
    .validate(value).error;

const e164PhoneNumberRegExp = /^\s*\+\S{10,15}\s*$/;
export const isE164PhoneNumber = (value?: string) =>
  !Joi.string()
    .regex(e164PhoneNumberRegExp)
    .message(
      t('`{{value}}` is not in the E.164 format (e.g. +15556667777)', {
        value,
      }),
    )
    .validate(value).error;

/**
 * Defined 6 years ago, but seems to still serve our purpose
 *
 * Examples
 * 'foo' => false
 * 'foo.com' => true
 * 'www.foo.com' => true
 * 'http://www.foo.com' => true
 * 'http://www.foo.com/' => true
 * 'http://www.foo.com/page' => true
 * 'http://www.foo.com/page?param=value' => true
 * 'http://www.foo.com/page?param=value#anchor' => true
 * 'http://www.foo.com/page?param=value#anchor&param2=value2' => true
 * '{URL with length greater than 2048 chars}' => false
 */
const urlRegExp =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
export const isUrl = (value?: string) =>
  !Joi.string()
    .max(2048) // limit imposed by Internet Explorer
    .regex(urlRegExp)
    .message(t('`{{value}}` is not a valid URL', { value }))
    .validate(value).error;

export const isUrlWithProtocol = (value?: string) =>
  !Joi.string()
    .max(2048) // limit imposed by Internet Explorer
    .uri()
    .message(t('`{{value}}` is not a valid URL', { value }))
    .validate(value).error;

/**
 * please be very cautious adding to this file, we want validators to remain as
 * self-contained and easy to read as possible
 *
 * Joi is ~quite~ expressive so you should be able to construct most validations
 * using it's built-in rules
 * */
