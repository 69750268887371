import { UseQueryResult, useQueries } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { queryRequest } from 'utils/queryRequest';
import { ensurePayloadAndQuery } from 'utils/ensurePayloadAndQuery';
import { UseOpQueryOptions } from './useOpQuery';

export const useOpQueries = <
  const T extends (keyof Api.ClientSpec)[],
>(queryParamsArray: { [K in keyof T]: UseOpQueryOptions<T[K]> }): {
  [K in keyof T]: UseQueryResult<Awaited<ReturnType<Api.Client[T[K]]>>>;
} => {
  /** we first look at the identity language and set the language to that.
   * If that is not set, we then fallback to the local storage setting.
   * If that is not set, then we fallback to the browser locale.
   * And if that is not supported then we use English (en). */
  const currentIdentityLanguage = useSelector(selectCurrentIdentityLanguage());
  const queries = queryParamsArray.map(
    ({
      apiEndpointName,
      parameters,
      suppressErrorMessage,
      queryKey = [apiEndpointName, JSON.stringify(parameters)],
      ...queryOptions
    }) => {
      const { parametersArray } = ensurePayloadAndQuery(
        apiEndpointName,
        parameters,
      );

      const queryFn = async () => {
        const result = await queryRequest({
          apiEndpointName,
          parameters: parametersArray as never,
          currentIdentityLanguage,
        });

        if (result.statusCode >= 400) {
          if (suppressErrorMessage) {
            console.error(`Error during ${apiEndpointName}`, { result });
          } else {
            throw result.json;
          }
        }

        return result;
      };

      // Ensure the requirements have been passed if the enabled option is false
      const hasValidRequirements = parametersArray.every(Boolean);

      return {
        queryKey,
        queryFn,
        enabled: hasValidRequirements,
        ...queryOptions,
      };
    },
  );

  return useQueries({ queries }) as never;
};
