export const BatteryEmpty = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7C2.89543 7 2 7.89543 2 9V15C2 16.1046 2.89543 17 4 17H18C19.1046 17 20 16.1046 20 15V14.5H21C21.5523 14.5 22 14.0523 22 13.5V10.5C22 9.94772 21.5523 9.5 21 9.5H20V9C20 7.89543 19.1046 7 18 7H4ZM18 8.5H4C3.72386 8.5 3.5 8.72386 3.5 9V15C3.5 15.2761 3.72386 15.5 4 15.5H18C18.2761 15.5 18.5 15.2761 18.5 15V9C18.5 8.72386 18.2761 8.5 18 8.5Z"
    />
  </svg>
);
