import { ComponentProps } from 'react';
import Row from 'antd/es/row';
import clsx from 'clsx';

interface OpRowProps extends ComponentProps<typeof Row> {
  testId?: string;
}

export const OpRow = ({
  className,
  testId = 'op-row',
  ...rowProps
}: OpRowProps) => {
  return (
    <Row
      className={clsx('op-row', className)}
      data-testid={testId}
      {...rowProps}
    />
  );
};
