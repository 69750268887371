import { t } from 'i18next';
import { opTime } from 'utils/dates';
import { DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT } from 'constants/Dates';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { ReactNode } from 'react';

const propertyToMessageSubject = {
  mailroom: 'Mailroom',
  parcelStatus: 'Package status',
  recipient: 'Recipient',
  trackingNumber: 'Tracking number',
  carrier: 'Carrier',
  sender: 'Sender',
};

const propertyToNullText = {
  mailroom: 'no mailroom',
  parcelStatus: 'no package status',
  recipient: 'no recipient',
  trackingNumber: 'no tracking number',
  carrier: 'no carrier',
  sender: 'no sender',
};

type PreviousFields = Utils.DeepNonNullable<
  Utils.DeepNonUndefined<
    Api.Response['listParcelHistory'][0]['data']
  >['previousFields']
>;

type ParcelPropertyKeys = Utils.KeysMatching<
  PreviousFields,
  string | Record<string, unknown>
>;

type PreviousFieldsKeyValues = Utils.Entries<PreviousFields>;

export const getParcelChangedMessage = ({
  property,
  oldValue,
  updatedValue,
}: {
  property: ParcelPropertyKeys;
  oldValue?: string | null;
  updatedValue?: string | null;
}) => {
  const finalOldValue = oldValue ?? propertyToNullText[property];
  const finalUpdatedValue = updatedValue ?? propertyToNullText[property];

  const message = t('{{messageSubject}} changed from {{finalOldValue}} to ', {
    messageSubject: propertyToMessageSubject[property] ?? '',
    finalOldValue,
  });

  const messageSpan = (
    <OpText inline fontWeight="300">
      {message}{' '}
      <OpText inline fontWeight="500">
        {finalUpdatedValue}
      </OpText>
    </OpText>
  );

  return messageSpan;
};

export const processParcelHistory = (
  parcelHistory: Api.Response['listParcelHistory'] = [],
) => {
  const rows: {
    index: string; // Used for row key, there isnt anything else unique
    event: ReactNode;
    time: string;
  }[] = [];

  parcelHistory
    .sort((a, b) => b.timestamp - a.timestamp)
    .forEach((history, i) => {
      switch (history.event) {
        case 'parcel.created':
          rows.push({
            index: `parcel.created-${i}`, // should only be one
            event: t('Initial scan'),
            time: opTime(history.timestamp * 1000).format(
              // TODO replace with mailroom timezone when exists
              DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT,
            ),
          });
          break;
        case 'parcel.changed': {
          // Nothing actually changed for this parcel in this update call - could happen with batch actions
          if (!history.data?.previousFields) {
            break;
          }

          const time = opTime(history.timestamp * 1000).format(
            // TODO replace with mailroom timezone when exists
            DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT,
          );

          let rowEvent = null;

          // loop thru previousFields and output a row for each
          const historyEntries = Object.entries(
            history.data.previousFields,
          ) as PreviousFieldsKeyValues;

          historyEntries.forEach(([key, value], j) => {
            switch (key) {
              case 'recipient':
                rowEvent = getParcelChangedMessage({
                  property: key,
                  oldValue: value?.identity?.fullName,
                  updatedValue: history.data?.recipient?.identity.fullName,
                });
                break;
              case 'mailroom':
                rowEvent = getParcelChangedMessage({
                  property: key,
                  oldValue: value?.name,
                  updatedValue: history.data?.mailroom?.name,
                });
                break;
              case 'parcelStatus':
                rowEvent = getParcelChangedMessage({
                  property: key,
                  oldValue: value?.name,
                  updatedValue: history.data?.parcelStatus?.name,
                });
                break;

              default:
                rowEvent = getParcelChangedMessage({
                  property: key,
                  oldValue: value,
                  updatedValue: history.data?.[key],
                });
                break;
            }

            rows.push({
              index: `parcel.changed-${i}-${j}`,
              event: rowEvent,
              time,
            });
          });
          break;
        }
        case 'parcel.deleted':
          // shouldn't ever see this but outputting something in case they saved the url somewhere
          rows.push({
            index: `parcel.deleted-${i}`, // should only be one
            event: t('Package was deleted'),
            time: opTime(history.timestamp * 1000).format(
              // TODO replace with mailroom timezone when exists
              DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT,
            ),
          });
      }
    });

  return rows;
};
