import { ComponentProps } from 'react';
import Segmented from 'antd/es/segmented';
import clsx from 'clsx';

interface OpSegmentedProps extends ComponentProps<typeof Segmented> {
  testId?: string;
}

export const OpSegmented = ({
  className,
  testId = 'op-segmented',
  ...segmentedProps
}: OpSegmentedProps) => {
  return (
    <Segmented
      className={clsx('op-segmented', className)}
      data-testid={testId}
      {...segmentedProps}
    />
  );
};
