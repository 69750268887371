import { fromJS } from 'immutable';
import { SET_PACKAGES } from './constants';

function packagesReducer(state = fromJS([]), action) {
  switch (action.type) {
    case SET_PACKAGES:
      return fromJS(action.packages);
    default:
      return state;
  }
}

export default packagesReducer;
