import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getAccessToken } from 'utils/accessToken';
import { getActiveScopes, validateScopes } from 'utils/scopes';
import { getOpParams } from '../getOpParams';

/**
 * @description Validates the current user's applicable scopes for the current org or mastermode route
 * and validates they have any of the allowed scopes for the current route
 * @returns {object}
 *  isValid {boolean} - true if the scopes found are valid, false if not
 *  isLoading {boolean} - true if currently determining if scopes are valid, false when finished
 */
export const useValidateScopes = ({
  allowedScopes,
}: {
  allowedScopes: string[];
}) => {
  const { orgId } = getOpParams();
  const accessToken = getAccessToken();

  // Fetch the current org information
  const { data: orgData, isPending: isOrgIdPending } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    select: (data) => data.json.data,
  });

  const { data: tokenData, isPending: isTokenPending } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    select: (data) => data.json.data,
  });

  // Get all the applicable scopes for the user and current org
  const activeScopes = getActiveScopes({
    tokenScopeList: tokenData?.tokenScopeList ?? [],
    orgId: Number(orgId),
    parentOrgId: orgData?.parentOrg?.id,
  });

  // Validate the user's applicable scopes against the current page's scopes
  const userHasAllowedScopes = validateScopes({
    activeScopes,
    rawAllowedScopes: allowedScopes,
    ...(orgId && { orgId: String(orgId) }),
  });

  return {
    isValid: userHasAllowedScopes,
    isLoading: Boolean(accessToken) && (isOrgIdPending || isTokenPending),
  };
};
