import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OpPage } from 'new-components/OpPage/OpPage';
import { getOpParams } from 'utils/getOpParams';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { OpTableRecordType } from 'new-components/DLS/OpTableCore/OpTableCore';
import { useUrlSearchParams } from 'utils/customHooks/useUrlSearchParams';
import { OpDataFetchTable } from 'new-components/DLS/OpDataFetchTable/OpDataFetchTable';
import { opTime } from 'utils/dates';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { OpFormDrawer } from 'new-components/DLS/OpFormDrawer/OpFormDrawer';
import { getMailroomsPageColumns } from './helpers/getMailroomsPageColumns';
import { MailroomForm } from './MailroomForm';

import './Mailrooms.scss';

const Mailrooms = () => {
  const { t } = useTranslation();
  const { orgId } = getOpParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { drawer } = useUrlSearchParams();
  const mailroomId = Number(searchParams.get('mailroomId'));

  const { data: mailroomName } = useOpQuery({
    apiEndpointName: 'describeMailroom',
    parameters: [orgId, mailroomId!],
    select: (data) => data?.json?.data?.name ?? '',
  });

  const editMode = Boolean(mailroomId);

  const drawerTitle = useMemo(() => {
    return editMode
      ? t('Edit mailroom ({{mailroomName}})', { mailroomName })
      : t('Create mailroom');
  }, [editMode, t, mailroomName]);

  const handleMailroomDrawerClose = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const deleteMailroom = useOpMutation({
    apiEndpointName: 'deleteMailroom',
    onSuccessMessage: t('Mailroom successfully deleted'),
    queryKeysToInvalidate: [['describeMailroom'], ['listMailrooms']],
  });

  const columns = getMailroomsPageColumns();

  const handleAdd = useCallback(() => {
    setSearchParams({ drawer: 'create' });
  }, [setSearchParams]);

  const handleUpdate = useCallback(
    (record: OpTableRecordType) => {
      setSearchParams({ drawer: 'edit', mailroomId: record.id });
    },
    [setSearchParams],
  );

  const handleDelete = useCallback(
    ({ id: deleteMailroomId }: Api.Response['describeMailroom']) => {
      deleteMailroom.mutate({
        apiEndpointRequirements: [orgId, deleteMailroomId],
      });
    },
    [deleteMailroom, orgId],
  );

  const rowActions = {
    onEditClick: handleUpdate,
    onDeleteClick: handleDelete,
  };

  return (
    <OpPage
      className="mailroom-page"
      title={t('Mailrooms')}
      testId="mailroom-page"
    >
      <OpDataFetchTable
        uiStateKey="MailroomsTable"
        height="calc(100vh - 344px)"
        opQueryProps={{
          apiEndpointName: 'listMailrooms',
          parameters: [orgId],
        }}
        columns={columns}
        rowActions={rowActions}
        allowExport={{
          filename: `Mailrooms-report_${opTime()}`,
        }}
        allowAddition={{
          itemName: t('mailroom'),
          onClick: handleAdd,
        }}
      />
      <OpFormDrawer
        title={drawerTitle}
        className="mailroom-add-edit-drawer"
        width={1000}
        onClose={handleMailroomDrawerClose}
        open={Boolean(drawer)}
        formComponent={<MailroomForm orgId={orgId} mailroomId={mailroomId!} />}
      />
    </OpPage>
  );
};

export default Mailrooms;
