// https://dev.to/ndrbrt/wait-for-the-websocket-connection-to-be-open-before-sending-a-message-1h12

export const waitForWebsocketConnectionToOpen = (socket) =>
  new Promise((resolve, reject) => {
    const maxNumberOfAttempts = 5;
    const baseIntervalTime = 100; // initial interval time in ms

    let currentAttempt = 0;

    const attemptConnection = () => {
      if (socket.readyState === socket.OPEN) {
        resolve();
      } else if (currentAttempt >= maxNumberOfAttempts) {
        reject(new Error('Maximum number of attempts exceeded'));
      } else {
        currentAttempt++;
        setTimeout(attemptConnection, baseIntervalTime * currentAttempt);
      }
    };

    attemptConnection();
  });
