// TODO - https://openpath.atlassian.net/browse/OPAC-11056 - ticket to have helium generate maps for this so we dont
// hardcode them everywhere (i.e. alarmSeverity, alarmStatus, credentialTypes, etc (all lookup/authority tables))

import { t } from 'i18next';

export const parcelStatusCodes = {
  awaitingPickup: 'awaitingPickup',
  pickedUp: 'pickedUp',
  lost: 'lost',
  unidentifiedRecipient: 'unidentifiedRecipient',
  archived: 'archived',
  returnToSender: 'returnToSender',
};

export const parcelStatusCodeToId = {
  [parcelStatusCodes.awaitingPickup]: 1,
  [parcelStatusCodes.pickedUp]: 2,
  [parcelStatusCodes.lost]: 3,
  [parcelStatusCodes.unidentifiedRecipient]: 4,
  [parcelStatusCodes.archived]: 5,
  [parcelStatusCodes.returnToSender]: 6,
};

export const getParcelStatusOptions = () => [
  { label: t('Awaiting pickup'), value: parcelStatusCodes.awaitingPickup },
  { label: t('Picked up'), value: parcelStatusCodes.pickedUp },
  { label: t('Lost'), value: parcelStatusCodes.lost },
  {
    label: t('Unidentified recipient'),
    value: parcelStatusCodes.unidentifiedRecipient,
  },
  { label: t('Archived'), value: parcelStatusCodes.archived },
  { label: t('Return to sender'), value: parcelStatusCodes.returnToSender },
];

type ParcelPictureTypeCodes = 'userUploaded' | 'wideAngle' | 'label';

export const parcelPictureType: Record<
  ParcelPictureTypeCodes,
  { code: ParcelPictureTypeCodes; id: number }
> = {
  label: { code: 'label', id: 1 },
  wideAngle: { code: 'wideAngle', id: 2 },
  userUploaded: { code: 'userUploaded', id: 3 },
};
