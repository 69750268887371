import { OpImage } from 'new-components/DLS/OpImage/OpImage';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { OpSegmented } from 'new-components/DLS/OpSegmented/OpSegmented';
import i18n from 'i18next';
import { getAppColorTheme } from 'routes/AuthenticatedContainer/components/NewNavIntroModal/helpers/getAppColorTheme';
import { useNav } from 'routes/AppContainer/NavContext';

import './NavTypePicker.scss';

const navTypes = [
  {
    label: (
      <>
        <OpImage
          src={
            getAppColorTheme() === 'dark'
              ? require('assets/img/unl-preview-old-nav-dark.png')
              : require('assets/img/unl-preview-old-nav.png')
          }
          alt="Legacy nav"
        />
        <OpText
          className="profile-settings-tab-content-org-settings__color-theme-text"
          testId="old-nav-option"
        >
          {i18n.t('Legacy nav')}
        </OpText>
      </>
    ),
    value: 'old',
  },
  {
    label: (
      <>
        <OpImage
          src={
            getAppColorTheme() === 'dark'
              ? require('assets/img/unl-preview-new-nav-dark.png')
              : require('assets/img/unl-preview-new-nav.png')
          }
          alt="New nav"
        />
        <OpText
          className="profile-settings-tab-content-org-settings__color-theme-text"
          testId="new-nav-option"
        >
          {i18n.t('New nav')}
        </OpText>
      </>
    ),
    value: 'new',
  },
];

export const NavTypePicker = () => {
  const { hasUserSelectedNewNav, setHasUserSelectedNewNav } = useNav();

  return (
    <OpSegmented
      className="nav-type-picker"
      options={navTypes}
      value={hasUserSelectedNewNav ? 'new' : 'old'}
      onChange={(newNavType) => {
        setHasUserSelectedNewNav(newNavType === 'new');
      }}
    />
  );
};
