import { useIsRestrictedBySiteSpecificRole } from 'utils/customHooks/useIsRestrictedBySiteSpecificRole';
import { useTranslation } from 'react-i18next';
import { OpSiteSpecificSiteDataFetchSelectProps } from './types';
import { OpDataFetchSelect } from '../OpDataFetchSelect/OpDataFetchSelect';
import { OpTooltip } from '../OpTooltip/OpTooltip';

/**
 * Component that wraps `OpDataFetchSelect` to fetch and display sites that would
 * normally not have data due to site-specific access limitations.
 *
 * @param {OpSiteSpecificSiteDataFetchSelectProps} props - The properties for the component.
 * @param {string} props.orgId - The organization ID used to fetch site data.
 * @param {object} props.opDataFetchSelectProps - Additional properties passed to `OpDataFetchSelect`.
 *
 * @returns {JSX.Element} The rendered `OpSiteSpecificSiteDataFetchSelect` component.
 *
 * @remarks
 * It uses the `useIsRestrictedBySiteSpecificRole` hook to determine if the site has
 * site-specific restrictions and modifies the options and labels accordingly.
 * The `createDisabledOption` function disables options for users restricted by site-specific roles
 * the `createOptionLabel` function displays a tooltip with the user's email and namespace or a
 * restriction message if the user is restricted.
 */
export const OpSiteSpecificSiteDataFetchSelect = ({
  orgId,
  ...opDataFetchSelectProps
}: OpSiteSpecificSiteDataFetchSelectProps) => {
  const { t } = useTranslation();
  const checkSiteSpecificRoleRestriction =
    useIsRestrictedBySiteSpecificRole(orgId);

  return (
    <OpDataFetchSelect
      queryOptions={{
        apiEndpointName: 'listSites',
        parameters: [orgId],
      }}
      fetchAllInitialValues={{
        /**
         * Pass ignoreSiteSpecificAccess to fetch all sites so that we can create options for
         * any sites that may have been restricted by site-specific access. Creating the options
         * allows us to display the site name as well as disabling the option if the site has
         * site-specific restrictions.
         */
        queryParamOverrides: {
          options: 'ignoreSiteSpecificAccess',
        },
      }}
      createDisabledOption={({ id }) => checkSiteSpecificRoleRestriction([id])}
      // eslint-disable-next-line react/no-unstable-nested-components
      createOptionLabel={({ id, name }) => (
        <OpTooltip
          title={
            checkSiteSpecificRoleRestriction([id])
              ? t('Your site-specific role prevents management of this site')
              : null
          }
        >
          {name}
        </OpTooltip>
      )}
      {...opDataFetchSelectProps}
    />
  );
};
