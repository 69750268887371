import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'semantic-ui-react/dist/es/elements/Icon/Icon';
import { Clickable } from 'components/Clickable/Clickable';

const FullscreenButton = memo(
  ({ handleFullscreenToggleClick, isNotFullscreen }) => (
    <Clickable
      className="player-controls-button op-justify-content-center op-cursor-pointer"
      onClick={handleFullscreenToggleClick}
    >
      <Icon
        size="large"
        style={{ height: 'auto' }}
        fitted
        inverted
        name={isNotFullscreen ? 'expand' : 'compress'}
      />
    </Clickable>
  ),
);

FullscreenButton.displayName = 'FullscreenButton';

FullscreenButton.propTypes = {
  handleFullscreenToggleClick: PropTypes.func.isRequired,
  isNotFullscreen: PropTypes.bool.isRequired,
};

export default FullscreenButton;
