import { t } from 'i18next';

export default () => [
  {
    date: '2024-05-13',
    notes: [
      {
        category: 'feature',
        note: t(
          'System audit log - Now you can audit actions taken on organizations in master mode.',
        ),
      },
    ],
  },
  {
    date: '2022-02-15',
    notes: [
      {
        category: 'feature',
        note: t(
          `Feature Flags - Now you can add/edit/delete feature flags, set global feature flag values, and assign per org feature flag values. Note: Per org feature flag values will override global feature flag values, if present.`,
        ),
      },
    ],
  },
  {
    date: '2021-02-01',
    notes: [
      {
        category: 'feature',
        note: t(
          `Master Dashboard - New stats boxes how show how many unique Users and Orgs have been active (have had at least one unlock attempt) in the last month, and in the last day.`,
        ),
      },
    ],
  },
  {
    date: '2020-12-22',
    notes: [
      {
        category: 'feature',
        note: t(
          `Add "Reference Number" field on create/edit form for Orgs. Value will be sent to Stripe to appear on invoices.`,
        ),
      },
    ],
  },
  {
    date: '2020-10-14',
    notes: [
      {
        note: t(
          `Organizations - Resellers can now be restricted to creating child orgs of a certain billing type.`,
        ),
      },
    ],
  },
  {
    date: '2020-10-05',
    notes: [
      {
        note: t(
          `Organizations - Table now includes an optional "Sites" column that shows the number of sites belonging to each org, and can be sorted to find orgs with the most/fewest sites.`,
        ),
      },
    ],
  },
  {
    date: '2020-10-02',
    notes: [
      {
        note: t(
          `Organizations - Table now includes an optional "Last Activity At" column (not real-time, but updated periodically and at least daily) that shows the timestamp of the most recent entry activity, and can be sorted to find orgs with/without recent activity. The Edit Organization page also shows this value.`,
        ),
      },
    ],
  },
  {
    date: '2020-09-08',
    notes: [
      {
        note: t(
          `Organizations - Table now includes an optional "Inactivated At" column that tracks the timestamp of when an org became inactive. The Edit Organization page also includes this value, and the Category Tags box can also be used for tags indicating why an org became inactive.`,
        ),
      },
    ],
  },
  {
    date: '2020-04-29',
    notes: [
      {
        note: t(
          `Bug Fix -   Org switcher only shows the orgs for which you have some level of portal admin access, excluding orgs for which you have only entry-user access.`,
        ),
      },
    ],
  },
  {
    date: '2020-02-06',
    notes: [
      {
        note: t(`Bug Fix - Fixed Parent Org text issue.`),
      },
    ],
  },
  {
    date: '2020-01-28',
    notes: [
      {
        note: t(
          `Identity Roles are now editable even if the user came from an Identity Provider`,
        ),
      },
    ],
  },
  {
    date: '2019-12-30',
    notes: [
      {
        note: t(
          `Accounts Payable Email visible to revshare customers on Account Settings.`,
        ),
      },
      {
        note: t(`Add accounts payable email to MasterOrgs Create/Edit.`),
      },
    ],
  },
  {
    date: '2019-12-12',
    notes: [
      {
        note: t(`Bug fix - Fixed breadcrumb issue.`),
      },
    ],
  },
  {
    date: '2019-11-07',
    notes: [
      {
        note: t(
          `Changed Permissions for viewing sensitive information from certain child organizations`,
        ),
      },
    ],
  },
  {
    date: '2019-10-25',
    notes: [
      {
        note: t(
          `UI - Updated UI to make create/edit organization pages more clear`,
        ),
      },
      {
        note: t(`Bug Fix - Fixed strange URL routing issues`),
      },
    ],
  },
  {
    date: '2019-10-22',
    notes: [
      {
        note: t(
          `Added option to sync namespace nicknames to include the organization name`,
        ),
      },
      {
        note: t(`Master Orgs Page UI redesign`),
      },
    ],
  },
  {
    date: '2019-07-15',
    notes: [
      {
        note: t(
          `Identity Page now shows a list of users attached to said Identity for lookup.`,
        ),
      },
    ],
  },
  {
    date: '2019-06-20',
    notes: [
      {
        note: t(
          `Release notes are now available in Master Mode. Updates for Master Mode will bee seen here moving forward.`,
        ),
      },
    ],
  },
];
