import clsx from 'clsx';
import Alert from 'antd/es/alert';
import { OpInfoProps } from '../OpInfo';

import '../OpInfo.scss';

export const OpInfoWithoutDismiss = ({
  className,
  ...alertProps
}: OpInfoProps) => (
  <Alert className={clsx('op-info', className)} showIcon {...alertProps} />
);
