import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import notification from 'antd/es/notification';
import { clearAlert } from 'routes/AppContainer/actions';
import { selectAlertStack } from 'routes/AppContainer/selectors';
import { useSelectorJs } from 'utils/customHooks';
import { useTranslation } from 'react-i18next';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { SoundEffects, Layers, WebAudio } from 'utils/audio';
import { addSlideOut } from 'global/slideOuts/actions';
import alarmEditRouteConfig from 'routes/AlarmDashboardPage/AlarmEditPage/route';
import { verifyScope } from 'utils/redirects';
import clsx from 'clsx';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { getOpParams } from 'utils/getOpParams';
import { getAccessToken } from 'utils/accessToken';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getActiveScopes } from 'utils/scopes';
import { iconLookup } from './helpers';
import { ClearAllAlertsButton } from './components/ClearAllAlertsButton';

import './AlertWrapper.scss';

interface Alert {
  alertId: string;
  alertTime: number;
  alertType: string;
  alertIcon?: string;
  alertButtons?: ButtonAction[];
  timeout?: number;
  alertHeader?: string;
  alertMessage: React.ReactNode | ((close: () => void) => React.ReactNode);
}

interface ButtonAction {
  target?: {
    resourceType: string;
    resourceUid?: string;
  };
  onClick?: (close: () => void) => void;
}
/**
 * Keep track of which alerts are playing audio so we don't
 * play them multiple times. Without this the alert audio
 * would play when it has finished and the alertStack changes
 * at all.
 */
const audioByAlertId: Record<string, boolean> = {};

const useGetActiveScopes = (orgId: number) => {
  const accessToken = getAccessToken();

  const { data: orgData, isLoading: isOrgDataLoading } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    select: (data) => data.json.data,
  });

  const { data: tokenData, isLoading: isAccessTokenDataLoading } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    select: (data) => data.json.data,
  });

  // Get the active scopes for the current user
  const activeScopes = getActiveScopes({
    tokenScopeList: tokenData?.tokenScopeList ?? [],
    orgId: Number(orgId),
    parentOrgId: orgData?.parentOrg?.id,
  });

  return {
    activeScopes,
    isLoading: isOrgDataLoading || isAccessTokenDataLoading,
  };
};

const AlertWrapperWithoutSuir: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const alertStack: Alert[] = useSelectorJs(selectAlertStack());
  const { orgId } = getOpParams();

  const { activeScopes } = useGetActiveScopes(orgId);

  const canEditAlarms = verifyScope(
    activeScopes,
    alarmEditRouteConfig.scope,
    orgId,
  );

  const [api, contextHolder] = notification.useNotification();

  const handleAlertClosed = useCallback(
    ({ alertId, alertType }: Alert) => {
      // Handle sound effects
      if (['alarmHigh', 'alarmMedium', 'alarmLow'].includes(alertType)) {
        WebAudio.stop(
          SoundEffects.ALARM_NOTIFICATION,
          Layers.ALARM_NOTIFICATIONS,
        );
        audioByAlertId[alertId] = false;
      } else if (alertType === 'intercom') {
        WebAudio.stop(
          SoundEffects.INTERCOM_NOTIFICATION,
          Layers.INTERCOM_NOTIFICATIONS,
        );
        audioByAlertId[alertId] = false;
      }

      dispatch(clearAlert(alertId));
      api.destroy(alertId);
    },
    [api, dispatch],
  );

  const displayNotification = useCallback(
    (alert: Alert) => {
      const {
        alertIcon,
        alertType,
        alertId,
        alertMessage,
        alertButtons,
        alertHeader,
        timeout: alertTimeout,
      } = alert;
      let notifType: 'success' | 'info' | 'warning' | 'error' = 'info';

      switch (alert.alertType) {
        case 'error':
        case 'alarmHigh':
        case 'alarmMedium':
        case 'alarmLow':
          notifType = 'error';
          break;
        case 'warning':
          notifType = 'warning';
          break;
        case 'success':
          notifType = 'success';
          break;
        case 'intercom':
          notifType = 'info';
          break;
        default:
          notifType = 'info';
      }

      // TODO - verify if we need to function version (parity with old version)
      const description =
        typeof alertMessage === 'function'
          ? alertMessage(() => handleAlertClosed(alert))
          : alertMessage;

      const btn = alertButtons?.length && (
        <OpSpace>
          {alertButtons.map((button, index) => {
            const { resourceType, resourceUid } = button.target || {};

            switch (resourceType) {
              case 'alarm':
                if (resourceUid && canEditAlarms) {
                  return (
                    <OpButton
                      key={index}
                      onClick={() => {
                        handleAlertClosed(alert);
                        dispatch(
                          addSlideOut({
                            routeConfig: {
                              ...alarmEditRouteConfig,
                              extraData: {
                                alarmIdExt: resourceUid,
                              },
                            },
                            slideOutProps: {
                              requiredExtraData: ['alarmIdExt'],
                              pathname: null,
                              debug:
                                window.sessionStorage.getItem(
                                  'op-history-debug',
                                ),
                            },
                          }),
                        );
                      }}
                      style={{ marginRight: 8 }}
                    >
                      {t('View Alarm')}
                    </OpButton>
                  );
                }
                return null;

              case 'intercomNotification':
                return (
                  <OpButton
                    key={index}
                    onClick={() => {
                      if (button.onClick) {
                        button.onClick(() => handleAlertClosed(alert));
                      }
                    }}
                    style={{ marginRight: 8 }}
                  >
                    {t('View Call')}
                  </OpButton>
                );

              default:
                return null;
            }
          })}
        </OpSpace>
      );

      const isAlarmHigh = alertType === 'alarmHigh' || alertIcon === 'p1';
      const isAlarmMedium =
        alertType === 'alarmMedium' ||
        (alertIcon && ['p2', 'p3'].includes(alertIcon));
      const isAlarmLow =
        alertType === 'alarmLow' ||
        (alertIcon && ['p4', 'p5'].includes(alertIcon));
      const isIntercom = alertType === 'intercom';

      api.open({
        key: alertId,
        message: (alertHeader || alertType).toUpperCase(),
        description,
        btn,
        type: notifType,
        icon: iconLookup[alertIcon || alertType],
        placement: 'bottomRight',
        onClose: () => handleAlertClosed(alert),
        className: clsx(`alert-wrapper-notification--${notifType}`, {
          'alert-wrapper-notification--alarmHigh': isAlarmHigh,
          'alert-wrapper-notification--alarmMedium': isAlarmMedium,
          'alert-wrapper-notification--alarmLow': isAlarmLow,
          'alert-wrapper-notification--intercom': isIntercom,
        }),
        duration:
          notifType === 'error' ? 0 : alertTimeout ? alertTimeout / 1000 : 5,
      });

      // Handle sound effects
      if (!audioByAlertId[alertId]) {
        if (['alarmHigh', 'alarmMedium', 'alarmLow'].includes(alertType)) {
          WebAudio.play(
            SoundEffects.ALARM_NOTIFICATION,
            Layers.ALARM_NOTIFICATIONS,
            { skipIfAlreadyPlaying: true },
          );
          audioByAlertId[alertId] = true;
        } else if (alertType === 'intercom') {
          WebAudio.play(
            SoundEffects.INTERCOM_NOTIFICATION,
            Layers.INTERCOM_NOTIFICATIONS,
            { skipIfAlreadyPlaying: true },
          );
          audioByAlertId[alertId] = true;
        }
      }
    },
    [api, canEditAlarms, dispatch, t, handleAlertClosed],
  );

  useEffect(() => {
    alertStack.forEach((alert) => {
      displayNotification(alert);
    });
  }, [alertStack, displayNotification]);

  const handleCloseAll = useCallback(() => {
    alertStack.forEach((alert) => {
      handleAlertClosed(alert);
    });
    api.destroy();
  }, [alertStack, api, handleAlertClosed]);

  return (
    <>
      {contextHolder}
      {alertStack.length > 3 && (
        <ClearAllAlertsButton onClick={handleCloseAll} />
      )}
    </>
  );
};

export default React.memo(AlertWrapperWithoutSuir);
