export const DonutIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10.5C8.65685 10.5 10 9.15685 10 7.5C10 5.84315 8.65685 4.5 7 4.5C5.34315 4.5 4 5.84315 4 7.5C4 9.15685 5.34315 10.5 7 10.5ZM7 13.5C10.3137 13.5 13 10.8137 13 7.5C13 4.18629 10.3137 1.5 7 1.5C3.68629 1.5 1 4.18629 1 7.5C1 10.8137 3.68629 13.5 7 13.5Z"
    />
  </svg>
);
