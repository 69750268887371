import { fromJS } from 'immutable';

import {
  LOADING,
  LIST,
  OPTIONS,
  ADD,
  REMOVE,
  COUNT,
  SET_OMNISELECT_COUNT,
  SET_OMNISELECT_LOADING,
  SET_OMNISELECT_LIST,
  SET_OMNISELECT_OPTIONS,
  ADD_OMNISELECT_ADD,
  ADD_OMNISELECT_REMOVE,
  REMOVE_OMNISELECT_REMOVE,
  REMOVE_OMNISELECT_ADD,
  RESET_OMNISELECT_STATE,
} from './constants';

const initialState = fromJS({
  // Loaders
  [LOADING]: true,
  [LIST]: fromJS([]),
  [OPTIONS]: fromJS([]),
  [ADD]: fromJS([]),
  [REMOVE]: fromJS([]),
  [COUNT]: -1,
});

function omniSelectReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OMNISELECT_COUNT:
      return state.set(COUNT, action[COUNT]);
    case SET_OMNISELECT_LOADING:
      return state.set(LOADING, action[LOADING]);
    case SET_OMNISELECT_LIST:
      return state.set(LIST, action[LIST] || fromJS([]));
    case SET_OMNISELECT_OPTIONS:
      return state.set(OPTIONS, action[OPTIONS] || fromJS([]));
    case ADD_OMNISELECT_ADD:
      return state.set(ADD, state.get(ADD).push(fromJS(action[ADD])));
    case ADD_OMNISELECT_REMOVE:
      return state.set(REMOVE, state.get(REMOVE).push(fromJS(action[REMOVE])));
    case REMOVE_OMNISELECT_REMOVE:
      return state.set(
        REMOVE,
        state
          .get(REMOVE)
          .filter((f) => String(f.get('id')) !== String(action[REMOVE].id)),
      );
    case REMOVE_OMNISELECT_ADD:
      return state.set(
        ADD,
        state
          .get(ADD)
          .filter((f) => String(f.get('id')) !== String(action[ADD].id)),
      );
    case RESET_OMNISELECT_STATE:
      return initialState;
    default:
      return state;
  }
}

export default omniSelectReducer;
