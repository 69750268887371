import { ReactNode } from 'react';

type Country = {
  short: string;
  phoneCode: number;
  emoji: ReactNode;
  name?: string;
};

export const countries: Country[] = [
  {
    emoji: '🇨🇦',
    phoneCode: 1,
    short: 'CA',
    name: 'Canada',
  },
  {
    emoji: '🇺🇸',
    phoneCode: 1,
    short: 'US',
    name: 'United States of America',
  },
  {
    emoji: '🇬🇧',
    phoneCode: 44,
    short: 'GB',
    name: 'United Kingdom',
  },
  {
    emoji: '🇧🇸',
    phoneCode: 1242,
    short: 'BS',
    name: 'The Bahamas',
  },
  {
    emoji: '🇧🇧',
    phoneCode: 1246,
    short: 'BB',
    name: 'Barbados',
  },
  {
    emoji: '🇦🇮',
    phoneCode: 1264,
    short: 'AI',
    name: 'Anguilla',
  },
  {
    emoji: '🇦🇬',
    phoneCode: 1268,
    short: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    emoji: '🇻🇮',
    phoneCode: 1284,
    short: 'VI',
    name: 'Virgin Islands, U.S.',
  },
  {
    emoji: '🇻🇬',
    phoneCode: 1340,
    short: 'VG',
    name: 'Virgin Islands, British',
  },
  {
    emoji: '🇰🇾',
    phoneCode: 1345,
    short: 'KY',
    name: 'Cayman Islands',
  },
  {
    emoji: '🇧🇲',
    phoneCode: 1441,
    short: 'BM',
    name: 'Bermuda',
  },
  {
    emoji: '🇬🇩',
    phoneCode: 1473,
    short: 'GD',
    name: 'Grenada',
  },
  {
    emoji: '🇹🇨',
    phoneCode: 1649,
    short: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    emoji: '🇲🇸',
    phoneCode: 1664,
    short: 'MS',
    name: 'Montserrat',
  },
  {
    emoji: '🇬🇺',
    phoneCode: 1671,
    short: 'GU',
    name: 'Guam',
  },
  {
    emoji: '🇦🇸',
    phoneCode: 1684,
    short: 'AS',
    name: 'American Samoa',
  },
  {
    emoji: '🇳🇱',
    phoneCode: 1721,
    short: 'SX',
    name: 'Sint Maarten (Dutch part)',
  },
  {
    emoji: '🇱🇨',
    phoneCode: 1758,
    short: 'LC',
    name: 'Saint Lucia',
  },
  {
    emoji: '🇩🇲',
    phoneCode: 1767,
    short: 'DM',
    name: 'Dominica',
  },
  {
    emoji: '🇻🇨',
    phoneCode: 1784,
    short: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    emoji: '🇵🇷',
    phoneCode: 1787,
    short: 'PR',
    name: 'Puerto Rico',
  },

  {
    emoji: '🇩🇴',
    phoneCode: 1809,
    short: 'DO',
    name: 'Dominican Republic',
  },
  {
    emoji: '🇹🇹',
    phoneCode: 1868,
    short: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    emoji: '🇰🇳',
    phoneCode: 1869,
    short: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    emoji: '🇯🇲',
    phoneCode: 1876,
    short: 'JM',
    name: 'Jamaica',
  },
  {
    emoji: '🇪🇬',
    phoneCode: 20,
    short: 'EG',
    name: 'Egypt',
  },
  {
    emoji: '🇲🇦',
    phoneCode: 212,
    short: 'MA',
    name: 'Morocco',
  },
  {
    emoji: '🇩🇿',
    phoneCode: 213,
    short: 'DZ',
    name: 'Algeria',
  },
  {
    emoji: '🇹🇳',
    phoneCode: 216,
    short: 'TN',
    name: 'Tunisia',
  },
  {
    emoji: '🇱🇾',
    phoneCode: 218,
    short: 'LY',
    name: 'Libya',
  },
  {
    emoji: '🇬🇲',
    phoneCode: 220,
    short: 'GM',
    name: 'Gambia',
  },
  {
    emoji: '🇸🇳',
    phoneCode: 221,
    short: 'SN',
    name: 'Senegal',
  },
  {
    emoji: '🇲🇷',
    phoneCode: 222,
    short: 'MR',
    name: 'Mauritania',
  },
  {
    emoji: '🇲🇱',
    phoneCode: 223,
    short: 'ML',
    name: 'Mali',
  },
  {
    emoji: '🇬🇳',
    phoneCode: 224,
    short: 'GN',
    name: 'Guinea',
  },
  {
    emoji: '🇨🇮',
    phoneCode: 225,
    short: 'CI',
    name: 'Ivory Coast',
  },
  {
    emoji: '🇧🇫',
    phoneCode: 226,
    short: 'BF',
    name: 'Burkina Faso',
  },
  {
    emoji: '🇳🇪',
    phoneCode: 227,
    short: 'NE',
    name: 'Niger',
  },
  {
    emoji: '🇹🇬',
    phoneCode: 228,
    short: 'TG',
    name: 'Togo',
  },
  {
    emoji: '🇧🇯',
    phoneCode: 229,
    short: 'BJ',
    name: 'Benin',
  },
  {
    emoji: '🇲🇺',
    phoneCode: 230,
    short: 'MU',
    name: 'Mauritius',
  },
  {
    emoji: '🇱🇷',
    phoneCode: 231,
    short: 'LR',
    name: 'Liberia',
  },
  {
    emoji: '🇸🇱',
    phoneCode: 232,
    short: 'SL',
    name: 'Sierra Leone',
  },
  {
    emoji: '🇬🇭',
    phoneCode: 233,
    short: 'GH',
    name: 'Ghana',
  },
  {
    emoji: '🇳🇬',
    phoneCode: 234,
    short: 'NG',
    name: 'Nigeria',
  },
  {
    emoji: '🇹🇩',
    phoneCode: 235,
    short: 'TD',
    name: 'Chad',
  },
  {
    emoji: '🇨🇫',
    phoneCode: 236,
    short: 'CF',
    name: 'Central African Republic',
  },
  {
    emoji: '🇨🇲',
    phoneCode: 237,
    short: 'CM',
    name: 'Cameroon',
  },
  {
    emoji: '🇨🇻',
    phoneCode: 238,
    short: 'CV',
    name: 'Cape Verde',
  },
  {
    emoji: '🇸🇹',
    phoneCode: 239,
    short: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    emoji: '🇬🇶',
    phoneCode: 240,
    short: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    emoji: '🇬🇦',
    phoneCode: 241,
    short: 'GA',
    name: 'Gabon',
  },
  {
    emoji: '🇨🇬',
    phoneCode: 242,
    short: 'CG',
    name: 'Congo',
  },
  {
    emoji: '🇨🇩',
    phoneCode: 243,
    short: 'CD',
    name: 'Democratic Republic of the Congo',
  },
  {
    emoji: '🇦🇴',
    phoneCode: 244,
    short: 'AO',
    name: 'Angola',
  },
  {
    emoji: '🇬🇼',
    phoneCode: 245,
    short: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    emoji: '🇸🇨',
    phoneCode: 248,
    short: 'SC',
    name: 'Seychelles',
  },
  {
    emoji: '🇸🇩',
    phoneCode: 249,
    short: 'SD',
    name: 'Sudan',
  },
  {
    emoji: '🇷🇼',
    phoneCode: 250,
    short: 'RW',
    name: 'Rwanda',
  },
  {
    name: 'Ethiopia',
    emoji: '🇪🇹',
    phoneCode: 251,
    short: 'ET',
  },
  {
    name: 'Somalia',
    emoji: '🇸🇴',
    phoneCode: 252,
    short: 'SO',
  },
  {
    name: 'Djibouti',
    emoji: '🇩🇯',
    phoneCode: 253,
    short: 'DJ',
  },
  {
    name: 'Kenya',
    emoji: '🇰🇪',
    phoneCode: 254,
    short: 'KE',
  },
  {
    name: 'Tanzania',
    emoji: '🇹🇿',
    phoneCode: 255,
    short: 'TZ',
  },
  {
    name: 'Uganda',
    emoji: '🇺🇬',
    phoneCode: 256,
    short: 'UG',
  },
  {
    name: 'Burundi',
    emoji: '🇧🇮',
    phoneCode: 257,
    short: 'BI',
  },
  {
    name: 'Mozambique',
    emoji: '🇲🇿',
    phoneCode: 258,
    short: 'MZ',
  },
  {
    name: 'Zambia',
    emoji: '🇿🇲',
    phoneCode: 260,
    short: 'ZM',
  },
  {
    name: 'Madagascar',
    emoji: '🇲🇬',
    phoneCode: 261,
    short: 'MG',
  },
  {
    name: 'Reunion',
    emoji: '🇷🇪',
    phoneCode: 262,
    short: 'RE',
  },
  {
    name: 'Zimbabwe',
    emoji: '🇿🇼',
    phoneCode: 263,
    short: 'ZW',
  },
  {
    name: 'Namibia',
    emoji: '🇳🇦',
    phoneCode: 264,
    short: 'NA',
  },
  {
    name: 'Malawi',
    emoji: '🇲🇼',
    phoneCode: 265,
    short: 'MW',
  },
  {
    name: 'Lesotho',
    emoji: '🇱🇸',
    phoneCode: 266,
    short: 'LS',
  },
  {
    name: 'Botswana',
    emoji: '🇧🇼',
    phoneCode: 267,
    short: 'BW',
  },
  {
    name: 'Eswatini',
    emoji: '🇸🇿',
    phoneCode: 268,
    short: 'SZ',
  },
  {
    name: 'Comoros',
    emoji: '🇰🇲',
    phoneCode: 269,
    short: 'KM',
  },
  {
    emoji: '🇾🇹',
    phoneCode: 269,
    short: 'YT',
    name: 'Mayotte',
  },
  {
    emoji: '🇿🇦',
    phoneCode: 27,
    short: 'ZA',
    name: 'South Africa',
  },
  {
    emoji: '🇪🇷',
    phoneCode: 291,
    short: 'ER',
    name: 'Eritrea',
  },
  {
    emoji: '🇦🇼',
    phoneCode: 297,
    short: 'AW',
    name: 'Aruba',
  },
  {
    emoji: '🇫🇴',
    phoneCode: 298,
    short: 'FO',
    name: 'Faroe Islands',
  },
  {
    emoji: '🇬🇱',
    phoneCode: 299,
    short: 'GL',
    name: 'Greenland',
  },
  {
    emoji: '🇬🇷',
    phoneCode: 30,
    short: 'GR',
    name: 'Greece',
  },
  {
    emoji: '🇳🇱',
    phoneCode: 31,
    short: 'NL',
    name: 'Netherlands',
  },
  {
    emoji: '🇧🇪',
    phoneCode: 32,
    short: 'BE',
    name: 'Belgium',
  },
  {
    emoji: '🇫🇷',
    phoneCode: 33,
    short: 'FR',
    name: 'France',
  },
  {
    emoji: '🇪🇸',
    phoneCode: 34,
    short: 'ES',
    name: 'Spain',
  },
  {
    emoji: '🇬🇮',
    phoneCode: 350,
    short: 'GI',
    name: 'Gibraltar',
  },
  {
    emoji: '🇵🇹',
    phoneCode: 351,
    short: 'PT',
    name: 'Portugal',
  },
  {
    emoji: '🇱🇺',
    phoneCode: 352,
    short: 'LU',
    name: 'Luxembourg',
  },
  {
    emoji: '🇮🇪',
    phoneCode: 353,
    short: 'IE',
    name: 'Ireland',
  },
  {
    emoji: '🇮🇸',
    phoneCode: 354,
    short: 'IS',
    name: 'Iceland',
  },
  {
    emoji: '🇦🇱',
    phoneCode: 355,
    short: 'AL',
    name: 'Albania',
  },
  {
    emoji: '🇲🇹',
    phoneCode: 356,
    short: 'MT',
    name: 'Malta',
  },
  {
    emoji: '🇨🇾',
    phoneCode: 357,
    short: 'CY',
    name: 'Cyprus',
  },
  {
    short: 'FI',
    emoji: '🇫🇮',
    phoneCode: 358,
    name: 'Finland',
  },
  {
    short: 'BG',
    emoji: '🇧🇬',
    phoneCode: 359,
    name: 'Bulgaria',
  },
  {
    short: 'HU',
    emoji: '🇭🇺',
    phoneCode: 36,
    name: 'Hungary',
  },
  {
    short: 'LT',
    emoji: '🇱🇹',
    phoneCode: 370,
    name: 'Lithuania',
  },
  {
    short: 'LV',
    emoji: '🇱🇻',
    phoneCode: 371,
    name: 'Latvia',
  },
  {
    short: 'EE',
    emoji: '🇪🇪',
    phoneCode: 372,
    name: 'Estonia',
  },
  {
    short: 'MD',
    emoji: '🇲🇩',
    phoneCode: 373,
    name: 'Moldova',
  },
  {
    short: 'AM',
    emoji: '🇦🇲',
    phoneCode: 374,
    name: 'Armenia',
  },
  {
    short: 'BY',
    emoji: '🇧🇾',
    phoneCode: 375,
    name: 'Belarus',
  },
  {
    short: 'AD',
    emoji: '🇦🇩',
    phoneCode: 376,
    name: 'Andorra',
  },
  {
    short: 'MC',
    emoji: '🇲🇨',
    phoneCode: 377,
    name: 'Monaco',
  },
  {
    short: 'SM',
    emoji: '🇸🇲',
    phoneCode: 378,
    name: 'San Marino',
  },
  {
    short: 'UA',
    emoji: '🇺🇦',
    phoneCode: 380,
    name: 'Ukraine',
  },
  {
    short: 'RS',
    emoji: '🇷🇸',
    phoneCode: 381,
    name: 'Serbia',
  },
  {
    short: 'ME',
    emoji: '🇲🇪',
    phoneCode: 382,
    name: 'Montenegro',
  },
  {
    short: 'HR',
    emoji: '🇭🇷',
    phoneCode: 385,
    name: 'Croatia',
  },
  {
    short: 'SI',
    emoji: '🇸🇮',
    phoneCode: 386,
    name: 'Slovenia',
  },
  {
    short: 'BA',
    emoji: '🇧🇦',
    phoneCode: 387,
    name: 'Bosnia and Herzegovina',
  },
  {
    short: 'MK',
    emoji: '🇲🇰',
    phoneCode: 389,
    name: 'North Macedonia',
  },
  {
    short: 'IT',
    emoji: '🇮🇹',
    phoneCode: 39,
    name: 'Italy',
  },
  {
    short: 'RO',
    emoji: '🇷🇴',
    phoneCode: 40,
    name: 'Romania',
  },
  {
    short: 'CH',
    emoji: '🇨🇭',
    phoneCode: 41,
    name: 'Switzerland',
  },
  {
    emoji: '🇨🇿',
    phoneCode: 420,
    short: 'CZ',
    name: 'Czech Republic',
  },
  {
    emoji: '🇸🇰',
    phoneCode: 421,
    short: 'SK',
    name: 'Slovakia',
  },
  {
    emoji: '🇱🇮',
    phoneCode: 423,
    short: 'LI',
    name: 'Liechtenstein',
  },
  {
    emoji: '🇦🇹',
    phoneCode: 43,
    short: 'AT',
    name: 'Austria',
  },
  {
    emoji: '🇩🇰',
    phoneCode: 45,
    short: 'DK',
    name: 'Denmark',
  },
  {
    emoji: '🇸🇪',
    phoneCode: 46,
    short: 'SE',
    name: 'Sweden',
  },
  {
    emoji: '🇳🇴',
    phoneCode: 47,
    short: 'NO',
    name: 'Norway',
  },
  {
    emoji: '🇵🇱',
    phoneCode: 48,
    short: 'PL',
    name: 'Poland',
  },
  {
    emoji: '🇩🇪',
    phoneCode: 49,
    short: 'DE',
    name: 'Germany',
  },
  {
    emoji: '🇧🇿',
    phoneCode: 501,
    short: 'BZ',
    name: 'Belize',
  },
  {
    emoji: '🇬🇹',
    phoneCode: 502,
    short: 'GT',
    name: 'Guatemala',
  },
  {
    emoji: '🇸🇻',
    phoneCode: 503,
    short: 'SV',
    name: 'El Salvador',
  },
  {
    emoji: '🇭🇳',
    phoneCode: 504,
    short: 'HN',
    name: 'Honduras',
  },
  {
    emoji: '🇳🇮',
    phoneCode: 505,
    short: 'NI',
    name: 'Nicaragua',
  },
  {
    emoji: '🇨🇷',
    phoneCode: 506,
    short: 'CR',
    name: 'Costa Rica',
  },
  {
    emoji: '🇵🇦',
    phoneCode: 507,
    short: 'PA',
    name: 'Panama',
  },
  {
    emoji: '🇵🇲',
    phoneCode: 508,
    short: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    emoji: '🇭🇹',
    phoneCode: 509,
    short: 'HT',
    name: 'Haiti',
  },
  {
    emoji: '🇵🇪',
    phoneCode: 51,
    short: 'PE',
    name: 'Peru',
  },
  {
    emoji: '🇲🇽',
    phoneCode: 52,
    short: 'MX',
    name: 'Mexico',
  },
  {
    emoji: '🇨🇺',
    phoneCode: 53,
    short: 'CU',
    name: 'Cuba',
  },
  {
    emoji: '🇦🇷',
    phoneCode: 54,
    short: 'AR',
    name: 'Argentina',
  },
  {
    emoji: '🇧🇷',
    phoneCode: 55,
    short: 'BR',
    name: 'Brazil',
  },
  {
    emoji: '🇨🇱',
    phoneCode: 56,
    short: 'CL',
    name: 'Chile',
  },
  {
    emoji: '🇨🇴',
    phoneCode: 57,
    short: 'CO',
    name: 'Colombia',
  },
  {
    emoji: '🇻🇪',
    phoneCode: 58,
    short: 'VE',
    name: 'Venezuela',
  },
  {
    emoji: '🇬🇵',
    phoneCode: 590,
    short: 'GP',
    name: 'Guadeloupe',
  },
  {
    emoji: '🇧🇴',
    phoneCode: 591,
    short: 'BO',
    name: 'Bolivia',
  },
  {
    emoji: '🇬🇾',
    phoneCode: 592,
    short: 'GY',
    name: 'Guyana',
  },
  {
    emoji: '🇪🇨',
    phoneCode: 593,
    short: 'EC',
    name: 'Ecuador',
  },
  {
    emoji: '🇬🇫',
    phoneCode: 594,
    short: 'GF',
    name: 'French Guiana',
  },
  {
    emoji: '🇵🇾',
    phoneCode: 595,
    short: 'PY',
    name: 'Paraguay',
  },
  {
    emoji: '🇲🇶',
    phoneCode: 596,
    short: 'MQ',
    name: 'Martinique',
  },
  {
    emoji: '🇸🇷',
    phoneCode: 597,
    short: 'SR',
    name: 'Suriname',
  },
  {
    emoji: '🇺🇾',
    phoneCode: 598,
    short: 'UY',
    name: 'Uruguay',
  },
  {
    emoji: '🇨🇼',
    phoneCode: 599,
    short: 'CW',
    name: 'Curaçao',
  },
  {
    emoji: '🇲🇾',
    phoneCode: 60,
    short: 'MY',
    name: 'Malaysia',
  },
  {
    emoji: '🇦🇺',
    phoneCode: 61,
    short: 'AU',
    name: 'Australia',
  },
  {
    emoji: '🇮🇩',
    phoneCode: 62,
    short: 'ID',
    name: 'Indonesia',
  },
  {
    emoji: '🇵🇭',
    phoneCode: 63,
    short: 'PH',
    name: 'Philippines',
  },
  {
    emoji: '🇳🇿',
    phoneCode: 64,
    short: 'NZ',
    name: 'New Zealand',
  },
  {
    emoji: '🇸🇬',
    phoneCode: 65,
    short: 'SG',
    name: 'Singapore',
  },
  {
    emoji: '🇹🇭',
    phoneCode: 66,
    short: 'TH',
    name: 'Thailand',
  },
  {
    emoji: '🇹🇱',
    phoneCode: 670,
    short: 'TL',
    name: 'Timor-Leste',
  },
  {
    emoji: '🇧🇳',
    phoneCode: 673,
    short: 'BN',
    name: 'Brunei Darussalam',
  },
  {
    emoji: '🇵🇬',
    phoneCode: 675,
    short: 'PG',
    name: 'Papua New Guinea',
  },
  {
    emoji: '🇹🇴',
    phoneCode: 676,
    short: 'TO',
    name: 'Tonga',
  },
  {
    emoji: '🇸🇧',
    phoneCode: 677,
    short: 'SB',
    name: 'Solomon Islands',
  },
  {
    emoji: '🇻🇺',
    phoneCode: 678,
    short: 'VU',
    name: 'Vanuatu',
  },
  {
    emoji: '🇫🇯',
    phoneCode: 679,
    short: 'FJ',
    name: 'Fiji',
  },
  {
    emoji: '🇵🇼',
    phoneCode: 680,
    short: 'PW',
    name: 'Palau',
  },
  {
    emoji: '🇨🇰',
    phoneCode: 682,
    short: 'CK',
    name: 'Cook Islands',
  },
  {
    emoji: '🇼🇸',
    phoneCode: 685,
    short: 'WS',
    name: 'Samoa',
  },
  {
    emoji: '🇰🇮',
    phoneCode: 686,
    short: 'KI',
    name: 'Kiribati',
  },
  {
    emoji: '🇳🇨',
    phoneCode: 687,
    short: 'NC',
    name: 'New Caledonia',
  },
  {
    emoji: '🇵🇫',
    phoneCode: 689,
    short: 'PF',
    name: 'French Polynesia',
  },
  {
    emoji: '🇰🇿',
    phoneCode: 7,
    short: 'KZ',
    name: 'Kazakhstan',
  },
  {
    emoji: '🇷🇺',
    phoneCode: 7,
    short: 'RU',
    name: 'Russia',
  },
  {
    emoji: '🇯🇵',
    phoneCode: 81,
    short: 'JP',
    name: 'Japan',
  },
  {
    emoji: '🇰🇷',
    phoneCode: 82,
    short: 'KR',
    name: 'South Korea',
  },
  {
    emoji: '🇻🇳',
    phoneCode: 84,
    short: 'VN',
    name: 'Vietnam',
  },
  {
    emoji: '🇭🇰',
    phoneCode: 852,
    short: 'HK',
    name: 'Hong Kong',
  },
  {
    emoji: '🇲🇴',
    phoneCode: 853,
    short: 'MO',
    name: 'Macau',
  },
  {
    emoji: '🇰🇭',
    phoneCode: 855,
    short: 'KH',
    name: 'Cambodia',
  },
  {
    emoji: '🇱🇦',
    phoneCode: 856,
    short: 'LA',
    name: 'Laos',
  },
  {
    emoji: '🇨🇳',
    phoneCode: 86,
    short: 'CN',
    name: 'China',
  },
  {
    emoji: '🇧🇩',
    phoneCode: 880,
    short: 'BD',
    name: 'Bangladesh',
  },
  {
    emoji: '🇹🇼',
    phoneCode: 886,
    short: 'TW',
    name: 'Taiwan',
  },
  {
    emoji: '🇹🇷',
    phoneCode: 90,
    short: 'TR',
    name: 'Turkey',
  },
  {
    emoji: '🇮🇳',
    phoneCode: 91,
    short: 'IN',
    name: 'India',
  },
  {
    emoji: '🇵🇰',
    phoneCode: 92,
    short: 'PK',
    name: 'Pakistan',
  },
  {
    emoji: '🇦🇫',
    phoneCode: 93,
    short: 'AF',
    name: 'Afghanistan',
  },
  {
    emoji: '🇱🇰',
    phoneCode: 94,
    short: 'LK',
    name: 'Sri Lanka',
  },
  {
    emoji: '🇲🇲',
    phoneCode: 95,
    short: 'MM',
    name: 'Myanmar',
  },
  {
    emoji: '🇲🇻',
    phoneCode: 960,
    short: 'MV',
    name: 'Maldives',
  },
  {
    emoji: '🇱🇧',
    phoneCode: 961,
    short: 'LB',
    name: 'Lebanon',
  },
  {
    emoji: '🇯🇴',
    phoneCode: 962,
    short: 'JO',
    name: 'Jordan',
  },
  {
    emoji: '🇸🇾',
    phoneCode: 963,
    short: 'SY',
    name: 'Syrian Arab Republic',
  },
  {
    emoji: '🇮🇶',
    phoneCode: 964,
    short: 'IQ',
    name: 'Iraq',
  },
  {
    emoji: '🇰🇼',
    phoneCode: 965,
    short: 'KW',
    name: 'Kuwait',
  },
  {
    emoji: '🇸🇦',
    phoneCode: 966,
    short: 'SA',
    name: 'Saudi Arabia',
  },
  {
    emoji: '🇾🇪',
    phoneCode: 967,
    short: 'YE',
    name: 'Yemen',
  },
  {
    emoji: '🇴🇲',
    phoneCode: 968,
    short: 'OM',
    name: 'Oman',
  },
  {
    emoji: '🇵🇸',
    phoneCode: 970,
    short: 'BL',
    name: 'Palestine',
  },
  {
    emoji: '🇦🇪',
    phoneCode: 971,
    short: 'AE',
    name: 'United Arab Emirates',
  },
  {
    emoji: '🇮🇱',
    phoneCode: 972,
    short: 'IL',
    name: 'Israel',
  },
  {
    emoji: '🇧🇭',
    phoneCode: 973,
    short: 'BH',
    name: 'Bahrain',
  },
  {
    emoji: '🇶🇦',
    phoneCode: 974,
    short: 'QA',
    name: 'Qatar',
  },
  {
    emoji: '🇧🇹',
    phoneCode: 975,
    short: 'BT',
    name: 'Bhutan',
  },
  {
    emoji: '🇲🇳',
    phoneCode: 976,
    short: 'MN',
    name: 'Mongolia',
  },
  {
    emoji: '🇳🇵',
    phoneCode: 977,
    short: 'NP',
    name: 'Nepal',
  },
  {
    emoji: '🇮🇷',
    phoneCode: 98,
    short: 'IR',
    name: 'Iran',
  },
  {
    emoji: '🇹🇯',
    phoneCode: 992,
    short: 'TJ',
    name: 'Tajikistan',
  },
  {
    emoji: '🇹🇲',
    phoneCode: 993,
    short: 'TM',
    name: 'Turkmenistan',
  },
  {
    emoji: '🇦🇿',
    phoneCode: 994,
    short: 'AZ',
    name: 'Azerbaijan',
  },
  {
    emoji: '🇬🇪',
    phoneCode: 995,
    short: 'GE',
    name: 'Georgia',
  },
  {
    emoji: '🇰🇬',
    phoneCode: 996,
    short: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    emoji: '🇺🇿',
    phoneCode: 998,
    short: 'UZ',
    name: 'Uzbekistan',
  },
];
