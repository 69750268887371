import {
  put,
  call,
  takeEvery,
  all,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { requestAndSet, sendToHelium } from 'utils/helpers';

import { setOrgContainerReducer } from 'routes/OrgContainer/actions';
import { REQUEST_UPDATE_TABLE, REQUEST_UPDATE_DATA_ORDER } from './constants';
import { setTableUpdating } from './actions';

// Helper functions
const createFilterValue = (filterValue, isMultiSelect) => {
  // Escape offending filter characters
  const sanitizedFilterValue = filterValue.replace(/[\\():]/g, '\\$&');

  // `~` is for fuzzy/partial matching, and `=` is for exact match.
  // Default is `~`, but we want to be explict from here on out.
  return `${sanitizedFilterValue
    .split(',')
    .map((str) => `${isMultiSelect ? '=' : '~'}${str}`)}`;
};

export const createApiQueryStringParamsFromReactTableState = ({
  offset,
  limit,
  filters = [],
  sortBy = [],
  columns,
  globalFilter,
  defaultQueryStringParams = {},
}) => {
  const queryStringParams = { ...defaultQueryStringParams };

  // Set offset if there is one
  if (offset) {
    queryStringParams.offset = offset;
  }

  // Set limit if there is one
  if (limit) {
    queryStringParams.limit = limit;
  }

  // Set filter option if any filtered columns
  if (filters.length) {
    queryStringParams.filter = filters.reduce(
      (acc, { id, value }) => {
        const isMultiSelect =
          columns?.find((col) => (col.id || col.accessor) === id)?.filter ===
          'multiselect';

        return `${acc ? `${acc} ` : ''}${id}:(${createFilterValue(
          value,
          isMultiSelect,
        )})`;
      },
      `${queryStringParams.filter || ''}`,
    );
  }

  // Set sort queryStringParams if any sorted columns
  if (sortBy.length) {
    const { id, desc } = sortBy[0];
    queryStringParams.sort = id;
    queryStringParams.order = desc ? 'desc' : 'asc';
  }

  // Set query if any search query
  if (globalFilter) {
    queryStringParams.q = globalFilter;
  }

  return queryStringParams;
};

export const createValidCsvData = (data) =>
  data.map((row) =>
    Object.entries(row).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          typeof value === 'string' ? value.replace(/^[@+\-=]/g, "'$&") : value,
      }),
      {},
    ),
  );

// Updates the table using the passed API endpoint
function* requestUpdateTable({
  page,
  tableId = null,
  state,
  endpointName,
  endpointQueryStringParams: defaultQueryStringParams,
  additionEndpointRequisites = [],
  setterActionType,
  gotoPage,
  tableColumns: columns,
  dataFilter,
}) {
  // Set loading to true immediately so we see loader even if not latest call
  yield put(setTableUpdating({ page, tableId, loading: true }));

  if (endpointName && setterActionType) {
    const { pageIndex, pageSize: limit, filters, sortBy, globalFilter } = state;
    const params = {
      offset: pageIndex * limit,
      limit,
      filters,
      columns,
      sortBy,
      globalFilter,
      defaultQueryStringParams,
    };

    yield call(requestAndSet, endpointName, additionEndpointRequisites, {
      queryStringParams: createApiQueryStringParamsFromReactTableState(params),
      createSetterAction: (metadata) => {
        const finalMetadata = {
          ...metadata,
          data: dataFilter ? metadata.data.filter(dataFilter) : metadata.data,
        };
        return setOrgContainerReducer(setterActionType, page, finalMetadata);
      },
      suppressErrorMessage: process.env.NODE_ENV === 'production',
    });
  }

  // Reset to page 1 if paginated and a filter was used
  if (gotoPage) gotoPage(0);

  yield put(setTableUpdating({ page, tableId, loading: false }));
}

function* requestUpdateDataOrder({
  originalMetadata,
  reorderedData,
  page,
  setterActionType,
  updaterEndpoint,
  endpointRequisites = [],
  payloadCallback,
  successMessage,
  iteratePayload = false,
  iteratePayloadCallback = (x) => x,
}) {
  // Update database so order matches what is shown
  const payload = payloadCallback(reorderedData);

  if (updaterEndpoint) {
    let errorDuringSend = false;
    // this is probably temp, but we should be able to have the ability to send data to endpoints that aren't "batch friendly"
    // at least until we make a new batch endpoint
    if (iteratePayload) {
      // need endpointRequisites to be a function so we can inject the last param from payload
      if (typeof endpointRequisites !== 'function') {
        throw new Error(
          'If using iteratePayload, endpointRequisites must be a function (otherwise how will you know the last url param?)',
        );
      }
      if (typeof iteratePayloadCallback !== 'function') {
        throw new Error(
          'If using iteratePayload, iteratePayloadCallback must be a function (to put the payload in the right format)',
        );
      }

      while (payload.length) {
        const p = payload.pop();
        const actualEndpointRequisites = endpointRequisites(p);
        const actualPayload = iteratePayloadCallback(p);
        const { errorMessage } = yield call(
          sendToHelium,
          updaterEndpoint,
          actualEndpointRequisites,
          actualPayload,
          { successMessage },
        );
        if (errorMessage) {
          errorDuringSend = true;
          return;
        }
      }
    } else {
      const { errorMessage } = yield call(
        sendToHelium,
        updaterEndpoint,
        endpointRequisites,
        payload,
        { successMessage },
      );
      errorDuringSend = !!errorMessage;
    }

    // Revert to original order if there is an error
    if (errorDuringSend) {
      yield put(
        setOrgContainerReducer(setterActionType, page, originalMetadata),
      );
    }
  }
}

function* rootSaga() {
  yield all([
    takeLatest(REQUEST_UPDATE_TABLE, requestUpdateTable),
    takeEvery(REQUEST_UPDATE_DATA_ORDER, requestUpdateDataOrder),
  ]);
  yield take(LOCATION_CHANGE);
}

export default rootSaga;
