export const formatMailroomAddress = (
  mailroom: Api.Response['describeMailroom'],
) =>
  [
    mailroom.address,
    mailroom.address2,
    mailroom.city,
    mailroom.state,
    mailroom.zip,
    mailroom.country,
  ]
    .filter(Boolean)
    .join(', ');
