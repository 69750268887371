// import { createSelector } from 'reselect'

export const selectSlideOuts = () => (state) =>
  state.getIn(['global', 'slideOuts']);

// import { createSelector } from 'reselect'

// export const selectGlobalState = () => state => {
//   console.log('state', state)
//   return state.get('global')
// }
// // export const selectSlideOuts = state => state.getIn(['global', 'slideOuts'])

// export const selectSlideOuts = createSelector(
//   selectGlobalState(),
//   globalState => globalState.get('slideOuts')
// )
