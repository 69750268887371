import clsx from 'clsx';
import { CustomIconComponentProps, OpIcon } from 'new-components/OpIcon/OpIcon';
import { BatteryFull } from 'new-components/svgs/BatteryFull';
import { BatteryMedium } from 'new-components/svgs/BatteryMedium';
import { BatteryLow } from 'new-components/svgs/BatteryLow';
import { BatteryEmpty } from 'new-components/svgs/BatteryEmpty';

import './OpBatteryIcon.scss';

export type BatteryIconVariant = 'full' | 'medium' | 'low' | 'empty';

interface OpBatteryIconProps extends CustomIconComponentProps {
  variant: BatteryIconVariant;
}

const iconMap = {
  full: BatteryFull,
  medium: BatteryMedium,
  low: BatteryLow,
  empty: BatteryEmpty,
};

export const OpBatteryIcon = ({ className, variant }: OpBatteryIconProps) => {
  const iconComponent = iconMap[variant];

  return (
    <OpIcon
      component={iconComponent}
      className={clsx(
        'op-battery-icon',
        `op-battery-icon--${variant}`,
        className,
      )}
    />
  );
};
