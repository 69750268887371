import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'semantic-ui-react/dist/es/elements/Icon/Icon';
import { opMoment } from 'utils/dates';
import intersectionBy from 'lodash/intersectionBy';
import { useDispatch } from 'react-redux';
import { requestUnlockWithCloudKey } from 'global/cloudKey/actions';
import { Popup } from 'components/Popup';
import { useSelectorJs } from 'utils/customHooks';
import { selectCloudKeys } from 'global/cloudKey/selectors';
import {
  selectCurrentOrgId,
  selectCurrentUserId,
} from 'global/accessToken/selectors';
import { heliumReceive } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { OpSelect } from 'new-components/DLS/OpSelect/OpSelect';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import LockOutlined from '@ant-design/icons/LockOutlined';
import { sortOptionsAlphabetically } from 'utils/sortOptionsAlphabetically';
import { Clickable } from 'components/Clickable/Clickable';

const styles = {
  wrapper: { paddingTop: 9, marginLeft: 8 },
  icon: { height: 'auto' },
};

export const EntryUnlockButton = memo(({ cameraId, style }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [userEntries, setUserEntries] = useState([]);
  const [acuEntries, setAcuEntries] = useState([]);
  const [selectedEntryId, setSelectedEntryId] = useState(null);

  const cloudKeys = useSelectorJs(selectCloudKeys());
  const currentOrgId = useSelectorJs(selectCurrentOrgId());
  const currentUserId = useSelectorJs(selectCurrentUserId());
  const currentIdentityLanguage = useSelectorJs(
    selectCurrentIdentityLanguage(),
  );

  // Get and set required data
  useEffect(() => {
    if (!currentOrgId || !currentUserId) {
      return;
    }

    (async () => {
      const [{ data: listUserEntriesData }, { data: listCameraEntriesData }] =
        await Promise.all([
          heliumReceive('listUserEntries', [currentOrgId, currentUserId], {
            headers: {
              'Accept-Language': currentIdentityLanguage,
            },
          }),
          heliumReceive('listCameraEntries', [currentOrgId, cameraId], {
            headers: {
              'Accept-Language': currentIdentityLanguage,
            },
          }),
        ]);

      if (listUserEntriesData) {
        setUserEntries(listUserEntriesData);
      }
      if (listCameraEntriesData) {
        setAcuEntries(listCameraEntriesData);
      }
    })();
  }, [cameraId, currentIdentityLanguage, currentOrgId, currentUserId]);

  const userHasValidCloudKey = !!cloudKeys.find(
    ({ startDate, endDate }) =>
      (startDate === null || opMoment(startDate).isBefore(opMoment())) &&
      (endDate === null || opMoment(endDate).isAfter(opMoment())),
  );

  // Don't show if user has no valid cloudKeys (return immediately here so don't do the following calcs)
  if (!userHasValidCloudKey) return null;

  const mapper = ({ id: entryId, name: entryName }) => ({
    key: entryId,
    value: entryId,
    text: entryName,
  });

  const unlockableEntryDropdownOptions = intersectionBy(
    acuEntries.map(mapper),
    userEntries.map(mapper),
    'value',
  );

  // Don't show if no acu entries or if the user doesn't have access to any entries
  if (!unlockableEntryDropdownOptions.length) return null;

  if (unlockableEntryDropdownOptions.length === 1) {
    const handleButtonClick = () => {
      const entryId = unlockableEntryDropdownOptions[0].value;
      dispatch(requestUnlockWithCloudKey(entryId));
    };

    return (
      <Popup
        trigger={
          <Clickable
            style={{ ...styles.wrapper, ...style }}
            className="player-controls-button op-justify-content-center op-cursor-pointer"
            onClick={handleButtonClick}
          >
            <Icon
              size="large"
              style={styles.icon}
              fitted
              inverted
              name="unlock"
            />
          </Clickable>
        }
        content={t('Unlock entry')}
        position="bottom center"
      />
    );
  }

  const handleEntryChange = (entryId) => {
    setSelectedEntryId(entryId);
  };

  const handleUnlockClick = () => {
    dispatch(
      requestUnlockWithCloudKey(
        selectedEntryId || unlockableEntryDropdownOptions[0].value,
      ),
    );
  };

  const options = unlockableEntryDropdownOptions.map(({ value, text }) => ({
    value,
    label: text,
  }));

  return (
    <OpSpace.Compact style={styles.wrapper}>
      <OpSelect
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        options={options}
        value={selectedEntryId || options[0].value}
        onChange={handleEntryChange}
        filterSort={sortOptionsAlphabetically}
      />
      <OpButton onClick={handleUnlockClick}>
        <LockOutlined />
      </OpButton>
    </OpSpace.Compact>
  );
});

EntryUnlockButton.displayName = 'EntryUnlockButton';

EntryUnlockButton.propTypes = {
  cameraId: PropTypes.number.isRequired,
  style: PropTypes.object,
};
