import { ComponentProps } from 'react';
import Col from 'antd/es/col';
import clsx from 'clsx';

interface OpColProps extends ComponentProps<typeof Col> {
  testId?: string;
}

export const OpCol = ({
  className,
  testId = 'op-col',
  ...colProps
}: OpColProps) => {
  return (
    <Col
      className={clsx('op-col', className)}
      data-testid={testId}
      {...colProps}
    />
  );
};
