import { Outlet } from 'react-router-dom-v5-compat';
import { useVerifyAuthentication } from 'utils/customHooks/useVerifyAuthentication';

/**
 * @component
 * @description A wrapper component for authenticated routes to check login
 */
export const OpAuthenticatedRoute = () => {
  // Check if logged in, if not redirect the user to login
  const { isLoading } = useVerifyAuthentication();

  return isLoading ? null : <Outlet />;
};
