import { ComponentProps, Key, ReactNode } from 'react';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Table, { ColumnType } from 'antd/es/table';
import clsx from 'clsx';
import { SelectFilter } from './components/SelectFilter';
import { tableStateKeys } from './constants/tableStateKeys';

import './OpTableCore.scss';

export type OpTableRecordType = Record<string, any>;

type HiddenOrRequired =
  | {
      hidden?: boolean;
      required?: never;
    }
  | {
      hidden?: never;
      required?: boolean;
    };

export type OpTableColumn<
  RecordType extends OpTableRecordType = OpTableRecordType,
> = Omit<ColumnType<RecordType>, 'defaultFilteredValue'> & {
  width?: number;
  label?: string;
  tooltip?: string | ReactNode;
  createCsvExportCellValue?(record: RecordType): string;
} & HiddenOrRequired;

type InputFilterType = {
  type: 'input';
};

type SelectFilterType = {
  type: 'select' | 'multiSelect';
  options: ComponentProps<typeof SelectFilter>['options'];
};

export type OpTableRawColumnType = Omit<
  OpTableColumn,
  'title' | 'hidden' | 'required'
> & {
  filter?: InputFilterType | SelectFilterType;
  defaultFilteredValue?: string[] | null;
} & (
    | { key: Key }
    | { dataIndex: string | number | readonly (string | number)[] }
  ) &
  HiddenOrRequired;

type OpTableCorePropsType<RecordType extends OpTableRecordType> =
  ComponentProps<typeof Table<RecordType>> & {
    loading?: boolean;
    testId?: string;
    gtm?: string;
    // Unique key used in the uiState table as uiComponentKey
    uiStateKey: Utils.ValueOf<typeof tableStateKeys>;
  };

interface AllowExport {
  /**
   * @deprecated `dataMapCallback` is deprecated. Please use
   * `createCsvExportCellValue` in the table columns directly.
   */
  dataMapCallback?(record: OpTableRecordType): OpTableRecordType;
  columnsFilterCallback?(record: OpTableColumn): boolean;
  filename?: string;
}

export type AllowExportType = boolean | AllowExport;

export const OpTableCore = <RecordType extends OpTableRecordType>({
  className,
  loading = false,
  testId = 'op-table-core',
  gtm,
  uiStateKey,
  ...tableProps
}: OpTableCorePropsType<RecordType>) => {
  /**
   * Manually throwing an error here incase this table is used in a non Typescript file
   * Realistically this will never happen. Currently we have no case of this occurring so its safe. Any new
   * code will catch this in development thus throwing is just very blatant for the dev.
   */
  if (!uiStateKey) {
    throw new Error('uiStateKey prop is required');
  }

  return (
    <Table
      data-testid={testId}
      data-gtm={gtm}
      className={clsx('op-table-core', className)}
      size="middle"
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined />,
      }}
      {...tableProps}
    />
  );
};
