import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { heliumSend } from 'utils/helpers';
import { DownloadSvg } from 'components/svgs/DownloadSvg';
import { Popup } from 'components/Popup';
import { useTranslation } from 'react-i18next';
import { useSelectorJs } from 'utils/customHooks';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { Clickable } from 'components/Clickable/Clickable';

const styles = {
  wrapper: { paddingTop: 9, marginLeft: 8 },
};

const VideoDownloadButton = memo(
  ({ orgId, opvideoDeviceId, startDate, endDate }) => {
    const { t } = useTranslation();
    const currentIdentityLanguage = useSelectorJs(
      selectCurrentIdentityLanguage(),
    );

    const handleClick = useCallback(async () => {
      const { data } = await heliumSend(
        'opvideoDeviceCreateClipDownload',
        [orgId, opvideoDeviceId],
        { startDate, endDate },
        {
          headers: {
            'Accept-Language': currentIdentityLanguage,
          },
        },
      );

      const downloadUrl = data?.downloadUrl;

      if (downloadUrl) {
        window.open(downloadUrl, '_blank');
      }
    }, [orgId, opvideoDeviceId, startDate, endDate, currentIdentityLanguage]);

    if (!orgId || !opvideoDeviceId || !startDate || !endDate) {
      return null;
    }

    return (
      <Popup
        trigger={
          <Clickable
            style={styles.wrapper}
            className="player-controls-button op-justify-content-center op-cursor-pointer"
            onClick={handleClick}
          >
            <DownloadSvg
              width="21"
              height="21"
              viewBox="0 0 12 12"
              fill="white"
              stroke="white"
              strokeWidth="0.4"
            />
          </Clickable>
        }
        content={t('Download video')}
        position="bottom center"
      />
    );
  },
);

VideoDownloadButton.displayName = 'VideoDownloadButton';

VideoDownloadButton.propTypes = {
  orgId: PropTypes.number.isRequired,
  opvideoDeviceId: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default VideoDownloadButton;
