import { Dispatch, SetStateAction, useState } from 'react';
import { useDebounce } from 'react-use';

/** The state will be set immediately, but the debounced state
 * will not be set until after the delay (ms).
 *
 * This is useful for things like inputs that are used for fetching
 * data, as you typically want to see the input state change immediately,
 * but you don't want to fetch data for each character typed */

export const useDebouncedState = <TableState>(
  defaultState: TableState,
  delay = 300,
): [TableState, Dispatch<SetStateAction<TableState>>, TableState] => {
  const [state, setState] = useState<TableState>(defaultState);
  const [debouncedState, setDebouncedState] =
    useState<TableState>(defaultState);

  useDebounce(
    () => {
      setDebouncedState(state);
    },
    delay,
    [JSON.stringify(state)],
  );

  return [debouncedState, setState, state];
};
