import { ReactNode, RefObject } from 'react';
import clsx from 'clsx';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { OpInfoTooltip } from '../OpInfoTooltip/OpInfoTooltip';

import './OpSection.scss';

interface OpSectionProps {
  children: ReactNode;
  label?: ReactNode;
  className?: string;
  disabled?: boolean;
  tooltip?: ReactNode;
  testId?: string;
  loading?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
}

export const OpSection = ({
  children,
  label,
  className,
  disabled,
  tooltip,
  loading = false,
  testId = 'op-section',
  innerRef,
}: OpSectionProps) => (
  <div
    data-testid={testId}
    className={clsx(
      'op-section',
      { 'op-section--disabled': disabled },
      className,
    )}
    ref={innerRef}
  >
    {Boolean(label || tooltip) && (
      <div>
        {Boolean(label) && <span className="op-section__label">{label}</span>}
        {Boolean(tooltip) && (
          <OpInfoTooltip className="op-section__tooltip" title={tooltip} />
        )}
      </div>
    )}
    {loading ? (
      <div className="op-section__loading-wrapper">
        <LoadingOutlined />
      </div>
    ) : (
      children
    )}
  </div>
);
