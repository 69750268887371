import {
  SAVE_TABLE_STATE,
  REQUEST_AND_SET_GENERAL_WRAPPER,
  SET_USER_GROUPS,
  SET_ORG,
  SET_GROUPS,
  SET_GROUP,
  SET_SITES,
  SET_ZONES,
  SET_ZONE,
  SET_ZONE_GROUPS,
  SET_ENTRIES,
  SET_ENTRY_USER_SCHEDULES,
  SET_USERS,
  SET_FILTERED_USERS_COUNT,
  SET_IDENTITY_PROVIDERS,
  SET_ENTRY_STATES,
  SET_TRIGGER_METHODS,
  SET_IOS,
  SET_SCHEDULES,
  SET_GROUP_SCHEDULES,
  SET_ACUS,
  SET_SCOPE_RESOURCES,
  SET_ROLES,
  SET_ROLE,
  REQUEST_GET_CURRENT_ORG_DATA,
  SET_ACTIVITY,
  SET_ALARM_ACTIVITY,
  SET_VISUAL_ACTIVITY,
  SET_USER_ACTIVITY,
  SET_IDENTITY_PROVIDER_GROUPS,
  SET_IDENTITY_PROVIDER_GROUP_RELATIONS,
  SET_ALL_ACU_ENTRIES_USERS,
  SET_USER_ENTRIES,
  SET_CREDENTIAL_TYPES,
  SET_CREDENTIALS,
  SET_CARD_FORMATS,
  SET_SCHEDULE_TYPES,
  SET_SCHEDULE_EVENTS,
  SET_AUDIT_LOGS,
  SET_SHARED_USERS,
  SET_ACU_PORTS,
  SET_REQUEST_TYPES,
  SET_PROTON_STATUSES,
  SET_USER_CREDENTIALS,
  SET_NAMESPACES,
  SET_TOTAL_USERS_COUNT,
  SET_HOOK_EVENTS,
  REQUEST_PAGE_DATA_GENERIC,
  REQUEST_HELIUM_DATA_GENERIC,
  SET_ALLEGION_INVITE_STATE,
  SET_NETSUITE_PACKAGE_PLANS,
  RESET_HLS_URL,
  RESET_POSTER_URL,
  SLIDE_OUT_SUCCESSFUL,
  UPDATE_OPTIONS,
  SET_ALARM_CONFIGURATIONS,
} from './constants';

// ** this line ends new testing actions

export function setUsers(page, data) {
  return {
    type: SET_USERS,
    page,
    data,
  };
}

export function setFilteredUsersCount(page, data) {
  return {
    type: SET_FILTERED_USERS_COUNT,
    page,
    data,
  };
}

export function setTotalUsersCount(page, data) {
  return {
    type: SET_TOTAL_USERS_COUNT,
    page,
    data,
  };
}

export function setGroups(page, data) {
  return {
    type: SET_GROUPS,
    page,
    data,
  };
}

export function setOrg(page, data) {
  return {
    type: SET_ORG,
    page,
    data,
  };
}

export function setNetsuitePackagePlans(page, data) {
  return {
    type: SET_NETSUITE_PACKAGE_PLANS,
    page,
    data,
  };
}

export function setUserEntries(page, data) {
  return {
    type: SET_USER_ENTRIES,
    page,
    data,
  };
}

export function setEntries(page, data) {
  return {
    type: SET_ENTRIES,
    page,
    data,
  };
}

export function setEntryUserSchedules(page, data) {
  return {
    type: SET_ENTRY_USER_SCHEDULES,
    page,
    data,
  };
}

export function setGroup(page, data) {
  return {
    type: SET_GROUP,
    page,
    data,
  };
}

export function setSites(page, data) {
  return {
    type: SET_SITES,
    page,
    data,
  };
}

export function setZones(page, data) {
  return {
    type: SET_ZONES,
    page,
    data,
  };
}

export function setZone(page, data) {
  return {
    type: SET_ZONE,
    page,
    data,
  };
}

export function setZoneGroups(page, data) {
  return {
    type: SET_ZONE_GROUPS,
    page,
    data,
  };
}

export function setIdentityProviders(page, data) {
  return {
    type: SET_IDENTITY_PROVIDERS,
    page,
    data,
  };
}

export function setEntryStates(page, data) {
  return {
    type: SET_ENTRY_STATES,
    page,
    data,
  };
}

export function setTriggerMethods(page, data) {
  return {
    type: SET_TRIGGER_METHODS,
    page,
    data,
  };
}

export function setIos(page, data) {
  return {
    type: SET_IOS,
    page,
    data,
  };
}

export function setSchedules(page, data) {
  return {
    type: SET_SCHEDULES,
    page,
    data,
  };
}

export function setGroupSchedules(page, data) {
  return {
    type: SET_GROUP_SCHEDULES,
    page,
    data,
  };
}

export function setAcus(page, data) {
  return {
    type: SET_ACUS,
    page,
    data,
  };
}

export function setAllegionInviteState(page, data) {
  return {
    type: SET_ALLEGION_INVITE_STATE,
    page,
    data,
  };
}

export function setScopeResources(page, data) {
  return {
    type: SET_SCOPE_RESOURCES,
    data,
    page,
  };
}

export function setRoles(page, data) {
  return {
    type: SET_ROLES,
    page,
    data,
  };
}

export function setRole(page, data) {
  return {
    type: SET_ROLE,
    page,
    data,
  };
}

export function setActivity(page, data) {
  return {
    type: SET_ACTIVITY,
    page,
    data,
  };
}

export function setAlarmActivity(page, data) {
  return {
    type: SET_ALARM_ACTIVITY,
    page,
    data,
  };
}

export function setVisualActivity(page, data) {
  return {
    type: SET_VISUAL_ACTIVITY,
    page,
    data,
  };
}

export function setUserActivity(page, data) {
  return {
    type: SET_USER_ACTIVITY,
    page,
    data,
  };
}

export function setUserGroups(page, data) {
  return {
    type: SET_USER_GROUPS,
    page,
    data,
  };
}

export function setUserCredentials(page, data) {
  return {
    type: SET_USER_CREDENTIALS,
    page,
    data,
  };
}

export function setIdentityProviderGroups(page, data) {
  return {
    type: SET_IDENTITY_PROVIDER_GROUPS,
    page,
    data,
  };
}

export function setIdentityProviderGroupRelations(page, data) {
  return {
    type: SET_IDENTITY_PROVIDER_GROUP_RELATIONS,
    page,
    data,
  };
}

export function setAllAcuEntriesUsers(page, data) {
  return {
    type: SET_ALL_ACU_ENTRIES_USERS,
    page,
    data,
  };
}

export function setCredentialTypes(page, data) {
  return {
    type: SET_CREDENTIAL_TYPES,
    page,
    data,
  };
}

export function setCredentials(page, data) {
  return {
    type: SET_CREDENTIALS,
    page,
    data,
  };
}

export function setCardFormats(page, data) {
  return {
    type: SET_CARD_FORMATS,
    page,
    data,
  };
}

export function setScheduleTypes(page, data) {
  return {
    type: SET_SCHEDULE_TYPES,
    page,
    data,
  };
}

export function setScheduleEvents(page, data) {
  return {
    type: SET_SCHEDULE_EVENTS,
    page,
    data,
  };
}

export function setAuditLogs(page, data) {
  return {
    type: SET_AUDIT_LOGS,
    page,
    data,
  };
}

export function setSharedUsers(page, data) {
  return {
    type: SET_SHARED_USERS,
    page,
    data,
  };
}

export function setAcuPorts(page, data) {
  return {
    type: SET_ACU_PORTS,
    page,
    data,
  };
}

export function setRequestTypes(page, data) {
  return {
    type: SET_REQUEST_TYPES,
    page,
    data,
  };
}

export function setProtonStatuses(page, data) {
  return {
    type: SET_PROTON_STATUSES,
    page,
    data,
  };
}

export function setNamespaces(page, data) {
  return {
    type: SET_NAMESPACES,
    page,
    data,
  };
}

export function setHookEvents(page, data) {
  return {
    type: SET_HOOK_EVENTS,
    page,
    data,
  };
}

export function setAlarmConfigurations(page, data) {
  return {
    type: SET_ALARM_CONFIGURATIONS,
    page,
    data,
  };
}

export function requestGetCurrentOrgData(orgId) {
  return {
    type: REQUEST_GET_CURRENT_ORG_DATA,
    orgId,
  };
}

/// ////////////////////

export function setOrgContainerReducer(type, page, data, subFilterId) {
  return { type, page, data, subFilterId };
}

export const updateOptions = ({
  endpointName,
  setterActionType,
  page,
  filterQuery,
  options,
}) => ({
  type: UPDATE_OPTIONS,
  endpointName,
  setterActionType,
  page,
  filterQuery,
  options,
});

// ** NOTE - these two actions are being tested on the dashboard
// ** for a new way to generalize calls to helium
// ** see justin if you have any questions
export function requestPageDataGeneric(page, injects) {
  return {
    type: REQUEST_PAGE_DATA_GENERIC,
    page,
    injects,
  };
}

export function requestHeliumDataGeneric(page, key) {
  return {
    type: REQUEST_HELIUM_DATA_GENERIC,
    page,
    key,
  };
}

// this action is a wrapper action to call a helium API without needing to make a "copypasta" saga
export function requestAndSetGeneralWrapper({
  heliumApi,
  queryStringParams = {},
  setterActionType = () => null,
  page,
  additionalEndpointRequisites = [],
}) {
  return {
    type: REQUEST_AND_SET_GENERAL_WRAPPER,
    heliumApi,
    queryStringParams,
    setterActionType,
    page,
    additionalEndpointRequisites,
  };
}

export function saveTableState(page, state, subFilter) {
  return {
    type: SAVE_TABLE_STATE,
    page,
    data: state,
    subFilter,
  };
}

// These are here rather than with the OpVideoPlayers because when a route was changed
// and the new route didn't have the OpVideoPlayers saga, the saga to reset the HLS
// URL and poster URL would not run. When they are at the org level they will always run
export const resetHlsUrl = (page, hlsSetterActionType, subFilter) => ({
  type: RESET_HLS_URL,
  page,
  hlsSetterActionType,
  subFilter,
});

export const resetPosterUrl = (page, subFilter) => ({
  type: RESET_POSTER_URL,
  page,
  subFilter,
});
export function slideOutSuccessful(data) {
  return {
    type: SLIDE_OUT_SUCCESSFUL,
    data,
  };
}
