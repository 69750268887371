import { useCallback, useMemo } from 'react';

import {
  SET_ENTRIES,
  SET_GROUPS,
  SET_SITES,
  SET_ZONES,
  SET_USERS,
} from 'routes/OrgContainer/constants';
import {
  selectGroupsForMultiSelect,
  selectOptionsForEntriesMultiSelect,
  selectSitesForMultiSelect,
  selectZonesForMultiSelect,
  selectUserOptionsForDropdown,
  selectOptionsForCredentialTypesMultiSelect,
} from 'routes/OrgContainer/selectors';

import { requestAndSetGeneralWrapper } from 'routes/OrgContainer/actions';

import { useTranslation } from 'react-i18next';
import { useSelectorJs } from '.';
import { useShowSitePartitioningItems } from './useShowSitePartitioningItems';

const useFiltersForReport = (PAGE, route, dispatch, orgId) => {
  const { t } = useTranslation();

  // filter options
  const userOptions = useSelectorJs(selectUserOptionsForDropdown, { route });
  const siteOptions = useSelectorJs(selectSitesForMultiSelect(), { route });
  const zoneOptions = useSelectorJs(selectZonesForMultiSelect(), { route });
  const entryOptions = useSelectorJs(selectOptionsForEntriesMultiSelect(), {
    route,
  });
  const groupOptions = useSelectorJs(selectGroupsForMultiSelect(), { route });
  const credentialTypesOptions = useSelectorJs(
    selectOptionsForCredentialTypesMultiSelect(),
    { route },
  );

  const { isShowingSitePartitioningItems } =
    useShowSitePartitioningItems(orgId);

  const filterOptions = useMemo(
    () => ({
      userId: userOptions,
      'entries.site.id': siteOptions,
      'entries.zone.id': zoneOptions,
      'entries.id': entryOptions,
      'userGroups.id': groupOptions,
      credentialTypeName: credentialTypesOptions,
      result: [
        { label: t('Granted'), value: 'Granted' },
        { label: t('Denied'), value: 'Denied' },
        { label: t('Pass-Through'), value: 'Pass-Through' },
      ],
      category: [
        { label: t('Device'), value: 'Device' },
        { label: t('Alert'), value: 'Alert' },
        { label: t('Entry'), value: 'Entry' },
      ],
    }),
    [
      t,
      userOptions,
      siteOptions,
      zoneOptions,
      entryOptions,
      groupOptions,
      credentialTypesOptions,
    ],
  );

  const filterOptionsUpdate = useCallback(
    (filterType, q) => {
      let actionInfo = null;
      switch (filterType) {
        case 'userId':
          actionInfo = {
            heliumApi: 'listUsers',
            queryStringParams: {
              q,
              ...(isShowingSitePartitioningItems && {
                options: 'withUserSites',
              }),
            },
            setterActionType: SET_USERS,
            page: PAGE,
          };
          break;
        case 'entries.site.id':
          actionInfo = {
            heliumApi: 'listSites',
            queryStringParams: { q },
            setterActionType: SET_SITES,
            page: PAGE,
          };
          break;
        case 'entries.zone.id':
          actionInfo = {
            heliumApi: 'listZones',
            queryStringParams: { q },
            setterActionType: SET_ZONES,
            page: PAGE,
          };
          break;
        case 'entries.id':
          actionInfo = {
            heliumApi: 'listEntries',
            queryStringParams: { q },
            setterActionType: SET_ENTRIES,
            page: PAGE,
          };
          break;
        case 'userGroups.id':
          actionInfo = {
            heliumApi: 'listGroups',
            queryStringParams: { q },
            setterActionType: SET_GROUPS,
            page: PAGE,
          };
          break;
        default:
        // do nothing, user hasn't selected anything yet
      }

      if (actionInfo) {
        // API doesn't like empty q's
        if (!actionInfo.queryStringParams.q?.length) {
          delete actionInfo.queryStringParams.q;
        }
        dispatch(requestAndSetGeneralWrapper(actionInfo));
      }
    },
    [isShowingSitePartitioningItems, PAGE, dispatch],
  );

  return [filterOptions, filterOptionsUpdate];
};

export default useFiltersForReport;
