export const timeoutStates = {
  CALL: 'CALL',
  PREVIEW: 'PREVIEW',
  DISCONNECT_WINDOW: 'DISCONNECT_WINDOW',
  DISCONNECT_WINDOW_EXCEEDED: 'DISCONNECT_WINDOW_EXCEEDED',
  NO_TIMEOUT: 'NO_TIMEOUT',
};

export const timeoutMs = {
  PREVIEW: 3300000, // 55 minutes
  CALL: 300000, // 5 minutes
  DISCONNECT: 60000, // 1 minute
  TRACK_DETECTED: 10000, // 10 seconds
};

export const disconnectReasonStatus = {
  CLIENT_INITIATED: 1,
  PARTICIPANT_REMOVED: 4,
  SIGNAL_CLOSE: 9,
};

export const FETCH_ATTEMPTS = 3;
