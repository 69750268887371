import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import { t } from 'i18next';
import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert } from 'routes/AppContainer/actions';
import { Layers, SoundEffects, WebAudio } from 'utils/audio';
import { useBroadcastChannel, useOxygenSocket } from 'utils/customHooks';

// only subscribe to these events
const INTERCOM_USER_EVENTS = [
  'opvideoIntercom.frontDeskConnect.requested',
  'opvideoIntercom.directConnect.requested',
  'opvideoIntercom.voicemail.recorded',
];
const INTERCOM_NON_USER_EVENTS = [
  'opvideoIntercom.directConnect.answered',
  'opvideoIntercom.frontDeskConnect.answered',
];
const OXYGEN_CLIENT_ID = 'intercom-notifications';

// note this does not connect on load, you have to use your own useEffect() to do initial connect

/**
 * @param {number} args.userId [Required] The current user's ID
 * @param {string} args.token [Required] The current user's Oxygen token
 * @param {function(alertTitle, {opvideoDeviceId: number, opvideoIntercomName: string})} args.displayIntercomAlert Called every time we want to visually display a notification; natural refactor point
 */
const useIntercomNotifications = ({
  orgId,
  userId,
  displayIntercomAlert,
  token,
}) => {
  const dispatch = useDispatch();
  // TODO: break out if the user is not in an org with an intercom

  // used for coordination between tabs (when one tab connects to Oxygen, it tells all others to disconnect)
  // note this is an abstraction layer on top of the BrowserChannel standard that works in more browsers more reliably
  // the API differs slightly from the browser version
  const [broadcastChannelRef, broadcastId] = useBroadcastChannel('oxygen');

  // used to debug when a customer is having deliverability issues
  const shouldAck = useSelector(
    selectFeatureFlag('SHOULD_ACK_INTERCOM_OXYGEN_MESSAGES'),
  );

  const subscriptionData = useMemo(() => {
    return [
      ...INTERCOM_USER_EVENTS.map((hookEventType) => ({
        preFilter: {
          hookEventType,
          userId,
        },
        trigger: {
          $id: 'https://openpath.com/all-events.json',
        },
        clientData: {},
      })),
      ...INTERCOM_NON_USER_EVENTS.map((hookEventType) => ({
        preFilter: {
          hookEventType,
        },
        trigger: {
          $id: 'https://openpath.com/all-events.json',
        },
        clientData: {},
      })),
    ];
  }, [userId]);

  // this will get called once for each hookEvent
  const handleEvent = useCallback(
    (event, socket) => {
      const {
        event: eventType,
        data: {
          opvideoDeviceId,
          acuName: opvideoIntercomName,
          sessionId,
          userIds,
          acuId,
          opvideoIntercomId,
        },
      } = event;

      // filter down to just intercom notifications we care about
      // technically we should never hit this because we're prefiltering, but it's not impossible
      if (
        ![...INTERCOM_USER_EVENTS, ...INTERCOM_NON_USER_EVENTS].includes(
          eventType,
        )
      ) {
        return;
      }

      // used to debug deliverability, see feature flag code above
      if (shouldAck && socket) {
        // eslint-disable-next-line no-console
        console.log('[Oxygen] Sending ack back');
        // tell Oxygen "we got the event - btw, here's some info about the event we got"
        socket.send(
          JSON.stringify({
            action: 'log',
            log: {
              type: 'eventAck',
              userId,
              orgId,
              acuId,
              opvideoDeviceId,
              opvideoIntercomId,
              eventType,
              eventTimestamp: event.timestamp,
              eventMetadata: event.metadata,
              clientReceivedTs: Date.now() / 1000,
            },
            clientRequestId: OXYGEN_CLIENT_ID,
          }),
        );
      }

      // The answered events are not user related, so we check to see if they occur and handle them here
      if (
        [
          'opvideoIntercom.directConnect.answered',
          'opvideoIntercom.frontDeskConnect.answered',
        ].includes(eventType)
      ) {
        // Clear the intercom notification
        dispatch(clearAlert(sessionId));

        // Stop the intercom notification audio
        WebAudio.stop(
          SoundEffects.INTERCOM_NOTIFICATION,
          Layers.INTERCOM_NOTIFICATIONS,
        );
      }

      // make sure the event contains a userIds payload
      // technically we should never hit this because we're prefiltering, but it's not impossible
      if (!userIds || userIds.length <= 0) {
        return;
      }

      // check if the event was addressed to the current user
      // technically we should never hit this because we're prefiltering, but it's not impossible
      if (!userIds.includes(userId)) {
        return;
      }

      if (eventType === 'opvideoIntercom.directConnect.requested') {
        displayIntercomAlert(t('Direct connect requested'), {
          opvideoDeviceId,
          opvideoIntercomName,
          sessionId,
        });
      } else if (eventType === 'opvideoIntercom.frontDeskConnect.requested') {
        displayIntercomAlert(t('Front desk connect requested'), {
          opvideoDeviceId,
          opvideoIntercomName,
          sessionId,
        });
      }
    },
    [dispatch, displayIntercomAlert, userId, shouldAck, orgId],
  );

  // this gets called every time we get an Oxygen message
  const onMessage = useCallback(
    (event, socket) => {
      if (!event || !event.data) return;

      try {
        const parsed = JSON.parse(event.data);
        const { type, data } = parsed;

        // grab event batches from the Oxygen message
        // pass them each to handleEvent
        if (type === 'events' && data && Array.isArray(data)) {
          data.map((d) => handleEvent(d, socket));
        }
      } catch (e) {
        console.error('got unhandled error, probably invalid json', e, event);
      }
    },
    [handleEvent],
  );

  // when Oxygen successfully connects, call this function
  // this function binds the onMessage function to each socket message we get
  const onConnectionHandler = useCallback(
    (socket) => {
      return socket.addEventListener('message', (m) => onMessage(m, socket));
    },
    [onMessage],
  );

  const {
    isConnected,
    connect: oxyConnect,
    disconnect,
  } = useOxygenSocket({
    orgId,
    onConnectionHandler,
    subscriptionData,
    clientRequestId: OXYGEN_CLIENT_ID,
    token,
    ignoreBroadcastDisconnect: true,
  });

  // wrap the underlying Oxygen connect() method to disconnect other tabs
  const connect = useCallback(() => {
    if (userId) {
      // first we connect this tab to Oxygen
      oxyConnect();

      // then we tell all the other tabs to disconnect
      try {
        broadcastChannelRef.current.postMessage({
          command: 'DISCONNECT',
          target: OXYGEN_CLIENT_ID,
          sender: broadcastId,
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      // eslint-disable-next-line no-console
      console.debug('Not connecting to Oxygen, userId param is required');
    }
  }, [oxyConnect, broadcastChannelRef, broadcastId, userId]);

  useEffect(() => {
    if (!window || !broadcastChannelRef.current) {
      return () => {}; // No-op to always return a function
    }

    const broadcastChannel = broadcastChannelRef.current;

    const handleMessage = (msg) => {
      if (
        msg?.command === 'DISCONNECT' &&
        (msg?.target === OXYGEN_CLIENT_ID || msg?.target === '*') && // only listen for messages addressed to us OR addressed to all
        msg?.sender !== broadcastId
      ) {
        disconnect();
      }
    };

    // listen for commands from other instances (in most cases other tabs)
    broadcastChannel.addEventListener('message', handleMessage);

    return () => {
      broadcastChannel.removeEventListener('message', handleMessage);
    };
  }, [broadcastChannelRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return { connect, disconnect, isConnected };
};

export default useIntercomNotifications;
