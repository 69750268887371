import { createSelector } from 'reselect';
import moment from 'moment';

export const selectCloudKeys = () => (state) =>
  state.getIn(['global', 'cloudKeys']);

export const selectFirstValidCloudKeyId = () =>
  createSelector(selectCloudKeys(), (cloudKeys) => {
    const foundValid = cloudKeys.find((ck) => {
      const validStart = ck.get('startDate')
        ? moment(ck.get('startDate')).isBefore(moment())
        : true;
      const validEnd = ck.get('endDate')
        ? moment(ck.get('endDate')).isAfter(moment())
        : true;
      return validStart && validEnd;
    });
    if (foundValid) return foundValid.get('id');
    return null;
  });
