import { ReactNode, useRef } from 'react';
import get from 'lodash/get';
import { UseOpQueryOptions, useOpQuery } from 'utils/customHooks/useOpQuery';
import { ensurePayloadAndQuery } from 'utils/ensurePayloadAndQuery';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import * as Sentry from '@sentry/react';
import difference from 'lodash/difference';
import {
  CreateFinalOptions,
  CreateInitialValueQueryOptions,
  CreateMainDataQueryOptions,
  CreateOptions,
  Option,
  UseCreateInitialValueOptions,
  UseCreateMainDataOptions,
  UseCreateOptions,
} from './types';
import { DEFAULT_LIMIT } from './constants';

const createOptions = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  data,
  pathToData,
  pathToLabel,
  pathToValue,
  numerifyOptionValues,
  optionRender,
  createDisabledOption,
  createOptionLabel,
}: CreateOptions<T, SelectData>) => {
  const optionData: Utils.GetApiReturnType<T> = pathToData
    ? get(data, pathToData)
    : data;

  let options: Option[] = [];
  let optionChildren: ReactNode[] = [];

  // If we have optionData, make sure that the optionData is an array
  if (optionData) {
    if (Array.isArray(optionData)) {
      if (optionRender) {
        optionChildren = optionData.map((item) => {
          const label = createOptionLabel?.(item) || get(item, pathToLabel);
          const optionValue = get(item, pathToValue);
          const finalOptionValue = numerifyOptionValues
            ? Number(optionValue) || optionValue
            : String(optionValue);

          return optionRender({
            label,
            value: finalOptionValue,
            disabled: createDisabledOption?.(item),
            item,
          });
        });
      } else {
        options = optionData.map((item) => {
          const label = createOptionLabel?.(item) || get(item, pathToLabel);
          const optionValue = get(item, pathToValue);
          const finalOptionValue = numerifyOptionValues
            ? Number(optionValue) || optionValue
            : String(optionValue);

          return {
            label,
            value: finalOptionValue,
            disabled: createDisabledOption?.(item),
          };
        });
      }
    } else {
      console.error('Data response needs to be an array for OpDataFetchSelect');
    }
  }

  return { options, optionChildren };
};

/**
 * List of API endpoints that do not support queries that we still allow these won't
 * work as designed with `q` filtering the data, so we need to ultimately update these
 * endpoints in helium to support query params
 */
const apiEndpointsWhiteList = [
  'globalListSupportedLanguages',
  'listOpvideoIntercomProfileTypes',
];

const verifyQueryOptions = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>(
  queryOptions: UseOpQueryOptions<T, SelectData>,
) => {
  const { parametersArray, withQuery } = ensurePayloadAndQuery(
    queryOptions.apiEndpointName,
    queryOptions.parameters,
  );

  // Find the index of the queries (last object)
  const queriesIndex = parametersArray.findLastIndex(
    (param: any) => typeof param === 'object',
  );

  // Verify that the endpoint supports queries
  if (withQuery) {
    if (queriesIndex === -1) {
      /**
       * ensurePayloadAndQuery guarantees the presence of a queries object, so
       * capture this since something is way off
       */
      const message = `Critical: ensurePayloadAndQuery did not ensure a query object for API endpoint ${queryOptions.apiEndpointName}`;
      console.error(message);
      Sentry.captureMessage(message);
    }
  } else if (!apiEndpointsWhiteList.includes(queryOptions.apiEndpointName)) {
    /**
     * We at least need to know to add to whitelist, but should find underlying
     * issue as to why swagger is not populating the withQuery field properly
     */
    const message = `Critical: No query object allowed for the API endpoint passed to OpDataFetchSelect: \`${queryOptions.apiEndpointName}\`. Did you pass the wrong API endpoint? Make sure the API endpoint supports query params.`;
    console.error(message);
    Sentry.captureMessage(message);
  }

  return { parametersArray, queriesIndex };
};

const createMainDataQueryOptions = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  queryOptions,
  fetchAllInitialValues,
  debouncedQuery,
}: CreateMainDataQueryOptions<T, SelectData>) => {
  // Make a deep clone of the query options to prevent mutation
  const clonedQueryOptions = cloneDeep(queryOptions);

  const { parametersArray, queriesIndex } =
    verifyQueryOptions(clonedQueryOptions);

  if (queriesIndex === -1) {
    // We can't do anything that requires a query object, so just return the queryOptions
    return queryOptions as UseOpQueryOptions<T>;
  }

  parametersArray[queriesIndex] = {
    ...(fetchAllInitialValues && { limit: DEFAULT_LIMIT }), // Will be overwritten if limit in the original query params
    ...(parametersArray[queriesIndex] as Record<string, any>),
    ...(debouncedQuery && { q: debouncedQuery }),
  };

  return {
    ...queryOptions,
    parameters: parametersArray,
    getAll: !fetchAllInitialValues, // Use getAll to make sure we always have the options for the values
  } as UseOpQueryOptions<T>;
};

const useCreateMainDataOptions = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  optionRender,
  createDisabledOption,
  createOptionLabel,
  queryOptions,
  pathToData,
  pathToLabel,
  pathToValue,
  fetchAllInitialValues,
  debouncedQuery,
  numerifyOptionValues, // TEMP - until we can correctly type Selects
}: UseCreateMainDataOptions<T, SelectData>) => {
  const mainDataQueryOptions = createMainDataQueryOptions({
    queryOptions,
    fetchAllInitialValues,
    debouncedQuery,
  });

  const { data, isLoading, isFetching } = useOpQuery(mainDataQueryOptions);

  return {
    isLoading,
    isFetching,
    ...createOptions({
      data,
      pathToData,
      pathToLabel,
      pathToValue,
      numerifyOptionValues,
      optionRender,
      createDisabledOption,
      createOptionLabel,
    }),
  };
};

const useCreateInitialValueQueryOptions = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  valueProp,
  mainDataOptions,
  queryOptions,
  fetchAllInitialValues,
}: CreateInitialValueQueryOptions<T, SelectData>) => {
  // Store the initial value in a ref to prevent a different value from subsequent renders
  const initialValue = useRef(valueProp);

  /**
   * Make the initial value an array if it isn't already as we need to handle
   * both singular selects and multi selects.
   */
  const initialValueArray = initialValue.current
    ? Array.isArray(initialValue.current)
      ? initialValue.current
      : [initialValue.current]
    : [];

  // Find if there are any initial values that are not in the main data options
  const initialValuesWithoutOptions = difference(
    initialValueArray.map(Number),
    mainDataOptions.map(({ value }) => Number(value)),
  );

  // Make a deep clone of the query options to prevent mutation
  const clonedQueryOptions = cloneDeep(queryOptions);

  const { parametersArray, queriesIndex } =
    verifyQueryOptions(clonedQueryOptions);

  const enabled = Boolean(
    fetchAllInitialValues &&
      initialValuesWithoutOptions.length && // Nothing to query for
      queriesIndex !== -1, // If there is no query object, we can't add the filter
  );

  // Prevent the query if not enabled
  if (!enabled) {
    return {
      ...queryOptions,
      queryKey: initialValueArray, // Cache buster as cached data is returned based on the queryKey
      enabled: false,
    };
  }

  const queryParams = parametersArray[queriesIndex] as Record<string, any>;

  const { queryParamOverrides = {}, filterPath = 'id' } =
    typeof fetchAllInitialValues === 'object' ? fetchAllInitialValues : {};

  // Merge queryParamOverrides into queryParams
  Object.assign(queryParams, queryParamOverrides);

  const filterRegex = new RegExp(
    `\\b${filterPath}:\\([^)]*\\)|\\b${filterPath}:[^\\(\\s]+`,
  ); // Matches patterns like id:(1,2,3), id:4, or identity.id:(1)
  const formattedFilter = `${filterPath}:(${initialValueArray})`; // Format the new filter

  // Check if queryParams.filter exists
  queryParams.filter = queryParams.filter
    ? // If filter exists, check if there is a filter matching the filterPath
      queryParams.filter.match(filterRegex)
      ? // If there is, replace the existing one with the new formattedFilter
        queryParams.filter.replace(filterRegex, formattedFilter)
      : // If there isn't, append the new formattedFilter to the existing filter
        `${queryParams.filter} ${formattedFilter}`
    : // If filter doesn't exist, create a new filter with the formattedFilter
      formattedFilter;

  // Set the parameters to the updated parametersArray
  clonedQueryOptions.parameters = parametersArray;

  /**
   * Safety check to make sure we get all the options, but in reality we should only
   * have a max of 50 initial values. If we have more than that we really should be
   * using a transfer component and patch calls to update the data in the db.
   */
  clonedQueryOptions.getAll = true;

  return clonedQueryOptions;
};

const useCreateInitialValueOptions = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  valueProp,
  mainDataOptions,
  queryOptions,
  pathToData,
  pathToLabel,
  pathToValue,
  fetchAllInitialValues,
  optionRender,
  createDisabledOption,
  createOptionLabel,
  numerifyOptionValues, // TEMP - until we can correctly type Selects
}: UseCreateInitialValueOptions<T, SelectData>) => {
  const initialDataQueryOptions = useCreateInitialValueQueryOptions({
    valueProp,
    mainDataOptions,
    queryOptions,
    fetchAllInitialValues,
  });

  const { data, isLoading } = useOpQuery(initialDataQueryOptions);

  return {
    isLoading,
    ...createOptions({
      data,
      pathToData,
      pathToLabel,
      pathToValue,
      numerifyOptionValues,
      optionRender,
      createDisabledOption,
      createOptionLabel,
    }),
  };
};

const createFinalOptions = ({
  mainDataOptions,
  mainDataOptionChildren,
  initialValueOptions,
  initialValueOptionChildren,
  debouncedQuery,
}: CreateFinalOptions) => {
  /**
   * In order to be able to show all disabled items when searching, we need to
   * render the main data options and the initial values data options separately
   */

  /**
   * When there is a search query, remove all but the disabled initial options
   * as we need those to keep the tags disabled (unless someone can find a way
   * to keep the tags disabled without the corresponding option)
   */
  const finalInitialValueOptions = debouncedQuery
    ? initialValueOptions?.filter(({ disabled }) => {
        return disabled === true;
      })
    : initialValueOptions;

  // Combine the main data options and the initial value options
  const finalOptions = [...mainDataOptions, ...finalInitialValueOptions];

  // Deduplicate finalOptions based on a unique property, e.g., 'id'
  const dedupedFinalOptions = uniqBy(finalOptions, (item) => item.value);

  // Create the final option children
  const finalOptionChildren = mainDataOptionChildren;

  // Add the initial value option children if there is no search query
  if (!debouncedQuery) {
    finalOptionChildren.push(...initialValueOptionChildren);
  }

  // Deduplicate finalOptionChildren based on a unique property, e.g., 'id'
  const dedupedFinalOptionChildren = uniqBy(finalOptionChildren, (item) => {
    if (typeof item === 'object' && item !== null && 'props' in item) {
      return item.props.value;
    }
  });

  return {
    options: dedupedFinalOptions,
    optionChildren: dedupedFinalOptionChildren,
  };
};

export const useCreateOptions = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  optionRender,
  createDisabledOption,
  createOptionLabel,
  queryOptions,
  pathToData,
  pathToLabel,
  pathToValue,
  fetchAllInitialValues,
  numerifyOptionValues, // TEMP - until we can correctly type Selects
  valueProp,
  debouncedQuery,
}: UseCreateOptions<T, SelectData>) => {
  // Used for both functions to create options
  const sharedArgs = {
    queryOptions,
    pathToData,
    pathToLabel,
    pathToValue,
    fetchAllInitialValues,
    optionRender,
    createDisabledOption,
    createOptionLabel,
    numerifyOptionValues,
  };

  const {
    options: mainDataOptions,
    optionChildren: mainDataOptionChildren,
    isLoading: isMainDataLoading,
    isFetching: isMainDataFetching,
  } = useCreateMainDataOptions({
    debouncedQuery,
    ...sharedArgs,
  });

  const {
    options: initialValueOptions,
    optionChildren: initialValueOptionChildren,
    isLoading: isInitialValueDataLoading,
  } = useCreateInitialValueOptions({
    valueProp,
    mainDataOptions,
    ...sharedArgs,
  });

  const {
    options: dedupedFinalOptions,
    optionChildren: dedupedFinalOptionChildren,
  } = createFinalOptions({
    mainDataOptions,
    mainDataOptionChildren,
    initialValueOptions,
    initialValueOptionChildren,
    debouncedQuery,
  });

  return {
    isLoading: isMainDataLoading || isInitialValueDataLoading,
    isFetching: isMainDataFetching,
    options: dedupedFinalOptions.length > 0 ? dedupedFinalOptions : undefined,
    optionChildren: dedupedFinalOptionChildren,
  };
};
