import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'semantic-ui-react/dist/es/elements/Icon/Icon';
import { Clickable } from 'components/Clickable/Clickable';

const PlayButton = memo(({ handlePlayToggleClick, iconName }) => (
  <Clickable
    className="player-controls-button op-justify-content-center op-cursor-pointer"
    onClick={handlePlayToggleClick}
    role="button"
    tabIndex={0}
  >
    <Icon
      fitted
      inverted
      name={iconName}
      className="op-height-auto"
      size="large"
    />
  </Clickable>
));

PlayButton.displayName = 'PlayButton';

PlayButton.propTypes = {
  handlePlayToggleClick: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
};

export default PlayButton;
