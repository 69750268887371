import {
  REQUEST_AND_SET_CSV_DATA,
  SET_TABLE_LOADING,
  SET_TABLE_STATE,
  REQUEST_UPDATE_TABLE,
  TABLE_HAS_BEEN_UPDATED,
  SET_TABLE_HAS_BEEN_UPDATED,
  SET_PAGE_COUNT,
  PAGE_COUNT,
  TABLE_STATE,
  REQUEST_UPDATE_DATA_ORDER,
  SET_TABLE_DATA_PERCENT_LOADED,
  PERCENT_LOADED,
} from './constants';

export function setTableUpdating({
  page,
  tableId = null,
  loading,
  updatingMessage = null,
}) {
  return {
    type: SET_TABLE_LOADING,
    page,
    tableId,
    loading,
    updatingMessage,
  };
}

export function setTableHasBeenUpdated(page, tableId, tableHasBeenUpdated) {
  return {
    type: SET_TABLE_HAS_BEEN_UPDATED,
    page,
    tableId,
    [TABLE_HAS_BEEN_UPDATED]: tableHasBeenUpdated,
  };
}

export function requestAndSetCsvData(params) {
  return {
    type: REQUEST_AND_SET_CSV_DATA,
    ...params,
  };
}

export function setTableDataPercentLoaded({
  page,
  tableId = null,
  tableDataPercentLoaded,
}) {
  return {
    type: SET_TABLE_DATA_PERCENT_LOADED,
    page,
    tableId,
    [PERCENT_LOADED]: tableDataPercentLoaded,
  };
}

export function setTableState(page, tableId, tableState) {
  return {
    type: SET_TABLE_STATE,
    page,
    tableId,
    [TABLE_STATE]: tableState,
  };
}

export function requestUpdateTable(params) {
  return {
    type: REQUEST_UPDATE_TABLE,
    ...params,
  };
}

export function updateDataOrder(params) {
  return {
    type: REQUEST_UPDATE_DATA_ORDER,
    ...params,
  };
}

export function setPageCount(page, tableId, pageCount) {
  return {
    type: SET_PAGE_COUNT,
    page,
    tableId,
    [PAGE_COUNT]: pageCount,
  };
}
