import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const P5Svg = ({ ...svgProps }) => (
  <SvgWrapper
    width="19.058823529411764"
    height="18"
    viewBox="0 0 72 68"
    fill="none"
    {...svgProps}
  >
    <path
      d="M29.5972 5.79268L29.597 5.79291L2.97046 53.7348C2.97042 53.7348 2.97038 53.7349 2.97035 53.735C0.24937 58.6332 3.57957 65.0017 9.33256 65.0017H62.5853C68.3255 65.0017 71.6749 58.6447 68.9475 53.735C68.9475 53.7349 68.9474 53.7348 68.9474 53.7348L42.3215 5.79292L42.3213 5.79266C39.515 0.741374 32.4097 0.730182 29.5972 5.79268Z"
      strokeWidth="4"
    />
    <path
      d="M22.37 53V33.44H28.91C30.35 33.44 31.65 33.63 32.81 34.01C33.97 34.39 34.89 35.04 35.57 35.96C36.27 36.86 36.62 38.09 36.62 39.65C36.62 41.15 36.28 42.38 35.6 43.34C34.92 44.3 34 45.02 32.84 45.5C31.7 45.96 30.43 46.19 29.03 46.19H26.48V53H22.37ZM26.48 42.89H28.76C30.06 42.89 31.02 42.62 31.64 42.08C32.26 41.52 32.57 40.71 32.57 39.65C32.57 38.55 32.23 37.79 31.55 37.37C30.89 36.93 29.92 36.71 28.64 36.71H26.48V42.89ZM45.1039 53.36C44.0639 53.36 43.1339 53.24 42.3139 53C41.4939 52.76 40.7639 52.44 40.1239 52.04C39.5039 51.64 38.9439 51.19 38.4439 50.69L40.3039 48.08C40.8839 48.62 41.5139 49.08 42.1939 49.46C42.8939 49.84 43.6739 50.03 44.5339 50.03C45.1939 50.03 45.7739 49.91 46.2739 49.67C46.7939 49.41 47.1939 49.05 47.4739 48.59C47.7539 48.11 47.8939 47.52 47.8939 46.82C47.8939 45.82 47.6039 45.05 47.0239 44.51C46.4439 43.97 45.6839 43.7 44.7439 43.7C44.1839 43.7 43.7039 43.78 43.3039 43.94C42.9039 44.08 42.4139 44.33 41.8339 44.69L39.9739 43.49L40.5139 33.95H51.1339V37.4H44.0539L43.7239 41.3C44.1039 41.12 44.4639 40.99 44.8039 40.91C45.1439 40.83 45.5339 40.79 45.9739 40.79C47.0539 40.79 48.0539 41.01 48.9739 41.45C49.8939 41.87 50.6239 42.52 51.1639 43.4C51.7239 44.26 52.0039 45.37 52.0039 46.73C52.0039 48.13 51.6839 49.33 51.0439 50.33C50.4039 51.31 49.5539 52.06 48.4939 52.58C47.4539 53.1 46.3239 53.36 45.1039 53.36Z"
      fill={svgProps.stroke || 'black'}
    />
  </SvgWrapper>
);
