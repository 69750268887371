import { fromJS } from 'immutable';
import { SET_CLOUD_KEYS } from './constants';
// Initial access token state
const initialState = fromJS([]);

function cloudKeysReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CLOUD_KEYS:
      return fromJS(action.cloudKeys);
    default:
      return state;
  }
}

export default cloudKeysReducer;
