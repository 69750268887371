import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { opMoment } from 'utils/dates';
import { DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT } from 'constants/Dates';

const CurrentTime = memo(
  ({
    timezone = opMoment.tz.guess(),
    format = DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT,
  }) => {
    const [currentTime, setCurrentTime] = useState(
      opMoment().tz(timezone).format(format),
    );
    const interval = useRef();

    useEffect(() => {
      interval.current = setInterval(() => {
        setCurrentTime(opMoment().tz(timezone).format(format));
      }, 1000);

      return () => {
        clearInterval(interval.current);
        interval.current = null;
      };
    }, [timezone, format]);

    return <span>{currentTime}</span>;
  },
);

CurrentTime.propTypes = {
  timezone: PropTypes.string,
  format: PropTypes.string,
};

CurrentTime.displayName = 'CurrentTime';

export default CurrentTime;
