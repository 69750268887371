import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpCheckbox } from 'new-components/DLS/OpCheckbox/OpCheckbox';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormCheckboxGroup.scss';

interface OpFormCheckboxGroup
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      'name' | 'label' | 'tooltip'
    >,
    Omit<ComponentProps<typeof OpCheckbox.Group>, 'name'> {
  formItemClassName?: string;
  isLoading?: boolean;
}

export const OpFormCheckboxGroup = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,

  // For skeleton loading state
  isLoading,

  // Wrapped element(s) props
  disabled,
  className,
  ...elementProps
}: OpFormCheckboxGroup) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx('op-form-checkbox-group', formItemClassName)}
      name={name}
      tooltip={tooltip}
      label={label}
      /** Checkbox.Group uses value instead of checked so we don't need to
       * set the valuePropName to "checked" here. */
    >
      {isDataLoading || isLoading ? (
        <div className="op-form-checkbox-group__skeleton-wrapper">
          {(elementProps.options || []).map((_, i) => (
            <OpSkeleton.Checkbox key={i} active />
          ))}
        </div>
      ) : (
        <OpCheckbox.Group
          className={clsx('op-form-checkbox-group__checkbox-group', className)}
          disabled={isReadOnly || disabled}
          {...elementProps}
        />
      )}
    </OpForm.Item>
  );
};
