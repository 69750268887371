export const getLabelsForOrg = ({
  hasMasterModeAccess,
  isLicenseBased,
  isParentOrg,
}: {
  hasMasterModeAccess: boolean;
  isLicenseBased?: boolean;
  isParentOrg: boolean | null;
}) => {
  // Find which labels to apply
  const subHeaderLabels = [];
  if (isParentOrg) {
    subHeaderLabels.push('partner');
  }

  if (hasMasterModeAccess) {
    // Only show for MM users (internal) for now
    if (isLicenseBased && !isParentOrg) {
      subHeaderLabels.push('license-based');
    }
  }

  return subHeaderLabels;
};
