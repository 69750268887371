import { ComponentProps } from 'react';
import Tag from 'antd/es/tag';
import clsx from 'clsx';

interface OpTagProps extends ComponentProps<typeof Tag> {
  testId?: string;
}

export const OpTag = ({
  className,
  testId = 'op-tag',
  ...tagProps
}: OpTagProps) => {
  return (
    <Tag
      className={clsx('op-tag', className)}
      data-testid={testId}
      {...tagProps}
    />
  );
};
