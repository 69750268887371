import { select } from 'redux-saga/effects';
import { selectOrgHasFeatureCode } from 'global/orgFeatures/selectors';

export function* isShowingSitePartitioningItemsSaga(): Generator<
  any,
  boolean,
  any
> {
  const orgHasUserSitePartitioningFeature = yield select(
    selectOrgHasFeatureCode('userSitePartitioning'),
  );

  return Boolean(orgHasUserSitePartitioningFeature);
}
