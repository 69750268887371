import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OpSteps } from 'new-components/DLS/OpSteps/OpSteps';
import { OpModal } from 'new-components/DLS/OpModal/OpModal';
import { MfaCredentialActivate, MfaCredentialCreate } from '.';
import { steps } from '../constants';

import './NewMfaCredentialModal.scss';

interface NewMfaCredentialModalProps {
  open: boolean;
  setIsModalOpen(isOpen: boolean): void;
  identityId: number;
  deleteMfaCredential(mfaCredential: number): void;
}

const NewMfaCredentialModal = ({
  open,
  setIsModalOpen,
  identityId,
  deleteMfaCredential,
}: NewMfaCredentialModalProps) => {
  const { t } = useTranslation();

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(
    steps.MFA_DEVICE_CREATE,
  );
  const [currentMfaCredentialData, setCurrentMfaCredentialData] =
    useState<Api.Response['createMfaCredential']>();

  const resetProfileSecurityTab = () => {
    setConfirmModalIsOpen(false);
    setIsModalOpen(false);
    setCurrentStep(steps.MFA_DEVICE_CREATE);
    setCurrentMfaCredentialData(undefined);
  };

  const onCancel = () => {
    if (currentStep === steps.MFA_DEVICE_ACTIVATE) {
      setConfirmModalIsOpen(true);
    } else {
      resetProfileSecurityTab();
    }
  };

  const onConfirmModalCancel = () => {
    setConfirmModalIsOpen(false);
  };

  const items = [
    {
      title: t('Create'),
      content: (
        <MfaCredentialCreate
          setCurrentStep={setCurrentStep}
          setCurrentMfaCredentialData={setCurrentMfaCredentialData}
          identityId={identityId}
        />
      ),
    },
    {
      title: t('Activate'),
      content: (
        <MfaCredentialActivate
          currentMfaCredentialData={currentMfaCredentialData}
          resetProfileSecurityTab={resetProfileSecurityTab}
          identityId={identityId}
        />
      ),
    },
  ];

  return (
    <OpModal
      className="new-mfa-credential-modal"
      title={t('New MFA device')}
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <OpSteps items={items} current={currentStep} size="small" />
      <div className="new-mfa-credential-modal__step-items">
        {items[currentStep].content}
      </div>
      <OpModal
        title={t('Cancel activation?')}
        open={confirmModalIsOpen}
        cancelText={t('Continue activation')}
        okText={t('Delete device')}
        onCancel={onConfirmModalCancel}
        onOk={() => {
          resetProfileSecurityTab();
          deleteMfaCredential(currentMfaCredentialData?.id!);
        }}
      >
        {t(
          "You haven't finished activating this device. By cancelling, the device will be deleted and you will have to start the process over again.",
        )}
      </OpModal>
    </OpModal>
  );
};

export default NewMfaCredentialModal;
