import clsx from 'clsx';
import Alert from 'antd/es/alert';
import { useLocalStorage } from 'react-use';
import { OpInfoProps } from '../OpInfo';

import '../OpInfo.scss';

interface OpInfoWithDismissProps extends Omit<OpInfoProps, 'dismissibleId'> {
  dismissibleId: string;
}

export const OpInfoWithDismiss = ({
  className,
  dismissibleId,
  ...alertProps
}: OpInfoWithDismissProps) => {
  /** Using the useLocalStorage hook in order to allow for the Tipbox to be hidden */
  const [isVisible, setIsVisible] = useLocalStorage(dismissibleId, true);

  if (!isVisible) {
    return null;
  }

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <Alert
      className={clsx('op-info', className)}
      showIcon
      {...alertProps}
      closable
      onClose={handleClose}
    />
  );
};
