import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { SET_ORG, SET_SAVING_BILLING_SUBSCRIPTION_INFO } from './constants';

function orgReducer(state = fromJS({}), action) {
  switch (action.type) {
    case SET_ORG:
      return fromJS(action.org);
    default:
      return state;
  }
}

function savingBillingSubscriptionInfoReducer(state = false, action) {
  switch (action.type) {
    case SET_SAVING_BILLING_SUBSCRIPTION_INFO:
      return action.saving;
    default:
      return state;
  }
}

const reducers = {
  orgReducer,
  savingBillingSubscriptionInfoReducer,
};

export default combineReducers(reducers);
