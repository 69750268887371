import { useState } from 'react';
import { OpDropdown } from 'new-components/DLS/OpDropdown/OpDropdown';
import { OpIdentityUserAvatar } from 'new-components/OpIdentityUserAvatar/OpIdentityUserAvatar';
import { UserMenuDropdownContent, ProfileDrawer } from './components';

import './UserMenu.scss';

export const UserMenu = () => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const dropdownRender = () => (
    <UserMenuDropdownContent
      setDropdownIsOpen={setDropdownIsOpen}
      setDrawerIsOpen={setDrawerIsOpen}
    />
  );

  const onOpenChange = (flag: boolean) => {
    setDropdownIsOpen(flag);
  };

  return (
    <>
      <OpDropdown
        open={dropdownIsOpen}
        trigger={['click']}
        dropdownRender={dropdownRender}
        className="user-menu"
        onOpenChange={onOpenChange}
      >
        {/** Why do we need this div? If code returned from OpIdentityUserAvatar put
         * here directly Dropdown onClick works, but the component by itself
         * (without the div wrapper) does not fire the onClick event */}
        <div className="user-menu__icon" data-testid="user-menu">
          <OpIdentityUserAvatar size={36} />
        </div>
      </OpDropdown>
      <ProfileDrawer
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
      />
    </>
  );
};
