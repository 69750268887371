import { ComponentProps, useEffect, useRef, useState } from 'react';
import Drawer from 'antd/es/drawer';

import './OpDrawer.scss';

interface OpDrawerProps extends ComponentProps<typeof Drawer> {}

export const OpDrawer = ({
  children,
  placement = 'right',
  className = '',
  maskClassName = '',
  onClose,
  ...drawerProps
}: OpDrawerProps) => {
  const classes = `op-drawer ${className}`.trim();
  const maskClasses = `op-drawer-mask ${maskClassName}`.trim();

  const drawerOpenTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  /**
   * Antd Drawer has what I would consider a bug where if there is a ton of content to
   * display in the drawer, it will not open the drawer until all is processed. This is
   * a workaround to show nothing until the drawer is ready to be displayed.
   * In most cases this will visual not affect anything
   */
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!drawerProps.open) return;

    drawerOpenTimeoutRef.current = setTimeout(() => {
      setLoading(false);
    }, 50); // Played with different values but below 50ms is the lowest I can get it to work

    return () => {
      setLoading(true);
      if (drawerOpenTimeoutRef.current) {
        clearTimeout(drawerOpenTimeoutRef.current);
        drawerOpenTimeoutRef.current = null;
      }
    };
  }, [drawerProps.open]);

  return (
    <Drawer
      placement={placement}
      className={classes}
      maskClassName={maskClasses}
      onClose={onClose}
      {...drawerProps}
    >
      {loading ? null : children}
    </Drawer>
  );
};
