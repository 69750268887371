import { useTranslation } from 'react-i18next';
import { FormOnSubmitType, OpForm } from 'new-components/DLS/OpForm/OpForm';
import { useCreateMfaCredential } from 'utils/customHooks/api/useCreateMfaCredential';
import { steps } from '../constants';

interface MfaCredentialCreateProps {
  setCurrentStep(step: number): void;
  setCurrentMfaCredentialData(
    mfaData: Api.Response['createMfaCredential'],
  ): void;
  identityId: number;
}

const MfaCredentialCreate = ({
  setCurrentStep,
  setCurrentMfaCredentialData,
  identityId,
}: MfaCredentialCreateProps) => {
  const { t } = useTranslation();

  const createMfaCredentialMutation = useCreateMfaCredential();

  const onSubmit: FormOnSubmitType = ({
    values: { mfaCredentialTypeId, name },
  }) => {
    createMfaCredentialMutation.mutate(
      {
        apiEndpointRequirements: [identityId],
        payload: {
          mfaCredentialTypeId,
          name,
          totpSoftDevice: {},
        },
      },
      {
        onSuccess: (data) => {
          setCurrentMfaCredentialData(data!);
          setCurrentStep(steps.MFA_DEVICE_ACTIVATE);
        },
      },
    );
  };

  return (
    <OpForm
      className="mfa-credential-create"
      initialValues={{ mfaCredentialTypeId: 1, name: '' }}
      onSubmit={onSubmit}
      testId="mfa-credential-create"
      hasError={false}
    >
      <OpForm.Select
        name="mfaCredentialTypeId"
        label={t('MFA Device Type')}
        rules={[{ required: true }]}
        testId="create-mfa-device"
        options={[
          {
            label: t(
              `TOTP Soft Device (Google Authenticator, Authy, Duo or compatible)`,
            ),
            value: 1,
          },
        ]}
      />
      <OpForm.Input
        autoFocus
        name="name"
        label={t('Name')}
        rules={[{ required: true, min: 3 }]}
      />
    </OpForm>
  );
};

export default MfaCredentialCreate;
