import { MutationFunction } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { queryRequest } from 'utils/queryRequest';
import { getWindowLocation } from 'utils/window';

interface RequestPayload {
  updateIdentitiesPayload: Partial<Api.Payload['updateIdentity']>;
  updateIdentityNicknamesPayload: Api.Payload['updateIdentityNicknames'];
}

type IMutationFunction = MutationFunction<void, RequestPayload>;
/** Although we can do separate calls and have one succeed and the other not, it
 * is better to do all or nothing so that we can get a single onSuccess callback
 * and immediately take action when it fires. Using mutateAsync is not an option
 * as using that will result in a red screen when the error is thrown (rather than
 * silent error in the console) */
export const useProfileInfoMutation = () => {
  const { t } = useTranslation();
  const { identityId } = useIdentityAndUser();
  const currentIdentityLanguage = useSelector(selectCurrentIdentityLanguage());

  const mutationFn: IMutationFunction = async ({
    updateIdentitiesPayload,
    updateIdentityNicknamesPayload,
  }) => {
    const res1 = await queryRequest({
      apiEndpointName: 'updateIdentity',
      parameters: [identityId!, updateIdentitiesPayload],
      currentIdentityLanguage,
    });

    if (res1.statusCode >= 400) {
      throw new Error(res1.json.message);
    }

    const res2 = await queryRequest({
      apiEndpointName: 'updateIdentityNicknames',
      parameters: [identityId!, updateIdentityNicknamesPayload],
      currentIdentityLanguage,
    });

    if (res2.statusCode >= 400) {
      throw new Error(res2.json.message);
    }

    // Neither of the responses return data so no return value needed
  };

  return useOpMutation({
    mutationKey: ['updateIdentityAndNicknames'],
    mutationFn,
    onSuccessMessage: t('Successfully updated info'),
    onSuccessCallback: (_, { updateIdentitiesPayload }) => {
      /**
       * Refresh the page when a language is changed to ensure all text in
       * modules (such as constants files) get re-evaluated as once a module is
       * imported the ESM module system caches that import and will not rerun
       * the translation function
       */
      if (updateIdentitiesPayload?.language) {
        getWindowLocation().reload();
      }
    },
    queryKeysToInvalidate: [['describeIdentity']],
  });
};
