import { OpTextButton } from 'new-components/DLS/OpTextButton/OpTextButton';
import { useTranslation } from 'react-i18next';

import './ClearAllAlertsButton.scss';

export const ClearAllAlertsButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="clear-all-alerts-button">
      <OpTextButton
        className="clear-all-alerts-button__text-button"
        size="small"
        onClick={onClick}
      >
        {t('Clear all')}
      </OpTextButton>
    </div>
  );
};
