/*
 *
 * App actions
 *
 */

import {
  INIT_STORE_FROM_COOKIE,
  FINALIZE_LOGIN,
  LOGIN_SSO_CALLBACK,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REQUEST_RESET_PASSWORD,
  SET_CURRENT_ORG,
  SET_CURRENT_ORG_NAME,
  APP_ERROR,
  SET_SIDE_MENU_VISIBILITY,
  SET_ORG_DROPDOWN_VISIBILITY,
  SET_LOCKDOWN_DROPDOWN_VISIBILITY,
  CHECK_FOR_MAINTENANCE_MODE,
  CHECK_VERSION_NUMBER,
  SET_UPDATE_REQUIRED,
  SET_MAINTENANCE_MODE,
  SET_DISABLED_ROUTES,
  CHECK_DISABLED_ROUTES,
  CHECK_ACCESS_TOKEN,
  CHECK_INVITE_TOKEN,
  CHECK_RESET_TOKEN,
  SET_ALERT,
  CLEAR_ALERT,
  SET_ERROR_MESSAGE,
  SET_ORG_USER_PROFILE_IMAGE_URL,
  SET_IDENTITY_INFO,
  SET_CONFIRMATION_PROPS,
  CHECK_INITIAL_LOGIN_FOR_EXISTING_COOKIE,
  SET_PUSH_NOTIFICATION_IS_ENABLED,
  SET_CURRENT_USER_PREFERENCES,
  REQUEST_SWITCH_ORG,
} from './constants';

export function checkInitialLoginForExistingCookie(attemptedRoute) {
  return {
    type: CHECK_INITIAL_LOGIN_FOR_EXISTING_COOKIE,
    attemptedRoute,
  };
}

export function setOrgUserProfileImage(imageUrl) {
  return {
    type: SET_ORG_USER_PROFILE_IMAGE_URL,
    imageUrl,
  };
}

export function setIdentityInfo(info) {
  return {
    type: SET_IDENTITY_INFO,
    info,
  };
}

export function setErrorMessage(message, cta) {
  return {
    type: SET_ERROR_MESSAGE,
    message,
    cta,
  };
}

export function requestCheckForMaintenanceMode(forceCheck, callback) {
  return {
    type: CHECK_FOR_MAINTENANCE_MODE,
    forceCheck,
    callback,
  };
}

export function requestCheckVersionNumber() {
  return {
    type: CHECK_VERSION_NUMBER,
  };
}

export function setUpdateRequired(required) {
  return {
    type: SET_UPDATE_REQUIRED,
    required,
  };
}

export function setMaintenanceMode(on) {
  return {
    type: SET_MAINTENANCE_MODE,
    on,
  };
}

export function setDisabledRoutes(routes) {
  return {
    type: SET_DISABLED_ROUTES,
    routes,
  };
}
/** @type {import('./types').SetAlert} */
export function setAlert(
  alertType,
  alertMessage,
  alertHeader,
  { timeout = null, alertIcon = null, alertButtons = null, alertId } = {},
) {
  return {
    type: SET_ALERT,
    alertType,
    alertIcon,
    alertButtons,
    alertMessage,
    alertHeader,
    timeout, // this will force the alert to trigger a timeout in ms
    alertTime: Date.now(),
    alertId,
  };
}

export function clearAlert(id, alertType, clearAll = false /* OPTIONAL */) {
  return {
    type: CLEAR_ALERT,
    id,
    alertType,
    clearAll,
  };
}

export function finalizeLogin(data, attemptedRoute) {
  return {
    type: FINALIZE_LOGIN,
    data,
    attemptedRoute,
  };
}

export function requestLoginSSOCallback(data) {
  return {
    type: LOGIN_SSO_CALLBACK,
    data,
  };
}

/**
 * Action that signifies a successful login, contains all the information that
 * is returned by the API on a successful login
 *
 * @param {string} identityId   ID of the identity who logged in
 * @param {string} orgId        Organization ID for the active organization
 * @param {object} orgs         Key value table of orgs that this admin has
 *                              access to containing IDs and Names for each org
 * @param {object} scopes       Key value table containing scopes for each org
 *                              this user has access to
 *
 * @return {object} An action object with a type of LOGIN_SUCCESS
 */
export function loginSuccess({
  identityId,
  orgId,
  orgName,
  parentOrgId,
  isLicenseBased,
  currentUserPreferences,
  errorMessage,
}) {
  return {
    type: LOGIN_SUCCESS,
    identityId,
    orgId,
    orgName,
    parentOrgId,
    isLicenseBased,
    currentUserPreferences,
    errorMessage,
  };
}

export function logoutRequest(redirect) {
  return {
    type: LOGOUT_REQUEST,
    redirect,
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function toggleSideMenuVisibility() {
  return {
    type: SET_SIDE_MENU_VISIBILITY,
  };
}

export function toggleOrgDropDownVisibility() {
  return {
    type: SET_ORG_DROPDOWN_VISIBILITY,
  };
}

export function toggleLockdownDropDownVisibility() {
  return {
    type: SET_LOCKDOWN_DROPDOWN_VISIBILITY,
  };
}

export function requestResetPwd(token, password) {
  return {
    type: REQUEST_RESET_PASSWORD,
    token,
    password,
  };
}

export function setCurrentOrg(orgId) {
  return {
    type: SET_CURRENT_ORG,
    orgId,
  };
}

export const setIntercomNotificationsAreEnabled = (
  intercomNotificationsAreEnabled,
) => ({
  type: SET_PUSH_NOTIFICATION_IS_ENABLED,
  intercomNotificationsAreEnabled,
});

export function setCurrentOrgName(orgId, orgName) {
  return {
    type: SET_CURRENT_ORG_NAME,
    orgId,
    orgName,
  };
}

/**
 * Dispatched when there is an application error.
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of APP_ERROR passing the error
 */
export function appError(error) {
  return {
    type: APP_ERROR,
    error,
  };
}

export function checkAccessToken(callback) {
  return {
    type: CHECK_ACCESS_TOKEN,
    callback,
  };
}

export function checkDisabledRoutes(nextPath, callback) {
  return {
    type: CHECK_DISABLED_ROUTES,
    nextPath,
    callback,
  };
}

export function checkInviteToken(callback) {
  return {
    type: CHECK_INVITE_TOKEN,
    callback,
  };
}

export function checkResetToken(callback) {
  return {
    type: CHECK_RESET_TOKEN,
    callback,
  };
}

export function initStoreFromCookie(callback) {
  return {
    type: INIT_STORE_FROM_COOKIE,
    callback,
  };
}

export function setConfirmationProps(confirmationProps) {
  return {
    type: SET_CONFIRMATION_PROPS,
    confirmationProps,
  };
}

export function setCurrentUserPreferences(currentUserPreferences) {
  return {
    type: SET_CURRENT_USER_PREFERENCES,
    currentUserPreferences,
  };
}

export function requestSwitchOrg(orgId) {
  return {
    type: REQUEST_SWITCH_ORG,
    orgId,
  };
}
