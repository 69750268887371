import { ChevronUpSvg } from 'components/svgs/ChevronUpSvg';
import { ChevronDownSvg } from 'components/svgs/ChevronDownSvg';
import { TableState } from 'new-components/DLS/OpTable/OpTable';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { OpSelect } from 'new-components/DLS/OpSelect/OpSelect';
import { useTranslation } from 'react-i18next';

import './TablePagination.scss';

interface TablePaginationProps {
  currentPage: number;
  pageSize: number;
  filteredCount: number;
  totalCount: number;
  setTableState: (callback: (state: TableState) => TableState) => void;
}

export const TablePagination = ({
  currentPage,
  pageSize,
  filteredCount,
  totalCount,
  setTableState,
}: TablePaginationProps) => {
  const { t } = useTranslation();

  if (!totalCount) {
    return null;
  }

  const itemStartCount = (currentPage - 1) * pageSize + 1;
  const itemEndCount = Math.min(filteredCount, currentPage * pageSize);
  const paginationTextPrefix = filteredCount
    ? t('{{itemStartCount}}-{{itemEndCount}} of ', {
        itemStartCount,
        itemEndCount,
      })
    : '';
  const paginationText = `${paginationTextPrefix}${t(
    '{{count}} items ({{totalCount}} total)',
    { count: filteredCount, totalCount },
  )}`;

  return (
    <div className="table-pagination">
      <div className="table-pagination__left">
        {filteredCount > pageSize && (
          <>
            <OpButton
              disabled={currentPage === 1}
              size="large"
              onClick={() =>
                setTableState((currentState) => ({
                  ...currentState,
                  pagination: {
                    ...currentState.pagination,
                    current: currentState.pagination.current - 1,
                  },
                }))
              }
            >
              <ChevronDownSvg className="table-pagination__prev-page" />
            </OpButton>
            <OpButton
              disabled={currentPage === Math.ceil(filteredCount / pageSize)}
              size="large"
              onClick={() =>
                setTableState((currentState) => ({
                  ...currentState,
                  pagination: {
                    ...currentState.pagination,
                    current: currentState.pagination.current + 1,
                  },
                }))
              }
            >
              <ChevronUpSvg className="table-pagination__next-page" />
            </OpButton>
          </>
        )}
        <div className="table-pagination__text">{paginationText}</div>
      </div>
      <div className="table-pagination__page-size-container">
        <OpSelect
          popupClassName="table-pagination__select-popup"
          size="large"
          options={[
            { label: t('10 / Page'), value: '10' },
            { label: t('25 / Page'), value: '25' },
            { label: t('50 / Page'), value: '50' },
            { label: t('100 / Page'), value: '100' },
            { label: t('200 / Page'), value: '200' },
            { label: t('500 / Page'), value: '500' },
          ]}
          value={String(pageSize)}
          onChange={(value) =>
            setTableState((currentState) => ({
              ...currentState,
              pagination: {
                ...currentState.pagination,
                pageSize: Number(value),
                current: 1, // Reset to page 1 when page size changes
              },
            }))
          }
        />
      </div>
    </div>
  );
};
