import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { useEffect, useState } from 'react';
import { getControlCenterOrigin } from 'utils/getControlCenterOrigin';
import { removeAccessToken, getAccessToken } from 'utils/accessToken';
import { getWindowLocation } from 'utils/window';

export const useVerifyAuthentication = () => {
  const windowLocation = getWindowLocation();
  const { href } = windowLocation;

  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(true);

  const controlCenterOrigin = getControlCenterOrigin();
  const loginRoute = `${controlCenterOrigin}/login?attemptedRoute=${href}`;

  const { data: checkAccessToken } = useOpQuery({
    apiEndpointName: 'validateAccessToken',
    parameters: [accessToken],
    enabled: Boolean(accessToken),
    select: (data) => data.json.data,
  });

  // Check if access token is valid
  useEffect(() => {
    if (checkAccessToken) {
      if (!checkAccessToken.isValid) {
        // invalid token - remove the token and return to login
        removeAccessToken();
        windowLocation.href = loginRoute;
      }

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAccessToken]);

  if (!accessToken) {
    // When not logged in -> redirect to login page with the attemptedRoute being the full url
    windowLocation.href = loginRoute;
  }

  return { isLoading };
};
