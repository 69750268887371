// Microphone changes for LiveKit replacement
import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'semantic-ui-react/dist/es/elements/Icon/Icon';
import { Popup } from 'components/Popup';
import { useTranslation } from 'react-i18next';
import { Clickable } from 'components/Clickable/Clickable';

const styles = {
  wrapper: { paddingTop: 9 },
  icon: { height: 'auto' },
};

const MicrophoneToggleButton = memo(({ toggleHandler, initState }) => {
  const { t } = useTranslation();
  const [isMicOn, setIsMicOn] = useState(initState);

  const handleButtonClick = useCallback(() => {
    toggleHandler(!isMicOn);
    setIsMicOn(!isMicOn);
  }, [toggleHandler, isMicOn]);

  return (
    <Popup
      trigger={
        <Clickable
          style={styles.wrapper}
          className="player-controls-button op-justify-content-center op-cursor-pointer"
          onClick={handleButtonClick}
          tabIndex={0}
          role="button"
          data-testid="muteButton"
        >
          <Icon
            size="large"
            style={styles.icon}
            fitted
            inverted
            name={isMicOn ? 'microphone' : 'microphone slash'}
            data-testid="muteButtonIcon"
          />
        </Clickable>
      }
      content={isMicOn ? t(`Mute mic`) : t(`Unmute mic`)}
      position="bottom center"
      data-testid="microphone-toggle-button"
    />
  );
});

MicrophoneToggleButton.displayName = 'MicrophoneToggleButton';

MicrophoneToggleButton.propTypes = {
  initState: PropTypes.bool.isRequired,
};

export default MicrophoneToggleButton;
