import { Key } from 'react';
import get from 'lodash/get';
import {
  OpTableColumn,
  OpTableRawColumnType,
  OpTableRecordType,
} from './OpTableCore';
import { ColumnHeader } from './components';
import { TableState } from '../OpTable/OpTable';
import { OpTableCorePersistedState } from '../OpTable/uiPersistence';
import { OnFilterUpdateType } from './components/ColumnHeaderFilter';

type PersistedColumnsMap = Record<string, OpTableColumn<OpTableRecordType>>;

export const createFinalColumnKey = (
  key?: Key,
  dataIndex?: OpTableRawColumnType['dataIndex'],
) => {
  if (key) {
    return key as string;
  }

  if (Array.isArray(dataIndex)) {
    return dataIndex.join('.') as string;
  }

  return dataIndex as string;
};

/** Width calculated based on the width of each column (default is 180px)
 * plus the width of the selection column (if there is one). We define the
 * width so that we can constrain the table width to the container width
 * and scroll horizontally */
export const createTableWidth = (
  columns: OpTableColumn[],
  extraWidth: number = 0,
) =>
  columns.reduce(
    (acc, { width = 180, hidden }) => (hidden ? acc : acc + width),
    0,
  ) + extraWidth;

const createPersistedColumnMap = (
  persistedState?: OpTableCorePersistedState,
): PersistedColumnsMap => {
  const persistedColumnsMap = {} as PersistedColumnsMap;

  persistedState?.columns?.forEach((persistedColumn) => {
    persistedColumnsMap[`${persistedColumn.key}`] = { ...persistedColumn };
  });

  return persistedColumnsMap;
};

export const convertRawColumnsToColumns = ({
  rawColumns,
  hasHeaderFilter,
  tableState,
  onFilterUpdate,
  resetRowSelections,
  gtm,
  isUsingSubstringMatch = false,
  shouldUsePersistedColumns,
  persistedState,
}: {
  rawColumns: OpTableRawColumnType[];
  persistedState?: OpTableCorePersistedState;
  shouldUsePersistedColumns?: boolean;
  hasHeaderFilter: boolean;
  tableState?: TableState;
  onFilterUpdate?: OnFilterUpdateType;
  resetRowSelections?: () => void;
  gtm?: string;
  isUsingSubstringMatch?: boolean;
}) => {
  let persistedColumnsMap: PersistedColumnsMap = {};

  if (shouldUsePersistedColumns) {
    persistedColumnsMap = createPersistedColumnMap(persistedState);
  }

  return rawColumns.map((rawColumn) => {
    const finalKey = createFinalColumnKey(rawColumn.key, rawColumn.dataIndex);

    const sorterState = shouldUsePersistedColumns
      ? persistedState?.sorter
      : tableState?.sorter;

    const sorter = Array.isArray(sorterState)
      ? sorterState?.find((sortObj) => sortObj.columnKey === finalKey)
      : sorterState?.columnKey === finalKey
        ? sorterState
        : null;

    const finalRawColumn = shouldUsePersistedColumns
      ? {
          ...rawColumn,
          ...persistedColumnsMap[finalKey],
          defaultSortOrder: sorter?.order,
        }
      : rawColumn;

    // This cant be in the column ever or it will never update when clicking the sorter as its uncontrolled
    delete finalRawColumn.sortOrder;

    const {
      label,
      tooltip,
      dataIndex,
      key,
      filter,
      onFilter,
      hidden,
      required,
      defaultFilteredValue,
      ...rest
    } = finalRawColumn;

    /** We want to use the latest state of the hidden columns (table state) so
     * that if new data comes in, the hidden columns are not reset to their
     * defaults (passed in rawColumns) */
    const isColumnHidden = shouldUsePersistedColumns
      ? hidden
      : (tableState?.columns.find(
          ({ key: currColumnKey }) => currColumnKey === finalKey,
        )?.hidden ?? hidden);

    const filterValue = shouldUsePersistedColumns
      ? persistedState?.filters[finalKey]
      : defaultFilteredValue;

    /** title needs to be a function so that setState can be used as it may not
     * be passed or initialized yet */
    const title = () => (
      <ColumnHeader
        gtm={gtm}
        label={label}
        tooltip={tooltip}
        hasHeaderFilter={hasHeaderFilter}
        filterKey={finalKey}
        filter={filter}
        onFilterUpdate={onFilterUpdate}
        resetRowSelections={resetRowSelections}
        // @ts-expect-error type mismatch here
        defaultFilteredValue={filterValue}
        isUsingSubstringMatch={isUsingSubstringMatch}
      />
    );

    /** If there is no filter, then we shave no need for onFilter. Otherwise we
     * use the passed onFilter or a default that matches any partial string */
    const finalOnFilter = !filter
      ? undefined
      : onFilter ||
        ((value, record) =>
          (get(record, finalKey) || '')
            .toString()
            .toLowerCase()
            .includes(String(value).toLowerCase()));

    if (typeof isColumnHidden === 'boolean') {
      return {
        key: finalKey,
        dataIndex,
        hidden: isColumnHidden,
        required: undefined,
        title,
        label, // Need the label for show/hide columns
        showSorterTooltip: false, // Hides the default tooltip
        onFilter: finalOnFilter,
        /** Explicitly not including defaultFilteredValue as we use custom filtering
         * and our filtered don't trigger anything on the internal table state that
         * will prevent defaultFilteredValue from taking affect */
        ...rest,
      } satisfies OpTableColumn;
    }

    return {
      key: finalKey,
      dataIndex,
      required,
      title,
      label, // Need the label for show/hide columns
      showSorterTooltip: false, // Hides the default tooltip
      onFilter: finalOnFilter,
      /** Explicitly not including defaultFilteredValue as we use custom filtering
       * and our filtered don't trigger anything on the internal table state that
       * will prevent defaultFilteredValue from taking affect */
      ...rest,
    } satisfies OpTableColumn;
  });
};
