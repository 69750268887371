import Button from 'antd/es/button';
import { colorMap, convertSemanticToAntdSize, iconMap } from './helpers';

import './ButtonWithoutSuir.scss';

export const ButtonWithoutSuir = ({
  size,
  content,
  basic,
  primary,
  negative,
  labelPosition,
  children,
  fluid,
  icon,
  color,

  // Passed through props
  // loading,
  // onClick,
  // disabled,
  // data-testid
  // data-gtm
  // style,
  // className,
  // id,
  ...passedThroughProps
}) => {
  const antdSize = convertSemanticToAntdSize(size);
  const colorProps = { color: colorMap[color] || 'default' };
  if (primary) {
    if (basic) {
      colorProps.color = 'primary';
      colorProps.variant = 'outlined';
    } else {
      colorProps.type = 'primary';
    }
  } else if (negative) {
    colorProps.color = 'danger';
    colorProps.variant = 'solid';
  } else if (!basic) {
    colorProps.color = colorMap[color] || 'default';
    colorProps.variant = colorMap[color] ? 'solid' : 'filled';
  }
  const finalIcon =
    typeof icon === 'string'
      ? iconMap[icon]
      : icon?.name
        ? iconMap[icon.name]
        : icon;

  return (
    <Button
      {...passedThroughProps}
      {...colorProps}
      block={fluid}
      size={antdSize}
      icon={finalIcon}
    >
      {content || children}
    </Button>
  );
};
