import { countries } from 'constants/countries';
import { OpSelect } from 'new-components/DLS/OpSelect/OpSelect';
import { sortOptionsAlphabetically } from 'utils/sortOptionsAlphabetically';

interface CountrySelectProps {
  onSelect(shortCode: string): void;
  value?: string;
  disabled?: boolean;
  filterCondition: string[] | undefined;
}

export const CountrySelect = ({
  onSelect,
  value = 'US',
  disabled,
  filterCondition,
}: CountrySelectProps) => {
  let filteredCountries = countries;

  if (filterCondition && filterCondition.length > 0) {
    filteredCountries = countries.filter((e) =>
      filterCondition?.includes(e.short!),
    );
  }

  const options = filteredCountries.map((item) => {
    const optionLabel = `${item.name} +${item.phoneCode}`;
    const fixedProps = {
      key: optionLabel,
      value: item.short,
      label: (
        <>
          {item.emoji} +{item.phoneCode}
        </>
      ),
    };
    return (
      <OpSelect.Option {...fixedProps}>
        {item.emoji} {optionLabel}
      </OpSelect.Option>
    );
  });

  return (
    <OpSelect
      variant="borderless"
      popupMatchSelectWidth={false}
      optionLabelProp="label"
      onSelect={onSelect}
      defaultValue="US"
      value={value}
      disabled={disabled}
      filterSort={sortOptionsAlphabetically}
    >
      {options}
    </OpSelect>
  );
};
