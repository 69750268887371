import { put, call, all, take, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { requestAndSet } from 'utils/helpers';
import { REQUEST_OMNISELECT_DATA } from './constants';
import {
  setOmniSelectList,
  setOmniSelectOptions,
  setOmniSelectLoading,
  setOmniSelectCount,
} from './actions';

function* requestOmniSelectData({ page, omniId, filter, apiData }) {
  yield put(setOmniSelectLoading({ page, omniId, loading: true }));

  // ** We need to populate the initial view, even though it may not show ALL the results
  // * first get the existing values for this query
  // ** users?filter=group.id:[!={groupId}]
  const queryStringParams = { limit: 50 };
  if (filter) {
    queryStringParams.q = filter;
  }

  /**
   * Fix put in place moving OmniSelects into a Create mode. The
   * getList call is being used to fetch data for already created
   * items in a `edit` mode
   */
  if (apiData.getList) {
    const { data: listData, totalCount } = yield call(
      requestAndSet,
      apiData.getList.resource,
      apiData.getList.params,
      {
        queryStringParams,
      },
    );

    yield put(setOmniSelectCount({ page, omniId, count: totalCount }));
    yield put(setOmniSelectList({ page, omniId, data: listData }));
  }

  // * Next get the available options for this query (limited)
  const requestAndSetOptions = {
    queryStringParams: {
      ...queryStringParams,
    },
  };

  if (apiData.getOptions.filter) {
    requestAndSetOptions.queryStringParams.filter = apiData.getOptions.filter;
  }

  if (apiData.getOptions.options) {
    requestAndSetOptions.queryStringParams.options = apiData.getOptions.options;
  }

  const { data: optionsData } = yield call(
    requestAndSet,
    apiData.getOptions.resource,
    apiData.getOptions.params,
    requestAndSetOptions,
  );

  yield put(setOmniSelectOptions({ page, omniId, data: optionsData }));

  // loaded!
  yield put(setOmniSelectLoading({ page, omniId, loading: false }));
}

function* rootSaga() {
  yield all([takeEvery(REQUEST_OMNISELECT_DATA, requestOmniSelectData)]);
  yield take(LOCATION_CHANGE);
}

export default rootSaga;
