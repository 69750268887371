/**
 * @description Checks the tokenScope scope array property for a scope with the provided prefix.
 * @returns {string | undefined} returns scope with the given prefix.
 */
export const findScopeWithPrefix = (
  scopePrefix: string,
  scopes?: string[] | null,
) => {
  const orgWithScope = scopes?.find((scp) => {
    const scopeRegex = new RegExp(`^(o[0-9]+-)?${scopePrefix}`);
    const matches = scp.match(scopeRegex);

    return matches;
  });

  return orgWithScope;
};
