import { fromJS } from 'immutable';

import { CLEAR_ORG_SPECIFIC_STORE_DATA } from 'routes/AuthenticatedContainer/constants';
import { LOGOUT_SUCCESS } from 'routes/AppContainer/constants';
import { SET_ORG_FEATURES } from './constants';

function orgFeaturesReducer(state = fromJS([]), action) {
  switch (action.type) {
    case SET_ORG_FEATURES:
      return fromJS(action.orgFeatures);
    case CLEAR_ORG_SPECIFIC_STORE_DATA:
    case LOGOUT_SUCCESS:
      return fromJS([]);
    default:
      return state;
  }
}

export default orgFeaturesReducer;
