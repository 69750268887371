import { fromJS } from 'immutable';

import { SHOW_MODAL, HIDE_MODAL } from './constants';

const initialState = fromJS({
  showModal: false,
});

function modalDeluxeReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return state.set('showModal', true);
    case HIDE_MODAL:
      return state.set('showModal', false);
    default:
      return state;
  }
}

export default modalDeluxeReducer;
