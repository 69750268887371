import { Dispatch, SetStateAction, useCallback } from 'react';
import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { OpNavMenuItem } from 'new-components/OpNav/components/OpNavMenu/types';
import { isFalsy } from 'utils/isFalsy';

export const useCreateNavItem = ({
  shouldShowItem,
  selectedPrimaryMenuKey,
  setSelectedPrimaryMenuKey,
  onClick,
  onActionClick,
}: {
  shouldShowItem: (item: OpRawMenuItemType) => boolean;
  selectedPrimaryMenuKey: string | null;
  setSelectedPrimaryMenuKey: Dispatch<SetStateAction<string | null>>;
  onClick: (item: OpRawMenuItemType) => void;
  onActionClick?: (action: string) => void;
}): {
  createNavItem: (
    item: OpRawMenuItemType,
    isPrimary?: boolean,
  ) => OpNavMenuItem | null;
} => {
  const createNavItem = useCallback(
    (
      item: OpRawMenuItemType,
      isPrimary: boolean = true,
    ): OpNavMenuItem | null => {
      if (!shouldShowItem(item)) return null;

      const {
        key,
        title,
        icon,
        selectedIcon,
        route,
        external,
        items: childrenItems,
        type,
        action,
      } = item;

      const navItem: OpNavMenuItem = {
        key,
        label: title,
        ...(isPrimary && {
          icon: selectedPrimaryMenuKey === key ? selectedIcon : icon,
        }),
        ...(external && { preventKeySelection: external }),
        ...(action &&
          onActionClick && { onClick: () => onActionClick(action) }),
        ...(route && {
          onClick: () => {
            if (isPrimary) {
              setSelectedPrimaryMenuKey(key);
            }

            if (external) {
              window.open(route, '_blank');
            } else {
              onClick(item);
            }
          },
        }),
      };

      if (childrenItems) {
        const secondaryItems = childrenItems
          .map((secondaryItem) => createNavItem(secondaryItem, false))
          .filter(isFalsy);

        if (secondaryItems.length > 0) {
          navItem.children = secondaryItems;

          if (type === 'group') {
            navItem.type = 'group';
          }
        }
      }

      return navItem;
    },
    [
      selectedPrimaryMenuKey,
      setSelectedPrimaryMenuKey,
      shouldShowItem,
      onClick,
      onActionClick,
    ],
  );

  return { createNavItem };
};
