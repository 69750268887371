import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const HamburgerMenuSvg = ({ ...svgProps }) => (
  <SvgWrapper {...svgProps}>
    <path d="M1 2.5C1 2.22386 1.22386 2 1.5 2H16.5C16.7761 2 17 2.22386 17 2.5C17 2.77614 16.7761 3 16.5 3H1.5C1.22386 3 1 2.77614 1 2.5Z" />
    <path d="M1 9C1 8.72386 1.22386 8.5 1.5 8.5H16.5C16.7761 8.5 17 8.72386 17 9C17 9.27614 16.7761 9.5 16.5 9.5H1.5C1.22386 9.5 1 9.27614 1 9Z" />
    <path d="M1 15.5C1 15.2239 1.22386 15 1.5 15H16.5C16.7761 15 17 15.2239 17 15.5C17 15.7761 16.7761 16 16.5 16H1.5C1.22386 16 1 15.7761 1 15.5Z" />
  </SvgWrapper>
);
