import React, { ReactNode } from 'react';
import { CompatRouter, Route, Routes } from 'react-router-dom-v5-compat';
import { BrowserRouter } from 'react-router-dom';
import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { OpAppLayout } from 'new-components/OpAppLayout/OpAppLayout';
import { NavProvider } from 'routes/AppContainer/NavContext';
import { SuirRemovedUiProvider } from 'routes/AppContainer/SuirRemovedUiContext';
import { OpAppHomePageRedirect } from './OpAppHomePageRedirect';
import { OpPageNotFound } from './OpPageNotFound';
import { sharedRoutes } from './constants';

type BasicRoute = {
  path: string;
  allowedScopes?: string[]; // allowed scopes to go to this route
  requiresOrg?: boolean; // does this route require an org
  element: ReactNode; // element to render for page.
};

type HomeRoute = {
  isHomeRoute: true; // Has to be Home route
} & BasicRoute;

type NotHomeRoute = {
  isHomeRoute?: false; // This cannot be a Home route - there can only be one
} & BasicRoute;

// Allow only one route to be the home route, also require a home route
export type RoutesConfig = [HomeRoute, ...NotHomeRoute[]];

/**
 * @component
 * @description Component that wraps the mini apps to do shared checks on every page
 */
export const OpAppScaffold = ({
  appScopePrefix,
  basename,
  children,
  noLayoutRoutes,
  homePath,
  sidebarItems,
  title,
  showOrgSwitcher,
}: {
  appScopePrefix: string;
  basename: string;
  children: ReactNode;
  noLayoutRoutes?: ReactNode;
  homePath: string;
  sidebarItems: OpRawMenuItemType[];
  title: string;
  showOrgSwitcher?: boolean;
}) => {
  return (
    <BrowserRouter basename={basename}>
      <CompatRouter>
        <Routes>
          {/* Builtin routes */}
          <Route
            key={sharedRoutes.redirectToHome}
            path={sharedRoutes.redirectToHome}
            element={
              <OpAppHomePageRedirect
                scopePrefix={appScopePrefix}
                homePath={homePath}
              />
            }
          />

          {/* page-not-found route for manual navigate calls */}
          <Route
            key={sharedRoutes.pageNotFound}
            path={sharedRoutes.pageNotFound}
            element={<OpPageNotFound />}
          />

          <Route element={children} />

          {/* handle non-existent routes going to the 404 page */}
          <Route path="*" element={<OpPageNotFound />} />

          {noLayoutRoutes && <Route path="/nl/">{noLayoutRoutes}</Route>}

          <Route
            key={'wrapper-/'}
            path="/"
            element={
              // Temp context providers while SUIR refactored out and new nav is optional in CC
              <SuirRemovedUiProvider>
                <NavProvider>
                  <OpAppLayout
                    homePath={homePath}
                    title={title}
                    items={sidebarItems}
                    showOrgSwitcher={showOrgSwitcher}
                  />
                </NavProvider>
              </SuirRemovedUiProvider>
            }
          >
            {children}
          </Route>
        </Routes>
      </CompatRouter>
    </BrowserRouter>
  );
};
