export const SignalQualityHigh = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 16C4 15.4477 4.44772 15 5 15H7C7.55228 15 8 15.4477 8 16V20H4V16Z" />
    <path d="M10 11C10 10.4477 10.4477 10 11 10H13C13.5523 10 14 10.4477 14 11V20H10V11Z" />
    <path d="M16 5C16 4.44772 16.4477 4 17 4H19C19.5523 4 20 4.44772 20 5V20H16V5Z" />
  </svg>
);
