import { t } from 'i18next';
import { OpTableRawColumnType } from 'new-components/DLS/OpTableCore/OpTableCore';
import { opTime } from 'utils/dates';
import { sortStrings } from 'utils/sortStrings';
import {
  getParcelStatusOptions,
  parcelPictureType,
  parcelStatusCodes,
} from './constants';

/**
 * getTablePicture
 * @description - Finds the most recent user uploaded photo.
 * If none - finds the most recent wide angle photo.
 * If none - finds the most recent label photo.
 * @param {object} parcelPictures - array of parcel pictures
 * @returns {string | null} - returns the url of the correct picture or null if none found
 */
const getTablePicture = (
  parcelPictures: Api.Response['listParcels'][0]['parcelPictures'],
) => {
  if (!parcelPictures?.length) return null;

  const typeCodes = [
    parcelPictureType.userUploaded.code,
    parcelPictureType.wideAngle.code,
    parcelPictureType.label.code,
  ];

  for (let i = 0; i < typeCodes.length; i += 1) {
    const pictures = parcelPictures.filter(
      (pic) => pic?.parcelPictureType?.code === typeCodes[i],
    );

    const mostRecentPicture =
      pictures?.length > 0
        ? pictures.reduce(
            (prev, cur) =>
              opTime(prev.updatedAt!).unix() > opTime(cur.updatedAt!).unix()
                ? prev
                : cur, // At this point updatedAt is guaranteed
          )
        : null;

    if (mostRecentPicture) return mostRecentPicture;
  }

  return null;
};

export const getActiveParcelColumns = (): OpTableRawColumnType[] => [
  {
    label: t('Photo'),
    dataIndex: ['parcelPictures'],
    hidden: true,
    render: (
      parcelPictures: Api.Response['listParcels'][0]['parcelPictures'],
    ) => {
      const pic = getTablePicture(parcelPictures);
      return pic?.url ? (
        <img
          className="active-parcels-dashboard__photo"
          src={pic.url}
          alt={`package ${pic.parcelPictureType?.name}`}
        />
      ) : null;
    },
  },
  {
    label: t('Recipient'),
    dataIndex: ['recipient', 'identity', 'fullName'],
    sorter: true,
    filter: { type: 'input' },
  },
  {
    label: t('Carrier'),
    dataIndex: ['carrier'],
    sorter: true,
    filter: { type: 'input' },
  },
  {
    label: t('Tracking number'),
    dataIndex: ['trackingNumber'],
    sorter: true,
    filter: { type: 'input' },
  },
  {
    label: t('Status'),
    dataIndex: ['parcelStatus', 'code'],
    sorter: (a, b) => {
      const aValue = a.parcelStatus.name;
      const bValue = b.parcelStatus.name;
      return sortStrings(aValue, bValue);
    },
    filter: {
      type: 'multiSelect',
      options: getParcelStatusOptions(),
    },
    defaultFilteredValue: [
      parcelStatusCodes.awaitingPickup,
      parcelStatusCodes.pickedUp,
      parcelStatusCodes.lost,
      parcelStatusCodes.unidentifiedRecipient,
      parcelStatusCodes.returnToSender,
    ],
    render: (_, record) => record.parcelStatus.name,
  },
  {
    label: t('Mailroom'),
    dataIndex: ['mailroom', 'name'],
    sorter: true,
    filter: { type: 'input' },
  },
  {
    label: t('Last updated'),
    dataIndex: ['updatedAt'],
    render: (time, record) =>
      opTime(time).tz(record.mailroom.timezone).format('M-D-YY hh:mma z'),
    sorter: true,
    defaultSortOrder: 'descend',
  },
  {
    label: t('Email'),
    dataIndex: ['recipient', 'identity', 'email'],
    hidden: true,
    sorter: true,
    filter: { type: 'input' },
  },
];
