import {
  ComponentProps,
  Key,
  MouseEvent,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd/es/table';
import clsx from 'clsx';
import { ChevronDownSvg } from 'components/svgs/ChevronDownSvg';
import { usePersistentUiState } from 'utils/customHooks/usePersistentUiState';
import get from 'lodash/get';
import { removeHiddenColumnsFromFilters } from 'utils/removeHiddenColumnsFromFilters';
import {
  CsvExportButton,
  GlobalSearch,
  ShowHideColumnsButton,
  TableHeader,
  AddButton,
} from '../OpTableCore/components';
import {
  AllowExportType,
  OpTableColumn,
  OpTableCore,
  OpTableRawColumnType,
  OpTableRecordType,
} from '../OpTableCore/OpTableCore';
import {
  convertRawColumnsToColumns,
  createFinalColumnKey,
  createTableWidth,
} from '../OpTableCore/helpers';
import { TablePagination } from '../OpTableCore/components/TablePagination';
import { RowActions } from '../OpDataFetchTable/components/RowActions';
import {
  BatchActions,
  BatchActionsMenuItem,
} from '../OpTableCore/components/BatchActions';
import { OpButton } from '../OpButton/OpButton';
import {
  OpTableCorePersistedState,
  getStateToPersist,
  opTablePersistedUiVersion,
} from './uiPersistence';
import { OnFilterUpdateType } from '../OpTableCore/components/ColumnHeaderFilter';
import { OnShowHideColumns } from '../OpTableCore/components/ShowHideColumnsButton';

import './OpTable.scss';

export interface TableState<
  RecordType extends OpTableRecordType = OpTableRecordType,
> {
  default: boolean;
  pagination: {
    current: number;
    pageSize: number;
  };
  sorter: SorterResult<RecordType> | SorterResult<RecordType>[];
  filters: Record<string, string | (string | number)[] | null>;
  q: string;
  columns: OpTableColumn<RecordType>[];
}

export interface OpRowActionFuncParams {
  record: OpTableRecordType;
  numRecords?: number;
}

// Discriminated union to only allow either icon or getIcon property simultaneously
export type OpCustomRowActionButton = {
  onClick(record: unknown): void;
  tooltip?: string;
} & (
  | {
      icon: JSX.Element;
      getIcon?: never;
    }
  | {
      icon?: never;
      getIcon(record: OpTableRecordType): JSX.Element | null;
    }
);

export interface OpRowActions {
  additionalActions?: OpCustomRowActionButton[];
  onEditClick?(record: OpTableRecordType): void;
  onDeleteClick?(record: OpTableRecordType): void;
  onRowClick?(record: OpTableRecordType): void;
  deleteModalTitle?: string | ((params: OpRowActionFuncParams) => string);
  deleteModalContent?: string | ((params: OpRowActionFuncParams) => string);
  deleteModalCancelText?: string | ((params: OpRowActionFuncParams) => string);
  deleteModalOkText?: string | ((params: OpRowActionFuncParams) => string);
}

interface AllowAddition {
  itemName: string;
  onClick(): void;
  disabled?: boolean;
  tooltip?: ReactNode;
  gtm?: string;
}

type CoreTableProps = Omit<
  ComponentProps<typeof OpTableCore>,
  'title' | 'columns'
>;

export type OpTableRefType = {
  resetRowSelections: () => void;
};

// TODO: Remove this export use ComponentProps<typeof OpTable>
export type IOpTableProps = CoreTableProps & {
  columns: OpTableRawColumnType[];
  testId?: string;
  allowGlobalSearch?: boolean;
  allowExport?: AllowExportType;
  allowAddition?: boolean | AllowAddition;
  allowShowHideColumns?: boolean;
  gtm?: string;
  rowActions?: OpRowActions;
  label?: ReactNode;
  batchActionMenuItems?: BatchActionsMenuItem[];
} & (
    | { virtual: true; height: string | number }
    | { virtual?: false; height?: string | number }
  );

export const OpTable = forwardRef<OpTableRefType, IOpTableProps>(
  (
    {
      dataSource = [],
      columns: rawColumns,
      rowKey = 'id',
      testId = 'op-table',
      height,
      rowActions,
      expandable,
      label: tableLabel,
      allowGlobalSearch = true,
      allowAddition = false,
      allowExport = true,
      allowShowHideColumns = true,
      gtm,
      pagination: tablePagination = {},
      batchActionMenuItems,
      uiStateKey,
      loading,
      ...tableProps
    },
    ref,
  ) => {
    // Ref used to calculate if a row has been clicked, dragged, highlighted, etc
    const rowMouseDownTimestamp = useRef(0);

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

    const {
      uiKey,
      persistedUiState,
      setPersistedUiState,
      canUsePersistedUiState,
      isPersistedUiStateFetching,
      resetPersistedUiState,
    } = usePersistentUiState<OpTableCorePersistedState>({
      uiComponentKey: uiStateKey,
      persistedUiVersion: opTablePersistedUiVersion,
    });

    const resetRowSelections = () => {
      setSelectedRowKeys([]);
    };

    useImperativeHandle(ref, () => {
      return {
        // Allow parent to reset selected rows
        resetRowSelections,
      };
    }, []);

    /** Use rawColumns to determine if any column is filterable. If not, we want
     * to hide the filter portion from all column headers for a cleaner UI */
    const hasHeaderFilter = rawColumns.some(({ filter }) => Boolean(filter));
    /**
     * These updated columns should match columns that would be passed to an antd Table.
     * We want to keep the OpTables props as close to Antd's props as possible and use
     * custom props only when absolutely necessary. See how defaultSortOrder and
     * defaultFilteredValue are used below
     * */
    const defaultStateColumns = useMemo(
      () =>
        convertRawColumnsToColumns({
          rawColumns,
          hasHeaderFilter,
          resetRowSelections,
          gtm,
        }),
      [gtm, hasHeaderFilter, rawColumns],
    );

    /** Antd will show UI for all columns with defaultSortOrder, but will only respect the
     * first one. Seems more logical to only show the first one in the UI as well as if
     * there are not 2 default sort orders then you are never allowed to sort by more than
     * one column. */
    const columnWithDefaultSort = defaultStateColumns.find(
      (column) => column.defaultSortOrder,
    );

    const defaultState: TableState<(typeof dataSource)[0]> = useMemo(
      () => ({
        default: true,

        // Pagination defaults
        pagination: {
          current: 1,
          pageSize: 100,
        },

        // Add a default sorter if there is one
        sorter: {
          column: columnWithDefaultSort,
          order: columnWithDefaultSort?.defaultSortOrder,
          columnKey: columnWithDefaultSort?.key,
          field: columnWithDefaultSort?.key
            ? [columnWithDefaultSort.key]
            : undefined,
        },

        /** Create filters based on how antd creates them. By default any filterable column
         * will have the column key with a value of null and any default values will be added
         * if there are any. */
        filters: rawColumns.reduce(
          (acc, { key, dataIndex, filter, defaultFilteredValue }) => {
            // Add the filter and defaultFilteredValue if it exists
            if (filter && (key || dataIndex)) {
              const finalKey = createFinalColumnKey(key, dataIndex);

              return {
                ...acc,
                [finalKey]: defaultFilteredValue || null,
              };
            }

            return acc;
          },
          {},
        ),

        // Add the columns to state so we can show/hide columns dynamically
        columns: defaultStateColumns,

        // Table global search defaults
        q: '',
      }),
      [columnWithDefaultSort, defaultStateColumns, rawColumns],
    );

    const [state, setState] =
      useState<TableState<(typeof dataSource)[0]>>(defaultState);

    const onFilterUpdate: OnFilterUpdateType = useCallback(
      (filterKey, filterValue) => {
        setState((prevState) => {
          const newState = {
            ...prevState,
            default: false,
            pagination: {
              ...prevState.pagination,
              current: 1, // Reset to page 1 on filter
            },
            filters: {
              ...prevState.filters,
              [`${filterKey}`]: filterValue,
            },
          };

          setPersistedUiState(
            getStateToPersist({
              columns: newState.columns,
              filters: newState.filters,
              sorter: newState.sorter,
            }),
          );

          return newState;
        });
      },
      [setPersistedUiState],
    );

    useEffect(() => {
      if (!isPersistedUiStateFetching) {
        setState((prevState) => {
          const {
            columns: persistedColumns = defaultState.columns,
            filters: persistedFilters = defaultState.filters,
            sorter: persistedSorter = defaultState.sorter,
          } = persistedUiState || {};

          /**
           * check canUsePersistedUiState which tells us if its ok to use the fetched persisted state.
           * The specific scenario this is helpful for is if for some reason a fetch call
           * for our data failed when there is valid data in the db.We don't want to accidentally
           * "reset" the table since persistedState would then be empty and falling back
           * to defaultState
           */
          const finalPrevState = canUsePersistedUiState
            ? ({
                ...prevState,
                columns: persistedColumns,
                filters: persistedFilters,
                sorter: persistedSorter,
              } satisfies TableState)
            : prevState;

          const updatedOpTableColumns = convertRawColumnsToColumns({
            rawColumns,
            hasHeaderFilter,
            resetRowSelections,
            gtm,
            tableState: finalPrevState,
            onFilterUpdate,
            persistedState: persistedUiState,
            shouldUsePersistedColumns: canUsePersistedUiState,
          });

          return {
            ...prevState,
            columns: updatedOpTableColumns,
            ...(canUsePersistedUiState && {
              default: false,
              filters: persistedFilters,
              sorter: persistedSorter,
            }),
          };
        });
      }
    }, [
      canUsePersistedUiState,
      defaultState.columns,
      defaultState.filters,
      defaultState.sorter,
      gtm,
      hasHeaderFilter,
      isPersistedUiStateFetching,
      onFilterUpdate,
      persistedUiState,
      rawColumns,
    ]);

    // Pagination and filtering are done separately so not included
    const onChange: TableProps<(typeof dataSource)[0]>['onChange'] = (
      _pagination,
      _filters,
      sorter,
    ) => {
      setState((prevState) => {
        const newState = {
          ...prevState,
          default: false,
          sorter,
        };

        setPersistedUiState(
          getStateToPersist({
            columns: newState.columns,
            filters: newState.filters,
            sorter: newState.sorter,
          }),
        );

        return newState;
      });
    };

    /** Width calculated based on the width of each column (default is 180px).
     * We define the width so that we can constrain the table width to the
     * container width and scroll horizontally */
    const tableWidth = createTableWidth(state.columns);

    /** Use state filters to filter the data source (filter returns back a new
     * array so we don't have to worry about modifying the original dataSource) */
    let filteredDataSource = dataSource.filter((record) => {
      let result = true;

      // For global filters
      if (state.q) {
        const filterableColumns = state.columns.filter(
          (column) => column.onFilter,
        );

        if (filterableColumns.length) {
          result = filterableColumns.some((column) =>
            column.onFilter!(state.q.toLowerCase(), record),
          );
        }
      }

      // For column specific filters
      const filtersWithValues = Object.entries(state.filters).filter(
        ([_key, value]) => value && value.length,
      );

      if (filtersWithValues.length) {
        result =
          result &&
          // To support multiple column filters a record must match all filters
          filtersWithValues.every(([key, value]) => {
            const columnOnFilter = state.columns.find(
              (column) => column.key === key,
            )?.onFilter;

            return Array.isArray(value)
              ? value.some((filter) => columnOnFilter!(filter, record))
              : columnOnFilter!(value!, record);
          });
      }

      return result;
    });

    // Set the filtered count for pagination
    const filteredCount = filteredDataSource.length;
    // sorter can optionally be an array, so we need to type narrow
    if (!Array.isArray(state.sorter)) {
      const sorterOrder = state.sorter.order;
      const sorterColumnKey = state.sorter.columnKey
        ? `${state.sorter.columnKey}`
        : undefined;

      // Use state sorter to sort the filtered data
      if (sorterOrder && sorterColumnKey) {
        /** To match the default behavior of the internal table sorter,
         * we allow for a custom sort to be passed */
        if (
          state.sorter.column?.sorter &&
          typeof state.sorter.column.sorter === 'function'
        ) {
          filteredDataSource = filteredDataSource.sort(
            state.sorter.column.sorter,
          );
          if (sorterOrder === 'descend') {
            filteredDataSource.reverse();
          }
        } else {
          const sorted = filteredDataSource.sort((a, b) => {
            // Being safe and checking the value exists

            /* Using get to handle nested dataKeys. Defaulting to empty
           string regardless of type.
           Our number type columns should always be positive integers.
           Empty string should work for both string and number in this case. 
           Note that get won't set null values to defaultValue. 
           Need to still check for null below */
            let aValue = get(a, sorterColumnKey, '');
            let bValue = get(b, sorterColumnKey, '');

            aValue =
              aValue !== null
                ? typeof aValue === 'string'
                  ? aValue.toLowerCase()
                  : aValue
                : '';

            bValue =
              bValue !== null
                ? typeof bValue === 'string'
                  ? bValue.toLowerCase()
                  : bValue
                : '';

            if (sorterOrder === 'ascend') {
              /**
               * Safeguard potential null values by pushing them to the end
               */
              if (!a) return 1;
              if (!b) return -1;
              if (aValue < bValue) {
                return -1;
              }

              if (aValue > bValue) {
                return 1;
              }

              return 0;
            }

            // Reverse of above
            if (!a) return -1;
            if (!b) return 1;

            // Descend
            if (bValue < aValue) {
              return -1;
            }

            if (bValue > aValue) {
              return 1;
            }

            return 0;
          });

          filteredDataSource = sorted;
        }
      }
    }

    // Use state pagination to paginate the filtered sorted data
    const finalDataSource = filteredDataSource.slice(
      (state.pagination.current - 1) * state.pagination.pageSize,
      state.pagination.current * state.pagination.pageSize,
    );

    const finalColumns = [...state.columns];

    const expandIcon = ({
      expanded,
      onExpand,
      record,
    }: {
      expanded: boolean;
      onExpand(record: OpTableRecordType, e: MouseEvent<HTMLElement>): void;
      record: OpTableRecordType;
    }) => {
      return (
        <OpButton
          className={clsx('op-table__row-expander-button', {
            'op-table__row-expander-button--expanded': expanded,
          })}
          size="small"
          icon={<ChevronDownSvg />}
          onClick={(e) => {
            e.stopPropagation(); // Stop propagation in case the row is editable
            onExpand(record, e);
          }}
          aria-label={expanded ? 'Collapse row' : 'Expand row'}
        />
      );
    };

    // Add an action column if rowActions are passed
    /** This is added as the last thing so that it persists and is
     * not part of the show/hide columns logic */
    if (rowActions) {
      let shouldIncludeWidth = true;

      Object.entries(tableProps).forEach(([propName, propValue]) => {
        /**
         * Explicitly passing scroll as undefined or null to turn off scroll thus we dont need to add a width.
         * This width was needed as the scroll prop adds `table-layout: fixed` which we then seemingly needed this for.
         * We should probably take another look at why this is needed at all
         */
        if (propName === 'scroll' && !propValue) {
          shouldIncludeWidth = false;
        }
      });

      // Ensure width only grows for items that will be displayed as buttons
      // If the display condition (see RowActions) is true, Number(true) === 1
      const numActionButtons =
        (rowActions.additionalActions?.length ?? 0) +
        (rowActions.onEditClick ? 1 : 0) +
        (rowActions.onDeleteClick ? 1 : 0);

      finalColumns.push({
        key: 'op-actions',
        render: (_, record) => (
          <RowActions
            {...{ rowActions, record, numRecords: dataSource.length }}
          />
        ),
        ...(shouldIncludeWidth && {
          width: 32 + 32 * numActionButtons, // 32 px as a base (16px horizontal padding) and 32 px per action button width looks nice for however many buttons are used.
        }),
        fixed: 'right',
      });
    }

    const onShowHideColumns: OnShowHideColumns = (newColumns) => {
      setState((prevState) => {
        const newFilters = removeHiddenColumnsFromFilters(
          newColumns,
          prevState.filters,
        );

        const newState = {
          ...prevState,
          default: false,
          columns: newColumns,
          filters: newFilters,
        };

        setPersistedUiState(
          getStateToPersist({
            columns: newState.columns,
            filters: newState.filters,
            sorter: newState.sorter,
          }),
        );

        return newState;
      });
    };

    const tableTitle = () => (
      <TableHeader
        tableLabel={tableLabel}
        batchActions={
          batchActionMenuItems && (
            <BatchActions
              menuItems={batchActionMenuItems}
              selectedRowKeys={selectedRowKeys}
              resetRowSelections={resetRowSelections}
            />
          )
        }
        globalSearch={
          allowGlobalSearch && (
            <GlobalSearch
              gtm={gtm}
              onGlobalSearchChange={({ target: { value } }) => {
                setState((prevState) => ({
                  ...prevState,
                  q: value,
                }));
              }}
            />
          )
        }
        addButton={
          allowAddition && (
            <AddButton
              gtm={gtm}
              onClick={
                typeof allowAddition === 'object'
                  ? allowAddition?.onClick
                  : undefined
              }
              itemName={
                typeof allowAddition === 'object'
                  ? allowAddition?.itemName
                  : undefined
              }
            />
          )
        }
        csvExportButton={
          allowExport && (
            <CsvExportButton
              gtm={gtm}
              testId="csv-export-button"
              // Can transform data with dataMapCallback
              data={filteredDataSource.map(
                typeof allowExport === 'object'
                  ? allowExport.dataMapCallback || ((record) => record)
                  : (record) => record,
              )}
              // Can filter columns with createColumns
              columns={state.columns.filter(
                typeof allowExport === 'object'
                  ? allowExport.columnsFilterCallback || (() => true)
                  : () => true,
              )}
              filename={
                typeof allowExport === 'object'
                  ? allowExport.filename
                  : undefined
              }
            />
          )
        }
        showHideColumnsButton={
          allowShowHideColumns && (
            <ShowHideColumnsButton
              gtm={gtm}
              tableState={state}
              onShowHideColumns={onShowHideColumns}
              onResetTable={() => {
                setState(defaultState);
                resetPersistedUiState();
              }}
            />
          )
        }
      />
    );

    return (
      <OpTableCore
        key={uiKey}
        uiStateKey={uiStateKey}
        data-testid={testId}
        dataSource={finalDataSource}
        onChange={onChange}
        columns={finalColumns}
        rowKey={rowKey}
        scroll={{
          y: height,
          x: tableWidth,
        }}
        gtm={gtm}
        pagination={false} // We are using custom pagination
        {...(tablePagination && {
          // eslint-disable-next-line react/no-unstable-nested-components
          footer: () => (
            <TablePagination
              currentPage={state.pagination.current}
              pageSize={state.pagination.pageSize}
              filteredCount={filteredCount}
              totalCount={dataSource.length}
              setTableState={setState}
            />
          ),
        })}
        // Allow row selection if batchActionOptions passed
        {...(batchActionMenuItems && {
          rowSelection: {
            selectedRowKeys,
            onChange: (newSelectedRowKeys) =>
              setSelectedRowKeys(newSelectedRowKeys),
            preserveSelectedRowKeys: true,
          },
        })}
        // Only show table title if there is content to show
        {...((tableLabel ||
          allowGlobalSearch ||
          allowExport ||
          allowShowHideColumns) && {
          title: tableTitle,
        })}
        // Allow row to be clicked to edit
        onRow={(record) => {
          return {
            onMouseDown: () => {
              rowMouseDownTimestamp.current = Date.now();
            },
            onClick: () => {
              /**
               * If user clicks a row and doesn't immediately trigger a mouseup
               * don't invoke any of the rowAction callbacks
               */
              if (Date.now() - rowMouseDownTimestamp.current > 150) {
                return;
              }

              if (rowActions?.onRowClick) {
                rowActions.onRowClick(record);
                return;
              }

              if (rowActions?.onEditClick) {
                rowActions.onEditClick(record);
              }
            },
          };
        }}
        expandable={
          expandable && {
            ...expandable,
            expandIcon,
          }
        }
        loading={isPersistedUiStateFetching || loading}
        {...tableProps}
      />
    );
  },
);
