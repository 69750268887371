import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

const selectNav = () => (state) => state.get('navigation', fromJS({}));

const selectRootItems = () =>
  createSelector(selectNav(), (substate) => {
    if (substate.get('items')) {
      return substate
        .get('items')
        .filter((item) => item.get('parent') === null);
    }
    return null;
  });

const selectItems = () =>
  createSelector(selectNav(), (substate) => substate.get('items', fromJS([])));

const selectActiveItemIndex = () =>
  createSelector(selectNav(), (substate) => {
    if (!substate) return null;
    return substate.get('activeMenuItem', null);
  });

// sometimes the highlighted item isnt the active one (like create user, since it's a hidden option)
const selectHighlightedItemIndex = () =>
  createSelector([selectItems(), selectActiveItem()], (substate, item) => {
    if (!substate || !item) return null;
    return item.get('hidden') ? item.get('parent') : item.get('index');
  });

const selectActiveItem = () =>
  createSelector(
    [selectItems(), selectActiveItemIndex()],
    (substate, index) => {
      if (!substate) return null;
      return substate.find((s) => s.get('index') === index);
    },
  );

const selectOpenItems = () =>
  createSelector(selectNav(), (substate) => substate.get('openMenuItems', []));

export {
  selectNav,
  selectRootItems,
  selectItems,
  selectActiveItem,
  selectActiveItemIndex,
  selectOpenItems,
  selectHighlightedItemIndex,
};
