import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { accountPageRoute } from 'routes/constants';
import { selectCurrentOrgId } from 'global/accessToken/selectors';
import { t } from 'i18next';

/// /////////////////////////////////////////
// IS IT POSSIBLE TO MOVE THIS LOADER TO THE FORM REDUCER SOMEHOW?
/// /////////////////////////////////////////
export const selectSavingBillingSubscriptionInfo = () =>
  createSelector(getBilling(), (substate) =>
    substate.get('savingBillingSubscriptionInfoReducer', false),
  );

/// /////////////////////////////////////////
// IS IT POSSIBLE TO MOVE THIS LOADER TO THE FORM REDUCER SOMEHOW?
/// /////////////////////////////////////////

// const selectPage = () => (state, props) => state.get(props.route.name)
const getBilling = () => (state) =>
  state.getIn(['global', 'billing'], fromJS({}));

export const selectOrg = () =>
  createSelector(getBilling(), (substate) =>
    substate.get('orgReducer', fromJS({})),
  );

export const selectBillingIsHidden = () =>
  createSelector(
    selectOrg(),
    (substate) => substate.get('billingMode') !== 'revshare',
  );

export const selectOrgStatus = () =>
  createSelector(selectOrg(), (substate) => {
    const label = t('Service status');
    const result = {
      status: '',
      cta: '',
      text: '',
    };
    let icon = null;
    let backgroundColor = 'var(--colorFill)';
    switch (substate.get('status')) {
      case 'I':
        result.status = t('INACTIVE');
        result.cta = t('Please contact support');
        backgroundColor = 'var(--colorError)';
        icon = 'fa-times-circle';
        break;
      default:
        result.status = t('ACTIVE');
        backgroundColor =
          substate.get('termsStatus') === 'A'
            ? 'var(--colorSuccess)'
            : 'var(--colorWarning)';
        icon = 'fa-check-circle';
        break;
    }
    return fromJS({
      icon,
      label,
      result,
      backgroundColor,
    });
  });

export const selectBillingStatus = () =>
  createSelector(selectOrg(), selectOrgStatus(), (substate, orgStatus) => {
    const label = t('Billing');
    const result = {
      status: '',
      cta: '',
      text: '',
    };
    let icon = null;
    let iconColor = 'var(--colorSuccess)';
    switch (substate.get('billingStatus')) {
      case 'A': // Active
        icon = 'fa-check-circle';
        result.status = t('ACTIVE');
        break;
      case 'N': // Non-Billable
        icon = 'fa-check-circle';
        result.status = t('NONE - No billable usage (entries)');
        break;
      case 'P': // Pre-Usage
        icon = 'fa-check-circle';
        result.status = t(
          'READY - Billing will begin following the first successful entry unlock',
        );
        break;
      default:
        result.status = t('UNKNOWN');
        iconColor =
          orgStatus.get('status') === 'I'
            ? 'var(--colorError)'
            : 'var(--colorWarning)';
        icon = 'fa-question-circle';
        break;
    }
    return fromJS({
      icon,
      label,
      result,
      iconColor,
    });
  });

export const selectLegalStatus = () =>
  createSelector(selectOrg(), selectOrgStatus(), (substate, orgStatus) => {
    const label = t('Terms & conditions');
    const result = {
      status: '',
      cta: '',
      text: '',
    };
    let icon = null;
    let iconColor = 'var(--colorSuccess)';
    switch (substate.get('termsStatus')) {
      case 'A':
        icon = 'fa-check-circle';
        result.status = t('SIGNED');
        break;
      case 'N':
        icon = 'fa-exclamation-triangle';
        iconColor = ['I'].includes(orgStatus.get('status'))
          ? 'var(--colorError)'
          : 'var(--colorWarning)';
        result.status = t('MISSING SIGNATURE -');
        result.cta = t('Please review our terms of service');
        break;
      case 'E':
        icon = 'fa-exclamation-triangle';
        iconColor = ['I'].includes(orgStatus.get('status'))
          ? 'var(--colorError)'
          : 'var(--colorWarning)';
        result.status = t('EXPIRED -');
        result.cta = t(
          'Please review our terms of service to reactivate your account',
        );
        break;
      default:
        result.status = t('UNKNOWN');
        iconColor = ['I'].includes(orgStatus.get('status'))
          ? 'var(--colorError)'
          : 'var(--colorWarning)';
        icon = 'fa-question-circle';
        break;
    }
    return fromJS({
      icon,
      label,
      result,
      iconColor,
    });
  });

export const selectBillingBarData = () =>
  createSelector(
    selectOrg(),
    selectOrgStatus(),
    selectLegalStatus(),
    selectBillingStatus(),
    selectCurrentOrgId(),
    (substate, orgStatus, legalStatus, billingStatus, currentOrg) => {
      if (!currentOrg) {
        return fromJS({});
      }
      const globalStatusBad = currentOrg && substate.get('status') === 'I';
      const getMessage = () => {
        if (globalStatusBad) {
          // global status problem (inactive) takes priority
          return orgStatus.get('result');
        }
        if (substate.get('termsStatus') !== 'A') {
          // ... lastly make sure they're up to date on the TOS!
          return legalStatus.get('result');
        }
        return null;
      };
      const text = getMessage();
      return fromJS({
        key: 'billingBanner',
        ctaRoute: accountPageRoute,
        showBanner: Boolean(text),
        type: globalStatusBad
          ? 'error'
          : substate.get('billingStatus') === 'T'
            ? 'info'
            : 'warning',
        text,
      });
    },
  );

export const selectOrgParent = () =>
  createSelector(selectOrg(), (org) => org.get('parentOrg'));

export const selectOrgParentName = () =>
  createSelector(selectOrgParent(), (orgParent) =>
    orgParent ? orgParent.get('name') : null,
  );

export const selectOrgParentId = () =>
  createSelector(selectOrgParent(), (orgParent) =>
    orgParent ? orgParent.get('id') : null,
  );

export const selectBillingMode = () =>
  createSelector(selectOrg(), (org) => org.get('billingMode'));

export const selectBillableFeatures = () =>
  createSelector(selectOrg(), (org) => org.get('billableFeatures', fromJS([])));

export const selectOrgHasAvailableEntryLicense = () =>
  createSelector(selectBillableFeatures(), (billableFeatures) => {
    const entryLicense = billableFeatures.find(
      (feat) => feat.getIn(['feature', 'id']) === 20,
    );
    if (!entryLicense) {
      return false;
    }

    return entryLicense.get('currentCount') < entryLicense.get('hardLimit');
  });
