import { createContext, useContext } from 'react';

const DEFAULT_FORM_VALUES = {
  isDataLoading: false,
  isReadOnly: false,
};

export const OpFormContext = createContext(DEFAULT_FORM_VALUES);

/**
 * This will get the OpForm context values from the closest form. We have components
 * outside of direct form components that may need access to these form values
 */
export const useFormContext = () => {
  const context = useContext(OpFormContext);

  // For now we don't need anything but we could get more robust with these checks.
  if (!context) {
    return DEFAULT_FORM_VALUES;
  }

  return context;
};
