import { fromJS } from 'immutable';
import {
  SET_IS_EDITING,
  SET_FORM_VALUE,
  SET_NESTED_FORM_VALUE,
  SET_FORM_VALUES,
  SET_INITIAL_FORM_VALUES,
  SET_IS_LOADING,
  SET_FORM_VALIDATION,
  SET_FORM_ERROR_MESSAGE,
  DELETE_FORM_ERROR_MESSAGE,
  RESET_FORM_VALUES,
} from './constants';

// Initial forms state
const formsInitialState = fromJS({
  isEditing: false,
});

function formReducer(state = formsInitialState, action) {
  switch (action.type) {
    case SET_INITIAL_FORM_VALUES:
      return state
        .setIn(['initialValues'], fromJS(action.data))
        .setIn(['values'], fromJS(action.data));
    case SET_FORM_VALUE:
      return state.setIn(['values', action.key], fromJS(action.value));
    case SET_NESTED_FORM_VALUE:
      return state.setIn(['values', ...action.keys], fromJS(action.value));
    case SET_FORM_VALUES:
      return state.setIn(['values'], fromJS(action.data));
    case SET_IS_LOADING:
      return state.setIn(['isLoading'], action.value);
    case SET_FORM_VALIDATION:
      return state.setIn(['validation'], fromJS(action.data));
    case SET_FORM_ERROR_MESSAGE:
      return state.setIn(
        ['errorMessages', action.key],
        fromJS(action.errorMessage),
      );
    case DELETE_FORM_ERROR_MESSAGE:
      return state.deleteIn(['errorMessages', action.key]);
    case RESET_FORM_VALUES:
      return state.setIn(
        ['values'],
        fromJS(action.resetValues || state.getIn(['initialValues'])),
      );
    case SET_IS_EDITING:
      return state.setIn(['isEditing'], action.isEditing);
    default:
      return state;
  }
}

export default formReducer;
