// ** NOTE ** //
// ** This file should be automatically generated before it is used
// ** outside of the dashboards
// ** ** //
import {
  SET_READERS,
  READERS_REDUCER,
  USER_ENTRIES_REDUCER,
  SET_USER_ENTRIES,
  ENTRIES_REDUCER,
  SET_ENTRIES,
  USERS_REDUCER,
  SET_USERS,
  DASHBOARDS_REDUCER,
  SET_DASHBOARDS,
  ACTIVITY_REDUCER,
  SET_ACTIVITY,
  WIDGET_TYPES_REDUCER,
  SET_WIDGET_TYPES,
  SET_SHARED_USERS,
  SHARED_USERS_REDUCER,
  SET_HOOK_EVENTS,
  HOOK_EVENTS_REDUCER,
  SITES_REDUCER,
  SET_SITES,
  ZONES_REDUCER,
  SET_ZONES,
} from 'routes/OrgContainer/constants';

export default {
  entries: {
    text: 'Entries',
    api: 'listEntries',
    reducer: ENTRIES_REDUCER,
    action: SET_ENTRIES,
  },
  users: {
    text: 'Users',
    api: 'listUsers',
    reducer: USERS_REDUCER,
    action: SET_USERS,
  },
  dashboard: {
    text: 'Dashboard',
    api: 'describeDashboard',
    reducer: 'dashboard',
    action: 'GENERIC_DATA',
  },
  sharedUser: {
    text: 'Shared User',
    api: 'describeSharedUser',
    reducer: 'sharedUser',
    action: 'GENERIC_DATA',
  },
  sharedUsers: {
    text: 'Shared Users',
    api: 'listSharedUsers',
    reducer: SHARED_USERS_REDUCER,
    action: SET_SHARED_USERS,
  },
  dashboards: {
    text: 'Dashboards',
    api: 'listDashboards',
    reducer: DASHBOARDS_REDUCER,
    action: SET_DASHBOARDS,
  },
  activity: {
    text: 'Activity',
    api: 'getActivity',
    reducer: ACTIVITY_REDUCER,
    action: SET_ACTIVITY,
  },
  widgetTypes: {
    text: 'Widget Types',
    api: 'listWidgetTypes',
    reducer: WIDGET_TYPES_REDUCER,
    action: SET_WIDGET_TYPES,
  },
  userEntries: {
    text: 'User Entries',
    api: 'listUserEntries',
    reducer: USER_ENTRIES_REDUCER,
    action: SET_USER_ENTRIES,
  },
  hookEvents: {
    text: 'Hook Events',
    api: 'listHookEvents',
    reducer: HOOK_EVENTS_REDUCER,
    action: SET_HOOK_EVENTS,
  },
  sites: {
    text: 'Sites',
    api: 'listSites',
    reducer: SITES_REDUCER,
    action: SET_SITES,
  },
  zones: {
    text: 'Zones',
    api: 'listZones',
    reducer: ZONES_REDUCER,
    action: SET_ZONES,
  },
  readers: {
    text: 'Readers',
    api: 'listReaders',
    reducer: READERS_REDUCER,
    action: SET_READERS,
  },
  opVideoDevices: {
    text: 'OP Video Devices',
    api: 'listOpvideoDevices',
    reducer: 'opVideoDevices',
    action: 'GENERIC_DATA',
  },
  acuConfig: {
    text: 'ACU Config',
    api: 'getAcuConfig',
    reducer: 'acuConfig',
    action: 'GENERIC_DATA3',
  },
  opvideoDeviceListClips: {
    text: 'Clips',
    api: 'opvideoDeviceListClips',
    reducer: 'opvideoDeviceListClips',
    action: 'GENERIC_DATA4',
  },
};
