import { MouseEvent, ComponentProps, ReactNode } from 'react';
import Select from 'antd/es/select';
import Tag from 'antd/es/tag';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { ChevronDownSvg } from 'components/svgs/ChevronDownSvg';
import clsx from 'clsx';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import './OpSelect.scss';

interface OpSelectProps
  extends ComponentProps<typeof Select<string | string[]>> {
  gtm?: string;
  testId?: string;
  tagTestId?: string;
  labelValidator?: (value: ReactNode) => boolean;
}
/**
 * @component
 * @description Main select component to be used. When using a Form you can use the OpForm.Select component which is a wrapper around this component. There is also OpDataFetchSelect if you need the select to fetch external api data. If you want to sort options passed alphabetically you should use our utility function from utils/sortOptionsAlphabetically
 *
 * @example
 * <OpSelect
 *  options={options}
 *  filterSort={sortOptionsAlphabetically}
 * />
 */
export const OpSelect = ({
  className,
  gtm,
  testId = 'op-select',
  tagTestId = 'op-select-tag',
  labelValidator,
  suffixIcon = <ChevronDownSvg fill={'#65697C'} />,
  ...selectProps
}: OpSelectProps) => {
  const tagRender = ({ label, closable, onClose }: CustomTagProps) => {
    const validationError =
      labelValidator && label && !labelValidator(String(label));

    const onPreventMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        bordered={false}
        className={clsx('op-select__tag', {
          'op-select__tag--error': validationError,
        })}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        data-testid={tagTestId}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      className={clsx('op-select', className)}
      // As we are using a custom suffixIcon we need to show the loading icon manually when loading
      suffixIcon={selectProps.loading ? <LoadingOutlined /> : suffixIcon}
      tagRender={tagRender}
      // Defaulting to label as Select should always have options and antd docs recommend using label
      optionFilterProp="label"
      {...selectProps}
      data-testid={testId}
      data-gtm={gtm}
    />
  );
};

OpSelect.Option = Select.Option;
