import { ComponentProps, useState } from 'react';
import clsx from 'clsx';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/Dates';
import DatePicker from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { generateRangePickerPresets } from './generateRangePickerPresets';

import './OpRangePicker.scss';

const { RangePicker } = DatePicker;

type RangePickerProps = ComponentProps<typeof RangePicker>;

interface OpRangePickerProps
  extends Omit<RangePickerProps, 'value' | 'onChange' | 'presets'> {
  className?: string;
  showTime?: boolean;
  showPresets?: boolean;
  presets?: () => RangePickerProps['presets'];
  value?: (string | null)[] | null;
  onChange?: (value: (string | null)[] | null, formatString: string[]) => void;
  gtm?: string;
  testId?: string;
}

export const OpRangePicker = ({
  className,
  onChange,
  onClick,
  value,
  showPresets = true,
  presets,
  gtm,
  testId = 'op-range-picker',
  ...rangePickerProps
}: OpRangePickerProps) => {
  const [finalPresets, setFinalPresets] = useState(
    showPresets ? presets?.() || generateRangePickerPresets() : undefined,
  );

  // We want to store array of strings in the form values rather than array of dayjs objects
  const handleChange: RangePickerProps['onChange'] = (values, formatString) => {
    onChange?.(
      values ? values?.map((v) => (v ? v.toISOString() : null)) : null,
      formatString,
    );
  };

  const handleClick: RangePickerProps['onClick'] = (event) => {
    onClick?.(event);
    setFinalPresets(
      showPresets ? presets?.() || generateRangePickerPresets() : undefined,
    );
  };

  // We need to convert the now stored array of strings to array dayjs objects
  const finalValue = value
    ? value.map((v) => (v ? dayjs(v) : undefined))
    : undefined;

  return (
    <RangePicker
      className={clsx('op-range-picker', className)}
      showTime={{ format: TIME_FORMAT }}
      format={DATE_TIME_FORMAT}
      value={finalValue as RangePickerProps['value']}
      onChange={handleChange}
      onClick={handleClick}
      presets={finalPresets}
      data-testid={testId}
      data-gtm={gtm}
      {...rangePickerProps}
    />
  );
};
