export const SET_FORM_VALUE = 'formWrapper/SET_FORM_VALUE';
export const SET_NESTED_FORM_VALUE = 'formWrapper/SET_NESTED_FORM_VALUE';
export const SET_FORM_VALUES = 'formWrapper/SET_FORM_VALUES';
export const SET_INITIAL_FORM_VALUES = 'formWrapper/SET_INITIAL_FORM_VALUES';
export const SET_IS_LOADING = 'formWrapper/SET_IS_LOADING';
export const SET_FORM_VALIDATION = 'formWrapper/SET_FORM_VALIDATION';
export const SET_FORM_ERROR_MESSAGE = 'formWrapper/SET_FORM_ERROR_MESSAGE';
export const DELETE_FORM_ERROR_MESSAGE =
  'formWrapper/DELETE_FORM_ERROR_MESSAGE';
export const CREATE_EDIT_ITEM = 'formWrapper/CREATE_EDIT_ITEM';
export const RESET_FORM_VALUES = 'formWrapper/RESET_FORM_VALUES';
export const SET_IS_EDITING = 'formWrapper/SET_IS_EDITING';
