import { ChangeEvent, ComponentProps, useState } from 'react';
import { CountryCode } from 'libphonenumber-js';
import { phoneUtils } from 'utils/phone';
import { OpInput } from '../OpInput/OpInput';
import { CountrySelect } from './components/CountrySelect';

import './OpPhoneInput.scss';

interface OpPhoneInputProps extends ComponentProps<typeof OpInput> {
  testId?: string;
  gtm?: string;
  className?: string;
  filterCondition?: string[];
}

export const OpPhoneInput = ({
  testId = 'op-phone-input',
  gtm,
  className = '',
  onChange,
  value = '',
  disabled,
  filterCondition = [],
  ...inputProps
}: OpPhoneInputProps) => {
  const [shortCode, setShortCode] = useState<CountryCode>(
    phoneUtils.getCountryCode(String(value)),
  );
  const phoneCode = phoneUtils.getFormattedE164CountryCode(shortCode);
  /**
   * We take out the country code from the value here so
   * that we dont see the redundant code after the Select
   */
  const phoneWithoutCountryCode = String(value).replace(`${phoneCode}`, '');

  const handleSelect = (newShortCode: CountryCode) => {
    const newPhoneCode = phoneUtils.getFormattedE164CountryCode(newShortCode);
    setShortCode(newShortCode);

    if (onChange) {
      const eventData = {
        target: {
          value: `${newPhoneCode}${phoneWithoutCountryCode}`,
        },
      } as ChangeEvent<HTMLInputElement>;

      onChange(eventData);
    }
  };

  return (
    <OpInput
      className={`op-phone-input ${className}`.trim()}
      data-testid={testId}
      gtm={gtm} // Pass through
      addonBefore={
        <CountrySelect
          disabled={disabled}
          onSelect={handleSelect}
          value={shortCode}
          filterCondition={filterCondition}
        />
      }
      value={phoneWithoutCountryCode}
      disabled={disabled}
      onChange={(e) => {
        // Only allow numerical values (empty string passes too)
        const reg = /^\d*$/;
        const inputValue = reg.test(e.target.value)
          ? e.target.value
          : phoneWithoutCountryCode;

        // if the new value is empty (perhaps deleting) clear everything including country code
        const newValue = inputValue ? `${phoneCode}${inputValue}` : '';

        const eventData = {
          target: {
            value: newValue,
          },
        } as ChangeEvent<HTMLInputElement>;

        if (onChange) {
          onChange(eventData);
        }
      }}
      {...inputProps}
    />
  );
};
