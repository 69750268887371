import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const BuildingSvg = ({ ...svgProps }) => (
  <SvgWrapper {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3H4.5C4.224 3 4 3.224 4 3.5V5.5C4 5.776 4.224 6 4.5 6H7.5C7.776 6 8 5.776 8 5.5V3.5C8 3.224 7.776 3 7.5 3ZM5 5H7V4H5V5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 7H7.5C7.776 7 8 7.224 8 7.5V9.5C8 9.776 7.776 10 7.5 10H4.5C4.224 10 4 9.776 4 9.5V7.5C4 7.224 4.224 7 4.5 7ZM7 9H5V8H7V9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 3H10.5C10.224 3 10 3.224 10 3.5V5.5C10 5.776 10.224 6 10.5 6H13.5C13.776 6 14 5.776 14 5.5V3.5C14 3.224 13.776 3 13.5 3ZM11 5H13V4H11V5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 7H13.5C13.776 7 14 7.224 14 7.5V9.5C14 9.776 13.776 10 13.5 10H10.5C10.224 10 10 9.776 10 9.5V7.5C10 7.224 10.224 7 10.5 7ZM13 9H11V8H13V9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3C1.5 1.61929 2.61929 0.5 4 0.5H14C15.3807 0.5 16.5 1.61929 16.5 3V17C16.5 17.2761 16.2761 17.5 16 17.5H2C1.72386 17.5 1.5 17.2761 1.5 17V3ZM2.5 16.5H7V12.5C7 12.2239 7.17909 12 7.4 12H10.6C10.8209 12 11 12.2239 11 12.5V16.5H15.5V3C15.5 2.17157 14.8284 1.5 14 1.5H4C3.17157 1.5 2.5 2.17157 2.5 3V16.5ZM10 16.5V13H8V16.5H10Z"
    />
  </SvgWrapper>
);
