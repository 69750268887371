// action constants
export const SET_OMNISELECT_LOADING = 'app/omniselect/SET_OMNISELECT_LOADING';
export const REQUEST_OMNISELECT_DATA = 'app/omniselect/REQUEST_OMNISELECT_DATA';
export const SET_OMNISELECT_LIST = 'app/omniselect/SET_OMNISELECT_LIST';
export const SET_OMNISELECT_OPTIONS = 'app/omniselect/SET_OMNISELECT_OPTIONS';
export const ADD_OMNISELECT_ADD = 'app/omniselect/ADD_OMNISELECT_ADD';
export const REMOVE_OMNISELECT_ADD = 'app/omniselect/REMOVE_OMNISELECT_ADD';
export const ADD_OMNISELECT_REMOVE = 'app/omniselect/ADD_OMNISELECT_REMOVE';
export const REMOVE_OMNISELECT_REMOVE =
  'app/omniselect/REMOVE_OMNISELECT_REMOVE';
export const RESET_OMNISELECT_STATE = 'app/omniselect/RESET_OMNISELECT_STATE';
export const SET_OMNISELECT_COUNT = 'app/omniselect/SET_OMNISELECT_COUNT';

// State item names
export const LOADING = 'loading';
export const LIST = 'list';
export const OPTIONS = 'options';
export const ADD = 'add';
export const REMOVE = 'remove';
export const COUNT = 'count';

// other
export const MAX_CHANGES_PER_SUBMIT = 50;
