import clsx from 'clsx';
import { OpTag } from 'new-components/DLS/OpTag/OpTag';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const OrgSwitcherOptionLabel = ({
  name,
  orgId,
  icon,
  labels,
  isMobile,
}: {
  name: string;
  orgId: number;
  icon: ReactNode;
  labels: string[];
  isMobile?: boolean;
}) => {
  const { t } = useTranslation();
  const showLabel = Boolean(labels?.length);

  return (
    <div
      className={clsx('org-switcher__option', {
        'org-switcher__option--mobile': isMobile,
      })}
    >
      {icon}
      <div
        className={clsx('org-switcher__option-org-name', {
          'org-switcher__option-org-name--not-labeled': !showLabel,
        })}
      >
        <OpText size="small" ellipsis>
          {name}
        </OpText>
        <OpText className="org-switcher__option-org-id" size="tiny">
          {t('ID: {{orgId}}', { orgId })}
        </OpText>
      </div>
      {showLabel && (
        <div className="org-switcher__labels">
          {labels.map((label) => {
            switch (label) {
              case 'partner':
                return (
                  <OpTag
                    key={`${orgId}-partner`}
                    className="org-switcher__tag"
                    color="var(--colorPrimary)"
                  >
                    {isMobile ? t('P') : t('Partner')}
                  </OpTag>
                );
              case 'license-based':
                return (
                  <OpTag
                    key={`${orgId}-licensed`}
                    className="org-switcher__tag"
                    color="var(--colorPurple)"
                  >
                    {isMobile ? t('L') : t(`License-based`)}
                  </OpTag>
                );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};
