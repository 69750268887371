import Helium from 'utils/helium';
// @TODO fix this dependency above ^
const openpathConfig = require('openpathConfig');

export function injectScopeHeaders(headers) {
  if (
    process.env.NODE_ENV === 'production' ||
    !openpathConfig.INJECT_SCOPE_HEADERS
  ) {
    return headers;
  }
  const newHeaders = { ...headers };
  try {
    const scopeTest = window.sessionStorage.getItem('op-scopes');
    if (scopeTest) {
      newHeaders['op-scope-test'] = scopeTest;
    }
  } catch {
    // do nothing
  }
  return newHeaders;
}

export async function parseDebugTeapot(fetchResponse) {
  if (
    process.env.NODE_ENV === 'production' ||
    !global.store ||
    !openpathConfig.INJECT_SCOPE_HEADERS
  ) {
    return fetchResponse;
  }

  if (fetchResponse.status === 418 || fetchResponse.statusCode === 418) {
    const jsonTest =
      typeof fetchResponse.json === 'function'
        ? await fetchResponse.json()
        : fetchResponse.json;
    global.store.dispatch({
      type: 'app/Authenticated/SET_ALERT',
      alertType: 'info',
      alertMessage: `🔒::${jsonTest.message}`,
    });
  }
  return fetchResponse;
}

export async function request(resource, options) {
  // @TODO - Look into factoring this out, I think its being forced
  // from non-requestAndSet where it should be using heliumclient
  const headers = injectScopeHeaders(options.headers || {}, resource);
  try {
    let data = null;
    let firePostRequestCallback = true;
    if (resource.includes('auth')) {
      firePostRequestCallback = false;
    }
    const response = await Helium.client.requestExternal(
      null,
      String(options.method).toUpperCase(),
      resource,
      options.body ? JSON.parse(options.body) : null,
      {},
      { headers, firePostRequestCallback },
    );
    if (response.statusCode >= 400) {
      const err = new Error(
        (response.json && response.json.message) ||
          `Unexpected response code ${response.statusCode}`,
      );
      err.statusCode = response.statusCode;
      err.localizedMessage =
        response?.json?.localizedMessage ||
        response?.json?.message ||
        `Unexpected response code ${response.statusCode}`;
      throw err;
    }

    const { json } = response;
    data = json;
    return { data };
  } catch (err) {
    return { err };
  }
}

export async function checkForVersionNumberMismatch() {
  try {
    const { json: versionNeedsUpdate } =
      await Helium.client.checkMaintenanceEndpoint(openpathConfig.versionUrl);
    return versionNeedsUpdate;
  } catch (ex) {
    console.error(`Something went wrong with version check! ${ex}`);
  }
  return null;
}
