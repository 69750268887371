export const XMarkIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.76739 7.5L11.6335 4.63393C12.1222 4.14521 12.1222 3.35526 11.6335 2.86654C11.1447 2.37782 10.3548 2.37782 9.86607 2.86654L7 5.73261L4.13393 2.86654C3.64521 2.37782 2.85526 2.37782 2.36654 2.86654C1.87782 3.35526 1.87782 4.14521 2.36654 4.63393L5.23261 7.5L2.36654 10.3661C1.87782 10.8548 1.87782 11.6447 2.36654 12.1335C2.61027 12.3772 2.93025 12.4997 3.25023 12.4997C3.57021 12.4997 3.89019 12.3772 4.13393 12.1335L7 9.26739L9.86607 12.1335C10.1098 12.3772 10.4298 12.4997 10.7498 12.4997C11.0697 12.4997 11.3897 12.3772 11.6335 12.1335C12.1222 11.6447 12.1222 10.8548 11.6335 10.3661L8.76739 7.5Z"
    />
  </svg>
);
