import { getWindowLocation } from './window';

export const validAltaAvigilonUrlRegEx = /^https:\/\/.*\.alta\.avigilon\.com/;

export const validDevelopmentUrlRegEx = /^https?:\/\/localhost:/;

/**
 * @description Checks if the origin url is using alta.avigilon.com finds the correct
 * domain for the current environment.
 * This method being used should only affect those using alta.avigilon.com. Openpath urls should be unaffected.
 * @returns the environment specific domain if using alta.avigilon.com in the url.
 */
export const getAltaDomain = () => {
  const { origin } = getWindowLocation();
  const domainRegex = /(dev.|sandbox.|eu.)?alta.avigilon.com/;
  const domain = origin?.match(domainRegex);

  return domain?.[0];
};

/**
 * @returns an environment specific regex
 */
const getEnvDomainRegex = (env: string) =>
  new RegExp(`${env}.alta.avigilon.com`);

/**
 * @description Checks domain for the current environment
 * This method being used should only affect non prod envrionments using alta.avigilon.com.
 * Openpath urls should be unaffected.
 * @returns the environment of the domain if using alta.avigilon.com in the url.
 */
export const getEnvironment = () => {
  const { origin } = getWindowLocation();
  const envs = ['dev', 'sandbox', 'eu'];
  const currentEnv = envs.find((env) => origin?.match(getEnvDomainRegex(env)));

  return currentEnv;
};
