import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useDefaultAppSpecificOrg } from 'utils/customHooks/useDefaultAppSpecificOrg';
import { useVerifyAuthentication } from 'utils/customHooks/useVerifyAuthentication';
import { ORG_ID_ROUTE_TEMPLATE, sharedRoutes } from './constants';

interface OpAppHomePageRedirectProps {
  scopePrefix: string;
  homePath: string;
}

/**
 * @component
 * @description Component that checks for a default org and redirects to the homepage
 * to be used when the user goes to /
 * If a valid org is not found, this component will navigate to pageNotFound
 * For now, all authenticated routes should require an orgId including the homepage,
 * If that changes, this can be updated to handle non org required homepages.
 * Note: Mastemode user's that go straight to "/" and do not also belong to an org
 * should get sent to "page-not-found"
 * @param {string} scopePrefix - prefix used to find scopes for the current app - NOTE: this may be
 * reworked as apps and scopes are reworked.
 * @param {string} homePath - route for the current apps homepage (ex: 'dashboard/') to redirect to from '/'
 * @param {boolean} requiresOrg - Boolean to determine if the home route requires an orgId. When true,
 * search for the user's default org id and insert /o/:orgId/ into the homePath before navigation. When false,
 * do not search for an org, and navigate straight to homePath after validating login.
 * @return null - this component is solely for redirecting. This may change if we ever have an app that needs
 * home to be the "/" route (maybe if signin becomes signin.alta.avigilon.com?)
 */
export const OpAppHomePageRedirect = ({
  scopePrefix,
  homePath,
}: OpAppHomePageRedirectProps) => {
  const navigate = useNavigate();

  // Login to app before redirect
  const { isLoading: isLoadingAuth } = useVerifyAuthentication();

  const requiresOrg = homePath.includes(ORG_ID_ROUTE_TEMPLATE);

  // Find the user's default org if the home route requires an org
  const { isLoading: isLoadingDefaultOrg, orgId } = useDefaultAppSpecificOrg({
    scopePrefix,
    enabled: requiresOrg, // Only find a default org if this route requires an org
  });

  // Check if we found a valid orgId
  useEffect(() => {
    // Don't run  until the login and current org are finished loading
    if (isLoadingAuth || isLoadingDefaultOrg) return;

    // finished loading - if the home route requires an org and it did not find a valid org, go to 404 page
    if (requiresOrg && !orgId) {
      navigate(sharedRoutes.pageNotFound);
    } else {
      // Navigate to the apps home page with the orgId inserted
      navigate(homePath.replace(ORG_ID_ROUTE_TEMPLATE, String(orgId)));
    }
  }, [
    homePath,
    isLoadingAuth,
    isLoadingDefaultOrg,
    navigate,
    orgId,
    requiresOrg,
  ]);

  return null;
};
