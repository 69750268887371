import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom-v5-compat';
import { getWindowLocation } from 'utils/window';

export const OrgIdOutlet = ({ homePage }: { homePage: string }) => {
  const navigate = useNavigate();
  const location = getWindowLocation();

  useEffect(() => {
    const EndsInorgIdRegex = /o\/[0-9]+\/?$/;
    if (EndsInorgIdRegex.test(location.pathname)) {
      navigate(homePage);
    }
  });

  return <Outlet />;
};
