import { GlobeSvg } from 'components/svgs/GlobeSvg';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { OpIcon } from 'new-components/OpIcon/OpIcon';
import { checkMasterModeAccess } from 'new-components/OrgSwitcher/helpers/orgAccess';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeRoute } from 'routes/AuthenticatedContainer/actions';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import { getControlCenterOrigin } from 'utils/getControlCenterOrigin';
import { getOpParams } from 'utils/getOpParams';
import { getWindowLocation } from 'utils/window';

import './MasterModeButton.scss';

export const MasterModeButton = ({
  isMobile,
  isControlCenter,
  onMastermodeSwitch,
}: {
  isMobile?: boolean;
  isControlCenter: boolean;
  onMastermodeSwitch(): void;
}) => {
  const { t } = useTranslation();
  const { orgId } = getOpParams();
  // Use dispatch when in CC and navigate when in another app
  const dispatch = useDispatch();
  const { token } = useIdentityAndUser();

  // Check if user has mastermode access
  const hasMasterModeAccess = useMemo(
    () =>
      checkMasterModeAccess({
        token,
      }),
    [token],
  );

  const handleMasterModeClick = useCallback(() => {
    onMastermodeSwitch();
    if (isControlCenter) {
      dispatch(changeRoute('', { orgId: null, master: true, root: true }));
    } else {
      const ccOrigin = getControlCenterOrigin();
      const windowLocation = getWindowLocation();
      windowLocation.href = `${ccOrigin}/master/masterDashboard`;
    }
  }, [dispatch, isControlCenter, onMastermodeSwitch]);

  return (
    hasMasterModeAccess &&
    Boolean(orgId) && (
      <OpButton className="master-mode-button" onClick={handleMasterModeClick}>
        {isMobile ? (
          <OpIcon component={GlobeSvg} />
        ) : (
          t('Switch to master mode')
        )}
      </OpButton>
    )
  );
};
