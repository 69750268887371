import Cookies from 'universal-cookie';
import openpathConfig from 'openpathConfig';
import { getAltaDomain, getEnvironment } from './domains';

/**
 * @description Checks if the domain is alta.avigilon. If true, it sets the access token based on the
 * environment (dev, prod, sandbox, eu) and the domain. If false, sets the access token the current openpath way.
 * @returns void
 */
export const setAccessToken = (token?: string | null) => {
  const cookies = new Cookies();
  const domain = getAltaDomain();

  if (domain) {
    const env = getEnvironment();
    const accessTokenName = env ? `accessToken-${env}` : 'accessToken';
    // Get current domain
    const cookieSettings = {
      ...openpathConfig.COOKIE_SETTINGS,
      domain,
    };

    cookies.set(accessTokenName, token, cookieSettings);
  } else {
    // do it the old openpath way
    cookies.set('accessToken', token, openpathConfig.COOKIE_SETTINGS);
  }
};

/**
 * @description Checks if the domain is alta.avigilon. If true, it gets the access token based on the
 * environment (dev, prod, sandbox, eu). If false, gets the access token the current openpath way.
 * @returns access token for the environment/domain.
 */
export const getAccessToken = () => {
  const cookies = new Cookies();
  const domain = getAltaDomain();

  const env = getEnvironment();
  return domain && env
    ? cookies.get(`accessToken-${env}`)
    : cookies.get('accessToken');
};

/**
 * @description Checks if the domain is alta.avigilon. If true, it removes the access token based on the
 * environment (dev, prod, sandbox, eu). If false, removes the access token the current openpath way.
 * @returns void
 */
export const removeAccessToken = () => {
  const cookies = new Cookies();
  const domain = getAltaDomain();

  if (domain) {
    const env = getEnvironment();
    const accessTokenName = env ? `accessToken-${env}` : 'accessToken';
    cookies.remove(accessTokenName, {
      path: '/',
      domain,
    });
  }

  /**
   * We call this secondary removal as a temporary "fix" for the following scenario as well as for control.openpath.com.
   * 1. Log into control.alta.avigilon.com (sets cookie to .alta.avigilon.com)
   * 2. App switch to Aware and app switch back (a cookie is set on the more specific control.alta.avigilon.com set from our cloudfront function we didn't update when this change was made for native apps like Mailroom to share cookies)
   *
   * The issue is when app switching back from Aware and then logging out from Access
   * you get stuck in an infinite loop because theres still the control.alta.avigilon.com
   * domain token sitting around and our app is trying to use it to "log in" and creates an
   * infinite rerender loop. We could try and fix the cloudfront function but this is a slightly
   * quicker and dirty qay to achieve the same thing. That function should potentially be obsolete
   * soon anyways once we permanently move to alta.avigilon.com
   */
  cookies.remove('accessToken', { path: '/' });
};
