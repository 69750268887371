import { OpTextButton } from 'new-components/DLS/OpTextButton/OpTextButton';
import { ComponentProps } from 'react';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { useTranslation } from 'react-i18next';

import './ResetTableButton.scss';

type OpTextButtonProps = ComponentProps<typeof OpTextButton>;

interface ResetTableButtonProps extends Pick<OpTextButtonProps, 'disabled'> {
  onReset(): void;
}

export const ResetTableButton = ({
  onReset,
  ...rest
}: ResetTableButtonProps) => {
  const { t } = useTranslation();
  const confirm = useConfirmModal();

  const openConfirmModal = () => {
    confirm({
      onOk: onReset,
      title: t('Reset table'),
      content: t(
        'All column customizations and filters will be reverted to the default. Are you sure you want to continue?',
      ),
      okText: t('Reset table'),
      okButtonProps: {
        'data-gtm': 'reset-table-button-confirm',
      },
    });
  };

  return (
    <div className="reset-table-button">
      <OpTextButton
        {...rest}
        onClick={openConfirmModal}
        gtm="reset-table-button"
      >
        Reset table
      </OpTextButton>
    </div>
  );
};
