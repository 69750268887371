import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const TooltipSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 16 16" {...svgProps}>
    <path d="M7.50002 9.60918C7.50002 9.88518 7.72402 10.1092 8.00002 10.1092C8.27602 10.1092 8.50002 9.88518 8.50002 9.60918C8.50002 9.24651 8.68669 8.91851 8.96535 8.79184C9.93469 8.35051 10.482 7.30451 10.296 6.24851C10.1314 5.30784 9.35935 4.53584 8.41869 4.37118C7.72802 4.24651 7.03002 4.43384 6.50069 4.87918C5.97069 5.32384 5.66669 5.97584 5.66669 6.66718C5.66669 6.94318 5.89069 7.16718 6.16669 7.16718C6.44269 7.16718 6.66669 6.94318 6.66669 6.66718C6.66669 6.27184 6.84069 5.89984 7.14335 5.64518C7.45135 5.38718 7.84269 5.28584 8.24535 5.35518C8.77935 5.44918 9.21802 5.88784 9.31202 6.42184C9.42002 7.03851 9.11469 7.62518 8.55202 7.88184C7.91269 8.17184 7.50002 8.84918 7.50002 9.60918Z" />
    <path d="M8.00002 11.6671C8.27602 11.6671 8.50002 11.4431 8.50002 11.1671C8.50002 10.8911 8.27602 10.6671 8.00002 10.6671C7.72402 10.6671 7.50002 10.8911 7.50002 11.1671C7.50002 11.4431 7.72402 11.6671 8.00002 11.6671Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
    />
  </SvgWrapper>
);
