import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/svgs/SvgWrapper';

interface CameraFilledSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const CameraFilledSvg = ({ ...svgProps }: CameraFilledSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M17.5 2H0.5C0.224 2 0 2.224 0 2.5C0 3.879 1.122 5 2.5 5H15.5C16.878 5 18 3.879 18 2.5C18 2.224 17.776 2 17.5 2ZM16.5 6C16.776 6 17 6.224 17 6.5V8C17 12.411 13.411 16 9 16C4.589 16 1 12.411 1 8V6.5C1 6.224 1.224 6 1.5 6H16.5ZM9 8C7.619 8 6.5 9.119 6.5 10.5C6.5 11.881 7.619 13 9 13C10.381 13 11.5 11.881 11.5 10.5C11.5 9.119 10.381 8 9 8ZM9 9C9.827 9 10.5 9.673 10.5 10.5C10.5 11.327 9.827 12 9 12C8.173 12 7.5 11.327 7.5 10.5C7.5 9.673 8.173 9 9 9Z" />
  </SvgWrapper>
);
