import { ComponentProps, useLayoutEffect, useRef } from 'react';
import Alert from 'antd/es/alert';
import { OpInfoWithDismiss } from './components/OpInfoWithDismiss';
import { OpInfoWithoutDismiss } from './components/OpInfoWithoutDismiss';

import './OpInfo.scss';

export interface OpInfoProps extends ComponentProps<typeof Alert> {
  className?: string;
  dismissibleId?: string;
  scrollIntoView?: boolean;
}

export const OpInfo = ({
  dismissibleId,
  scrollIntoView,
  ...alertProps
}: OpInfoProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollIntoView) {
      // scrollRef.current will not be null in a layout effect
      scrollRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // We only want to run once on initial render

  const content = dismissibleId ? (
    <OpInfoWithDismiss {...alertProps} dismissibleId={dismissibleId} />
  ) : (
    <OpInfoWithoutDismiss {...alertProps} />
  );

  return scrollIntoView ? (
    <>
      <div ref={scrollRef} />
      {content}
    </>
  ) : (
    content
  );
};
