import { ComponentProps, forwardRef } from 'react';
import Button from 'antd/es/button';

import './OpButton.scss';

interface OpButtonProps extends ComponentProps<typeof Button> {
  testId?: string;
  gtm?: string;
}

export const OpButton = forwardRef<HTMLButtonElement, OpButtonProps>(
  (
    {
      testId = 'op-button',
      children,
      className = '',
      gtm,
      ...buttonProps
    }: OpButtonProps,
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        data-testid={testId}
        data-gtm={gtm}
        className={`op-button ${className}`.trim()}
        {...buttonProps}
        variant="filled"
      >
        {children}
      </Button>
    );
  },
);
