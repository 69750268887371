/* This is a Semantic UI React Button */

import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import SemanticButton from 'semantic-ui-react/dist/es/elements/Button/Button';
import { Ref } from '@fluentui/react-component-ref/dist/es/Ref';
import { useHover } from 'utils/customHooks';

import './ButtonWithSuir.scss';

export const ButtonWithSuir = memo(
  ({ icon, isHoveredIcon, className, isMobile, ...props }) => {
    const buttonRef = useRef(null);
    const isHovered = useHover(buttonRef);
    const derivedIcon = isHovered ? isHoveredIcon || icon : icon;

    // Determine className
    let derivedClassName = 'op';
    if (isHoveredIcon && !isMobile) {
      derivedClassName += ' op-display-flex';
    }
    if (className) {
      derivedClassName += ` ${className}`;
    }

    return (
      <Ref innerRef={buttonRef}>
        <SemanticButton
          // Need this so button onClick not fired when ENTER pressed
          type="button"
          className={derivedClassName}
          icon={isHovered ? isHoveredIcon || icon : icon}
          // Allow all other semantic props to pass through
          {...props}
          // This allows for custom icons to have a hovered state color change
          {...(derivedIcon && { icon: derivedIcon })}
        />
      </Ref>
    );
  },
);

ButtonWithSuir.propTypes = {
  icon: PropTypes.element,
  isHoveredIcon: PropTypes.element,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
};

ButtonWithSuir.displayName = 'ButtonWithSuir';

// Need to attach the Group (and any other static properties on SUIR ButtonWithSuir)
// to allow dot notation to work on this component as well
Object.assign(ButtonWithSuir, SemanticButton);
