import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const P2Svg = ({ ...svgProps }) => (
  <SvgWrapper
    width="19.058823529411764"
    height="18"
    viewBox="0 0 72 68"
    fill="none"
    {...svgProps}
  >
    <path
      d="M29.5972 5.79268L29.597 5.79291L2.97046 53.7348C2.97042 53.7348 2.97038 53.7349 2.97035 53.735C0.24937 58.6332 3.57957 65.0017 9.33256 65.0017H62.5853C68.3255 65.0017 71.6749 58.6447 68.9475 53.735C68.9475 53.7349 68.9474 53.7348 68.9474 53.7348L42.3215 5.79292L42.3213 5.79266C39.515 0.741374 32.4097 0.730182 29.5972 5.79268Z"
      strokeWidth="4"
    />
    <path
      d="M22.37 33.41H28.91C31.27 33.41 33.14 33.9 34.52 34.88C35.92 35.84 36.62 37.42 36.62 39.62C36.62 41.78 35.91 43.41 34.49 44.51C33.07 45.61 31.25 46.16 29.03 46.16H26.48V53H22.37V33.41ZM28.76 42.89C31.32 42.89 32.6 41.8 32.6 39.62C32.6 38.58 32.27 37.83 31.61 37.37C30.97 36.91 29.98 36.68 28.64 36.68H26.48V42.89H28.76ZM38.8939 50.63C40.9739 48.65 42.5539 47.09 43.6339 45.95C44.7339 44.81 45.6139 43.72 46.2739 42.68C46.9339 41.64 47.2639 40.68 47.2639 39.8C47.2639 38.86 47.0139 38.13 46.5139 37.61C46.0139 37.07 45.3139 36.8 44.4139 36.8C43.7739 36.8 43.1739 36.98 42.6139 37.34C42.0539 37.7 41.4939 38.18 40.9339 38.78L38.6839 36.56C39.6439 35.54 40.6039 34.79 41.5639 34.31C42.5439 33.81 43.6839 33.56 44.9839 33.56C46.8439 33.56 48.3439 34.11 49.4839 35.21C50.6239 36.29 51.1939 37.74 51.1939 39.56C51.1939 41.04 50.6439 42.58 49.5439 44.18C48.4439 45.76 46.8639 47.62 44.8039 49.76C45.9639 49.62 47.0339 49.55 48.0139 49.55H52.1239V53H38.8939V50.63Z"
      fill={svgProps.stroke || 'black'}
    />
  </SvgWrapper>
);
