import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OpTooltip } from 'new-components/DLS/OpTooltip/OpTooltip';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { OpDataFetchTable } from 'new-components/DLS/OpDataFetchTable/OpDataFetchTable';
import { OpInfoTooltip } from 'new-components/DLS/OpInfoTooltip/OpInfoTooltip';
import { OpInfo } from 'new-components/DLS/OpInfo/OpInfo';
import { OpTableRawColumnType } from 'new-components/DLS/OpTableCore/OpTableCore';
import { useDeleteMfaCredential } from 'utils/customHooks/api/useDeleteMfaCredential';
import { setAlert } from 'routes/AppContainer/actions';
import { loginRoute } from 'routes/constants';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import { NewMfaCredentialModal } from '.';

import './ProfileSecurityTabContent.scss';

const ProfileSecurityTabContent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { identityId, isFetching: isFetchingIdentityId } = useIdentityAndUser();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const deleteMfaCredentialMutation = useDeleteMfaCredential();

  /** In the case we don't have an identity, we redirect to login
   * (navigate directly to url or refresh page) */
  useEffect(() => {
    if (!identityId && !isFetchingIdentityId) {
      navigate(loginRoute);
    }
  }, [identityId, isFetchingIdentityId, navigate]);

  if (!identityId) {
    return null;
  }

  const columns = [
    {
      label: t('MFA device type')!,
      dataIndex: ['mfaCredentialType', 'name'],
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (mfaCredentialTypeName: string) => (
        <OpTooltip placement="topLeft" title={mfaCredentialTypeName}>
          {mfaCredentialTypeName}
        </OpTooltip>
      ),
    },
    {
      label: t('Name')!,
      dataIndex: 'name',
    },
    {
      label: t('Status')!,
      dataIndex: 'status',
      render: (status: string) => {
        return status === 'A' ? (
          t('Active')
        ) : (
          <span className="op-align-items-center">
            {t('Inactive')}&nbsp;
            <OpInfoTooltip
              title={t(
                'This device was not activated during creation and the QR code/secret key cannot be shown again. This device should be deleted and a new device should be created and and activated in its place.',
              )}
            />
          </span>
        );
      },
    },
  ] satisfies OpTableRawColumnType[];

  const rowActions = {
    onDeleteClick: ({ id: mfaCredentialId }: { id: number }) => {
      deleteMfaCredentialMutation.mutate(
        {
          apiEndpointRequirements: [identityId, mfaCredentialId],
        },
        {
          onSuccess: () => {
            dispatch(
              setAlert('success', t('Successfully deleted MFA credential')),
            );
          },
        },
      );
    },
  };

  const deleteMfaCredential = (mfaCredentialId: number) => {
    deleteMfaCredentialMutation.mutate({
      apiEndpointRequirements: [identityId, mfaCredentialId],
    });
  };

  return (
    <OpSpace
      className="profile-security-tab-content"
      data-testid="profile-security-tab-content"
      direction="vertical"
      size="middle"
    >
      {/** Table */}
      <OpDataFetchTable
        uiStateKey="ProfileSecurityTabContentMfaCredentialsTable"
        columns={columns}
        opQueryProps={{
          apiEndpointName: 'listMfaCredentials',
          parameters: [identityId],
        }}
        height="calc(100vh - 344px)"
        rowActions={rowActions}
        allowAddition={{
          onClick: () => setIsModalOpen(true),
          itemName: t('MFA device'),
        }}
      />

      {/** Tipbox */}
      <OpInfo
        message={t('Definitions')}
        description={
          <ul>
            <li>
              <i>MFA = {t('Multi-Factor Authentication')}</i> -{' '}
              {t(
                "A method of confirming a user's claimed identity in which a user is granted access only after successfully presenting 2 or more pieces of evidence (or factors) to an authentication mechanism: knowledge (something they and only they know), possession (something they and only they have), and inherence (something they and only they are)",
              )}
            </li>
            <li>
              <i>TOTP = {t('Time-based One-Time Password')}</i> -{' '}
              {t(
                'A time-based one-time password (TOTP) is a temporary passcode, generated by an algorithm (using the time of day and a shared secret key), for use in authenticating access to computer systems.',
              )}
            </li>
          </ul>
        }
      />

      {/** Modal */}
      <NewMfaCredentialModal
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        identityId={identityId}
        deleteMfaCredential={deleteMfaCredential}
      />
    </OpSpace>
  );
};
export default ProfileSecurityTabContent;
