import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  skipToken,
} from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { queryRequest } from 'utils/queryRequest';
import { ensurePayloadAndQuery } from 'utils/ensurePayloadAndQuery';
import { HELIUM_LIMIT_MAX } from 'utils/constants';

interface WithParameters<
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
> extends Omit<
    UseQueryOptions<
      Awaited<ReturnType<Api.Client[T]>>,
      OP.ApiError,
      SelectData
    >,
    'queryKey'
  > {
  apiEndpointName: T;
  parameters: Parameters<Api.Client[T]>;
  suppressErrorMessage?: boolean;
  getAll?: boolean;
  queryKey?: QueryKey;
}

interface WithOptionalParameters<
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
> extends Omit<
    UseQueryOptions<
      Awaited<ReturnType<Api.Client[T]>>,
      OP.ApiError,
      SelectData
    >,
    'queryKey'
  > {
  apiEndpointName: T;
  parameters?: Parameters<Api.Client[T]>;
  suppressErrorMessage?: boolean;
  getAll?: boolean;
  queryKey?: QueryKey;
}

export type UseOpQueryOptions<
  T extends keyof Api.ClientSpec = keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
> = Api.ClientSpec[T]['pathParams'] extends []
  ? WithOptionalParameters<T, SelectData>
  : WithParameters<T, SelectData>;

// T = 'listOrgs' | 'describeUser' | ...
export const useOpQuery = <
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  apiEndpointName,
  parameters,
  suppressErrorMessage = false,
  getAll,
  queryKey = [apiEndpointName, JSON.stringify(parameters)],
  ...queryOptions
}: UseOpQueryOptions<T, SelectData>) => {
  const { parametersArray, withQuery } = ensurePayloadAndQuery(
    apiEndpointName,
    parameters,
  );

  // TODO - get the language based on authenticated page logic if not in identity
  /** we first look at the identity language and set the language to that.
   * If that is not set, we then fallback to the local storage setting.
   * If that is not set, then we fallback to the browser locale.
   * And if that is not supported then we use English (en). */
  const currentIdentityLanguage = useSelector(selectCurrentIdentityLanguage());

  // Find the index of the queries (last object)
  const queriesIndex = parametersArray.findLastIndex(
    (param) => typeof param === 'object',
  );

  const queryFn = async () => {
    let result;

    /** When getAll is true we add a limit of 1000 and loops through calls
     * until we get all the results in the db. */
    if (getAll && withQuery) {
      let offset = 0;
      let filteredCount;

      if (queriesIndex === -1) {
        // ensurePayloadAndQuery guarantees the presence of a queries object, so throw since something is way off...
        throw new Error(
          `Critical: ensurePayloadAndQuery did not ensure a query object for API endpoint ${apiEndpointName}`,
        );
      }

      parametersArray[queriesIndex] = {
        ...(parametersArray[queriesIndex] as Record<string, any>),
        limit: HELIUM_LIMIT_MAX,
        offset,
      };

      result = await queryRequest({
        apiEndpointName,
        parameters: parametersArray,
        currentIdentityLanguage,
      });

      if (result.statusCode >= 400) {
        // Not allowing to suppress error message
        throw result.json;
      }

      filteredCount = result?.json?.filteredCount;
      offset += HELIUM_LIMIT_MAX;

      while (filteredCount && offset <= filteredCount) {
        parametersArray[queriesIndex] = {
          ...(parametersArray[queriesIndex] as Record<string, any>),
          limit: HELIUM_LIMIT_MAX,
          offset,
        };

        /** Need to disable the lint rule below as we do want to await
         * each call and act accordingly */
        // eslint-disable-next-line no-await-in-loop
        const subResult = await queryRequest({
          apiEndpointName,
          parameters: parametersArray,
          currentIdentityLanguage,
        });

        if (result.statusCode >= 400) {
          // Not allowing to suppress error message
          throw result.json;
        }

        if (
          Array.isArray(result.json.data) &&
          Array.isArray(subResult.json.data)
        ) {
          result.json.data = [...result.json.data, ...subResult.json.data];
        }

        filteredCount = result?.json?.filteredCount;
        offset += HELIUM_LIMIT_MAX;
      }

      return result;
    }

    /** When getAll is false we call the endpoint normally */
    result = await queryRequest({
      apiEndpointName,
      parameters: parametersArray,
      currentIdentityLanguage,
    });

    if (result.statusCode >= 400) {
      const finalErrorObject = {
        ...result.json,
        suppressErrorMessage,
      };

      throw finalErrorObject;
    }

    return result;
  };

  const hasValidParams = parametersArray.every(Boolean); // The query should never run if a falsy value is passed in parameters

  return useQuery<Awaited<ReturnType<Api.Client[T]>>, OP.ApiError, SelectData>({
    queryKey,
    queryFn: hasValidParams ? queryFn : skipToken,
    ...queryOptions,
    ...(process.env.NODE_ENV === 'development' && {
      refetchOnWindowFocus: false,
    }),
  });
};
