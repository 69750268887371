import { ComponentProps } from 'react';
import { ButtonWithoutSuir } from './ButtonWithoutSuir';
import { ButtonWithSuir } from './ButtonWithSuir';

export interface ButtonProps extends ComponentProps<typeof ButtonWithSuir> {
  isMobile: boolean;
  isHoveredIcon: boolean;
}
export const Button = (props: any) => {
  const hasUserSelectedSuirRemovedUi =
    localStorage.getItem('hasUserSelectedSuirRemovedUi') === 'true';

  return hasUserSelectedSuirRemovedUi ? (
    <ButtonWithoutSuir {...props} />
  ) : (
    <ButtonWithSuir {...props} />
  );
};
