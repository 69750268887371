import StopOutlined from '@ant-design/icons/StopOutlined';
import { BuildingSvg } from 'components/svgs/BuildingSvg';
import { CheckSvg } from 'components/svgs/CheckSvg';
import { PartnerSvg } from 'components/svgs/PartnerSvg';
import { OpIcon } from 'new-components/OpIcon/OpIcon';

export const getIconForOrg = ({
  isSelected,
  accessRemoved,
  isParentOrg,
}: {
  isSelected: boolean;
  accessRemoved?: boolean;
  isParentOrg: boolean | null;
}) => {
  // Find which icon to use for org type
  let icon = <OpIcon className="org-switcher__icon" component={BuildingSvg} />;

  if (isSelected) {
    icon = <OpIcon className="org-switcher__icon" component={CheckSvg} />;
  } else if (accessRemoved) {
    icon = <StopOutlined className="org-switcher__icon" />;
  } else if (isParentOrg) {
    icon = <OpIcon className="org-switcher__icon" component={PartnerSvg} />;
  }

  return icon;
};
