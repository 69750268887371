import * as Sentry from '@sentry/react';

const audioContext = window.AudioContext ? new window.AudioContext() : null;

const WEB_AUDIO_INTERACTION_EVENTS = ['click', 'touchstart', 'keydown'];

async function onInteractionHandler() {
  if (audioContext && audioContext.state === 'suspended') {
    try {
      await audioContext.resume();
      // Remove the event listeners to prevent this code from running multiple times
      WEB_AUDIO_INTERACTION_EVENTS.forEach((event) => {
        document.removeEventListener(event, onInteractionHandler);
      });
    } catch (error) {
      console.error('Error trying to resume the audioContext:', error);
    }
  }
}

// This is the enumeration of available audio files.
const audioFiles: OP.Audio.AudioFileDictionary = {};

// This is the enumeration of possible audio layers.
// Audio layers are groups of sounds. An audio layer has its own volume setting, and when playing a sound,
// it can replace all other sounds currently playing on that layer or skip playing if the same
// sound is already playing on that layer.
// See: WebAudio.adjustLayerVolume, PlayOptions.replaceAll and PlayOptions.skipIfAlreadyPlaying
const audioLayers: OP.Audio.AudioLayerDictionary = {};

const getAudioFileBuffer = async (url: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(
        `Error retrieving audio data: ${response.status} ${response.statusText}`,
      );
    }

    return response.arrayBuffer();
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

const play = (
  { fileName }: OP.Audio.SoundEffect,
  layer: string,
  options: OP.Audio.PlayOptions,
  buffer: AudioBuffer,
  pausedAt: number = 0,
) => {
  // suspended audioContext cannot play sound
  if (!audioContext || audioContext.state === 'suspended') {
    return;
  }

  const bufferSource = audioContext.createBufferSource();
  bufferSource.buffer = buffer;
  if (options.loop) {
    bufferSource.loop = true;
  }

  const source = audioContext.createGain();
  if (typeof options.volume === 'number' && !isNaN(options.volume)) {
    source.gain.setValueAtTime(options.volume, audioContext.currentTime);
  }

  const onEnded = () => {
    bufferSource.disconnect(source);
    source.disconnect(audioLayers[layer].volumeNode);
    delete audioLayers[layer].sources[fileName];
  };
  bufferSource.addEventListener('ended', onEnded, false);

  bufferSource.connect(source);
  source.connect(audioLayers[layer].volumeNode);

  bufferSource.start(0, pausedAt);
  audioLayers[layer].sources[fileName] = {
    source,
    bufferSource,
    pausedAt: 0,
    startedAt: audioContext.currentTime - pausedAt,
    onEnded,
  };
};

const createLayerIfNeeded = (layer: string) => {
  if (!audioContext || audioLayers[layer]) {
    return;
  }

  const volumeNode = audioContext.createGain();

  audioLayers[layer] = {
    volumeNode,
    sources: {},
  };

  volumeNode.connect(audioContext.destination);
};

const adjustLayerVolume = (layer: string, volume: number) => {
  if (!audioContext) {
    return;
  }

  audioLayers[layer].volumeNode.gain.setValueAtTime(
    volume,
    audioContext.currentTime,
  );
};

const adjustVolume = (
  { fileName }: OP.Audio.SoundEffect,
  layer: string,
  volume: number,
) => {
  if (!audioContext) {
    return;
  }

  const sourceInfo = audioLayers[layer].sources[fileName];
  if (sourceInfo && sourceInfo.source) {
    sourceInfo.source.gain.setValueAtTime(volume, audioContext.currentTime);
  }
};

const getEffectivePlayOptions = (
  options: OP.Audio.PlayOptions,
): OP.Audio.PlayOptions => ({
  loop: false,
  volume: 1,
  ...(options ?? {}),
});

// Note: New mp3 files added should be added to /app/assets/audio
// If a new file extension/format is used, webpack.loader.rules.js must be updated to include it!
export const SoundEffects: OP.Audio.SoundEffectLibrary = {
  ALARM_NOTIFICATION: {
    fileName: 'alarm-notification.mp3',
  },
  INTERCOM_NOTIFICATION: {
    fileName: 'intercom-notification.mp3',
  },
};

export const Layers: OP.Audio.LayerLibrary = {
  ALARM_NOTIFICATIONS: 'ALARM_NOTIFICATIONS',
  INTERCOM_NOTIFICATIONS: 'INTERCOM_NOTIFICATIONS',
};

export class WebAudio {
  static initialize() {
    if (!audioContext) {
      return;
    }

    // Add event listeners for the first user interaction
    WEB_AUDIO_INTERACTION_EVENTS.forEach((event) => {
      document.addEventListener(event, onInteractionHandler);
    });

    Object.values(SoundEffects).forEach(async ({ fileName }) => {
      try {
        const audioData = await getAudioFileBuffer(`/assets/audio/${fileName}`);

        // Note: This is intentionally assigning a promise, so no await here! That way we can try/catch await this value later.
        audioFiles[fileName] = audioContext.decodeAudioData(audioData);
      } catch (e) {
        // If getAudioFileBuffer fails, store the error as a rejected promise so we can still await and re-catch this error later.
        audioFiles[fileName] = Promise.reject(e);
      }
    });
  }

  static async play(
    soundEffect: OP.Audio.SoundEffect,
    layer: string,
    options: OP.Audio.PlayOptions,
  ) {
    const { fileName } = soundEffect;
    if (!audioFiles[fileName]) {
      console.error(`WebAudio: Cannot play unknown audio file: ${fileName}`);
      return;
    }

    let buffer = null;
    try {
      buffer = await audioFiles[fileName];
    } catch {
      // Ignore, this effect was unable to be fetched and/or decoded for this session
      return;
    }

    createLayerIfNeeded(layer);

    const effectiveOptions = getEffectivePlayOptions(options);

    if (effectiveOptions.replaceAll) {
      // Stop all sounds playing on this layer since we are replacing them all
      Object.keys(audioLayers[layer].sources)
        .filter((sourceName) => sourceName !== fileName)
        .forEach((sourceName) =>
          WebAudio.stop({ fileName: sourceName }, layer),
        );

      if (
        audioLayers[layer].sources[fileName] &&
        audioLayers[layer].sources[fileName].source === null
      ) {
        // This is actually paused, so let's resume it!
        await WebAudio.resume(soundEffect, layer, effectiveOptions, buffer);
        return;
      }
    } else if (effectiveOptions.skipIfAlreadyPlaying) {
      const sourceInfo = audioLayers[layer].sources[fileName];
      if (sourceInfo && sourceInfo.source && sourceInfo.bufferSource) {
        // Ignore, it is already playing!
        return;
      }
    }

    play(soundEffect, layer, effectiveOptions, buffer);
  }

  static pause({ fileName }: OP.Audio.SoundEffect, layer: string) {
    if (!audioContext) {
      return;
    }

    if (!audioFiles[fileName]) {
      console.error(`WebAudio: Cannot pause unknown audio file: ${fileName}`);
      return;
    }

    createLayerIfNeeded(layer);

    const sourceInfo = audioLayers[layer].sources[fileName];
    if (!sourceInfo || !sourceInfo.source || !sourceInfo.bufferSource) {
      // Ignore, nothing to pause!
      return;
    }

    sourceInfo.source.removeEventListener('ended', sourceInfo.onEnded);
    sourceInfo.pausedAt = audioContext.currentTime - sourceInfo.startedAt;
    sourceInfo.bufferSource.stop();
    sourceInfo.bufferSource.disconnect(sourceInfo.source);
    sourceInfo.bufferSource = null;
    sourceInfo.source.disconnect(audioLayers[layer].volumeNode);
    sourceInfo.source = null;
    sourceInfo.startedAt = 0;
  }

  static async resume(
    soundEffect: OP.Audio.SoundEffect,
    layer: string,
    options: OP.Audio.PlayOptions,
    buffer?: AudioBuffer,
  ) {
    if (!audioContext) {
      return;
    }

    const { fileName } = soundEffect;
    if (!audioFiles[fileName]) {
      console.error(`WebAudio: Cannot resume unknown audio file: ${fileName}`);
      return;
    }

    let effectiveBuffer;
    try {
      effectiveBuffer = buffer || (await audioFiles[fileName]);
    } catch {
      // Ignore, this effect was unable to be fetched and/or decoded for this session
      return;
    }

    createLayerIfNeeded(layer);

    const sourceInfo = audioLayers[layer].sources[fileName];
    if (!sourceInfo || sourceInfo.source) {
      // Ignore, nothing to resume or already playing!
      return;
    }

    const effectiveOptions = getEffectivePlayOptions(options);

    play(
      soundEffect,
      layer,
      effectiveOptions,
      effectiveBuffer,
      sourceInfo.pausedAt,
    );
  }

  static stop({ fileName }: OP.Audio.SoundEffect, layer: string) {
    if (!audioContext) {
      return;
    }

    if (!audioFiles[fileName]) {
      console.error(`WebAudio: Cannot stop unknown audio file: ${fileName}`);
      return;
    }

    createLayerIfNeeded(layer);

    const sourceInfo = audioLayers[layer].sources[fileName];
    if (sourceInfo && sourceInfo.bufferSource) {
      sourceInfo.bufferSource.stop();
    }
  }

  static adjustLayerVolume(layer: string, volume: number) {
    if (!audioContext) {
      return;
    }

    createLayerIfNeeded(layer);

    adjustLayerVolume(layer, volume);
  }

  static adjustVolume(
    soundEffect: OP.Audio.SoundEffect,
    layer: string,
    volume: number,
  ) {
    if (!audioContext) {
      return;
    }

    createLayerIfNeeded(layer);

    adjustVolume(soundEffect, layer, volume);
  }
}
