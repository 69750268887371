import clsx from 'clsx';
import { OpForm } from 'new-components/DLS/OpForm/OpForm';
import { OpCol } from 'new-components/DLS/OpCol/OpCol';
import { OpRow } from 'new-components/DLS/OpRow/OpRow';
import { useTranslation } from 'react-i18next';
import { FormProps } from 'new-components/forms/formTypes';
import { useValidateScopes } from 'utils/customHooks/useValidateScopes';
import { useMailroomForm } from './useMailroomForm';

interface MailroomFormProps extends FormProps {
  orgId: number;
  mailroomId?: number;
}

export const MailroomForm = ({
  className,
  orgId,
  mailroomId,
  ...opFormProps
}: MailroomFormProps) => {
  const { t } = useTranslation();
  const { isValid: hasWriteAccess } = useValidateScopes({
    allowedScopes: ['o{orgId}-parcelMgmtParcels:w', 'o:w', 's-o:w'],
  });
  const { mailroomFormData, handleSubmit, hasFormLoadError } = useMailroomForm({
    orgId,
    mailroomId,
  });

  return (
    <OpForm
      className={clsx('mailroom-info-form', className)}
      hasError={hasFormLoadError}
      isReadOnly={!hasWriteAccess}
      initialValues={mailroomFormData}
      onSubmit={(data) => handleSubmit(data)}
      testId="mailroom-edit-Mailroom"
      {...opFormProps}
    >
      <OpForm.Input
        name="name"
        label={t('Name')}
        rules={[
          {
            required: true,
            message: t('Name is required'),
          },
        ]}
        testId="mailroom-name"
      />
      <OpForm.SiteSpecificSiteDataFetchSelect
        orgId={orgId}
        name="siteId"
        label={t('Site')}
        rules={[{ required: true, message: t('Site is required') }]}
        testId="mailroom-site"
      />
      <OpForm.Input
        name="address"
        label={t('Address line 1')}
        rules={[
          {
            required: true,
            message: t('Address is required'),
          },
        ]}
        testId="mailroom-address1"
      />
      <OpForm.Input
        name="address2"
        label={t('Address line 2')}
        testId="mailroom-address2"
      />
      <OpRow gutter={[16, 16]}>
        <OpCol span={8}>
          <OpForm.Input
            name="city"
            label={t('City')}
            rules={[
              {
                required: true,
                message: t('City is required'),
              },
            ]}
            testId="mailroom-city"
          />
        </OpCol>
        <OpCol span={8}>
          <OpForm.Input
            name="state"
            label={t('State')}
            rules={[
              {
                required: true,
                message: t('State is required'),
              },
            ]}
            testId="mailroom-state"
          />
        </OpCol>
        <OpCol span={8}>
          <OpForm.Input
            name="country"
            label={t('Country')}
            rules={[
              {
                required: true,
                message: t('Country is required'),
              },
            ]}
            testId="mailroom-country"
          />
        </OpCol>
      </OpRow>
      <OpRow gutter={[16, 16]}>
        <OpCol span={12}>
          <OpForm.Input
            name="zip"
            label={t('Zip code')}
            rules={[
              {
                required: true,
                message: t('Zip code is required'),
              },
            ]}
            testId="mailroom-zip"
          />
        </OpCol>
        <OpCol span={12}>
          <OpForm.PhoneInput
            name="phone"
            label={t('Phone number')}
            validateTrigger={'onBlur'}
            testId="mailroom-phone"
          />
        </OpCol>
      </OpRow>
      <OpForm.Input
        name="description"
        label={t('Description')}
        testId="mailroom-desc"
      />
    </OpForm>
  );
};
