import { ORG_ID_ROUTE_TEMPLATE } from 'new-components/OpAppScaffold/constants';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import { requestSwitchOrg } from 'routes/AppContainer/actions';

import { mfaManagementRoute } from 'routes/constants';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getControlCenterOrigin } from 'utils/getControlCenterOrigin';
import { getOpParams } from 'utils/getOpParams';
import { getWindowLocation } from 'utils/window';

export const useNavigateToOrg = ({
  homePath,
  identityId,
  orgId,
  token,
  setWaitingForNavigate,
}: {
  homePath?: string;
  identityId?: number;
  orgId?: number;
  token: Api.Response['describeAccessToken'];
  setWaitingForNavigate(f: boolean): void;
}) => {
  const { orgId: orgIdUrlParam } = getOpParams();
  // Use dispatch to navigate when in CC and navigate when in another app
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useMemo(
    () =>
      token?.tokenScopeList?.find((scope) => scope.org?.id === orgId)?.user?.id,
    [orgId, token?.tokenScopeList],
  );

  // Get the userRoles for the user in the current org if in their tokenScopeList
  const {
    data: userRoles,
    isError: isErrorUserRoles,
    isFetching: isFetchingUserRoles,
  } = useOpQuery({
    apiEndpointName: 'listUserRoles',
    enabled: Boolean(homePath && userId), // Only need userRoles for Non CC org switching
    parameters: [orgId!, userId!], // when userId undefined it wont fetch
    select: (data) => data.json.data ?? [],
  });

  const { data: mfaCredentials } = useOpQuery({
    apiEndpointName: 'listMfaCredentials',
    parameters: [identityId!],
    select: (data) => data.json.data ?? [],
  });

  const shouldChangeOrg = useMemo(
    () => !!orgId && orgId !== orgIdUrlParam && !isFetchingUserRoles,
    [isFetchingUserRoles, orgId, orgIdUrlParam],
  );

  useEffect(() => {
    // only navigate if shouldChangeOrg is true
    if (!shouldChangeOrg) {
      return;
    }

    setWaitingForNavigate(false);
    // In non control center app
    if (homePath) {
      // handle they need mfa in the org they are orgswitching too
      if (
        !isErrorUserRoles &&
        Boolean(userRoles?.find((role) => role.isMfaRequired)) &&
        (!mfaCredentials || mfaCredentials.length === 0)
      ) {
        // send to mfa route in control center
        const ccOrigin = getControlCenterOrigin();
        const windowLocation = getWindowLocation();
        windowLocation.href = `${ccOrigin}/o/${orgId}${mfaManagementRoute}`;
      } else {
        // homepath means we are in a non CC app (ex: mailroom)
        navigate(homePath.replace(ORG_ID_ROUTE_TEMPLATE, String(orgId)));
      }
    } else {
      // we are in Control center
      dispatch(requestSwitchOrg(orgId));
    }
    // only trigger when shouldChangeOrg changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldChangeOrg]);

  return { isOrgSwitching: shouldChangeOrg };
};
