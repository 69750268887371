/**
 * @description string sorter method to be used in OpTable sorter method when
 * sorting on strings that are not the data key.
 * @return {boolean} - true or false based if a or b are greater
 * */
export const sortStrings = (a: string, b: string) => {
  const aValue = a ? a.trim().toLowerCase() : '';
  const bValue = b ? b.trim().toLowerCase() : '';
  return aValue.localeCompare(bValue);
};
