import { useTranslation } from 'react-i18next';
import { OpDrawer } from 'new-components/DLS/OpDrawer/OpDrawer';
import { OpTabs } from 'new-components/DLS/OpTabs/OpTabs';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import {
  ProfileInfoTabContent,
  ProfilePasswordTabContent,
  ProfileSecurityTabContent,
  ProfileSettingsTabContent,
} from '.';

interface ProfileDrawerProps {
  drawerIsOpen: boolean;
  setDrawerIsOpen(value: boolean): void;
}

const ProfileDrawer = ({
  drawerIsOpen,
  setDrawerIsOpen,
}: ProfileDrawerProps) => {
  const { identity } = useIdentityAndUser();
  const { t } = useTranslation();

  const tabItems = [
    {
      label: t('Info'),
      key: 'info',
      children: <ProfileInfoTabContent />,
    },
    {
      label: t('Password'),
      key: 'password',
      children: <ProfilePasswordTabContent />,
    },
    {
      label: t('Security'),
      key: 'security',
      children: <ProfileSecurityTabContent />,
      disabled: Boolean(identity?.namespace?.identityProvider?.isSsoEnabled),
    },
    {
      label: t('Settings'),
      key: 'settings',
      children: <ProfileSettingsTabContent />,
    },
  ];

  const onDrawerClose = () => {
    setDrawerIsOpen(false);
  };

  return (
    <OpDrawer
      title={t('Profile')}
      size="large"
      placement="right"
      onClose={onDrawerClose}
      open={drawerIsOpen}
    >
      <OpTabs items={tabItems} />
    </OpDrawer>
  );
};

export default ProfileDrawer;
