import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeBackend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { locizePlugin } from 'locize';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { locizeOptions } from './locize';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import fr from './locales/fr/translation.json';
import it from './locales/it/translation.json';
import de from './locales/de/translation.json';

/**
 * We are keeping these files empty to minimize our bundle size until we know we need them.
 * They may need to live in a CDN.
 * Once Locize.com is setup, we can ignore these files and use Locize.com as the source of truth.
 * We are keeping the config setup so we know what it looks like when needed
 */
const bundledResources = {
  en: { translation: en },
  es: { translation: es },
  fr: { translation: fr },
  it: { translation: it },
  de: { translation: de },
};

const isProduction = process.env.NODE_ENV === 'production';

// both production and development environments use language detector and react-i18next
i18n.use(initReactI18next);

export const i18nInitOptions = {
  fallbackLng: 'en',
  ns: ['translation'],
  defaultNS: 'translation',
  keySeparator: false,
  nsSeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    transKeepBasicHtmlNodesFor: [
      'br',
      'strong',
      'i',
      'p',
      'div',
      'a',
      'em',
      'span',
    ],
    useSuspense: true,
  },
  nonExplicitSupportedLngs: true,
  load: 'languageOnly',
};

/**
 * This function allows for setting up additional custom formatters to be used in translated strings.
 *
 * It must only be called AFTER i18next.init().
 *
 * See: https://www.i18next.com/translation-function/formatting
 *
 * @param {import('i18next').i18n} i18next The initialized i18next instance
 */
export const i18nPostInit = ({ services }) => {
  services.formatter?.addCached('numericList', (lng, options) => {
    const listFormatter = new Intl.ListFormat(lng, {
      ...options.listOptions,
    });

    if (options.raw) {
      return (val) => listFormatter.format(val.map(String));
    }

    const numberFormatter = new Intl.NumberFormat(lng, {
      ...options.numberOptions,
    });

    const formattedNumber = (item) => numberFormatter.format(item);

    return (val) => listFormatter.format(val.map(formattedNumber));
  });
};

i18n.use(LanguageDetector);

if (!isProduction) {
  // development has only locize as backend, not chained in order to have saveMissing and updateMissing
  i18n.use(LocizeBackend).use(LastUsed).use(locizePlugin);

  // i18nInitOptions.debug = true;
  i18nInitOptions.saveMissing = true;
  i18nInitOptions.updateMissing = true;
  i18nInitOptions.backend = locizeOptions;
  i18nInitOptions.locizeLastUsed = locizeOptions;
}

if (isProduction) {
  // production has two chained backends, locize and http, locize is primary and http is fallback
  i18n.use(ChainedBackend);

  i18nInitOptions.debug = false;
  i18nInitOptions.saveMissing = false;
  i18nInitOptions.updateMissing = false;
  i18nInitOptions.backend = {
    backends: [LocizeBackend, resourcesToBackend(bundledResources)],
    backendOptions: [locizeOptions],
  };
}

export default i18n;
