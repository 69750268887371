import { call, select, take, all, takeEvery, put } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { selectCurrentOrgId } from 'global/accessToken/selectors';
import { requestAndSet, sendToHelium } from 'utils/helpers';
import { setOrgContainerReducer } from 'routes/OrgContainer/actions';
import {
  setFormValidation,
  setInitialFormValues,
} from 'global/formWrapper/actions';
import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import { deleteSlideOut } from 'global/slideOuts/actions';
import { setAlert } from 'routes/AppContainer/actions';
import { SET_USERS } from 'routes/OrgContainer/constants';
import moment from 'moment/moment';
import { DATE_TIME_FORMAT } from 'constants/Dates';
import { t } from 'i18next';
import { isShowingSitePartitioningItemsSaga } from 'utils/isShowingSitePartitioningItemsSaga';
import {
  FILTER_ORG_USERS,
  FORM,
  PAGE,
  REQUEST_ALARM_ACTION,
  SET_PAGE_DATA,
} from './constants';

function* requestAndSetPageData({ orgId, alarmIdExt }) {
  const { data: alarmData } = yield call(requestAndSet, 'describeAlarm', [
    orgId,
    alarmIdExt,
  ]);

  if (!alarmData) {
    yield put(
      setAlert(
        'error',
        t(
          `Failed to retrieve the chosen alarm. The alarm may no longer exist.`,
        ),
      ),
    );
    yield put(deleteSlideOut());
    return;
  }

  const { timestamp: sourceEventTimestamp } = alarmData.sourceEvent;

  const visualActivity = [];

  // Load camera snapshots
  // TODO: Limit scope to JUST those who can see visual activity report here (the code below currently only checks against the webpack config)
  const orgHasFeature = yield select(
    selectFeatureFlag('SHOW_VISUAL_ACTIVITY_REPORT'),
  );
  if (orgHasFeature && alarmData.cameras) {
    const { data: cameras } = yield call(
      requestAndSet,
      'listOrgCameras',
      [orgId],
      {
        queryStringParams: {
          sort: 'nameExt',
          order: 'asc',
          filter: `id:(${alarmData.cameras.map(({ id }) => id).join(',')})`,
        },
      },
    );

    const startDate = sourceEventTimestamp - 2;
    for (let i = 0; i < cameras.length; i++) {
      const { data: visualActivityData } = yield call(
        requestAndSet,
        'listSnapshots',
        [orgId],
        {
          queryStringParams: {
            startDate,
            endDate: sourceEventTimestamp + 2,
            idExt: cameras[i].idExt,
            includeDeeplinks: true,
          },
        },
      );

      if (visualActivityData.length) {
        visualActivity.push(...visualActivityData);
      }
    }
  }

  yield put(
    setInitialFormValues(PAGE, FORM, {
      name:
        alarmData.alarmConfiguration?.name || alarmData.alarmConfigurationName,
      severity: alarmData.alarmSeverity.name,
      triggeredTime: moment(alarmData.triggeredAt).format(DATE_TIME_FORMAT),
      status: alarmData.alarmStatus.name,
      event: alarmData.eventName || '--',
      entry: alarmData.sourceEvent.data?.entries?.[0]?.name,
      site: alarmData.siteName,
      assignedUser: alarmData.assignedUser?.identity.fullName,
      hardware: '--',
      description: alarmData.alarmConfiguration?.description || '--',
      instructions: alarmData.alarmConfiguration?.instructions || '--',
      alarmActionId: null,
      note: '',
      assignedUserId: null,
      activity: alarmData.activity,
      isNoteRequiredToAcknowledge: alarmData.alarmConfiguration?.isNoteRequired,
      isEditable: !!alarmData.idExt, // This only exists when the alarm exists in the Helium cache table!
      sourceEventTimestamp,
      visualActivity,
    }),
  );

  yield put(
    setFormValidation(PAGE, FORM, { alarmActionId: { required: true } }),
  );
}

function* requestAndSetOrgUsers({ filterQuery } = {}) {
  const orgId = yield select(selectCurrentOrgId());

  const isShowingSitePartitioningItems = yield call(
    isShowingSitePartitioningItemsSaga,
  );

  return yield call(requestAndSet, 'listUsers', [orgId], {
    queryStringParams: {
      filter: 'status:(!=I)',
      limit: 50,
      ...(filterQuery && { q: filterQuery }),
      ...(isShowingSitePartitioningItems && {
        options: 'withUserSites',
      }),
    },
    createSetterAction: ({ data }) =>
      setOrgContainerReducer(SET_USERS, PAGE, data),
  });
}

function* requestAlarmAction({ orgId, alarmIdExt, formValues }) {
  const { alarmActionId, assignedUserId, note } = formValues;
  const payload = { alarmActionId };
  if (assignedUserId) payload.assignedUserId = assignedUserId;
  if (note) payload.note = note;

  yield call(sendToHelium, 'updateAlarm', [orgId, alarmIdExt], payload, {
    successMessage: t('Successfully took action on this alarm'),
  });

  yield call(requestAndSetPageData, { orgId, alarmIdExt });
}

function* rootSaga() {
  yield all([
    takeEvery(SET_PAGE_DATA, requestAndSetPageData),
    takeEvery(FILTER_ORG_USERS, requestAndSetOrgUsers),
    takeEvery(REQUEST_ALARM_ACTION, requestAlarmAction),
  ]);
  yield take(LOCATION_CHANGE);
}

export default rootSaga;
