import { ComponentProps, ReactNode } from 'react';
import Radio from 'antd/es/radio';
import { OpInfoTooltip } from '../OpInfoTooltip/OpInfoTooltip';

interface OpRadioProps extends ComponentProps<typeof Radio> {
  tooltip?: ReactNode;
  gtm?: string;
}
export const OpRadio = ({
  children,
  className = '',
  tooltip,
  gtm,
  ...radioProps
}: OpRadioProps) => {
  return (
    <Radio
      className={`op-radio ${className}`.trim()}
      data-gtm={gtm}
      {...radioProps}
    >
      {children}
      {tooltip && <OpInfoTooltip title={tooltip} />}
    </Radio>
  );
};

/** Need to attach static properties on antd Radio to allow dot
 * notation subcomponents to work on this component as well */
OpRadio.Group = Radio.Group;
OpRadio.Button = Radio.Button;
