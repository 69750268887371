import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/svgs/SvgWrapper';

interface DocumentFilledSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const DocumentFilledSvg = ({ ...svgProps }: DocumentFilledSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M14 1C15.1046 1 16 1.89543 16 3V15C16 16.1046 15.1046 17 14 17H4C2.89543 17 2 16.1046 2 15V3C2 1.89543 2.89543 1 4 1H14ZM9.5 11H5.5C5.22386 11 5 11.2239 5 11.5C5 11.7761 5.22386 12 5.5 12H9.5C9.77614 12 10 11.7761 10 11.5C10 11.2239 9.77614 11 9.5 11ZM12.5 8H5.5C5.22386 8 5 8.22386 5 8.5C5 8.77614 5.22386 9 5.5 9H12.5C12.7761 9 13 8.77614 13 8.5C13 8.22386 12.7761 8 12.5 8ZM12.5 5H5.5C5.22386 5 5 5.22386 5 5.5C5 5.77614 5.22386 6 5.5 6H12.5C12.7761 6 13 5.77614 13 5.5C13 5.22386 12.7761 5 12.5 5Z" />
  </SvgWrapper>
);
