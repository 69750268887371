import { t } from 'i18next';

export default {
  customFields: {
    revshare: {
      url: '/rs-custom-fields',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-custom-fields',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  occupancyReport: {
    revshare: {
      url: '/rs-occupancy-reports',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-occupancy-reports',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  basicRulesEngine: {
    revshare: {
      url: '/rs-rules-engine',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-rules-engine',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  granularPermissions: {
    revshare: {
      url: '/rs-granular-permissions',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-granular-permissions',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  lockdown: {
    revshare: {
      url: '/rs-flexible-lockdown',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-flexible-lockdown',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  azureIdentityProvider: {
    revshare: {
      url: '/rs-essential-user-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-essential-user-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  gsuiteIdentityProvider: {
    revshare: {
      url: '/rs-essential-user-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-essential-user-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  oktaIdentityProvider: {
    revshare: {
      url: '/rs-premier-user-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-premier-user-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  oneloginIdentityProvider: {
    revshare: {
      url: '/rs-premier-user-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: 'h/ch-premier-user-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  occupancyManagement: {
    revshare: {
      url: '/rs-occupancy-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-occupancy-management',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  vms: {
    revshare: {
      url: '/rs-milestone-integration',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-milestone-integration',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  merakiVms: {
    revshare: {
      url: '/rs-meraki-integration',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-meraki-integration',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
  customDashboards: {
    revshare: {
      url: '/rs-custom-dashboards',
      popUpCopy: '',
      buttonCta: '',
    },
    channel: {
      url: '/ch-custom-dashboards',
      popUpCopy: '',
      buttonCta: '',
    },
  },
  allegionNdebLeb: {
    revshare: {
      url: '/rs-allegion-ndebleb-integration',
      popUpCopy: '',
      buttonCta: '',
    },
    channel: {
      url: '/ch-custom-dashboards',
      popUpCopy: '',
      buttonCta: '',
    },
  },
  voiceAssistant: {
    revshare: {
      popUpCopy: t('FEATURECODE app is required'),
      buttonCta: t(`Install app`),
    },
    channel: {
      popUpCopy: t('FEATURECODE app is required'),
      buttonCta: t(`Install app`),
    },
  },
  musterReport: {
    revshare: {
      url: '/rs-premium-feature',
      popUpCopy: t('FEATURECODE app is required'),
      buttonCta: t(`Install app`),
    },
    channel: {
      url: '/ch-premium-feature',
      popUpCopy: t('FEATURECODE app is required'),
      buttonCta: t(`Install app`),
    },
  },
  alarmManagement: {
    revshare: {
      url: '/rs-premium-feature',
      popUpCopy: t('FEATURECODE app is required'),
      buttonCta: t(`Install app`),
    },
    channel: {
      url: '/ch-premium-feature',
      popUpCopy: t('FEATURECODE app is required'),
      buttonCta: t(`Install app`),
    },
  },
  samlSso: {
    revshare: {
      url: '/rs-premium-feature',
      popUpCopy: t('FEATURECODE app is required'),
      buttonCta: t(`Install app`),
    },
    channel: {
      url: '/ch-premium-feature',
      popUpCopy: t('FEATURECODE app is required'),
      buttonCta: t(`Install app`),
    },
  },
  generic: {
    revshare: {
      url: '/rs-premium-feature',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
    channel: {
      url: '/ch-premium-feature',
      popUpCopy: t(`FEATURECODE is a Premium feature`),
      buttonCta: t(`Learn More`),
    },
  },
};
