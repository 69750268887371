import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/svgs/SvgWrapper';

interface SettingOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const SettingOutlinedSvg = ({
  ...svgProps
}: SettingOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M11.5 10C12.7097 10 13.7187 10.8592 13.9501 12.0006L16.5 12C16.7761 12 17 12.2239 17 12.5C17 12.7761 16.7761 13 16.5 13L13.9499 13.0004C13.7181 14.1413 12.7093 15 11.5 15C10.2907 15 9.2819 14.1413 9.0501 13.0004L1.5 13C1.22386 13 1 12.7761 1 12.5C1 12.2239 1.22386 12 1.5 12L9.04989 12.0006C9.28131 10.8592 10.2903 10 11.5 10ZM11.5 11C10.6716 11 10 11.6716 10 12.5C10 13.3284 10.6716 14 11.5 14C12.3284 14 13 13.3284 13 12.5C13 11.6716 12.3284 11 11.5 11ZM6.5 3C7.70968 3 8.71869 3.85917 8.95011 5.00057L16.5 5C16.7761 5 17 5.22386 17 5.5C17 5.77614 16.7761 6 16.5 6L8.9499 6.00043C8.7181 7.14134 7.70933 8 6.5 8C5.29067 8 4.2819 7.14134 4.0501 6.00043L1.5 6C1.22386 6 1 5.77614 1 5.5C1 5.22386 1.22386 5 1.5 5L4.04989 5.00057C4.28131 3.85917 5.29032 3 6.5 3ZM6.5 4C5.67157 4 5 4.67157 5 5.5C5 6.32843 5.67157 7 6.5 7C7.32843 7 8 6.32843 8 5.5C8 4.67157 7.32843 4 6.5 4Z" />
  </SvgWrapper>
);
