import { fromJS } from 'immutable';

import { LOGOUT_SUCCESS } from 'routes/AppContainer/constants';
import { SET_ALL_FEATURES } from './constants';

function featuresReducer(state = fromJS([]), action) {
  switch (action.type) {
    case SET_ALL_FEATURES:
      return fromJS(action.features);
    case LOGOUT_SUCCESS:
      return fromJS([]);
    default:
      return state;
  }
}

export default featuresReducer;
