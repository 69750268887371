import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Clickable.scss';

/**
 * @name Clickable
 * @description The Clickable component's purpose is to provide an a11y
 * compliant means of creating a clickable element (button vs divs, spans etc...)
 */
export const Clickable = ({
  children,
  onClick,
  inline,
  className = '',
  ...htmlButtonProps
}) => {
  const classnames = clsx(
    'op-clickable',
    { 'op-clickable--inline': inline },
    { [className]: Boolean(className) },
  );
  return (
    <button
      type="button"
      className={classnames}
      onClick={onClick}
      {...htmlButtonProps}
    >
      {children}
    </button>
  );
};

Clickable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  inline: PropTypes.bool,
};
