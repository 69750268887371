import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const EyeSvg = ({ ...svgProps }) => (
  <SvgWrapper {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99981 2C12.3538 2 15.4758 4.213 17.7928 8.231C18.0668 8.708 18.0668 9.292 17.7958 9.762C15.4758 13.787 12.3538 16 8.99981 16C5.64581 16 2.52381 13.787 0.206806 9.769C0.206806 9.768 0.206806 9.768 0.205806 9.767L0.201806 9.76C-0.0671941 9.292 -0.0671941 8.708 0.203806 8.238C2.52381 4.213 5.64581 2 8.99981 2ZM8.99981 3C6.01981 3 3.20381 5.035 1.07381 8.731C0.976806 8.899 0.976806 9.101 1.06881 9.262L1.07381 9.269C3.20381 12.965 6.01981 15 8.99981 15C11.9798 15 14.7958 12.965 16.9258 9.269C17.0228 9.101 17.0228 8.899 16.9308 8.738C14.7958 5.035 11.9798 3 8.99981 3ZM8.99981 6C10.6538 6 11.9998 7.346 11.9998 9C11.9998 10.654 10.6538 12 8.99981 12C7.34581 12 5.99981 10.654 5.99981 9C5.99981 7.346 7.34581 6 8.99981 6ZM9.00001 7C7.89734 7 7.00001 7.89733 7.00001 9C7.00001 10.1027 7.89734 11 9.00001 11C10.1027 11 11 10.1027 11 9C11 7.89733 10.1027 7 9.00001 7Z"
    />
  </SvgWrapper>
);
