import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import opConfig from 'openpathConfig';

interface TanstackDevtoolsProps {
  enabled?: boolean;
}

/**
 * @component
 * @description - Component that renders ReactQueryDevtools if in "mine" and debugging is enabled
 */
export const TanstackDevtools = ({
  // DON'T CHANGE THIS TO "true". PASS IN THE PROP WHERE THE COMPONENT IS USED SO WE CAN RELY ON THE CUSTOM LINTER PREVENTING ACCIDENTALLY COMMITTING THE VALUE CHANGE
  enabled = false,
}: TanstackDevtoolsProps) => {
  if (opConfig.ENV !== 'mine' || !enabled) {
    return null;
  }

  return <ReactQueryDevtools initialIsOpen={false} />;
};
