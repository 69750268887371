export const SignalQualityDisconnected = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 16H5V19H7V16ZM5 15C4.44772 15 4 15.4477 4 16V20H8V16C8 15.4477 7.55228 15 7 15H5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 11H11V19H13V11ZM11 10C10.4477 10 10 10.4477 10 11V20H14V11C14 10.4477 13.5523 10 13 10H11Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 5H19V14.5H20V5C20 4.44772 19.5523 4 19 4H17C16.4477 4 16 4.44772 16 5V14.5H17V5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1521 16.1357C16.3474 15.9405 16.6639 15.9405 16.8592 16.1357L18.0057 17.2822L19.1521 16.1357C19.3474 15.9405 19.6639 15.9405 19.8592 16.1357C20.0545 16.331 20.0545 16.6476 19.8592 16.8429L18.7128 17.9893L19.8592 19.1357C20.0545 19.331 20.0545 19.6476 19.8592 19.8429C19.6639 20.0381 19.3474 20.0381 19.1521 19.8429L18.0057 18.6964L16.8592 19.8429C16.6639 20.0381 16.3474 20.0381 16.1521 19.8429C15.9568 19.6476 15.9568 19.331 16.1521 19.1357L17.2985 17.9893L16.1521 16.8429C15.9568 16.6476 15.9568 16.331 16.1521 16.1357Z"
    />
  </svg>
);
