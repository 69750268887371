import {
  ComponentProps,
  ReactNode,
  useEffect,
  useRef,
  useState,
  Key,
} from 'react';
import { UseOpQueryOptions, useOpQuery } from 'utils/customHooks/useOpQuery';
import difference from 'lodash/difference';
import debounce from 'lodash/debounce';
import differenceBy from 'lodash/differenceBy';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import uniqBy from 'lodash/uniqBy';
import { ensurePayloadAndQuery } from 'utils/ensurePayloadAndQuery';
import { OpTag } from '../OpTag/OpTag';
import { OpTransfer } from '../OpTransfer/OpTransfer';

import './OpDataFetchTransfer.scss';

interface DataParams<T extends keyof Api.ClientSpec> {
  queryOptions: UseOpQueryOptions<T>;
  createKey?: (item: Record<string, any>) => string;
  createTitle?: (item: Record<string, any>) => string;
  createDescription?: (item: Record<string, any>) => string;
  createDisabled?: (item: Record<string, any>) => boolean;
}

interface RecordType {
  key?: Key;
  title?: string;
  description?: string;
  disabled?: boolean;
}

interface Queries {
  limit?: number;
  offset?: number;
  sort?: string;
  order?: string;
  q?: string;
  filter?: string;
  options?: string;
}

interface OpDataFetchTransferProps<
  T extends keyof Api.ClientSpec,
  U extends keyof Api.ClientSpec,
> extends Omit<ComponentProps<typeof OpTransfer>, 'onChange'> {
  availableItemsParams: DataParams<T>;
  existingItemsParams: DataParams<U>;
  createLabel: (record: RecordType) => ReactNode;
  existingItemsLabel?: string;
  testId?: string;
  onChange?: (idsToAddAndRemove: { add: number[]; remove: number[] }) => void;
}

const appendQueriesInQueryOptions = <T extends keyof Api.ClientSpec>(
  queryOptions: UseOpQueryOptions<T>,
  queriesToAppend: Queries,
) => {
  const { parametersArray, withQuery } = ensurePayloadAndQuery(
    queryOptions.apiEndpointName,
    queryOptions.parameters,
  );
  if (withQuery) {
    // Find the index of the queries (last object)
    const queriesIndex = parametersArray.findLastIndex(
      (param: any) => typeof param === 'object',
    );

    if (queriesIndex === -1) {
      // ensurePayloadAndQuery guarantees the presence of a queries object, so throw since something is way off...
      throw new Error(
        `Critical: ensurePayloadAndQuery did not ensure a query object for API endpoint ${queryOptions.apiEndpointName}`,
      );
    }

    parametersArray[queriesIndex] = {
      ...(parametersArray[queriesIndex] as Queries),
      ...queriesToAppend,
    } satisfies Queries;
  }

  return {
    ...queryOptions,
    parameters: parametersArray,
  } as UseOpQueryOptions<T>;
};

export const OpDataFetchTransfer = <
  T extends keyof Api.ClientSpec,
  U extends keyof Api.ClientSpec,
>({
  className = '',
  testId = 'op-data-fetch-transfer',
  availableItemsParams,
  existingItemsParams,
  createLabel = (record: RecordType) => record.title,
  existingItemsLabel,
  onChange: onChangeProp,

  // Props passed through to OpTransfer
  ...opTransferProps
}: OpDataFetchTransferProps<T, U>) => {
  const { t } = useTranslation();

  const [availableDataSource, setAvailableDataSource] = useState<RecordType[]>(
    [],
  );
  const [existingDataSource, setExistingDataSource] = useState<RecordType[]>(
    [],
  );
  const [availableTotalItems, setAvailableTotalItems] = useState<number>();
  const [existingTotalItems, setExistingTotalItems] = useState<number>();
  const [availableItemsQuery, setAvailableItemsQuery] = useState<string>();
  const [existingItemsQuery, setExistingItemsQuery] = useState<string>();
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const addedItems = useRef<RecordType[]>([]);
  const removedItems = useRef<RecordType[]>([]);

  const { data: availableItemsHeliumResponse } = useOpQuery(
    appendQueriesInQueryOptions<T>(availableItemsParams.queryOptions, {
      limit: 100 + addedItems.current.length, // Makes sure there are always items (if more than 50 in db)
      ...(availableItemsQuery && { q: availableItemsQuery }),
    }),
  );
  const { data: existingItemsHeliumResponse } = useOpQuery(
    appendQueriesInQueryOptions<U>(existingItemsParams.queryOptions, {
      limit: 50 + removedItems.current.length, // Makes sure there are always items (if more than 50 in db)
      ...(existingItemsQuery && { q: existingItemsQuery }),
    }),
  );

  // Cleanup
  useEffect(
    () => () => {
      addedItems.current = [];
      removedItems.current = [];
    },
    [],
  );

  // Set the existingDataSource
  useEffect(() => {
    const existingItemsData = existingItemsHeliumResponse?.json?.data;

    if (Array.isArray(existingItemsData)) {
      setExistingTotalItems(existingItemsHeliumResponse?.json?.totalCount);

      setExistingDataSource(() =>
        uniqBy(
          existingItemsData.map((item) => ({
            key: existingItemsParams.createKey?.(item) || String(item.id),
            title: existingItemsParams.createTitle?.(item) || String(item.name),
            description: existingItemsParams.createDescription?.(item),
            disabled: existingItemsParams.createDisabled?.(item),
          })),
          'key',
        ),
      );

      // Add group users to targetKeys
      setTargetKeys(
        existingItemsData
          .map(
            (item) => existingItemsParams.createKey?.(item) || String(item.id),
          )
          .filter(
            (key) =>
              key &&
              !removedItems.current
                .map(({ key: removedItemKey }) => removedItemKey)
                .includes(key),
          ),
      );
    }
  }, [
    existingItemsHeliumResponse?.json?.data,
    existingItemsHeliumResponse?.json?.totalCount,
    existingItemsParams,
  ]);

  // TODO - persist removed item on available when not in available or existing fetched data
  // Set the availableDataSource
  useEffect(() => {
    const availableItemsData = availableItemsHeliumResponse?.json?.data;

    if (Array.isArray(availableItemsData)) {
      setAvailableTotalItems(availableItemsHeliumResponse?.json?.totalCount);

      setAvailableDataSource(() =>
        uniqBy(
          availableItemsData.map((item) => ({
            key: availableItemsParams.createKey?.(item) || String(item.id),
            title:
              availableItemsParams.createTitle?.(item) || String(item.name),
            description: availableItemsParams.createDescription?.(item),
          })),
          'key',
        ),
      );
    }
  }, [
    availableItemsHeliumResponse?.json?.data,
    availableItemsHeliumResponse?.json?.totalCount,
    availableItemsParams,
  ]);

  const onChange: ComponentProps<typeof OpTransfer>['onChange'] = (
    newTargetKeys,
    direction,
    moveKeys,
  ) => {
    // Update targetKeys
    setTargetKeys(newTargetKeys);

    if (direction === 'right') {
      // Only add tag if wasn't there originally
      const moveKeysNotInRemoveKeys = difference(
        moveKeys,
        removedItems.current.map(({ key }) => key),
      );

      const newAddedItems = [
        ...addedItems.current,
        ...dataSource.filter(({ key }) =>
          moveKeysNotInRemoveKeys.includes(key),
        ),
      ];
      const newRemovedItems = differenceBy(
        removedItems.current,
        dataSource.filter(({ key }) => key && moveKeys.includes(key)),
        'key',
      );

      onChangeProp?.({
        add: newAddedItems.map(({ key }) => Number(key)),
        remove: newRemovedItems.map(({ key }) => Number(key)),
      });

      addedItems.current = newAddedItems;

      // Remove old tags
      removedItems.current = newRemovedItems;
    } else {
      // Only add tag if wasn't there originally
      const moveKeysNotInAddKeys = difference(
        moveKeys,
        addedItems.current.map(({ key }) => key),
      );

      const newRemovedItems = [
        ...removedItems.current,
        ...dataSource.filter(({ key }) => moveKeysNotInAddKeys.includes(key)),
      ];
      const newAddedItems = differenceBy(
        addedItems.current,
        dataSource.filter(({ key }) => key && moveKeys.includes(key)),
        'key',
      );

      onChangeProp?.({
        remove: newRemovedItems.map(({ key }) => Number(key)),
        add: newAddedItems.map(({ key }) => Number(key)),
      });

      removedItems.current = newRemovedItems;

      // Remove old tags
      addedItems.current = newAddedItems;
    }
  };

  const onSearch = debounce((direction, value) => {
    if (direction === 'left') {
      setAvailableItemsQuery(value);
    } else {
      setExistingItemsQuery(value);
    }
  }, 300);

  // DataSource is the combined responses from available and existing item endpoints
  let dataSource = availableDataSource.concat(existingDataSource);

  // If there is an existing items query, add removed items to the end of the dataSource
  if (existingItemsQuery) {
    dataSource.push(...removedItems.current);
  }

  // If there is an available items query, add added items to the end of the dataSource
  if (availableItemsQuery) {
    dataSource.push(...addedItems.current);
  }

  // Make sure dataSource items are unique
  dataSource = uniqBy(dataSource, 'key');

  // Sort the dataSource so removed items are at top of available list
  dataSource = dataSource.sort((a, b) => {
    // Sorted so removed items are at top of available list
    const aKeyIndex = a.key
      ? removedItems.current.map(({ key }) => key).indexOf(a.key)
      : -1;
    const bKeyIndex = b.key
      ? removedItems.current.map(({ key }) => key).indexOf(b.key)
      : -1;

    if (aKeyIndex !== -1 && bKeyIndex === -1) {
      // a's key is in removedItems, but b's key is not
      return -1;
    }
    if (aKeyIndex === -1 && bKeyIndex !== -1) {
      // b's key is in removedItems, but a's key is not
      return 1;
    }
    // Both a's key and b's key are in removedItems or both are not in removedItems
    // Preserve the original order
    return 0;
  });

  // This is needed for antd Transfer component to keep data items in the correct column
  const uniqueTargetKeys = [
    ...new Set(addedItems.current.map(({ key }) => key!).concat(targetKeys)),
  ];

  return (
    <OpTransfer
      className={clsx('op-data-fetch-transfer', className)}
      dataSource={dataSource}
      showSearch
      testId={testId}
      selectAllLabels={[
        ({
          selectedCount,
          totalCount,
        }: {
          selectedCount: number;
          totalCount: number;
        }) => {
          const count =
            selectedCount > 0
              ? t('{{count}}/{{totalCount}} items selected', {
                  count: selectedCount,
                  totalCount,
                })
              : t('Showing {{count}} items', { count: totalCount });

          const total =
            availableTotalItems && totalCount !== availableTotalItems
              ? `(${t('{{availableTotalItems}} available', {
                  availableTotalItems,
                })})`
              : '';

          return `${count} ${total}`;
        },
        ({
          selectedCount,
          totalCount,
        }: {
          selectedCount: number;
          totalCount: number;
        }) => {
          const count =
            selectedCount > 0
              ? t('{{count}}/{{totalCount}} items selected', {
                  count: selectedCount,
                  totalCount,
                })
              : t('Showing {{count}} items', { count: totalCount });

          const total = existingTotalItems
            ? `(${t('{{existingTotalItems}} {{existingItemsLabel}}', {
                existingTotalItems:
                  existingTotalItems +
                  addedItems.current.length -
                  removedItems.current.length,
                existingItemsLabel: existingItemsLabel || t('total'),
              })})`
            : '';

          return `${count} ${total}`;
        },
      ]}
      listStyle={{
        width: '100%',
        height: 500,
        overflow: 'hidden', // Keeps overall width constrained to drawer width
      }}
      targetKeys={uniqueTargetKeys}
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(inputValue, option) => {
        return option.title!.toLowerCase().includes(inputValue.toLowerCase());
      }}
      render={(record: RecordType) => {
        const { key, title } = record;

        return {
          label: (
            <div className="op-data-fetch-transfer__label-wrapper">
              <div>{createLabel(record)}</div>
              {key &&
              addedItems.current
                .map(({ key: addedItemKey }) => addedItemKey)
                .includes(key) ? (
                <OpTag className="op-data-fetch-transfer__tag">added</OpTag>
              ) : null}
              {key &&
              removedItems.current
                .map(({ key: removedItemKey }) => removedItemKey)
                .includes(key) ? (
                <OpTag className="op-data-fetch-transfer__tag">removed</OpTag>
              ) : null}
            </div>
          ),
          value: title || '--',
        };
      }}
      {...opTransferProps}
    />
  );
};
