import { useState } from 'react';
import { FilterSvg } from 'components/svgs/FilterSvg';
import { useTranslation } from 'react-i18next';
import { TableState } from 'new-components/DLS/OpTable/OpTable';
import { OpCheckbox } from 'new-components/DLS/OpCheckbox/OpCheckbox';
import { OpMenu } from 'new-components/DLS/OpMenu/OpMenu';
import { OpTooltip } from 'new-components/DLS/OpTooltip/OpTooltip';
import { OpDropdown } from 'new-components/DLS/OpDropdown/OpDropdown';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { OpTableColumn, OpTableRecordType } from '../OpTableCore';
import { ResetTableButton } from './ResetTableButton';

import './ShowHideColumnsButton.scss';

export type OnShowHideColumns = (columns: OpTableColumn[]) => void;

interface ShowHideColumnsButtonProps {
  tableState: TableState<OpTableRecordType>;
  onShowHideColumns: OnShowHideColumns;
  gtm?: string;
  onResetTable(): void;
}

export const ShowHideColumnsButton = ({
  tableState,
  onShowHideColumns,
  gtm,
  onResetTable,
}: ShowHideColumnsButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onClick = ({ key }: { key: string }) => {
    const newColumns = [...tableState.columns];
    const foundColumnIndex = newColumns.findIndex(
      (column) => column.key === key,
    );
    newColumns[foundColumnIndex].hidden = !newColumns[foundColumnIndex].hidden;

    onShowHideColumns(newColumns);
  };

  const items = tableState.columns.map(({ key, label, hidden, required }) => {
    return {
      key: key as string,
      label,
      icon: (
        <OpCheckbox
          disabled={required}
          checked={!hidden}
          data-gtm={gtm && `${gtm}-${label}`}
        />
      ),
      disabled: required,
    };
  });

  const menu = () => (
    <div className="show-hide-columns-button__menu-container">
      <OpMenu
        testId="show-hide-columns-button-menu"
        className="show-hide-columns-button__menu"
        onClick={onClick}
        items={items}
      />
      <ResetTableButton disabled={tableState.default} onReset={onResetTable} />
    </div>
  );

  return (
    <OpTooltip placement="topRight" title={t('Show/hide columns')}>
      <OpDropdown
        className="show-hide-columns-button"
        dropdownRender={menu}
        testId="show-hide-dropdown"
        trigger={['click']}
        open={open}
        onOpenChange={(flag, { source }) => {
          // Ensures clicks within children rendered don't close the dropdown
          if (source === 'trigger') {
            setOpen(flag);
          }
        }}
      >
        <OpButton
          gtm={gtm && `${gtm}-state-edit`}
          testId="show-hide-columns-button"
          size="large"
          shape="circle"
          icon={<FilterSvg />}
        />
      </OpDropdown>
    </OpTooltip>
  );
};
