import { useDebouncedState } from 'utils/customHooks/useDebouncedState';
import { UseOpQueryOptions, useOpQuery } from 'utils/customHooks/useOpQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { ensurePayloadAndQuery } from 'utils/ensurePayloadAndQuery';
import { usePersistentUiState } from 'utils/customHooks/usePersistentUiState';
import { keepPreviousData } from '@tanstack/react-query';
import { TableState } from '../OpTable/OpTable';
import {
  convertRawColumnsToColumns,
  createFinalColumnKey,
} from '../OpTableCore/helpers';
import { OpTableRawColumnType } from '../OpTableCore/OpTableCore';
import { createQueryStringParamsFromTableState } from './createQueryStringParamsFromTableState';
// For now assuming OpTable and OpDataFetchTable stay in sync on this
import {
  OpTableCorePersistedState,
  getStateToPersist,
  opTablePersistedUiVersion,
} from '../OpTable/uiPersistence';
import { tableStateKeys } from '../OpTableCore/constants/tableStateKeys';
import { OnFilterUpdateType } from '../OpTableCore/components/ColumnHeaderFilter';

interface UseDataFetchTableParams<T extends keyof Api.ClientSpec> {
  rawColumns: OpTableRawColumnType[];
  opQueryProps: UseOpQueryOptions<T>;
  resetRowSelections(): void;
  uiStateKey: Utils.ValueOf<typeof tableStateKeys>;
}

export const useDataFetchTable = <T extends keyof Api.ClientSpec>({
  rawColumns,
  opQueryProps,
  resetRowSelections,
  uiStateKey,
}: UseDataFetchTableParams<T>) => {
  const {
    uiKey,
    persistedUiState,
    setPersistedUiState,
    canUsePersistedUiState,
    isPersistedUiStateFetching,
    resetPersistedUiState,
  } = usePersistentUiState<OpTableCorePersistedState>({
    uiComponentKey: uiStateKey,
    persistedUiVersion: opTablePersistedUiVersion,
  });

  /** Use rawColumns to determine if any column is filterable. If not, we want
   * to hide the filter portion from all column headers for a cleaner UI */
  const hasHeaderFilter = rawColumns.some(({ filter }) => Boolean(filter));

  const defaultStateColumns = useMemo(
    () =>
      convertRawColumnsToColumns({
        rawColumns,
        hasHeaderFilter,
        resetRowSelections,
      }),
    [hasHeaderFilter, rawColumns, resetRowSelections],
  );

  /** Antd will show UI for all columns with defaultSortOrder, but will only respect the
   * first one. Seems more logical to only show the first one in the UI as well as if
   * there are not 2 default sort orders then you are never allowed to sort by more than
   * one column. */
  const columnWithDefaultSort = defaultStateColumns.find(
    (column) => column.defaultSortOrder,
  );

  const defaultState: TableState = useMemo(
    () => ({
      default: true,
      // TODO try to type this from opQueryProps.apiEndpointName
      // Pagination defaults
      pagination: {
        current: 1,
        pageSize: 100,
      },

      // Add a default sorter if there is one
      sorter: {
        column: columnWithDefaultSort,
        order: columnWithDefaultSort?.defaultSortOrder,
        columnKey: columnWithDefaultSort?.key,
        field: columnWithDefaultSort?.key
          ? [columnWithDefaultSort.key]
          : undefined,
      },

      /** Create filters based on how antd creates them. By default any filterable column
       * will have the column key with a value of null and any default values will be added
       * if there are any. */
      filters: rawColumns.reduce(
        (acc, { key, dataIndex, filter, defaultFilteredValue }) => {
          // Add the filter and defaultFilteredValue if it exists
          if (filter && (key || dataIndex)) {
            const finalKey = createFinalColumnKey(key, dataIndex);

            return {
              ...acc,
              /**
               * Helium uses default match logic which is “if it looks like a number then do a number
               * match, otherwise do a string match". Since we explicitly want a string match, we
               * should be sending an explicit substring-match operator ~ to helium so it will always
               * use string matching, not number matching.
               */
              [finalKey]:
                defaultFilteredValue && defaultFilteredValue.length > 0
                  ? defaultFilteredValue?.map((v) => `~${v}`)
                  : null,
            };
          }

          return acc;
        },
        {},
      ),

      // Add the columns to state so we can show/hide columns dynamically
      columns: defaultStateColumns,

      // Table global search defaults
      q: '',
    }),
    [columnWithDefaultSort, defaultStateColumns, rawColumns],
  );

  /** Debouncing updating the state so things like filters do not fetch
   * on every change */
  const [debouncedState, setState, state] =
    useDebouncedState<TableState>(defaultState);

  const onFilterUpdate: OnFilterUpdateType = useCallback(
    (filterKey, filterValue) => {
      setState((prevState) => {
        const newState = {
          ...prevState,
          default: false,
          pagination: {
            ...prevState.pagination,
            current: 1, // Reset to page 1 on filter
          },
          filters: {
            ...prevState.filters,
            [`${filterKey}`]: filterValue,
          },
        };

        setPersistedUiState(
          getStateToPersist({
            columns: newState.columns,
            filters: newState.filters,
            sorter: newState.sorter,
          }),
        );

        return newState;
      });
    },
    [setPersistedUiState, setState],
  );

  useEffect(() => {
    if (!isPersistedUiStateFetching) {
      setState((prevState) => {
        const {
          columns: persistedColumns = defaultState.columns,
          filters: persistedFilters = defaultState.filters,
          sorter: persistedSorter = defaultState.sorter,
        } = persistedUiState || {};

        /**
         * check canUsePersistedUiState which tells us if its ok to use the fetched persisted state.
         * The specific scenario this is helpful for is if for some reason a fetch call
         * for our data failed when there is valid data in the db.We don't want to  accidentally
         * "reset" the table since persistedState would then be empty and falling back
         * to defaultState
         */
        const finalPrevState = canUsePersistedUiState
          ? ({
              ...prevState,
              columns: persistedColumns,
              filters: persistedFilters,
              sorter: persistedSorter,
            } satisfies TableState)
          : prevState;

        const updatedOpTableColumns = convertRawColumnsToColumns({
          rawColumns,
          shouldUsePersistedColumns: canUsePersistedUiState,
          hasHeaderFilter,
          resetRowSelections,
          tableState: finalPrevState,
          onFilterUpdate,
          persistedState: persistedUiState,
          /**
           * Helium uses default match logic which is "if it looks like a number then do a number
           * match, otherwise do a string match". Since we explicitly want a string match, we
           * should be sending an explicit substring-match operator ~ to helium so it will always
           * use string matching, not number matching.
           */
          isUsingSubstringMatch: true,
        });

        const newState = {
          ...prevState,
          columns: updatedOpTableColumns,
          ...(canUsePersistedUiState && {
            default: false,
            filters: persistedFilters,
            sorter: persistedSorter,
          }),
        };

        return newState;
      });
    }

    // We only want this hook to trigger once
  }, [
    canUsePersistedUiState,
    defaultState.columns,
    defaultState.filters,
    defaultState.sorter,
    hasHeaderFilter,
    isPersistedUiStateFetching,
    onFilterUpdate,
    persistedUiState,
    rawColumns,
    resetRowSelections,
    setState,
  ]);

  const { parametersArray, withQuery } = ensurePayloadAndQuery(
    opQueryProps.apiEndpointName,
    opQueryProps.parameters,
  );

  if (withQuery) {
    // Find the index of the queries (last object)
    const queriesIndex = parametersArray.findLastIndex(
      (param: any) => typeof param === 'object',
    );

    if (queriesIndex === -1) {
      // ensurePayloadAndQuery guarantees the presence of a queries object, so throw since something is way off...
      throw new Error(
        `Critical: ensurePayloadAndQuery did not ensure a query object for API endpoint ${opQueryProps.apiEndpointName}`,
      );
    }

    const queryStringParams = parametersArray[queriesIndex] as {
      limit?: number | undefined;
      offset?: number | undefined;
      sort?: string | undefined;
      order?: string | undefined;
      q?: string | undefined;
      filter?: string | undefined;
      options?: string | undefined;
    };

    const finalQueryStringParams = {
      ...queryStringParams,
      ...createQueryStringParamsFromTableState({
        tableState: debouncedState,
      }),
    };

    // Add the original queryStringParams filter to the end of the filters from table state
    if (queryStringParams.filter) {
      finalQueryStringParams.filter += ' ' + queryStringParams.filter;
    }

    // Add queryStringParams to their correct spot in parameters array
    parametersArray[queriesIndex] = finalQueryStringParams;
  }

  const finalQueryOptions = {
    ...opQueryProps,
    parameters: parametersArray,
  } as UseOpQueryOptions<T>;

  const queryResult = useOpQuery({
    placeholderData: keepPreviousData,
    ...finalQueryOptions,
  });

  // Filtering is done separately so not included
  // @ts-expect-error Fix these later
  const onChange = (_pagination, _filters, sorter) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        default: false,
        sorter,
      };

      setPersistedUiState(
        getStateToPersist({
          columns: newState.columns,
          filters: newState.filters,
          sorter: newState.sorter,
        }),
      );

      return newState;
    });
  };

  return {
    state, // Returning non-debounced state so it can be used to update UI immediately
    debouncedState, // Returning debounced state so we can use it for csv export
    setState,
    onChange,
    resetState: () => {
      setState(defaultState);
      resetPersistedUiState();
    },
    ...queryResult,
    uiKey,
    isFetching: queryResult.isFetching || isPersistedUiStateFetching,
    setPersistedUiState,
  };
};
