import Input from 'antd/es/input';
import type { InputRef } from 'antd/es/input/Input';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import './OpInput.scss';

interface OpSearchInputProps extends ComponentProps<typeof Input.Search> {
  testId?: string;
}

const OpSearchInput = ({
  className = '',
  testId = 'op-search-input',
  ...inputProps
}: OpSearchInputProps) => {
  return (
    <Input.Search
      className={clsx('op-search-input', className)}
      data-testid={testId}
      data-lpignore // Prevents LastPass autofill
      {...inputProps}
    />
  );
};

interface OpPasswordInputProps extends ComponentProps<typeof Input.Password> {
  testId?: string;
}

const OpPasswordInput = ({
  className = '',
  testId = 'op-password-input',
  ...inputProps
}: OpPasswordInputProps) => {
  return (
    <Input.Password
      className={clsx('op-password-input', className)}
      data-testid={testId}
      {...inputProps}
    />
  );
};

interface OpTextAreaInputProps extends ComponentProps<typeof Input.TextArea> {
  testId?: string;
}

const OpTextAreaInput = ({
  className,
  testId = 'op-text-area-input',
  ...inputProps
}: OpTextAreaInputProps) => {
  return (
    <Input.TextArea
      className={clsx('op-text-area-input', className)}
      data-testid={testId}
      data-lpignore // Prevents LastPass autofill
      {...inputProps}
    />
  );
};

interface OpRefInputProps extends ComponentProps<typeof Input> {
  testId?: string;
  gtm?: string;
}

const OpRefInput = forwardRef<InputRef, OpRefInputProps>(
  (
    {
      className = '',
      testId = 'op-ref-input',
      gtm,
      ...inputProps
    }: OpRefInputProps,
    ref,
  ) => {
    return (
      <Input
        ref={ref}
        className={clsx('op-ref-input', className)}
        data-testid={testId}
        data-gtm={gtm}
        data-lpignore // Prevents LastPass autofill
        {...inputProps}
      />
    );
  },
);

interface OpInputProps extends ComponentProps<typeof Input> {
  gtm?: string;
  testId?: string;
  allowLastPass?: boolean;
}

export const OpInput = ({
  gtm,
  testId = 'op-input',
  className,
  allowLastPass = false,
  ...inputProps
}: OpInputProps) => (
  <Input
    className={clsx('op-input', className)}
    data-gtm={gtm}
    data-testid={testId}
    data-lpignore={allowLastPass} // Prevents LastPass autofill
    {...inputProps}
  />
);

// Get all Components defined within Input
OpInput.RefInput = OpRefInput;
OpInput.Password = OpPasswordInput;
OpInput.Search = OpSearchInput;
OpInput.TextArea = OpTextAreaInput;
