import { fromJS } from 'immutable';
import { SET_LOCKDOWN_PLANS } from './constants';
// Initial access token state
const initialState = fromJS({
  lockdownPlanActive: null,
  lockdownPlans: fromJS([]),
});

function lockdownPlansReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCKDOWN_PLANS:
      return state.set('lockdownPlans', fromJS(action.plans));
    default:
      return state;
  }
}

export default lockdownPlansReducer;
