import { fromJS } from 'immutable';
import {
  ADD_BANNER_DATA,
  REMOVE_BANNER_BY_KEY,
  REMOVE_ALL_BANNERS,
} from './constants';
// Initial access token state
const bannerDataInitialState = fromJS({
  banners: [],
});

function bannerReducer(state = bannerDataInitialState, action) {
  switch (action?.type) {
    case REMOVE_BANNER_BY_KEY: {
      const i = state
        ?.get('banners')
        ?.findIndex((b) => b?.get('key') === action?.key);
      return i > -1
        ? state?.set('banners', state?.get('banners')?.remove(i))
        : state;
    }
    case ADD_BANNER_DATA: {
      // keys make banners unique
      if (action?.data?.key) {
        const i = state
          ?.get('banners')
          ?.findIndex((b) => b?.get('key') === action?.data?.key);
        if (i > -1) {
          return state;
        }
      }
      return state?.set(
        'banners',
        state?.get('banners')?.push(fromJS(action?.data)),
      );
    }
    case REMOVE_ALL_BANNERS:
      return bannerDataInitialState;
    default:
      return state;
  }
}

export default bannerReducer;
