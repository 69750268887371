import { getWindowLocation } from 'utils/window';

export const getOpParams = () => {
  const { pathname } = getWindowLocation();

  const orgIdRegex = /o\/[0-9]+/;

  const orgId = pathname.match(orgIdRegex)?.[0]?.split('/')[1] ?? 0;

  return { orgId: Number(orgId) };
};
