import {
  REQUEST_LIST_CLOUD_KEYS,
  SET_CLOUD_KEYS,
  REQUEST_UNLOCK_WITH_CLOUD_KEY,
} from './constants';

// requestUnlockWithCloudKey
export function requestUnlockWithCloudKey(entryId) {
  return {
    type: REQUEST_UNLOCK_WITH_CLOUD_KEY,
    entryId,
  };
}

export function requestListCloudKeyCredentials() {
  return {
    type: REQUEST_LIST_CLOUD_KEYS,
  };
}

export function setCloudKeys(cloudKeys) {
  return {
    type: SET_CLOUD_KEYS,
    cloudKeys,
  };
}
