import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { formatSecondsToClock } from 'utils/dates';

const TimeDisplay = memo(({ duration, currentTime }) => (
  <div className="time-display">
    {formatSecondsToClock(currentTime)}
    {isFinite(duration) ? ` / ${formatSecondsToClock(duration)}` : null}
  </div>
));

TimeDisplay.displayName = 'TimeDisplay';

TimeDisplay.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};

export default TimeDisplay;
