import { call, put, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import set from 'lodash/set';
import transform from 'lodash/transform';
import get from 'lodash/get';
import { compareVersions, validate as validateVersion } from 'compare-versions';

import Helium from 'utils/helium';
import { injectScopeHeaders } from 'utils/request';
import { setAlert } from 'routes/AppContainer/actions';
import * as Sentry from '@sentry/react';
import { appConfigurationRoute, videoProvidersRoute } from 'routes/constants';
import { parse } from 'query-string';
import { changeRoute } from 'routes/AuthenticatedContainer/actions';
import { requestUpsertIdentityProvider } from 'routes/IntegrationsPage/actions';
import * as yup from 'yup';
import { t } from 'i18next';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { HELIUM_LIMIT_MAX, UNIT_TO_SECONDS_LOOKUP_TABLE } from './constants';
import { getTokenHash, getTokenPayload } from './logging';
import { getAccessToken } from './accessToken';

const openpathConfig = require('openpathConfig');

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  let timeout;
  return (...args) => {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, args);
  };
}

// Returns a URI encoded query string
export function createQueryString(urlOptions) {
  return Object.keys(urlOptions)
    .map(
      (option) =>
        `${encodeURIComponent(option)}=${encodeURIComponent(
          urlOptions[option],
        )}`,
    )
    .join('&');
}

// Calculates the column width based on longest text length in a cell
// from that column
export const getColumnWidth = (
  rows,
  pathToAccessorString,
  headerText,
  { maxWidth, magicSpacing = 7.5, extraWidth = 0 } = {},
) => {
  const TOTAL_PADDING = 14;
  const MIN_WIDTH = 80;

  const stringLength = Math.max(
    ...rows.map((row) => {
      if (
        row?.pathToAccessorString === 'string' &&
        (typeof pathToAccessorString === 'string' ||
          Array.isArray(pathToAccessorString))
      ) {
        return row?.pathToAccessorString?.length;
      }

      return 0;
    }),
    headerText.length,
  );

  const width = Math.max(
    stringLength * magicSpacing + TOTAL_PADDING + extraWidth,
    MIN_WIDTH,
  );
  return maxWidth ? Math.min(maxWidth, width) : width;
};

// Lookup table for port settings
export const makePortOptionHumanReadable = (portOption) => {
  const lookupTable = {
    // Global
    name: t('Name'),
    mode: t('Mode'),
    isExit: t('Used as exit'),

    // Relay
    openDurationSeconds: t('Entry open duration (seconds)'),
    invertOutput: t('Output inverted'),

    // Reader
    capTouchRssi: t('Wave to unlock range'),
    autoUnlockRssi: t('Auto proximity unlock range'),
    advTxPower: t('Mobile reader range'),
    autoUnlockEnabled: t('Auto proximity unlock enabled'),
    capTouchEnabled: t('Wave To unlock enabled'),
    nfcEnabled: t('Card reading enabled'),
    softwareVersionNumber: t('Software version number'),
    serialNumber: t('Serial number'),
    beaconTxPower: t('Mobile beacon range'),

    // REX
    isTriggerRelayEnabled: t('Trigger relay to unlock entry enabled'),
    bypassDurationSeconds: t('Bypass duration (seconds)'),

    // Contact
    isAjarEnabled: t('Ajar Feature enabled'),
    ajarDurationSeconds: t('Ajar duration (seconds)'),
    isForcedOpenEnabled: t('Forced-Open detection enabled'),

    // Wiegand
    defaultCardNumBits: t('Default card number Bits'),
    isPassThroughEnabled: t('Gateway credential pass-through enabled'),
    defaultCardNumber: t('Default gateway card number'),

    // General Input
    description: t('Description'),
  };

  return lookupTable[portOption];
};

const heliumRequest = async (
  endpointName,
  requisites = [],
  options = {},
  isGetter = false,
) => {
  const {
    setIsLoading,

    // GET specific
    setter,
    loopToGetAll, // !This will set the limit to 1000 and loop through calls until ALL results are returned

    // POST, PATCH, PUT, DELETE specific
    payload,

    headers: _headers = {},
  } = options;
  // Make sure we're returning something that matches the API format so later crashes
  // are less likely to happen
  const result = {
    statusCode: null,
    data: null,
    errorMessage: null,
    localizedErrorMessage: null,
  };

  try {
    // Start loader
    if (setIsLoading) {
      setIsLoading(true);
    }

    const api = Helium.client[endpointName];

    // Check if valid API name
    if (!api) {
      result.errorMessage = `Invalid API endpoint name (${endpointName})`;
      result.localizedErrorMessage = t(
        'Invalid API endpoint name ({{endpointName}})',
        {
          endpointName,
        },
      );

      console.error(result.errorMessage);

      // Stop loader
      if (setIsLoading) {
        setIsLoading(false);
      }

      return result;
    }

    const headers = injectScopeHeaders(_headers, endpointName);

    // Add a limit when loopToGetAll is true so we don't error out on first call
    if (loopToGetAll) {
      set(options, 'queryStringParams.limit', HELIUM_LIMIT_MAX);
    }

    // We only pass a payload for non-getter API requests
    // Using options.queryStringParams here and not destructuring because if we destructure,
    // we do not assign to the prop being used in the recursive version
    const { statusCode, json } = isGetter
      ? await api(...requisites, options.queryStringParams, { headers })
      : await api(...requisites, payload, options.queryStringParams, {
          headers,
        });

    // Stop loader
    if (setIsLoading) {
      setIsLoading(false);
    }

    result.statusCode = statusCode;
    const errorMessage =
      statusCode === 500 ? 'Internal Error, Please Check Logs' : json?.message;
    const localizedErrorMessage = json?.localizedMessage || errorMessage;

    // check for special 418 error
    if (openpathConfig.INJECT_SCOPE_HEADERS && statusCode === 418) {
      result.errorMessage = `(heliumRequest) ${errorMessage}`;
      result.localizedErrorMessage = `(heliumRequest) ${errorMessage}`;
      return result;
    }

    // Return if error
    if (errorMessage || statusCode >= 400) {
      result.errorMessage = errorMessage || 'Error';
      result.localizedErrorMessage = localizedErrorMessage || t('Error');
      if (!errorMessage) {
        console.error(
          "An error message wasn't returned from Helium, so a generic error message was displayed",
        );
      }
      return result;
    }

    // We don't get json in the cases of some non GET API requests
    if (json) {
      const { data, filteredCount, totalCount, meta, cursors } = json;
      result.data = data;

      // Add GET specific params
      if (filteredCount !== undefined) result.filteredCount = filteredCount;
      if (totalCount !== undefined) result.totalCount = totalCount;
      if (meta !== undefined) result.meta = meta;
      if (cursors !== undefined) result.cursors = cursors;
    }

    // Get all the results if loopToGetAll has been passed
    if (loopToGetAll && result.filteredCount) {
      // Set offset to 0 if there is none so comparing to filteredCount works
      if (!options?.queryStringParams?.offset) {
        set(options, 'queryStringParams.offset', HELIUM_LIMIT_MAX);
      } else {
        // eslint-disable-next-line no-param-reassign
        options.queryStringParams.offset += HELIUM_LIMIT_MAX;
      }

      // TODO - add percentage to response if we ever need it
      // Recursively loop through calls to get all the data
      if (options.queryStringParams.offset <= result.filteredCount) {
        const res = await heliumRequest(
          endpointName,
          requisites,
          options,
          true,
        );

        // If we don't have data something went wrong and we return
        if (!res.data) {
          return result;
        }

        // Add the data retrieved to the collected data thus far (we format it here)
        result.data = [...result.data, ...res.data];
      }
    }

    if (setter) {
      if (Array.isArray(setter)) {
        for (let i = 0; i < setter.length; i += 1) {
          setter[i]?.(result);
        }
      } else {
        setter(result);
      }
    }

    return result;
  } catch (ex) {
    console.error(
      `Something went wrong in heliumRequest, ${endpointName}, ${ex}`,
    );

    const jwt = getAccessToken();
    const tokenHash = await getTokenHash(jwt);
    const userData = getTokenPayload(jwt);
    Sentry.withScope((scope) => {
      scope.setFingerprint(['heliumRequest', endpointName]);
      Sentry.captureException(ex, {
        extra: {
          apiInfo: { endpointName, requisites },
          userData,
          tokenHash,
        },
      });
    });
    result.errorMessage = `Internal Error, Please Check Logs - ${ex}`;
    result.localizedErrorMessage = `Internal Error, Please Check Logs - ${ex}`;
    return result;
  }
};

export const heliumReceive = async (...args) => {
  if (args.length > 3) {
    console.error('requestAndSet() - Too many arguments passed');
  }
  const [endpointName, requisites = [], options = {}] = args;
  return heliumRequest(endpointName, requisites, options, true);
};

export const heliumSend = async (endpointName, requisites, payload, options) =>
  heliumRequest(endpointName, requisites, { ...options, payload });

function* heliumRequestSaga(
  endpointName,
  requisites = [],
  rawOptions = {},
  isGetter = false,
) {
  // We modify options and we don't want to modify the function param directly
  const options = { ...rawOptions };

  // Create the schema to validate options and make sure we aren't passing something by mistake
  const optionsSchema = yup
    .object({
      loadingAction: yup.object(),
      suppressErrorMessage: yup.boolean(),
      successMessage: yup.string(),
      onSuccessSaga: yup.object(),

      // GET specific
      createSetterAction: yup.lazy((value) =>
        Array.isArray(value) ? yup.array() : yup.object(),
      ),
      loopToGetAll: yup.boolean(),
      queryStringParams: yup.object().shape({
        limit: yup.number(),
        offset: yup.number(),
        filter: yup.string(),
        q: yup.string(),
        sort: yup.string(),
      }),

      // POST, PATCH, PUT, DELETE specific
      payload: yup.lazy((value) =>
        Array.isArray(value) ? yup.array() : yup.object().nullable(),
      ),
    })
    .noUnknown(true)
    .strict();

  // Parse and assert validity on the schema
  try {
    yield optionsSchema.validate(options);
  } catch (err) {
    console.error(`${err.message} (${endpointName})`);
  }

  const {
    loadingAction,
    suppressErrorMessage,
    successMessage,
    onSuccessSaga,

    // GET specific
    createSetterAction,
    loopToGetAll, // !This will set the limit to 1000 and loop through calls until ALL results are returned

    // POST, PATCH, PUT, DELETE specific
    payload,
  } = options;

  // Make sure we're returning something that matches the API format so later crashes
  // are less likely to happen
  const result = {
    statusCode: null,
    data: null,
    errorMessage: null,
    localizedErrorMessage: null,
  };

  try {
    // Start loader
    if (loadingAction) yield put(loadingAction(true));

    const currentIdentityLanguage = yield select(
      selectCurrentIdentityLanguage(),
    );

    const api = Helium.client.withHeaders({
      'Accept-Language': currentIdentityLanguage,
    })[endpointName];

    // Check if valid API name
    if (!api) {
      result.errorMessage = `Invalid API endpoint name (${endpointName})`;
      result.localizedErrorMessage = `Invalid API endpoint name (${endpointName})`;
      yield put(setAlert('error', result.localizedErrorMessage));

      // Stop loader
      if (loadingAction) yield put(loadingAction(false));

      return result;
    }

    const headers = injectScopeHeaders({}, endpointName);

    // Add a limit when loopToGetAll is true so we don't error out on first call
    if (loopToGetAll) {
      set(options, 'queryStringParams.limit', HELIUM_LIMIT_MAX);
    }

    // We only pass a payload for non-getter API requests
    const { statusCode, json } = isGetter
      ? yield call(api, ...requisites, options.queryStringParams, { headers })
      : yield call(api, ...requisites, payload, options.queryStringParams, {
          headers,
        });

    // Stop loader
    if (loadingAction) {
      yield put(loadingAction(false));
    }

    result.statusCode = statusCode;
    const errorMessage =
      statusCode === 500 ? 'Internal Error, Please Check Logs' : json?.message;
    const localizedErrorMessage = json?.localizedMessage || errorMessage;

    // check for special 418 error
    if (openpathConfig.INJECT_SCOPE_HEADERS && statusCode === 418) {
      yield put(setAlert('info', `(heliumRequestSaga) ${errorMessage}`));
      return result;
    }

    // Return if error
    if (errorMessage || statusCode >= 400) {
      result.errorMessage = errorMessage || 'Error';
      result.localizedErrorMessage = localizedErrorMessage || t('Error');
      if (!suppressErrorMessage) {
        if (!errorMessage) {
          console.error(
            "An error message wasn't returned from Helium, so a generic error message was displayed",
          );
        }
        yield put(setAlert('error', result.localizedErrorMessage));
      }
      return result;
    }

    // We don't get json in the cases of some non GET API requests
    if (json) {
      const { data, filteredCount, totalCount, meta, cursors } = json;
      result.data = data;

      // Add GET specific params
      if (filteredCount !== undefined) result.filteredCount = filteredCount;
      if (totalCount !== undefined) result.totalCount = totalCount;
      if (meta !== undefined) result.meta = meta;
      if (cursors !== undefined) result.cursors = cursors;
    }

    // Get all the results if loopToGetAll has been passed
    if (loopToGetAll && result.filteredCount) {
      // Set offset to 0 if there is none so comparing to filteredCount works
      if (!options?.queryStringParams?.offset) {
        set(options, 'queryStringParams.offset', HELIUM_LIMIT_MAX);
      } else {
        options.queryStringParams.offset += HELIUM_LIMIT_MAX;
      }

      // TODO - add percentage to response if we ever need it
      // Recursively loop through calls to get all the data
      if (options.queryStringParams.offset <= result.filteredCount) {
        const res = yield call(
          heliumRequestSaga,
          endpointName,
          requisites,
          options,
          true,
        );

        // If we don't have data something went wrong and we return
        if (!res.data) {
          return result;
        }

        // Add the data retrieved to the collected data thus far (we format it here)
        result.data = [...result.data, ...res.data];
      }
    }

    if (createSetterAction) {
      if (Array.isArray(createSetterAction)) {
        for (let i = 0; i < createSetterAction.length; i += 1) {
          const action = createSetterAction[i]?.(result);
          if (typeof action !== 'object') {
            throw Error(
              `\`createSetterAction\` is not returning an action (${action})`,
            );
          }
          yield put(action);
        }
      } else {
        const action = createSetterAction(result);
        if (typeof action !== 'object') {
          throw Error(
            `\`createSetterAction\` is not returning an action (${action})`,
          );
        }
        yield put(action);
      }
    }

    if (successMessage) {
      yield put(
        setAlert(
          'success',
          typeof successMessage === 'string'
            ? successMessage
            : successMessage(result),
        ),
      );
    }

    if (onSuccessSaga) {
      yield call(onSuccessSaga, result);
    }

    return result;
  } catch (ex) {
    result.errorMessage = ex;
    result.localizedErrorMessage = ex;
    if (!suppressErrorMessage) {
      yield put(setAlert('error', `Internal Error, Please Check Logs - ${ex}`));
    }
    console.error(
      `Something went wrong in heliumRequestSaga, ${endpointName}, ${ex}`,
    );

    const jwt = getAccessToken();
    const tokenHash = yield call(getTokenHash, jwt);
    const userData = yield call(getTokenPayload, jwt);
    Sentry.withScope((scope) => {
      scope.setFingerprint(['heliumRequestSaga', endpointName]);
      Sentry.captureException(ex, {
        extra: {
          apiInfo: { endpointName, requisites },
          userData,
          tokenHash,
        },
      });
    });

    return result;
  }
}

export function* requestAndSet(...args) {
  if (args.length > 3) {
    console.error('requestAndSet() - Too many arguments passed');
  }
  const [endpointName, requisites = [], options = {}] = args;
  return yield call(heliumRequestSaga, endpointName, requisites, options, true);
}

export function* sendToHelium(endpointName, requisites, payload, options) {
  return yield call(heliumRequestSaga, endpointName, requisites, {
    ...options,
    payload,
  });
}

// TODO: look into why this didn't work correctly on normal sort
export const tableSort =
  (pathToStringArray = []) =>
  (a, b, accessor) => {
    const first =
      typeof a?.values?.[accessor] === 'string'
        ? a?.values?.[accessor]
        : get(a, ['values', accessor, ...pathToStringArray]);
    const second =
      typeof b?.values?.[accessor] === 'string'
        ? b?.values?.[accessor]
        : get(b, ['values', accessor, ...pathToStringArray]);
    if (first === second) return 0;
    return isNaN(first - second) ? (first > second ? 1 : -1) : first - second;
  };

export const tableFilter =
  (pathToStringArray) => (data, columnAccessor, filterValue) =>
    data.filter((row) => {
      let string = '';
      if (typeof get(row, ['values', columnAccessor]) === 'string') {
        string = get(row, ['values', columnAccessor]);
      } else {
        string = get(row, ['values', columnAccessor, ...pathToStringArray]);
      }
      const regex = new RegExp(filterValue, 'gi');
      return regex.test(string);
    });

export function isJsonString(str) {
  if (!str) return false; // prevents null from passing
  try {
    const json = JSON.parse(str);
    return typeof json === 'object';
  } catch {
    return false;
  }
}

// Used for DurationPicker
export const secondsToTimeAndUnit = (seconds, units) => {
  const secondsArray = units
    .map((unit) => UNIT_TO_SECONDS_LOOKUP_TABLE[unit])
    .reverse();
  let time;
  let unit;

  // Loop through unit values (seconds) from greatest to least in order
  // to get largest unit that goes evenly into stored seconds
  for (let i = 0; i < secondsArray.length; i += 1) {
    if (seconds % secondsArray[i] === 0) {
      time = seconds / secondsArray[i];
      unit = secondsArray[i];
      break;
    }
  }

  return { time, unit };
};

export function includesAnyPortalScope(scopes) {
  return scopes.some((s) => !s.match(/^o[0-9]+-user[0-9]+:/));
}

export const convertUrlToBase64 = async (url, urlIsCacheable = false) => {
  try {
    // Append the dynamic query string to prevent caching and thus prevent CORS issues
    const urlToFetch = urlIsCacheable ? url : `${url}?nocache=${Date.now()}`;

    const response = await fetch(urlToFetch);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ base64: reader.result, status: response.status });
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (e) {
    console.error(e);
    return new Promise((resolve) => {
      resolve({ base64: '', status: 401 });
    });
  }
};

export const getFileTypeFromBase64 = (base64) => {
  let result = null;

  if (typeof base64 !== 'string') {
    return result;
  }

  const mime = base64.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime?.length) {
    result = /(\w+)\/(\w+)/g.exec(mime[1])[2];
  }

  return result;
};

export const visualActivityEventMatchesActivityEvent = ({
  eventTime,
  vaEventTime,
  eventEntryId = 0, // By defaulting to 0 we prevent undefined === undefined
  vaEventEntryId,
  eventCameraIds = [],
  vaCameraId,
  variability = 5,
}) =>
  Math.abs(eventTime - vaEventTime) <= variability && // Only show visual activity close to event time
  (eventEntryId === vaEventEntryId || eventCameraIds.includes(vaCameraId)); // Only show visual activity that matches this event's entryId or cameraIds

// parse config 'T'/'F' -> true/false
export const parseObjBools = (obj) => {
  if (obj !== Object(obj)) return obj;

  const parsedConfig = cloneDeep(obj);
  Reflect.ownKeys(parsedConfig).forEach((key) => {
    const value = parsedConfig[key];
    if (value === 'T' || value === 'F') {
      parsedConfig[key] = value === 'T';
    } else {
      parsedConfig[key] = parseObjBools(value);
    }
  });
  return parsedConfig;
};

// reverse parses config 'T'/'F' <- true/false
export const revParseObjBools = (obj) => {
  if (obj !== Object(obj)) return obj;

  // eslint-disable-next-line
  console.log('revParseObjBools:::', obj);

  const parsedConfig = cloneDeep(obj);
  Reflect.ownKeys(parsedConfig).forEach((key) => {
    const value = parsedConfig[key];
    if (typeof value === 'boolean') {
      parsedConfig[key] = value === true ? 'T' : 'F';
    } else {
      // eslint-disable-next-line
      console.log('parsedConfig', parsedConfig);
      // eslint-disable-next-line
      console.log('key', key);
      parsedConfig[key] = revParseObjBools(value);
    }
  });
  return parsedConfig;
};

// FIXME: This function does not need currying due to it's implementation / how it is used
/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export const deepDiff = (origObj, newObj) => {
  const changes = (newObj2, origObj2) => {
    let arrayIndexCounter = 0;
    return transform(newObj2, (result, value, key) => {
      if (!isEqual(value, origObj2[key])) {
        const resultKey = Array.isArray(origObj2) ? arrayIndexCounter++ : key;
        // eslint-disable-next-line no-param-reassign
        result[resultKey] =
          isObject(value) && isObject(origObj2[key])
            ? changes(value, origObj2[key])
            : value;
      }
    });
  };
  return changes(newObj, origObj);
};

/** This is used on the App Marketplace pages in order to determine
 * what to do when attempting to configure the installed app
 * */
export const createInstalledAppLogicForButtonClick = ({
  orgId,
  appId,
  configureUrl,
  dispatch,
  setModalVideoProviderTypeId,
  currentIdentityLanguage,
}) => {
  if (!configureUrl) {
    dispatch(changeRoute(`${appConfigurationRoute.replace(/:appId/, appId)}`));
    return;
  }

  const parsedUrl = parse(configureUrl.split('?')[1]);

  const {
    ipt: idptCodeLegacy, // TODO = remove when helium deployed
    idptCode,
    integration,
  } = parsedUrl;

  let { idptId } = parsedUrl;

  if (integration === 'Meraki') {
    // In this case we are dealing with Meraki video provider
    // Get the org video providers in order to see if Meraki has been created
    (async () => {
      const { data, errorMessage } = await heliumReceive(
        'listVideoProviders',
        [orgId],
        {
          headers: {
            'Accept-Language': currentIdentityLanguage,
          },
        },
      );

      if (errorMessage) {
        return;
      }

      const { id: merakiVideoProviderId } =
        data.find(({ videoProviderTypeId }) => videoProviderTypeId === 1) || {};

      if (merakiVideoProviderId) {
        // If Meraki has been created go to the video provider route
        dispatch(
          changeRoute(
            `${videoProvidersRoute.replace(
              /:videoProviderId/,
              merakiVideoProviderId,
            )}`,
          ),
        );
      } else {
        // If it hasn't then open that modal to create it
        setModalVideoProviderTypeId(1);
      }
    })();
  } else if (integration === 'Ava') {
    // Get the org video providers in order to see if Meraki has been created
    (async () => {
      const { data, errorMessage } = await heliumReceive(
        'listVideoProviders',
        [orgId],
        {
          headers: {
            'Accept-Language': currentIdentityLanguage,
          },
        },
      );
      if (errorMessage) {
        return;
      }

      const { id: avaVideoProviderId } =
        data.find(({ videoProviderTypeId }) => videoProviderTypeId === 5) || {};

      if (avaVideoProviderId) {
        // If Avigilon Alta Aware has been created go to the video provider route
        dispatch(
          changeRoute(
            `${videoProvidersRoute.replace(
              /:videoProviderId/,
              avaVideoProviderId,
            )}`,
          ),
        );
      } else {
        // If it hasn't then open that modal to create it
        setModalVideoProviderTypeId(5);
      }
    })();
  } else if (idptCode || idptCodeLegacy) {
    // In this case we are dealing with an IDP
    // We must use this until Helium changes are in prod and we can grab the idptId directly from the configureUrl
    // TODO - remove this when Helium changes are out
    if (!idptId) {
      switch (idptCodeLegacy) {
        case 'gsuite':
          idptId = 1;
          break;
        case 'msazuread':
          idptId = 2;
          break;
        case 'okta':
          idptId = 3;
          break;
        case 'onelogin':
          idptId = 4;
          break;
        case 'workday':
          idptId = 5;
          break;
      }
    }

    dispatch(
      requestUpsertIdentityProvider({
        idpt: {
          code: idptCode || idptCodeLegacy,
          id: idptId,
        },
      }),
    );
  } else {
    // In the rest of the cases we simply go to the configureUrl
    dispatch(changeRoute(configureUrl));
  }
};

/** Checks if valid json */
export const determineIfIsValidJsonString = (jsonString) => {
  if (!(jsonString && typeof jsonString === 'string')) {
    return false;
  }

  try {
    JSON.parse(jsonString);
    return true;
  } catch {
    return false;
  }
};

export const dedupe = (
  // Array that you want to dedupe
  arr,

  // Array of strings that reprisent the keys of which you want to remove duplicates
  // If nothing is passed it will assume that it is a flat array (no objects)
  compArr,
) =>
  arr.filter(
    (val, i, self) =>
      i ===
      self.findIndex((o) =>
        compArr ? compArr.every((e) => o[e] === val[e]) : o === val,
      ),
  );

export const dedupeImmutable = (
  // Immutable List that you want to dedupe
  arr,

  // Array of strings that reprisent the keys of which you want to remove duplicates
  // If nothing is passed it will assume that it is a flat List (no Maps)
  compArr,
) =>
  arr.filter(
    (val, i, self) =>
      i ===
      self.findIndex((o) =>
        compArr ? compArr.every((e) => o.get(e) === val.get(e)) : o === val,
      ),
  );

export const meetsMinimumVersion = ({
  version,
  minimumVersion,
  skipVersionCheck = false,
}) => {
  // you can use the skipVersionCheck param to skip over undefined/null values for example
  if (skipVersionCheck) {
    return true;
  }

  if (!validateVersion(version) || !validateVersion(minimumVersion)) {
    return false;
  }

  try {
    const result = compareVersions(version, minimumVersion);
    // 0 === equal versions
    // 1 === greater version
    return [0, 1].includes(result);
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
};
