import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const P1Svg = ({ ...svgProps }) => (
  <SvgWrapper
    width="19.058823529411764"
    height="18"
    viewBox="0 0 72 68"
    fill="none"
    {...svgProps}
  >
    <path
      d="M29.5972 5.79268L29.597 5.79291L2.97046 53.7348C2.97042 53.7348 2.97038 53.7349 2.97035 53.735C0.24937 58.6332 3.57957 65.0017 9.33256 65.0017H62.5853C68.3255 65.0017 71.6749 58.6447 68.9475 53.735C68.9475 53.7349 68.9474 53.7348 68.9474 53.7348L42.3215 5.79292L42.3213 5.79266C39.515 0.741374 32.4097 0.730182 29.5972 5.79268Z"
      strokeWidth="4"
    />
    <path
      d="M22.37 33.41H28.91C31.27 33.41 33.14 33.9 34.52 34.88C35.92 35.84 36.62 37.42 36.62 39.62C36.62 41.78 35.91 43.41 34.49 44.51C33.07 45.61 31.25 46.16 29.03 46.16H26.48V53H22.37V33.41ZM28.76 42.89C31.32 42.89 32.6 41.8 32.6 39.62C32.6 38.58 32.27 37.83 31.61 37.37C30.97 36.91 29.98 36.68 28.64 36.68H26.48V42.89H28.76ZM38.9539 49.67H43.1539V38.15H39.6139V35.6C40.5939 35.42 41.4439 35.2 42.1639 34.94C42.8839 34.68 43.5739 34.34 44.2339 33.92H47.2639V49.67H50.8939V53H38.9539V49.67Z"
      fill={svgProps.stroke || 'black'}
    />
  </SvgWrapper>
);
