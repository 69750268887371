import { useHasFeatures } from './useHasFeatures';

export const useShowSitePartitioningItems = (orgId: number) => {
  const {
    hasFeatures: orgHasUserSitePartitioningFeature,
    isLoading: areFeaturesLoading,
  } = useHasFeatures(orgId, ['userSitePartitioning']);

  return {
    isShowingSitePartitioningItems: Boolean(orgHasUserSitePartitioningFeature),
    isLoading: areFeaturesLoading,
  };
};
