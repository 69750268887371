import React, { memo, useMemo } from 'react';
import { Popup } from 'components/Popup';
import { useTranslation } from 'react-i18next';
import { Layers, SoundEffects, WebAudio } from 'utils/audio';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { PhoneFilledSvg } from 'components/svgs/PhoneFilledSvg/PhoneFilledSvg';
import { callStates, connectionStates } from '../constants';

const styles = {
  wrapper: { paddingTop: 9 },
  button: { transform: 'rotate(135deg)' },
};

const CALL_ICONS = {
  ERROR: <WarningOutlined />,
  START_CALL: <PhoneFilledSvg />,
  END_CALL: <PhoneFilledSvg style={{ transform: 'rotate(135deg)' }} />,
  NO_ICON: null,
};

/*
  ConnectionState models the actual webRTC connection state of an ongoing call
  CallState determines if the call is in "Preview mode" (no mic) or "Call mode" (mic enabled)
*/

interface WebRtcButtonProps {
  onClick(): void;
  connectionState: keyof typeof connectionStates;
  callState: keyof typeof callStates | null;
  legacyMode?: boolean;
  micPermission: string | null;
}

const WebRtcButton = memo(
  ({
    onClick,
    connectionState,
    callState = null,
    legacyMode = false,
    micPermission,
  }: WebRtcButtonProps) => {
    const { t } = useTranslation();

    const icon = useMemo(() => {
      switch (connectionState) {
        case connectionStates.CONNECTED:
          if (callState === callStates.PREVIEW) {
            if (micPermission === 'denied') {
              return CALL_ICONS.ERROR;
            }
            return CALL_ICONS.START_CALL;
          }
          if (callState === callStates.CALL) {
            return CALL_ICONS.END_CALL;
          }
          break;
        case connectionStates.CONNECTING:
        case connectionStates.DISCONNECTED:
        case connectionStates.SHOULD_RECONNECT:
          return CALL_ICONS.NO_ICON;
        case connectionStates.CONNECTION_ERROR:
          return CALL_ICONS.ERROR;
        default:
          return CALL_ICONS.ERROR;
      }
    }, [connectionState, callState, micPermission]);

    const popupContent = useMemo(() => {
      switch (connectionState) {
        case connectionStates.CONNECTED:
          if (callState === callStates.PREVIEW) {
            if (micPermission === 'denied') {
              return t('Browser microphone permission denied');
            }

            return t('Start call');
          }
          if (callState === callStates.CALL) {
            return t('End call');
          }
          break;
        case connectionStates.CONNECTING:
        case connectionStates.DISCONNECTED:
        case connectionStates.SHOULD_RECONNECT:
          return t('Loading...');
        case connectionStates.CONNECTION_ERROR:
          return t('Connection Error');
        default:
          return t('Unknown state');
      }
    }, [connectionState, callState, t, micPermission]);

    return (
      <Popup
        className="web-rtc-button"
        trigger={
          <div
            style={styles.wrapper}
            className="control-button op-cursor-pointer op-flex-direction-column op-align-items-center"
          >
            <OpButton
              loading={
                connectionState === connectionStates.CONNECTING ||
                (!legacyMode &&
                  connectionState === connectionStates.DISCONNECTED)
              }
              data-testid="web-rtc-button"
              shape="circle"
              size="large"
              type="primary"
              danger={
                connectionState === connectionStates.CONNECTION_ERROR ||
                (connectionState === connectionStates.CONNECTED &&
                  ((!legacyMode && callState === callStates.CALL) ||
                    legacyMode))
              }
              icon={icon}
              onClick={() => {
                if (micPermission === 'denied') {
                  // todo
                } else {
                  onClick();
                  WebAudio.stop(
                    SoundEffects.INTERCOM_NOTIFICATION,
                    Layers.INTERCOM_NOTIFICATIONS,
                  );
                }
              }}
              {...(connectionState === connectionStates.CONNECTED &&
                (legacyMode ||
                  (!legacyMode && callState === callStates.CALL)) && {
                  style: styles.button,
                })}
            />
          </div>
        }
        content={popupContent}
        position="bottom center"
      />
    );
  },
);

export default WebRtcButton;
