import { fromJS } from 'immutable';
import {
  SET_CURRENT_ORG_NAME,
  LOGOUT_SUCCESS,
} from 'routes/AppContainer/constants';
import {
  SET_ACCESS_TOKEN,
  SET_PROCESSED_SCOPES,
  SET_REMOTE_FEATURE_FLAGS,
  SET_MFA_CREDENTIALS,
} from './constants';
// Initial access token state
const accessTokenInitialState = fromJS({
  identity: fromJS({}),
  scopes: fromJS([]),
  expiresAt: null,
  processedScopes: fromJS([]),
  remoteFeatureFlags: fromJS({
    null: [],
  }),
  mfaCredentials: fromJS([]),
});

function accessTokenReducer(state = accessTokenInitialState, action) {
  switch (action.type) {
    case SET_REMOTE_FEATURE_FLAGS:
      // return state.setIn(['remoteFeatureFlags', action.orgId, action.key], fromJS(action.value))
      return state.setIn(
        ['remoteFeatureFlags', action.orgId],
        state
          .getIn(['remoteFeatureFlags', action.orgId], fromJS({}))
          .merge(action.flags),
      );
    case LOGOUT_SUCCESS:
      return accessTokenInitialState;
    case SET_CURRENT_ORG_NAME: {
      const index = state
        .get('scopes')
        .findIndex(
          (s) => Number(s.getIn(['org', 'id'])) === Number(action.orgId),
        );
      // list = list.setIn([index, "count"], 4)
      return state.setIn(['scopes', index, 'org', 'name'], action.orgName);
    }

    case SET_ACCESS_TOKEN:
      return state
        .set('identity', fromJS(action.data.identity))
        .set('scopes', fromJS(action.data.tokenScopeList))
        .set('expiresAt', action.data.expiresAt)
        .set('token', action.data.token);
    case SET_PROCESSED_SCOPES:
      return state.set('processedScopes', fromJS(action.scopes));
    case SET_MFA_CREDENTIALS: {
      return state.set('mfaCredentials', fromJS(action.mfaCredentials));
    }
    default:
      return state;
  }
}

export default accessTokenReducer;
