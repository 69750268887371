import clsx from 'clsx';
import { CustomIconComponentProps, OpIcon } from 'new-components/OpIcon/OpIcon';
import { CircleIcon } from 'new-components/svgs/CircleIcon';

import './OpCircleIcon.scss';

export const OpCircleIcon = ({ className }: CustomIconComponentProps) => {
  return (
    <OpIcon
      component={CircleIcon}
      className={clsx('op-circle-icon', className)}
    />
  );
};
