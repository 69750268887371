import { useEffect, useRef, useState } from 'react';
import { DownloadSvg } from 'components/svgs/DownloadSvg';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { opTime } from 'utils/dates';
import { LinkProps } from 'react-csv/components/Link';
import { OpTooltip } from 'new-components/DLS/OpTooltip/OpTooltip';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import set from 'lodash/set';
import get from 'lodash/get';
import { OpTableColumn } from '../OpTableCore';

// Define the type for the CSVLink ref
type CSVLinkRef = CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement };

interface CsvExportButtonProps extends Omit<LinkProps, 'data'> {
  isLoading?: boolean;
  onClick?(): void;
  columns: OpTableColumn[];
  gtm?: string;
  testId?: string;
  data: Record<string, any>[];
}

export const CsvExportButton = ({
  isLoading = false,
  onClick = () => {},
  data,
  columns,
  filename = `Table-Data_${opTime().format('YYYY-MM-DD_HH-mm')}`,
  gtm,
  testId,
}: CsvExportButtonProps) => {
  const { t } = useTranslation();
  const csvLinkRef = useRef<CSVLinkRef>(null);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked && !isLoading) {
      csvLinkRef?.current?.link.click();
      setIsClicked(false);
    }
  }, [isClicked, isLoading, data]);

  const onExportButtonClick = () => {
    // Typically used to manually fire the queries
    onClick();

    /** Need to wrap in timeout so that the click event doesn't
     * fire before the isFetching flag changes on the query */
    setTimeout(() => {
      setIsClicked(true);
    }, 0);
  };

  const headers = columns
    .filter(({ hidden }) => !hidden)
    .map(({ key, label }) => ({
      key: String(key),
      label: String(label),
    }));

  const sanitizedData = data.map((row) => {
    headers.forEach(({ key }) => {
      const value = get(row, key);

      /**
       * If the value at the path is a string, escape double quotes
       * so that commas after double quotes are not treated as delimiters
       */
      if (typeof value === 'string') {
        set(row, key, value.replace(/"/g, '""'));
      }
    });

    return row;
  });

  return (
    <>
      <OpTooltip placement="topRight" title={t('Export to CSV')}>
        <OpButton
          testId={testId}
          gtm={gtm && `${gtm}-export`}
          size="large"
          shape="circle"
          loading={isLoading}
          icon={<DownloadSvg />}
          onClick={onExportButtonClick}
        />
      </OpTooltip>
      {isClicked && (
        <CSVLink
          ref={csvLinkRef}
          data={sanitizedData}
          headers={headers}
          filename={`${filename}.csv`}
        />
      )}
    </>
  );
};
