import { ComponentProps } from 'react';
import clsx from 'clsx';
import Carousel from 'antd/es/carousel';

interface OpCarouselProps extends ComponentProps<typeof Carousel> {
  testId?: string;
}

export const OpCarousel = ({
  className,
  testId = 'op-carousel',
  children,
  ...carouselProps
}: OpCarouselProps) => {
  return (
    <Carousel
      className={clsx('op-carousel', className)}
      data-testid={testId}
      {...carouselProps}
    >
      {children}
    </Carousel>
  );
};
