import { ReactNode } from 'react';
import { useValidateScopes } from 'utils/customHooks/useValidateScopes';

interface OpScopeGuardProps {
  allowedScopes: string[];
  element: ReactNode;
  fallback?: ReactNode | null;
}

/**
 * @component
 * @description A wrapper component that validates scopes for a page
 * @param allowedScopes - list of scopes allowed for the page
 * @param element - element to render if the user's scopes are valid
 * @param fallback - element to render if the user's scopes are invalid. Defaults to 404 page
 */
export const OpScopeGuard = ({
  allowedScopes,
  element,
  fallback = null,
}: OpScopeGuardProps) => {
  // Validate the user's scopes for the page
  const { isValid: doesUserHaveRights, isLoading } = useValidateScopes({
    allowedScopes,
  });

  // return null while loading
  if (isLoading) return null;

  // render either the page or the fallback depending on if the user has valid scopes
  return doesUserHaveRights ? element : fallback;
};
