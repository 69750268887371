import { CustomIconComponentProps, OpIcon } from 'new-components/OpIcon/OpIcon';

import './OpDeviceStatusIcon.scss';

interface OpDeviceStatusIconProps extends CustomIconComponentProps {
  status:
    | 'connected'
    | 'disconnected'
    | 'warning'
    | 'muted'
    | 'advertising'
    | 'unknown'
    | 'offlineLock';
}

export const OpDeviceStatusIcon = ({
  status,
  ...restProps
}: OpDeviceStatusIconProps) => {
  if (status === 'connected' || status === 'unknown') {
    return (
      <OpIcon.Circle
        className={`op-device-status-icon ${status}`}
        {...restProps}
      />
    );
  }

  if (status === 'disconnected' || status === 'offlineLock') {
    return (
      <OpIcon.XMark
        className={`op-device-status-icon ${status}`}
        {...restProps}
      />
    );
  }

  if (status === 'warning') {
    return (
      <OpIcon.Exclamation
        className={`op-device-status-icon ${status}`}
        {...restProps}
      />
    );
  }

  if (status === 'muted' || status === 'advertising') {
    return (
      <OpIcon.Donut
        className={`op-device-status-icon ${status}`}
        {...restProps}
      />
    );
  }
};
