import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';

interface SuirRemovedUiContextProps {
  hasUserSelectedSuirRemovedUi?: boolean;
  setHasUserSelectedSuirRemovedUi: (value: boolean) => void;
  hasSuirRemovedUiFeature: boolean | null | undefined;
}

const SuirRemovedUiContext = createContext<
  SuirRemovedUiContextProps | undefined
>(undefined);

export const SuirRemovedUiProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const hasSuirRemovedUiFeature = useSelector(
    selectFeatureFlag('IS_SUIR_REMOVED'),
  );

  const [hasUserSelectedSuirRemovedUi, setHasUserSelectedSuirRemovedUi] =
    useLocalStorage<boolean>('hasUserSelectedSuirRemovedUi', false);

  const contextValue = useMemo(
    () => ({
      hasUserSelectedSuirRemovedUi,
      setHasUserSelectedSuirRemovedUi,
      hasSuirRemovedUiFeature,
    }),
    [
      hasUserSelectedSuirRemovedUi,
      hasSuirRemovedUiFeature,
      setHasUserSelectedSuirRemovedUi,
    ],
  );

  return (
    <SuirRemovedUiContext.Provider value={contextValue}>
      {children}
    </SuirRemovedUiContext.Provider>
  );
};

export const useSuirRemovedUi = (): SuirRemovedUiContextProps => {
  const context = useContext(SuirRemovedUiContext);
  if (!context) {
    throw new Error(
      'useSuirRemovedUi must be used within a SuirRemovedUiProvider',
    );
  }
  return context;
};
