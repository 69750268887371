import { ComponentProps } from 'react';
import Spin from 'antd/es/spin';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import clsx from 'clsx';

import './OpSpin.scss';

export const OpSpin = ({
  className,
  ...spinProps
}: ComponentProps<typeof Spin>) => {
  return (
    <Spin
      className={clsx('op-spin', className)}
      indicator={<LoadingOutlined />}
      size="large"
      {...spinProps}
    />
  );
};
