import { fromJS } from 'immutable';
import {
  ADD_SLIDE_OUT,
  DELETE_SLIDE_OUT,
  REPLACE_SLIDE_OUT,
} from './constants';

const initialSlideOutsState = fromJS([]);

function slideOutsReducer(state = initialSlideOutsState, action) {
  switch (action.type) {
    case ADD_SLIDE_OUT: {
      return state.push(fromJS(action.slideOutData));
    }
    case REPLACE_SLIDE_OUT:
      return state.pop().push(fromJS(action.slideOutData));
    case DELETE_SLIDE_OUT: {
      // TEMP to support deleting individual slideouts
      if (action?.slideOutData?.path) {
        return state.filter(
          (s) => s.getIn(['routeConfig', 'path']) !== action.slideOutData.path,
        );
      }
      return state.pop();
    }
    default:
      return state;
  }
}

export default slideOutsReducer;
