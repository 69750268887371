import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const GlobeSvg = ({ ...svgProps }) => (
  <SvgWrapper {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16C8.538 16 7.454 13.618 7.454 9C7.454 8.467 7.472 7.971 7.498 7.498C7.971 7.472 8.467 7.454 9 7.454C13.618 7.454 16 8.538 16 9C16 12.859 12.859 16 9 16ZM2 9C2 8.632 3.538 7.877 6.49 7.582C6.467 8.054 6.454 8.529 6.454 9C6.454 11.615 6.822 14.362 7.605 15.859C4.412 15.211 2 12.382 2 9ZM7.605 2.141C7.066 3.172 6.725 4.797 6.564 6.564C4.797 6.725 3.172 7.066 2.141 7.605C2.697 4.862 4.862 2.697 7.605 2.141ZM15.859 7.605C14.362 6.822 11.615 6.454 9 6.454C8.529 6.454 8.054 6.467 7.582 6.49C7.877 3.538 8.632 2 9 2C12.382 2 15.211 4.412 15.859 7.605ZM9 1C4.589 1 1 4.589 1 9C1 13.411 4.589 17 9 17C13.411 17 17 13.411 17 9C17 4.589 13.411 1 9 1Z"
    />
  </SvgWrapper>
);
