import { Outlet, useNavigate } from 'react-router-dom-v5-compat';
import { OpLayout } from 'new-components/OpLayout/OpLayout';
import { OpContent } from 'new-components/OpContent/OpContent';
import { useCallback, useState } from 'react';
import { OpAppHeader } from 'new-components/OpAppHeader/OpAppHeader';
import { OpAppNav } from 'new-components/OpAppNav/OpAppNav';
import { ORG_ID_ROUTE_TEMPLATE } from 'new-components/OpAppScaffold/constants';
import { getOpParams } from 'utils/getOpParams';

import { OpRawMenuItemType } from 'config/createRawMenuItems';
import clsx from 'clsx';

import './OpAppLayout.scss';

interface OpAppLayoutProps {
  homePath: string;
  items: OpRawMenuItemType[];
  title: string;
  showOrgSwitcher?: boolean;
}

export const OpAppLayout = ({
  homePath,
  items,
  title,
  showOrgSwitcher,
}: OpAppLayoutProps) => {
  const { orgId } = getOpParams();
  const navigate = useNavigate();
  const [isShowingNavOnMobile, setIsShowingNavOnMobile] = useState(true);

  const handleLogoClick = useCallback(() => {
    navigate(homePath.replace(ORG_ID_ROUTE_TEMPLATE, orgId?.toString() ?? ''));
  }, [homePath, navigate, orgId]);

  const handleHamburgerClick = useCallback(() => {
    setIsShowingNavOnMobile((prev) => !prev);
  }, [setIsShowingNavOnMobile]);

  return (
    <OpLayout className="op-app-layout">
      <div
        className={clsx(
          'op-left-nav-container',
          !isShowingNavOnMobile && 'op-left-nav-container__mobile-closed',
        )}
      >
        <OpAppNav
          items={items}
          title={title}
          onLogoClick={handleLogoClick}
          setIsShowingNavOnMobile={setIsShowingNavOnMobile}
        />
      </div>
      <div
        className={clsx(
          'op-app-layout__header-content-container',
          !isShowingNavOnMobile &&
            'op-app-layout__header-content-container__mobile-closed',
        )}
      >
        <OpAppHeader
          className="op-app-layout__header"
          showHamburger={!isShowingNavOnMobile}
          onHamburgerClick={handleHamburgerClick}
          homePath={homePath}
          showOrgSwitcher={showOrgSwitcher}
        />
        <OpLayout className="op-app-layout__inner-layout">
          <OpContent className="op-app-layout__content">
            <Outlet /> {/* Render content from child routes */}
          </OpContent>
        </OpLayout>
      </div>
    </OpLayout>
  );
};
