import {
  SET_IS_EDITING,
  SET_FORM_VALUE,
  SET_NESTED_FORM_VALUE,
  SET_FORM_VALUES,
  SET_INITIAL_FORM_VALUES,
  SET_IS_LOADING,
  SET_FORM_VALIDATION,
  SET_FORM_ERROR_MESSAGE,
  DELETE_FORM_ERROR_MESSAGE,
  CREATE_EDIT_ITEM,
  RESET_FORM_VALUES,
} from './constants';

export function setFormValue(page, subFilterId, key, value) {
  // for updating a single form value while keeping the rest the same
  return {
    type: SET_FORM_VALUE,
    page,
    subFilterId,
    key,
    value,
  };
}

export function setNestedFormValue(page, subFilterId, keys, value) {
  // for updating a single form value, at a nested key path within the formValues object, while keeping the rest the same
  return {
    type: SET_NESTED_FORM_VALUE,
    page,
    subFilterId,
    keys,
    value,
  };
}

export function setFormValues(page, subFilterId, data) {
  // for replacing all formValues with new ones
  return {
    type: SET_FORM_VALUES,
    page,
    subFilterId,
    data,
  };
}

export function setInitialFormValues(page, subFilterId, data) {
  return {
    type: SET_INITIAL_FORM_VALUES,
    page,
    subFilterId,
    data,
  };
}

export function setIsLoading(page, subFilterId, value) {
  return {
    type: SET_IS_LOADING,
    page,
    subFilterId,
    value,
  };
}

export function setIsEditing(page, subFilterId, isEditing) {
  return {
    type: SET_IS_EDITING,
    page,
    subFilterId,
    isEditing,
  };
}

export function setFormValidation(page, subFilterId, data) {
  return {
    type: SET_FORM_VALIDATION,
    page,
    subFilterId,
    data,
  };
}

export function setFormErrorMessage(page, subFilterId, key, errorMessage) {
  return {
    type: SET_FORM_ERROR_MESSAGE,
    page,
    subFilterId,
    key,
    errorMessage,
  };
}

export function deleteFormErrorMessage(page, subFilterId, key) {
  return {
    type: DELETE_FORM_ERROR_MESSAGE,
    page,
    subFilterId,
    key,
  };
}

export function createEditItem(
  formValues,
  initialFormValues,
  submitFormConfig,
) {
  return {
    type: CREATE_EDIT_ITEM,
    formValues,
    initialFormValues,
    submitFormConfig,
  };
}

export function resetFormValues(page, subFilterId, resetValues) {
  return {
    type: RESET_FORM_VALUES,
    page,
    subFilterId,
    resetValues,
  };
}
