import { renderNamespace } from 'new-components/forms/LockdownUserConfigForm/helpers';
import { useIsRestrictedBySiteSpecificRole } from 'utils/customHooks/useIsRestrictedBySiteSpecificRole';
import { useTranslation } from 'react-i18next';
import { OpSiteSpecificUserDataFetchSelectProps } from './types';
import { OpDataFetchSelect } from '../OpDataFetchSelect/OpDataFetchSelect';
import { OpTooltip } from '../OpTooltip/OpTooltip';

/**
 * Component that wraps `OpDataFetchSelect` to fetch and display users that would
 * normally not have data due to site-specific access limitations.
 *
 * @param {OpSiteSpecificUserDataFetchSelectProps} props - The properties for the component.
 * @param {string} props.orgId - The organization ID used to fetch site data.
 * @param {object} props.opDataFetchSelectProps - Additional properties passed to `OpDataFetchSelect`.
 *
 * @returns {JSX.Element} The rendered `OpSiteSpecificUserDataFetchSelect` component.
 *
 * @remarks
 * It uses the `useIsRestrictedBySiteSpecificRole` hook to determine if the user has
 * site-specific restrictions and modifies the options and labels accordingly.
 * The `createDisabledOption` function disables options for users restricted by site-specific roles
 * the `createOptionLabel` function displays a tooltip with the user's email and namespace or a
 * restriction message if the user is restricted.
 */
export const OpSiteSpecificUserDataFetchSelect = ({
  orgId,
  ...opDataFetchSelectProps
}: OpSiteSpecificUserDataFetchSelectProps) => {
  const { t } = useTranslation();
  const checkSiteSpecificRoleRestriction =
    useIsRestrictedBySiteSpecificRole(orgId);

  return (
    <OpDataFetchSelect
      queryOptions={{
        apiEndpointName: 'listUsers',
        parameters: [
          orgId,
          { filter: 'status:(!=I)', options: 'withUserSites' },
        ],
      }}
      fetchAllInitialValues={{
        /**
         * Pass ignoreSiteSpecificAccess to fetch all users so that we can create options for
         * any users that may have been restricted by site-specific access. Creating the options
         * allows us to display the user name as well as disabling the option if the user has
         * site-specific restrictions.
         */
        queryParamOverrides: {
          options: 'withUserSites,ignoreSiteSpecificAccess',
        },
      }}
      createDisabledOption={({ sites }) =>
        checkSiteSpecificRoleRestriction(sites?.map(({ id }) => id))
      }
      // eslint-disable-next-line react/no-unstable-nested-components
      createOptionLabel={({ id: userId, identity, sites }) => (
        <OpTooltip
          title={
            checkSiteSpecificRoleRestriction(sites?.map(({ id }) => id))
              ? t('Your site-specific role prevents management of this user')
              : `${identity?.email} (${renderNamespace(identity)})`
          }
        >
          {identity?.fullName || identity?.email || userId}
        </OpTooltip>
      )}
      {...opDataFetchSelectProps}
    />
  );
};
