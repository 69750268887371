import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { changeRoute } from 'routes/AuthenticatedContainer/actions';
import { OpTextButton } from '../OpTextButton/OpTextButton';

interface OpLinkProps
  extends Omit<ComponentProps<typeof OpTextButton>, 'title' | 'onClick'> {
  route: string;
}

export const OpLink = ({
  className,
  children,
  route,
  ...buttonProps
}: OpLinkProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <OpTextButton
      className={clsx('op-link', className)}
      {...buttonProps}
      title={t('(Changes page)')}
      onClick={() => dispatch(changeRoute(route))}
    >
      {children}
    </OpTextButton>
  );
};
