import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { parseSlideoutUrls } from 'global/slideOuts/utils';
import { selectSlideOuts } from 'global/slideOuts/selectors';
import { addSlideOut, deleteSlideOut } from 'global/slideOuts/actions';
import { useSelectorJs } from 'utils/customHooks';
import { getWindowLocation } from 'utils/window';

const VALID_OPTIONS_FOR_SLIDEOUT_PROPS = [
  'debug', // DEFAULT: null :: show debugging information for the slideout
  'showCloseButton', // DEFAULT: true :: whether or not we want to show the close button
  'requiredExtraData', //  DEFAULT: null :: extraData/url param that is required to open this slideout (will close if not available)
  'closeButtonStyle', // DEFAULT: null :: css to be passed to the close button
  'maxWidth', // DEFAULT: 1200 :: the max width (integer) that the slideout should take up.
  'pathname', // DEFAULT: null :: if this slideout should use a path (i.e. /edit) instead of a query param
];

// This works in conjunction with SlideOuts/index.js to manage URL state
const useSlideOut = (routeConfig, extraData, slideOutProps = {}) => {
  const dispatch = useDispatch();
  const activeSlideOuts = useSelectorJs(selectSlideOuts());

  if (
    !Object.keys(slideOutProps).every((el) =>
      VALID_OPTIONS_FOR_SLIDEOUT_PROPS.includes(el),
    )
  ) {
    throw new Error(
      `useSlideOut() - Invalid slideOutProps passed to hook. You passed ${JSON.stringify(
        Object.keys(slideOutProps),
      )} (valid options: ${JSON.stringify(VALID_OPTIONS_FOR_SLIDEOUT_PROPS)})`,
    );
  }

  // whether or not we want to show additional debug
  if (window.sessionStorage.getItem('op-history-debug')) {
    // eslint-disable-next-line no-param-reassign
    slideOutProps.debug = true;
  }
  const { debug } = slideOutProps;

  // error checking
  if (
    slideOutProps?.requiredExtraData &&
    !Array.isArray(slideOutProps.requiredExtraData)
  ) {
    if (debug) {
      // eslint-disable-next-line no-console
      console.debug(
        'useSlideOut() - requiredExtraData is not an array, you should correct this',
      );
    }
    // eslint-disable-next-line no-param-reassign
    slideOutProps.requiredExtraData = [slideOutProps.requiredExtraData];
  }

  const config = useMemo(
    () => ({
      routeConfig: {
        ...routeConfig,
        extraData: {
          ...extraData,
        },
      },
      slideOutProps: {
        ...slideOutProps,
      },
    }),

    // note - this is on purpose, we only want to caluclate this once on mount. Otherwise, it will trigger a re-render on openSlideOut and then again on the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // create a helper function that also manages the URL
  // otherData is data passed at execution time that we may want to inject
  // into the slideout
  const openSlideout = useCallback(
    (otherData) => {
      const configWithAdditionalData = { ...config };
      configWithAdditionalData.routeConfig.extraData = {
        ...configWithAdditionalData.routeConfig.extraData,
        ...otherData,
      };
      dispatch(addSlideOut(configWithAdditionalData));
    },
    [config, dispatch],
  );

  const closeSlideout = useCallback(
    (otherData) => {
      const configWithAdditionalData = { ...config };
      configWithAdditionalData.routeConfig.extraData = {
        ...configWithAdditionalData.routeConfig.extraData,
        ...otherData,
      };
      dispatch(deleteSlideOut(configWithAdditionalData));
    },
    [dispatch, config],
  );

  const location = useLocation();

  // this effect is used on page load to see if we need to open a slideout
  useEffect(() => {
    const { parsed, otherData } = parseSlideoutUrls(location);

    const activeSlideoutRoutes = activeSlideOuts.map(
      (s) => s?.routeConfig?.path,
    );
    const urlMatch1 =
      parsed.findIndex((x) => x.route.includes(routeConfig.path)) > -1;
    const pathNameMatch =
      !activeSlideoutRoutes.length &&
      !!(
        slideOutProps.pathname &&
        getWindowLocation().pathname.includes(slideOutProps.pathname)
      ); // this is the problem, it matches multiple times!

    const urlMatch = urlMatch1 || pathNameMatch;

    if (urlMatch && !activeSlideoutRoutes.includes(routeConfig.path)) {
      if (debug) {
        /* eslint-disable no-console */
        console.debug('\n\n***************************');
        console.debug(
          `useSlideOut() - ${JSON.stringify(slideOutProps.pathname, null, 2)}`,
        );
        console.debug(
          `[routeConfig.path] - ${JSON.stringify(
            routeConfig.path,
            null,
            2,
          )} (urlMatch: ${urlMatch} - urlMatch1: ${urlMatch1} - pathNameMatch: ${pathNameMatch})`,
        );
        console.debug(`[otherData] - ${JSON.stringify(otherData, null, 2)}`);
        console.debug('\n\n***************************');
        /* eslint-enable no-console */
      }
      if (debug) {
        // eslint-disable-next-line no-console
        console.debug(`useSlideOut() - launching slideout ${routeConfig.path}`);
      }
      openSlideout(otherData);
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return [openSlideout, closeSlideout, config];
};

export default useSlideOut;
