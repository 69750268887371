import { LoaderWithoutSuir } from './LoaderWithoutSuir';
import { LoaderWithSuir } from './LoaderWithSuir';
import { LoaderProps } from './types';

export const Loader = (props: LoaderProps) => {
  const hasUserSelectedSuirRemovedUi =
    localStorage.getItem('hasUserSelectedSuirRemovedUi') === 'true';

  return hasUserSelectedSuirRemovedUi ? (
    <LoaderWithoutSuir {...props} />
  ) : (
    <LoaderWithSuir {...props} />
  );
};
