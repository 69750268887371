import { parse } from 'query-string';

export function parseSlideoutUrls(location) {
  const { search } = location;
  // get the slideouts array, but they could be in either format:
  // - routeName
  // - routeName?x=123 <--- "params" will be passed as extraData
  // eslint-disable-next-line prefer-const
  let { s = [], ...otherData } = parse(search, { arrayFormat: 'none' });

  s = Array.isArray(s) ? s : [s];
  const parsed = s.reduce(
    (acc, curr) => {
      const [route, extraData = ''] = curr.split('?'); // split on ?
      const parsedExtraData = extraData.split('&').reduce((prev, next) => {
        const [key, value] = next.split('=');
        // eslint-disable-next-line no-param-reassign
        prev[key] = value;
        return prev;
      }, {});
      acc.parsed.push({ route, extraData: parsedExtraData });
      return acc;
    },
    { original: s, parsed: [] },
  );

  return { parsed: parsed.parsed, otherData };
}

export function convertDataToSlideoutUrl(data) {
  const qp = { s: data.map((d) => d.route) };
  return qp;
}
