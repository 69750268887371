import { REQUEST_CHANNELTIVITY_LOGIN } from 'routes/MasterContainer/constants';

// Using the release notes to determine the alertCount on the Release Notes menu item
import releaseNotesJson from 'routes/ReleaseNotesPage/releaseNotes.json';
import {
  appMarketplaceRoute,
  alarmDashboardRoute,
  errorMessageRoute,
  hardwareDashboardRoute,
  entryDashboardRoute,
  buildingsRoute,
  buildingsCreateRoute,
  buildingsEditRoute,
  licensesRoute,
  usersTableRoute,
  userSchedulesTableRoute,
  userSchedulesCreateRoute,
  userSchedulesEditRoute,
  userCustomFieldsTableRoute,
  userCustomFieldsCreateRoute,
  userCustomFieldsEditRoute,
  userCredentialsManagementRoute,
  presenceReportRoute,
  usersImportRoute,
  rolesTableRoute,
  rolesCreateRoute,
  rolesEditRoute,
  sitesTableRoute,
  sitesCreateRoute,
  sitesEditRoute,
  zonesTableRoute,
  zonesCreateRoute,
  zonesEditDetailsRoute,
  zonesEditAntiPassbackRoute,
  entriesTableRoute,
  entriesCreateRoute,
  entriesEditRoute,
  entryStatesTableRoute,
  entryStatesCreateRoute,
  entryStatesEditRoute,
  entrySchedulesTableRoute,
  entrySchedulesCreateRoute,
  entrySchedulesEditRoute,
  acusTableRoute,
  acusCreateRoute,
  acusPortsRoute,
  acusEditRoute,
  readersTableRoute,
  readersCreateRoute,
  readersEditRoute,
  thirdPartyReadersTableRoute,
  thirdPartyReadersEditRoute,
  activityLogsRoute,
  userActivityRoute,
  entryActivityRoute,
  userActivitySummaryRoute,
  entryActivitySummaryRoute,
  entryAccessAuditRoute,
  userAccessAuditRoute,
  portalAuditReportRoute,
  integrationsRoute,
  integrationsIdentityProviderGsuite,
  integrationsIdentityProviderAzure,
  integrationsIdentityProviderOkta,
  integrationsIdentityProviderOnelogin,
  mobileAppSettingsRoute,
  mobileAppBadgeUiRoute,
  badgeTemplatesTablePageRoute,
  intercomUserDirectoryRoute,
  quickStartRoute,
  accountPageRoute,
  releaseNotesRoute,
  masterDashboardRoute,
  masterOrgTableRoute,
  masterOrgEditRoute,
  masterOrgEditPackageRoute,
  masterOrgEditFeatureFlagRoute,
  masterOrgCreateRoute,
  masterIdentitiesTableRoute,
  masterIdentitiesEditRoute,
  masterIdentitiesEditSecurityRoute,
  masterIdentitiesCreateRoute,
  masterRolesTableRoute,
  masterRolesEditRoute,
  masterRolesCreateRoute,
  masterAcusTableRoute,
  masterAcusEditRoute,
  masterEntriesTableRoute,
  masterThirdPartyDevicesRoute,
  masterAllegionFobsRoute,
  masterFeatureFlagsTableRoute,
  masterFeatureFlagsEditRoute,
  masterFeatureFlagsCreateRoute,
  masterPortalAuditReportRoute,
  masterAcuSoftwareReportRoute,
  myAppsTableRoute,
  partnerRequestsTableRoute,
  masterReleaseNotesRoute,
  alertSettingsRoute,
  lockdownTableRoute,
  camerasRoute,
  cameraDetailsRoute,
  lockdownCreateRoute,
  lockdownEditRoute,
  lockdownEditConfigRoute,
  requiredTermsRoute,
  subscriptionsTableRoute,
  subscriptionsCreateRoute,
  subscriptionsEditRoute,
  visualActivityReportRoute,
  wirelessLockGatewaysTableRoute,
  wirelessLocksTableRoute,
  wirelessLocksEditRoute,
  deviceUpdateManagement,
  partnerDashboardRoute,
  partnerBillingRoute,
  partnerStoreRoute,
  partnerSubscriptionRenewalsTableRoute,
  customDashboardsRoute,
  partnerOrgTableRoute,
  partnerOrgCreateRoute,
  partnerOrgEditRoute,
  partnerOrgEditPackageRoute,
  partnerAcusTableRoute,
  partnerAcusEditRoute,
  partnerThirdPartyDevicesRoute,
  partnerEntriesTableRoute,
  scheduledReportsRoute,
  scheduledReportsCreateRoute,
  scheduledReportsEditRoute,
  cameraDeeplinkRedirectRoute,
  alarmEditRoute,
  alarmReportRoute,
  musterReportsRoute,
  accessGroupsRoute,
  activityDashboardRoute,
  videoIntercomReadersRoute,
  videoReadersRoute,
  occupancyReportRoute,
  alarmConfigurationsRoute,
} from '../routes/constants';

export default (
  mergedOpenpathConfig = {},
  orgConfig = { isLicenseBased: false, hasAppMarketplace: false },
) => {
  const seenReleaseNotesCount = localStorage.getItem('release-notes-count');
  const newReleaseNotesCount = releaseNotesJson.length - seenReleaseNotesCount;
  return {
    items: [
      {
        title: 'Required terms',
        route: requiredTermsRoute,
        faIcon: 'lock',
        hidden: true,
      },
      {
        title: 'No permission',
        route: errorMessageRoute,
        scope: [],
        hidden: true,
      },

      {
        title: 'Partner center',
        scope: [
          'o{orgId}-erpParentStore:w',
          'o{orgId}-erpParentCenter:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        requiredFeatureCode: 'subOrgs',
        faIcon: 'handshake',
        items: [
          {
            title: 'Partner dashboard',
            route: partnerDashboardRoute,
            faIcon: 'tachometer-alt',
            scope: ['o:w', 'o:r', 's-o:r', 's-o:w'],
            requiredFeatureCode: 'subOrgs',
          },
          {
            title: 'Store access',
            route: partnerStoreRoute,
            faIcon: 'store-alt',
            scope: ['o{orgId}-erpParentStore:w', 'o:w', 'o:r'], // no s-o scopes here since users specifically need the erpParentStore permission here
            requiredFeatureCode: 'subOrgs',
          },
          {
            title: 'Manage organizations',
            route: partnerOrgTableRoute,
            faIcon: 'cube',
            scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
            requiredFeatureCode: 'subOrgs',

            items: [
              {
                title: 'Create organization',
                route: partnerOrgCreateRoute,
                faIcon: 'cube',
                scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
                requiredFeatureCode: 'subOrgs',
                hidden: true,
              },
              {
                title: 'Edit organization',
                route: partnerOrgEditRoute,
                faIcon: 'cube',
                scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
                requiredFeatureCode: 'subOrgs',
                hidden: true,
              },
              {
                title: 'Edit organization package',
                route: partnerOrgEditPackageRoute,
                faIcon: 'archive',
                scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
                requiredFeatureCode: 'subOrgs',
                hidden: true,
              },
            ],
          },
          {
            title: 'Renewals',
            route: partnerSubscriptionRenewalsTableRoute,
            faIcon: 'globe',
            scope: ['o{orgId}-erpParentStore:w', 'o:w', 'o:r'], // no s-o scopes here since users specifically need the erpParentStore permission here
            requiredFeatureCode: 'subOrgs',
          },
          {
            title: 'License requests',
            route: partnerRequestsTableRoute,
            faIcon: 'clipboard-list',
            scope: ['o:w', 'o:r', 's-o:r', 's-o:w'],
            requiredFeatureCode: 'subOrgs',
          },
          {
            title: 'Devices',
            faIcon: 'microchip',
            scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
            requiredFeatureCode: 'subOrgs',

            items: [
              {
                title: 'ACUs',
                route: partnerAcusTableRoute,
                faIcon: 'microchip',
                scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
                requiredFeatureCode: 'subOrgs',
                items: [
                  {
                    title: 'ACUs',
                    route: partnerAcusEditRoute,
                    faIcon: 'microchip',
                    scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
                    requiredFeatureCode: 'subOrgs',
                    hidden: true,
                  },
                ],
              },
              {
                title: 'Third-party devices',
                route: partnerThirdPartyDevicesRoute,
                faIcon: 'puzzle-piece',
                scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
                requiredFeatureCode: 'subOrgs',
              },
            ],
          },
          {
            title: 'Manage entries',
            route: partnerEntriesTableRoute,
            faIcon: 'building',
            scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
            requiredFeatureCode: 'subOrgs',
          },
          {
            title: 'Billing account',
            route: partnerBillingRoute,
            faIcon: 'shopping-cart',
            scope: ['o{orgId}-erpParentCenter:w', 'o:w', 'o:r'], // no s-o scopes here since users specifically need the erpParentStore permission here
            requiredFeatureCode: 'subOrgs',
            // action: REQUEST_ERP_SAML_APP_LOGIN,
          },
          {
            title: 'Marketing',
            route: null,
            faIcon: 'bullhorn',
            scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
            requiredFeatureCode: 'subOrgs',

            action: REQUEST_CHANNELTIVITY_LOGIN,
            external: true,
          },
          {
            title: 'Training',
            route: 'https://vsatraining.myabsorb.com/',
            faIcon: 'chalkboard-teacher',
            scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
            requiredFeatureCode: 'subOrgs',

            external: true,
          },
        ],
      },

      {
        title: '-',
        // scope + requiredFeatureCode + hidden should match that of the Partner Center item
        scope: [
          'o{orgId}-erpParentStore:w',
          'o{orgId}-erpParentCenter:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        requiredFeatureCode: 'subOrgs',
      },

      {
        title: 'Dashboards',
        route: 'dashboards',
        scope: [
          'o{orgId}-dash:r',
          'o{orgId}-dash:w',
          'o{orgId}-dashActivity:r',
          'o{orgId}-dashActivity:w',
          'o{orgId}-dashAlarm:r',
          'o{orgId}-dashAlarm:w',
          'o{orgId}-dashEntry:r',
          'o{orgId}-dashEntry:w',
          'o{orgId}-hw:r',
          'o{orgId}-hw:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        faIcon: 'tachometer-alt',
        items: [
          {
            title: 'Activity dashboard',
            route: activityDashboardRoute,
            scope: [
              'o{orgId}-dash:r',
              'o{orgId}-dash:w',
              'o{orgId}-dashActivity:r',
              'o{orgId}-dashActivity:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: true,
          },
          {
            title: 'Alarms dashboard',
            route: alarmDashboardRoute,
            scope: [
              'o{orgId}-dash:r',
              'o{orgId}-dash:w',
              'o{orgId}-dashAlarm:r',
              'o{orgId}-dashAlarm:w',
              'o:w',
              'o:r',
            ],
            hidden: true,
          },
          {
            title: 'Entry dashboard',
            route: entryDashboardRoute,
            scope: [
              'o{orgId}-dash:r',
              'o{orgId}-dash:w',
              'o{orgId}-dashEntry:r',
              'o{orgId}-dashEntry:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: true,
          },
          {
            title: 'Device dashboard',
            route: hardwareDashboardRoute,
            scope: [
              'o{orgId}-dash:r',
              'o{orgId}-dash:w',
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: true,
          },
          {
            title: 'Custom dashboard',
            route: customDashboardsRoute,
            scope: [
              'o{orgId}-dash:r',
              'o{orgId}-dash:w',
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: true,
          },
        ],
      },

      // Can open from anywhere, not a real page, just a slideout!
      {
        title: 'Alarm edit',
        route: alarmEditRoute,
        scope: [
          'o{orgId}-dash:r',
          'o{orgId}-dash:w',
          'o{orgId}-dashAlarm:r',
          'o{orgId}-dashAlarm:w',
          'o{orgId}-rpt:r',
          'o{orgId}-rpt:w',
          'o{orgId}-rptAlarm:r',
          'o{orgId}-rptAlarm:w',
          'o{orgId}-configurations:r',
          'o{orgId}-configurations:w',
          'o{orgId}-alarmActions:r',
          'o{orgId}-alarmActions:w',
          'o:r',
          'o:w',
        ],
        // faIcon: 'exclamation-triangle',
        hidden: true,
      },

      {
        title: 'Cameras',
        route: camerasRoute,
        faIcon: 'video',
        scope: [
          'o:w',
          's-o:w',
          'o{orgId}-videoProviderLive:w', // they can see because they have "play back videoProvider live" scope
          'o{orgId}-videoProviderPlayback:w', // they can see because they have "play back videoProvider clips" scope
          'o{orgId}-opvideoDevice:w', // they can see it because they have "fine tune video settings" scope
          'o-supportVideo:w', // they can see it because they have support scope
        ],
        items: [
          {
            title: 'Camera details',
            route: cameraDetailsRoute,
            scope: [
              'o:w',
              's-o:w',
              'o{orgId}-videoProviderLive:w', // they can see because they have "play back videoProvider live" scope
              'o{orgId}-videoProviderPlayback:w', // they can see because they have "play back videoProvider clips" scope
              'o{orgId}-opvideoDevice:w', // they can see it because they have "fine tune video settings" scope
              'o-supportVideo:w', // they can see it because they have support scope
            ],
            hidden: true,
          },
          {
            title: 'Camera deeplink redirect',
            route: cameraDeeplinkRedirectRoute,
            scope: [
              'o:w',
              's-o:w',
              'o{orgId}-videoProviderLive:w', // they can see because they have "play back videoProvider live" scope
              'o{orgId}-videoProviderPlayback:w', // they can see because they have "play back videoProvider clips" scope
              'o{orgId}-opvideoDevice:w', // they can see it because they have "fine tune video settings" scope
              'o-supportVideo:w', // they can see it because they have support scope
            ],
            hidden: true,
          },
        ],
      },

      {
        title: 'Users',
        faIcon: 'users',
        scope: [
          'o{orgId}-user:r',
          'o{orgId}-user:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
          's-o-video:r',
          's-o-video:w',
          'o-user:r',
          'o-user:w',
        ],
        items: [
          {
            title: 'Users',
            route: usersTableRoute,
            faIcon: 'users',
            scope: [
              'o{orgId}-user:r',
              'o{orgId}-user:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
              's-o-video:r',
              's-o-video:w',
              'o-user:r',
              'o-user:w',
            ],
          },
          {
            title: 'Import users',
            route: usersImportRoute,
            faIcon: 'file-import',
            scope: [
              'o{orgId}-user:r',
              'o{orgId}-user:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
              's-o-video:r',
              's-o-video:w',
            ],
          },
          {
            title: 'Access groups',
            route: accessGroupsRoute,
            faIcon: 'users',
            scope: [
              'o{orgId}-user:r',
              'o{orgId}-user:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
              's-o-video:r',
              's-o-video:w',
              'o-user:r',
              'o-user:w',
            ],
          },
          {
            title: 'Roles',
            route: rolesTableRoute,
            faIcon: 'universal-access',
            scope: [
              'o{orgId}-role:r',
              'o{orgId}-role:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
              's-o-video:r',
              's-o-video:w',
              'o-role:r',
              'o-role:w',
            ],
            items: [
              {
                title: 'Create role',
                route: rolesCreateRoute,
                scope: [
                  'o{orgId}-role:r',
                  'o{orgId}-role:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                  's-o-video:r',
                  's-o-video:w',
                  'o-role:r',
                  'o-role:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit role',
                route: rolesEditRoute,
                scope: [
                  'o{orgId}-role:r',
                  'o{orgId}-role:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                  's-o-video:r',
                  's-o-video:w',
                  'o-role:r',
                  'o-role:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'User schedules',
            route: userSchedulesTableRoute,
            faIcon: 'calendar-alt',
            scope: [
              'o{orgId}-user:r',
              'o{orgId}-user:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create schedule',
                route: userSchedulesCreateRoute,
                scope: [
                  'o{orgId}-user:r',
                  'o{orgId}-user:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit schedule',
                route: userSchedulesEditRoute,
                scope: [
                  'o{orgId}-user:r',
                  'o{orgId}-user:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Custom fields',
            route: userCustomFieldsTableRoute,
            faIcon: 'tools',
            scope: [
              'o{orgId}-user:r',
              'o{orgId}-user:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create schedule',
                route: userCustomFieldsCreateRoute,
                scope: [
                  'o{orgId}-user:r',
                  'o{orgId}-user:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit schedule',
                route: userCustomFieldsEditRoute,
                scope: [
                  'o{orgId}-user:r',
                  'o{orgId}-user:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        title: 'Sites',
        faIcon: 'building',
        scope: [
          'o{orgId}-site:r',
          'o{orgId}-site:w',
          'o{orgId}-siteGeneral:r',
          'o{orgId}-siteGeneral:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        items: [
          {
            title: 'Sites',
            route: sitesTableRoute,
            faIcon: 'building',
            scope: [
              'o{orgId}-site:r',
              'o{orgId}-site:w',
              'o{orgId}-siteGeneral:r',
              'o{orgId}-siteGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create site',
                route: sitesCreateRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit site',
                route: sitesEditRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Buildings',
            route: buildingsRoute,
            faIcon: 'building',
            scope: [
              'o{orgId}-site:r',
              'o{orgId}-site:w',
              'o{orgId}-building:r',
              'o{orgId}-building:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create building',
                route: buildingsCreateRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-building:r',
                  'o{orgId}-building:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit building',
                route: buildingsEditRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-building:r',
                  'o{orgId}-building:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Zones',
            route: zonesTableRoute,
            faIcon: 'cogs',
            scope: [
              'o{orgId}-site:r',
              'o{orgId}-site:w',
              'o{orgId}-siteGeneral:r',
              'o{orgId}-siteGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create zone',
                route: zonesCreateRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit zone',
                route: zonesEditDetailsRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit zone',
                route: zonesEditAntiPassbackRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Entries',
            route: entriesTableRoute,
            faIcon: 'lock',
            scope: [
              'o{orgId}-site:r',
              'o{orgId}-site:w',
              'o{orgId}-siteGeneral:r',
              'o{orgId}-siteGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create entry',
                route: entriesCreateRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit entry',
                route: entriesEditRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Entry states',
            route: entryStatesTableRoute,
            faIcon: 'shield-alt',
            hidden: mergedOpenpathConfig.ENTRY_STATES_MENU_ITEM_HIDDEN,
            scope: [
              'o{orgId}-site:r',
              'o{orgId}-site:w',
              'o{orgId}-entryState:r',
              'o{orgId}-entryState:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create entry state',
                route: entryStatesCreateRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-entryState:r',
                  'o{orgId}-entryState:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit entry state',
                route: entryStatesEditRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-entryState:r',
                  'o{orgId}-entryState:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Entry schedules',
            route: entrySchedulesTableRoute,
            faIcon: 'calendar-alt',
            scope: [
              'o{orgId}-site:r',
              'o{orgId}-site:w',
              'o{orgId}-siteGeneral:r',
              'o{orgId}-siteGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create schedule',
                route: entrySchedulesCreateRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit schedule',
                route: entrySchedulesEditRoute,
                scope: [
                  'o{orgId}-site:r',
                  'o{orgId}-site:w',
                  'o{orgId}-siteGeneral:r',
                  'o{orgId}-siteGeneral:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Lockdown plans',
            route: lockdownTableRoute,
            faIcon: ['op', 'lockdown'],
            scope: [
              'o{orgId}-ldp:r',
              'o{orgId}-ldp:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create lockdown plan',
                route: lockdownCreateRoute,
                scope: [
                  'o{orgId}-ldp:r',
                  'o{orgId}-ldp:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit lockdown plan',
                route: lockdownEditRoute,
                scope: [
                  'o{orgId}-ldp:r',
                  'o{orgId}-ldp:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit lockdown user config',
                route: lockdownEditConfigRoute,
                scope: [
                  'o{orgId}-ldp:r',
                  'o{orgId}-ldp:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        title: 'Devices',
        faIcon: 'microchip',
        scope: [
          'o{orgId}-hw:r',
          'o{orgId}-hw:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        items: [
          {
            title: 'ACUs',
            route: acusTableRoute,
            faIcon: 'microchip',
            scope: [
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create ACU',
                route: acusCreateRoute,
                scope: [
                  'o{orgId}-hw:r',
                  'o{orgId}-hw:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit ACU',
                route: acusEditRoute,
                scope: [
                  'o{orgId}-hw:r',
                  'o{orgId}-hw:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit ACU ports',
                route: acusPortsRoute,
                scope: [
                  'o{orgId}-hw:r',
                  'o{orgId}-hw:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Readers',
            route: readersTableRoute,
            faIcon: ['op', 'reader'],
            scope: [
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create reader',
                route: readersCreateRoute,
                scope: [
                  'o{orgId}-hw:r',
                  'o{orgId}-hw:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit reader',
                route: readersEditRoute,
                scope: [
                  'o{orgId}-hw:r',
                  'o{orgId}-hw:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Third-party readers',
                route: thirdPartyReadersTableRoute,
                scope: [
                  'o{orgId}-hw:r',
                  'o{orgId}-hw:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit third-party reader',
                route: thirdPartyReadersEditRoute,
                scope: [
                  'o{orgId}-hw:r',
                  'o{orgId}-hw:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Wireless locks',
            route: wirelessLocksTableRoute,
            faIcon: 'lock',
            scope: [
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Edit wireless lock',
                route: wirelessLocksEditRoute,
                scope: [
                  'o{orgId}-hw:r',
                  'o{orgId}-hw:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
            requiredFeatureCode: ['allegionNdebLeb', 'allegionNdebLebNonOp'],
          },
          {
            title: 'Wireless lock gateways',
            route: wirelessLockGatewaysTableRoute,
            faIcon: 'satellite-dish',
            scope: [
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [],
            requiredFeatureCode: ['allegionNdebLeb', 'allegionNdebLebNonOp'],
          },
          {
            title: 'Video readers',
            faIcon: ['op', 'video-reader'],
            route: videoReadersRoute,
            scope: [
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Video intercom readers',
            faIcon: ['op', 'video-reader'],
            route: videoIntercomReadersRoute,
            scope: [
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Device updates',
            route: deviceUpdateManagement,
            scope: [
              'o{orgId}-hw:r',
              'o{orgId}-hw:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
        ],
      },
      {
        title: 'Reports',
        faIcon: 'copy',
        scope: [
          'o{orgId}-rpt:r',
          'o{orgId}-rpt:w',
          'o{orgId}-rptGeneral:r',
          'o{orgId}-rptGeneral:w',
          'o{orgId}-musterReport:r',
          'o{orgId}-musterReport:w',
          'o{orgId}-occupancyReport:r',
          'o{orgId}-occupancyReport:w',
          'o{orgId}-entryAccessAudit:r',
          'o{orgId}-entryAccessAudit:w',
          'o{orgId}-portalAudit:r',
          'o{orgId}-portalAudit:w',
          'o{orgId}-credential:r',
          'o{orgId}-credential:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        items: [
          {
            title: 'Activity logs',
            route: activityLogsRoute,
            faIcon: 'history',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-rptGeneral:r',
              'o{orgId}-rptGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Alarms',
            route: alarmReportRoute,
            faIcon: 'exclamation-triangle',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-rptAlarm:r',
              'o{orgId}-rptAlarm:w',
              'o:w',
              'o:r',
            ],
          },
          {
            title: 'Credentials',
            route: userCredentialsManagementRoute,
            faIcon: 'id-card',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-credential:r',
              'o{orgId}-credential:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Entry access audit',
            route: entryAccessAuditRoute,
            faIcon: 'clipboard',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-entryAccessAudit:r',
              'o{orgId}-entryAccessAudit:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Entry activity (by user)',
            route: entryActivityRoute,
            faIcon: 'sign-in-alt',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-rptGeneral:r',
              'o{orgId}-rptGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Entry activity summary',
            route: entryActivitySummaryRoute,
            faIcon: 'chart-bar',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-rptGeneral:r',
              'o{orgId}-rptGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Muster',
            route: musterReportsRoute,
            faIcon: 'clipboard',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-musterReport:r',
              'o{orgId}-musterReport:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Occupancy',
            route: occupancyReportRoute,
            faIcon: 'clipboard',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-occupancyReport:r',
              'o{orgId}-occupancyReport:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Portal audit report',
            route: portalAuditReportRoute,
            faIcon: 'clipboard',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-portalAudit:r',
              'o{orgId}-portalAudit:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: mergedOpenpathConfig.API_AUDIT_REPORT_HIDDEN,
          },
          {
            title: 'Scheduled reports',
            route: scheduledReportsRoute,
            faIcon: 'calendar-alt',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-rptGeneral:w',
              'o{orgId}-rptGeneral:r',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: mergedOpenpathConfig.SCHEDULED_REPORTS_DISABLED,
            items: [
              {
                title: 'Create scheduled report',
                route: scheduledReportsCreateRoute,
                scope: [
                  'o{orgId}-rpt:r',
                  'o{orgId}-rpt:w',
                  'o{orgId}-credential:r',
                  'o{orgId}-credential:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit scheduled report',
                route: scheduledReportsEditRoute,
                scope: [
                  'o{orgId}-rpt:r',
                  'o{orgId}-rpt:w',
                  'o{orgId}-credential:r',
                  'o{orgId}-credential:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'User access audit',
            route: userAccessAuditRoute,
            faIcon: 'user-shield',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'User activity (by entry)',
            route: userActivityRoute,
            faIcon: 'user',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-rptGeneral:r',
              'o{orgId}-rptGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'User activity summary',
            route: userActivitySummaryRoute,
            faIcon: 'chart-bar',
            scope: [
              'o{orgId}-rpt:r',
              'o{orgId}-rpt:w',
              'o{orgId}-rptGeneral:r',
              'o{orgId}-rptGeneral:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },

          {
            title: 'Visual activity report',
            route: visualActivityReportRoute,
            faIcon: 'eye',
            scope: [
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
              'o{orgId}-videoProviderPlayback:w',
            ],
            hidden: !mergedOpenpathConfig.SHOW_VISUAL_ACTIVITY_REPORT,
          },
        ],
      },

      {
        title: 'App marketplace',
        faIcon: ['op', 'app-marketplace'],
        scope: [
          'o{orgId}-integrations:r',
          'o{orgId}-integrations:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        hidden:
          mergedOpenpathConfig.APP_MARKETPLACE_DISABLED ||
          (!orgConfig?.isLicenseBased && !orgConfig?.hasAppMarketplace),
        items: [
          {
            title: 'Get apps',
            faIcon: ['op', 'get-apps'],
            route: appMarketplaceRoute,
            scope: [
              'o{orgId}-integrations:r',
              'o{orgId}-integrations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'My apps',
            route: myAppsTableRoute,
            faIcon: ['op', 'my-apps'],
            scope: [
              'o{orgId}-integrations:r',
              'o{orgId}-integrations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
        ],
      },
      {
        title: 'App marketplace',
        route: integrationsRoute,
        hidden:
          mergedOpenpathConfig.INTEGRATIONS_MENU_ITEM_HIDDEN ||
          orgConfig?.isLicenseBased ||
          orgConfig?.hasAppMarketplace,
        scope: [
          'o{orgId}-integrations:r',
          'o{orgId}-integrations:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        faIcon: 'globe-americas',
        items: [
          {
            title: 'GSuite',
            route: integrationsIdentityProviderGsuite,
            scope: [
              'o{orgId}-integrations:r',
              'o{orgId}-integrations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: true,
          },
          {
            title: 'Azure',
            route: integrationsIdentityProviderAzure,
            scope: [
              'o{orgId}-integrations:r',
              'o{orgId}-integrations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: true,
          },
          {
            title: 'Okta',
            route: integrationsIdentityProviderOkta,
            scope: [
              'o{orgId}-integrations:r',
              'o{orgId}-integrations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: true,
          },
          {
            title: 'Onelogin',
            route: integrationsIdentityProviderOnelogin,
            scope: [
              'o{orgId}-integrations:r',
              'o{orgId}-integrations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden: true,
          },
        ],
      },
      {
        title: 'Configurations',
        faIcon: 'puzzle-piece',
        scope: [
          'o{orgId}-configurations:r',
          'o{orgId}-configurations:w',
          'o{orgId}-alarmConfigurations:r',
          'o{orgId}-alarmConfigurations:w',
          'o{orgId}-outboundWebhooks:r',
          'o{orgId}-outboundWebhooks:w',
          'o{orgId}-alertSettings:r',
          'o{orgId}-alertSettings:w',
          'o{orgId}-mobileAppSettings:r',
          'o{orgId}-mobileAppSettings:w',
          'o{orgId}-badgePrinting:r',
          'o{orgId}-badgePrinting:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        items: [
          {
            title: 'Alarms',
            faIcon: 'exclamation-triangle',
            route: alarmConfigurationsRoute,
            scope: [
              'o{orgId}-configurations:r',
              'o{orgId}-configurations:w',
              'o{orgId}-alarmConfigurations:r',
              'o{orgId}-alarmConfigurations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Rules',
            faIcon: 'code-branch',
            route: subscriptionsTableRoute,
            scope: [
              'o{orgId}-configurations:r',
              'o{orgId}-configurations:w',
              'o{orgId}-outboundWebhooks:r',
              'o{orgId}-outboundWebhooks:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Create rule',
                route: subscriptionsCreateRoute,
                scope: [
                  'o{orgId}-configurations:r',
                  'o{orgId}-configurations:w',
                  'o{orgId}-outboundWebhooks:r',
                  'o{orgId}-outboundWebhooks:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
              {
                title: 'Edit rule',
                route: subscriptionsEditRoute,
                scope: [
                  'o{orgId}-configurations:r',
                  'o{orgId}-configurations:w',
                  'o{orgId}-outboundWebhooks:r',
                  'o{orgId}-outboundWebhooks:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Alerts',
            faIcon: 'wrench',
            route: alertSettingsRoute,
            hidden: mergedOpenpathConfig.ALERTS_SETTINGS_MENU_ITEM_HIDDEN,
            scope: [
              'o{orgId}-configurations:r',
              'o{orgId}-configurations:w',
              'o{orgId}-alertSettings:r',
              'o{orgId}-alertSettings:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Mobile app',
            route: mobileAppSettingsRoute,
            faIcon: 'mobile-alt',
            hidden: mergedOpenpathConfig.ADMINISTRATION_MENU_ITEM_HIDDEN,
            scope: [
              'o{orgId}-configurations:r',
              'o{orgId}-configurations:w',
              'o{orgId}-mobileAppSettings:r',
              'o{orgId}-mobileAppSettings:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            items: [
              {
                title: 'Badge UI',
                route: mobileAppBadgeUiRoute,
                scope: [
                  'o{orgId}-configurations:r',
                  'o{orgId}-configurations:w',
                  'o{orgId}-mobileAppSettings:r',
                  'o{orgId}-mobileAppSettings:w',
                  'o:w',
                  'o:r',
                  's-o:r',
                  's-o:w',
                ],
                hidden: true,
              },
            ],
          },
          {
            title: 'Badge templates',
            requiredFeatureCode: 'badgePrinting',
            route: badgeTemplatesTablePageRoute,
            faIcon: 'print',
            scope: [
              'o{orgId}-configurations:r',
              'o{orgId}-configurations:w',
              'o{orgId}-badgePrinting:r',
              'o{orgId}-badgePrinting:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Intercom user directory',
            route: intercomUserDirectoryRoute,
            scope: [
              'o{orgId}-configurations:r',
              'o{orgId}-configurations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
        ],
      },
      {
        title: 'Presence',
        faIcon: 'clipboard',
        scope: [
          'o{orgId}-presence:r',
          'o{orgId}-presence:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        items: [
          {
            title: 'Presence report',
            route: presenceReportRoute,
            faIcon: 'clipboard',
            scope: [
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
              'o{orgId}-presence:r',
              'o{orgId}-presence:w',
            ],
          },
        ],
        hidden: mergedOpenpathConfig.PRESENCE_HIDDEN,
      },
      {
        title: 'Master dashboard',
        route: masterDashboardRoute,
        faIcon: 'chart-line',
        scope: [
          'o:r',
          'o:w',
          'o-basic:r',
          'o-basic:w',
          'i:r',
          'i:w',
          's-o:r',
          's-o:w',
        ],
        admin: true,
      },
      {
        title: 'Organizations',
        route: masterOrgTableRoute,
        faIcon: 'cube',
        scope: [
          'o:r',
          'o:w',
          'o-basic:r',
          'o-basic:w',
          's-o:r',
          's-o:w',
          'o-support:r',
          'o-support:w',
        ],
        admin: true,
        items: [
          {
            title: 'Create organization',
            route: masterOrgCreateRoute,
            faIcon: 'cube',
            scope: ['o:w', 'o-basic:w', 's-o:w'],
            hidden: true,
            admin: true,
          },
          {
            title: 'Edit organization',
            route: masterOrgEditRoute,
            faIcon: 'cube',
            scope: ['o:r', 'o:w', 'o-basic:r', 'o-basic:w', 's-o:r', 's-o:w'],
            hidden: true,
            admin: true,
          },
          {
            title: 'Edit organization package',
            route: masterOrgEditPackageRoute,
            faIcon: 'archive',
            scope: ['o:r', 'o:w', 'o-basic:r', 'o-basic:w', 's-o:r', 's-o:w'],
            hidden: true,
            admin: true,
          },
          {
            title: 'Edit organization feature flag',
            route: masterOrgEditFeatureFlagRoute,
            faIcon: 'cogs',
            scope: [
              'o:r',
              'o:w',
              's-o:r',
              's-o:w', // Writes will fail for s-o but they can still view them here...
              'o-support:r',
              'o-support:w',
            ],
            hidden: true,
            admin: true,
          },
        ],
      },
      {
        title: 'Identities',
        faIcon: 'users',
        scope: ['i:r', 'i:w'],
        admin: true,
        items: [
          {
            title: 'Identities',
            route: masterIdentitiesTableRoute,
            faIcon: 'user',
            scope: ['i:r', 'i:w'],
            admin: true,
            items: [
              {
                title: 'Edit Identity',
                route: masterIdentitiesEditRoute,
                faIcon: 'cube',
                scope: ['i:r', 'i:w'],
                hidden: true,
                admin: true,
              },
              {
                title: 'Edit identity security',
                route: masterIdentitiesEditSecurityRoute,
                scope: ['i:r', 'i:w'],
                hidden: true,
                admin: true,
              },
              {
                title: 'Create identity',
                route: masterIdentitiesCreateRoute,
                faIcon: 'cube',
                scope: ['i:r', 'i:w'],
                hidden: true,
                admin: true,
              },
            ],
          },
          {
            title: 'Roles',
            route: masterRolesTableRoute,
            faIcon: 'universal-access',
            scope: ['i:r', 'i:w'],
            admin: true,
            items: [
              {
                title: 'Edit role',
                route: masterRolesEditRoute,
                faIcon: 'cube',
                scope: ['i:r', 'i:w'],
                hidden: true,
                admin: true,
              },
              {
                title: 'Create role',
                route: masterRolesCreateRoute,
                faIcon: 'cube',
                scope: ['i:r', 'i:w'],
                hidden: true,
                admin: true,
              },
            ],
          },
        ],
      },
      {
        title: 'ACUs',
        route: masterAcusTableRoute,
        faIcon: 'microchip',
        scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
        admin: true,
        items: [
          {
            title: 'View ACU',
            route: masterAcusEditRoute,
            faIcon: 'microchip',
            scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
            hidden: true,
            admin: true,
          },
        ],
      },
      {
        title: 'Entries',
        route: masterEntriesTableRoute,
        faIcon: 'building',
        scope: ['o:r', 'o:w'], // no s-o here, since this page is new and we are migrating partners into Partner Center
        admin: true,
      },
      {
        title: 'Third-party devices',
        faIcon: 'puzzle-piece',
        route: masterThirdPartyDevicesRoute,
        scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
        admin: true,
      },
      {
        title: 'Allegion fobs',
        faIcon: 'tag',
        route: masterAllegionFobsRoute,
        scope: ['o:r', 'o:w'],
        admin: true,
      },
      {
        title: 'Feature flags',
        route: masterFeatureFlagsTableRoute,
        faIcon: 'cogs',
        scope: ['o:r', 'o:w', 'o-support:r', 'o-support:w'],
        admin: true,
        items: [
          {
            title: 'Create feature flag',
            route: masterFeatureFlagsCreateRoute,
            faIcon: 'cog',
            scope: ['o:w', 'o-support:w'],
            hidden: true,
            admin: true,
          },
          {
            title: 'Edit feature flag',
            route: masterFeatureFlagsEditRoute,
            faIcon: 'cog',
            scope: ['o:r', 'o:w', 'o-support:r', 'o-support:w'],
            hidden: true,
            admin: true,
          },
        ],
      },
      {
        title: 'System audit log',
        route: masterPortalAuditReportRoute,
        faIcon: 'clipboard',
        scope: ['o:r', 'o:w', 'o-support:r', 'o-support:w'],
        admin: true,
      },
      {
        title: 'ACU software report',
        faIcon: 'puzzle-piece',
        route: masterAcuSoftwareReportRoute,
        scope: [
          'o:r',
          'o:w',
          'o-acu:r',
          'o-acu:w',
          'o-support:r',
          'o-support:w',
        ],
        admin: true,
      },
      {
        title: 'Release notes',
        faIcon: 'list-alt',
        route: masterReleaseNotesRoute,
        scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
        admin: true,
      },
      {
        title: 'Marketing',
        route: null,
        faIcon: 'bullhorn',
        scope: ['s-o:r', 's-o:w'],
        admin: true,
        action: REQUEST_CHANNELTIVITY_LOGIN,
        external: true,
      },
      {
        title: 'Training',
        route: 'https://www.openpath.com/openpath-university',
        faIcon: 'chalkboard-teacher',
        scope: ['s-o:r', 's-o:w'],
        admin: true,
        external: true,
      },
      {
        title: 'Administration',
        faIcon: 'users-cog',
        scope: [
          'o{orgId}-admin:r',
          'o{orgId}-admin:w',
          'o{orgId}-account:r',
          'o{orgId}-account:w',
          'o{orgId}-quickStart:r',
          'o{orgId}-quickStart:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        items: [
          {
            title: 'Account',
            route: accountPageRoute,
            faIcon: 'user',
            scope: [
              'o{orgId}-admin:r',
              'o{orgId}-admin:w',
              'o{orgId}-account:r',
              'o{orgId}-account:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
          {
            title: 'Requests',
            faIcon: 'question-circle',
            route: licensesRoute,
            scope: [
              'o{orgId}-integrations:r',
              'o{orgId}-integrations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden:
              mergedOpenpathConfig.APP_MARKETPLACE_DISABLED ||
              orgConfig?.isLicenseBased ||
              !orgConfig?.hasAppMarketplace ||
              (orgConfig?.hasAppMarketplace &&
                orgConfig?.billingMode !== 'channel'),
          },
          {
            title: 'Licenses',
            faIcon: 'question-circle',
            route: licensesRoute,
            scope: [
              'o{orgId}-integrations:r',
              'o{orgId}-integrations:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
            hidden:
              mergedOpenpathConfig.APP_MARKETPLACE_DISABLED ||
              !orgConfig?.isLicenseBased,
          },
          {
            title: 'Quick start',
            route: quickStartRoute,
            faIcon: 'rocket',
            scope: [
              'o{orgId}-admin:r',
              'o{orgId}-admin:w',
              'o{orgId}-quickStart:r',
              'o{orgId}-quickStart:w',
              'o:w',
              'o:r',
              's-o:r',
              's-o:w',
            ],
          },
        ],
      },
      {
        title: 'Release notes',
        faIcon: 'list-alt',
        route: releaseNotesRoute,
        scope: [
          'o{orgId}-admin:r',
          'o{orgId}-admin:w',
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        alertCount: newReleaseNotesCount,
      },
      {
        title: 'Support',
        faIcon: 'question-circle',
        // route: supportRoute,
        scope: [],
        items: [
          {
            title: 'FAQs',
            faIcon: 'question-circle',
            external: true,
            route: 'https://opcx.org',
            scope: [],
          },
          {
            title: 'Installation guides',
            faIcon: 'wrench',
            external: true,
            route: 'https://www.openpath.com/installation-manuals',
            scope: [],
          },
          {
            title: 'Security docs',
            faIcon: 'shield-alt',
            external: true,
            route: 'https://www.openpath.com/request-security-docs',
            scope: [],
          },
          {
            title: 'Developer support',
            faIcon: 'laptop-code',
            external: true,
            route: 'https://openpath.readme.io/',
            scope: [],
          },
          {
            title: 'Licensing information',
            faIcon: 'info-circle',
            external: true,
            route: 'https://control.openpath.com/license-attribution.html',
            scope: [],
          },
        ],
      },
      {
        title: 'Developer menu',
        faIcon: 'laptop',
        scope: [],
        hidden: !mergedOpenpathConfig.SHOW_DEVELOPER_MENU,
        items: [
          {
            title: 'Semantic UI react (docs)',
            faIcon: 'question-circle',
            external: true,
            route: 'https://react.semantic-ui.com/',
            scope: [],
          },
        ],
      },
    ],
  };
};
