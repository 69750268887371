import { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import * as Sentry from '@sentry/react';

export interface BroadcastMessage {
  command?: string;
  target?: string;
  sender?: string;
}

interface BroadcastChannelMapEntry {
  channelRef: {
    current: BroadcastChannel<BroadcastMessage> | null;
  };
  refCount: number;
}

interface BroadcastChannelMap {
  [name: string]: BroadcastChannelMapEntry;
}

const broadcastChannelMap: BroadcastChannelMap = {};

// each tab/instance needs it's own unique ID
// This is useful in certain cases, e.g. so that it knows to ignore the disconnect messages it sent
const broadcastId = (Math.random() + 1).toString(36).substring(2);

const useBroadcastChannel = (name: string) => {
  if (!broadcastChannelMap[name]) {
    // Handle external memoization
    broadcastChannelMap[name] = {
      channelRef: {
        current: new BroadcastChannel(name),
      },
      refCount: 0, // refCount is handled in the effect
    };
  }

  useEffect(() => {
    if (!broadcastChannelMap[name].channelRef.current) {
      // Sometimes, this can be cleaned up in the interim, which is why we return a ref
      // and fix the current value here...
      broadcastChannelMap[name].channelRef.current = new BroadcastChannel(name);
    }

    // refCount incremented once per name per component
    broadcastChannelMap[name].refCount++;

    return () => {
      // cleanup this broadcast channel ref
      if (
        broadcastChannelMap[name]?.channelRef.current &&
        --broadcastChannelMap[name].refCount === 0
      ) {
        // <= instead of === just because...
        const {
          channelRef: { current: channelToRemove },
        } = broadcastChannelMap[name];

        broadcastChannelMap[name].channelRef.current = null;

        // no need to await close()
        channelToRemove.close().catch((e) => {
          console.error(e);

          Sentry.captureMessage('Issue broadcast channel', {
            contexts: {
              debugInfo: {
                error: JSON.stringify(e),
              },
            },
          });
        });
      }
    };
  }, [name]);

  return [broadcastChannelMap[name].channelRef, broadcastId] as const;
};

export default useBroadcastChannel;
