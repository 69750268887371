import { getLocation, getParsedByString } from 'jsonpos';
import { IAnnotation } from 'react-ace';
import { type Schema, ValidationError } from 'joi';

// Function to validate JSON and return errors
export const validateJSON = async (
  json: string,
  schema?: Schema,
): Promise<IAnnotation[] | undefined> => {
  try {
    const value = JSON.parse(json);

    if (!schema) {
      return [];
    }

    await schema.validateAsync(value, { abortEarly: false });

    return [];
  } catch (error) {
    if (error instanceof SyntaxError) {
      const message = error.message;
      const lineColumnMatch = message.match(/line (\d+) column (\d+)/);

      const row = lineColumnMatch ? parseInt(lineColumnMatch[1], 10) - 1 : 0; // Ace uses 0-based index for rows
      const column = lineColumnMatch ? parseInt(lineColumnMatch[2], 10) - 1 : 0;

      return [
        {
          row,
          column,
          text: message,
          type: 'error',
        },
      ];
    }

    if (error instanceof ValidationError) {
      const parsedJson = getParsedByString(json);

      return error.details
        .map((err) => {
          const path = err.path;

          /**
           * If a required member is missing that means the path won't exist.
           * Pop off the last segment of the JSON path to attach an error to
           * the parent key in the JSON structure.
           */
          if (
            err.message.match(/(is required|must contain at least one of)/i)
          ) {
            path.pop();
          }

          // Gets the column and line details from parsed JSON
          const location = getLocation(parsedJson, { path });

          // Don't attempt to add an annotation if column/line can't be identified
          if (!location.start) {
            return null;
          }

          const annotation: IAnnotation = {
            row: location.start.line - 1,
            column: location.start.column,
            text: err.message,
            type: 'error',
          };

          return annotation;
        })
        .filter<NonNullable<IAnnotation>>((annotation) => !!annotation);
    }
  }
};
