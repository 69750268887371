import React, {
  useEffect,
  forwardRef,
  useState,
  memo,
  useRef,
  useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';
import { LiveKitRoom, useLiveKitRoom } from '@livekit/components-react';

import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { connectionStates } from 'containers/OpVideoPlayer/constants';
import { useVideoState, VideoEvents } from '@openpathsec/opvideo-state-machine';
import { useSelectorJs } from 'utils/customHooks';
import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import { FETCH_ATTEMPTS, timeoutMs } from './constants';
import { LiveKitCallManager } from './components/LiveKitCallManager';

interface LiveKitVideoPlayerProps {
  room: any;
  token: string | undefined;
  wsUrl: string | undefined;
  connectionState: string;
  callState: string;
  orgId: number;
  opvideoDeviceId: number;
  currentUserId?: number;
  isMicrophoneEnabled: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setConnectionState: (state: string) => void;
  setCallState: (state: string) => void;
  setErrorProps: (props: object | null) => void;
}

interface ConfigInterface {
  wsUrl: string | null | undefined;
  token: string | null | undefined;
}

const GENERIC_ERROR_MSG =
  'Something went wrong when starting the video session';
const MM_ERROR_MSG =
  'This user does not have permission to view video on this camera';
const CONNECTION_TIMEOUT_MSG = 'Unable to load video';

const LiveKitVideoPlayer = forwardRef<
  HTMLVideoElement,
  LiveKitVideoPlayerProps
>(
  (
    {
      room: roomProp,
      token: tokenProp,
      wsUrl: wsUrlProp,
      connectionState,
      callState,
      orgId,
      opvideoDeviceId,
      currentUserId,
      isMicrophoneEnabled,
      setIsLoading,
      setConnectionState,
      setErrorProps,
      setCallState,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [config, setConfig] = useState<ConfigInterface | null>(null);
    const [shouldStartLive, setShouldStartLive] = useState(true); // legacy, do not repeat
    const noVideoDetectedTimeout = useRef<any>(null);

    const isMasterMode = !currentUserId;
    const hasAllParameters = [
      orgId,
      opvideoDeviceId,
      currentUserId!,
      { protocol: 'lk-v1' },
    ].every(Boolean);

    /*
      startLive triggers the start_live MQTT message on the intercom side
    */
    const { mutate: startLive, isError: startLiveDidError } = useOpMutation({
      apiEndpointName: 'opvideoDeviceStartLive',
      retry: FETCH_ATTEMPTS,
    });

    const generateUserLiveToken = useOpQuery({
      apiEndpointName: 'opvideoDeviceGenerateUserLiveToken',
      parameters: [
        orgId,
        opvideoDeviceId,
        currentUserId!,
        { protocol: 'lk-v1' },
      ],
      enabled: !isMasterMode && hasAllParameters,
      retry: FETCH_ATTEMPTS,
    });

    const { refetch: fetchUserToken } = generateUserLiveToken;

    const generateObserverLiveToken = useOpQuery({
      apiEndpointName: 'opvideoDeviceGenerateObserverLiveToken',
      parameters: [
        orgId,
        opvideoDeviceId,
        { protocol: 'lk-v1', startLive: true },
      ],
      enabled: isMasterMode,
      retry: 0,
    });

    const { room } = useLiveKitRoom({
      room: roomProp, // 99% of the time this will be null, if it's passed it will bypass everything else and just return the passed value
      serverUrl: undefined, // set below when we call .connect()
      token: undefined, // set below when we call .connect()
      connect: false,
    });

    const { sendEvent } = useVideoState();

    const isVideoStateMachineEnabled = useSelectorJs(
      selectFeatureFlag('IS_VIDEO_STATE_MACHINE_ENABLED'),
    );

    // on initial render, loader spinner should start spinning
    // spinner is stopped when player fires off a canplay event
    // here: https://github.com/OpenPathSec/Platinum/blob/4c9b5f77bd8bddcdb7e63cfd6f625c833913567c/app/containers/OpVideoPlayer/index.js#L414
    useEffect(() => {
      setIsLoading(true);
    }, [
      setIsLoading, // there is apparently no way to make this immutable short of 2 useCallbacks
    ]);

    // connect() ONLY on initial render
    useEffect(() => {
      if (!room) return;
      if (!config?.wsUrl || !config?.token) return;
      if (room.state !== 'disconnected') return;

      // eslint-disable-next-line no-console
      console.log('[LK] connect()');
      room.connect(config.wsUrl, config.token);
    }, [room, config]);

    const reconnect = useCallback(async () => {
      if (config?.wsUrl && config?.token && room?.state === 'disconnected') {
        // eslint-disable-next-line no-console
        console.log('[LK] reconnect()');
        room?.connect(config.wsUrl, config.token);
      }
    }, [room, config]);

    // Trigger call on the intercom
    useEffect(() => {
      if (orgId && opvideoDeviceId && currentUserId && shouldStartLive) {
        // on initial render, loader spinner should start spinning
        // spinner is stopped when player fires off a canplay event
        // here: https://github.com/OpenPathSec/Platinum/blob/4c9b5f77bd8bddcdb7e63cfd6f625c833913567c/app/containers/OpVideoPlayer/index.js#L414
        setIsLoading(true);
        reconnect();
        startLive({
          apiEndpointRequirements: [orgId, opvideoDeviceId, currentUserId],
          payload: { protocol: 'lk-v1' },
        });
        setShouldStartLive(false);
      }
    }, [
      orgId,
      opvideoDeviceId,
      currentUserId,
      startLive,
      shouldStartLive,
      setShouldStartLive,
      setIsLoading,
      reconnect,
    ]);

    // all token logic
    useEffect(() => {
      // we already have a token, we don't need to fetch a new one
      // in practice the actual token value that is being used can change without the
      // token state being updated (because it's happening entirely within the LK SDK)
      // but ultimately it doesn't really matter because what we really care about is presence
      // of token
      if (config?.token) return;

      // there was an error hitting the startLive Helium endpoint-
      // in practice this will almost never happen because that endpoint is just firing off an MQTT message
      if (startLiveDidError) {
        setConnectionState(connectionStates.CONNECTION_ERROR);
      }
    }, [config?.token, setConnectionState, startLiveDidError]);

    // handle errors encountered while fetching token
    const didEncounterErrorWhileFetchingToken =
      generateUserLiveToken?.error || generateObserverLiveToken?.error;

    const showErrorMessageAndStopSpinner = useCallback(
      (message: string, retryHandler: Function | undefined) => {
        setErrorProps({
          errorMessage: t(message),
          handleRetryClick: retryHandler,
        });
        setIsLoading(false);
      },
      [setErrorProps, setIsLoading, t],
    );

    useEffect(() => {
      if (didEncounterErrorWhileFetchingToken) {
        // stop the loading spinner and display a helpful error message to the user
        setConnectionState(connectionStates.CONNECTION_ERROR);
        showErrorMessageAndStopSpinner(GENERIC_ERROR_MSG, fetchUserToken);
      }
    }, [
      didEncounterErrorWhileFetchingToken,
      setConnectionState,
      fetchUserToken,
      showErrorMessageAndStopSpinner,
    ]);

    // sync from props -> state
    // this is used when "preloading" a token/wsUrl from a notification payload
    useEffect(() => {
      if (config) return; // we already set config

      // a token/wsUrl prop was passed and it hasn't been stored to our config state yet
      // this (should) only fire first render
      if (tokenProp || wsUrlProp) {
        setConfig({
          token: tokenProp,
          wsUrl: wsUrlProp,
        });
        if (isVideoStateMachineEnabled) {
          sendEvent({
            type: VideoEvents.UpdateCallData,
            // TODO: this should be used here when the changes from the next milestone of the video state machine are implemented.
            // deviceData: { opvideoIntercomId, opvideoDeviceId },
            roomData: { wsUrl: wsUrlProp, token: tokenProp },
          });
        }
      }
    }, [config, isVideoStateMachineEnabled, sendEvent, tokenProp, wsUrlProp]);

    // sync from generateUserLiveToken response -> state
    useEffect(() => {
      if (config) return; // we already set config

      // the call to generateUserLiveToken worked,
      // we should store that token to state
      if (generateUserLiveToken?.status === 'success') {
        const { token, wsUrl } = generateUserLiveToken.data?.json?.data || {};
        setConfig({ token, wsUrl });
        if (isVideoStateMachineEnabled) {
          sendEvent({
            type: VideoEvents.UpdateCallData,
            // TODO: this should be used here when the changes from the next milestone of the video state machine are implemented.
            // deviceData: { opvideoIntercomId, opvideoDeviceId },
            roomData: { wsUrl, token },
          });
        }
      }
    }, [
      config,
      generateUserLiveToken.status,
      generateUserLiveToken.data?.json?.data,
      isVideoStateMachineEnabled,
      sendEvent,
    ]);

    // sync from generateObserverLiveToken response -> state
    useEffect(() => {
      if (config) return; // we already set config
      if (!isMasterMode) return;

      // the call to generateObserverLiveToken worked,
      // we should store that token to state
      if (generateObserverLiveToken?.status === 'success') {
        const { token, wsUrl } =
          generateObserverLiveToken.data?.json?.data || {};
        setConfig({ token, wsUrl });
        if (isVideoStateMachineEnabled) {
          sendEvent({
            type: VideoEvents.UpdateCallData,
            // TODO: this should be used here when the changes from the next milestone of the video state machine are implemented.
            // deviceData: { opvideoIntercomId, opvideoDeviceId },
            roomData: { wsUrl, token },
          });
        }
      } else {
        // Display error message when token fetching fails for master mode user
        showErrorMessageAndStopSpinner(MM_ERROR_MSG, undefined);
      }
    }, [
      config,
      isMasterMode,
      generateObserverLiveToken.status,
      generateObserverLiveToken.data?.json?.data,
      showErrorMessageAndStopSpinner,
      isVideoStateMachineEnabled,
      sendEvent,
    ]);

    const clearConnectionTimeout = useCallback(() => {
      clearTimeout(noVideoDetectedTimeout.current);
      setErrorProps(null);
      if (ref !== null && typeof ref !== 'function') {
        const videoElement = ref?.current;
        videoElement?.removeEventListener('canplay', clearConnectionTimeout);
        videoElement?.removeEventListener('timeupdate', clearConnectionTimeout);
      }
    }, [ref, setErrorProps]);

    const startNoVideoDetectedTimeout = useCallback(() => {
      if (!noVideoDetectedTimeout.current) {
        noVideoDetectedTimeout.current = setTimeout(() => {
          if (ref !== null && typeof ref !== 'function') {
            const videoElement = ref?.current;
            const videoQuality = videoElement?.getVideoPlaybackQuality();
            if (videoQuality && videoQuality?.totalVideoFrames !== 0) {
              clearConnectionTimeout();
              return;
            }
          }
          setConnectionState(connectionStates.CONNECTION_ERROR);
          showErrorMessageAndStopSpinner(CONNECTION_TIMEOUT_MSG, undefined);
        }, timeoutMs.TRACK_DETECTED);
      }
    }, [
      clearConnectionTimeout,
      ref,
      setConnectionState,
      showErrorMessageAndStopSpinner,
    ]);

    // Start timeout if no tracks detected
    useEffect(() => {
      if (ref !== null && typeof ref !== 'function') {
        startNoVideoDetectedTimeout();
        const videoElement = ref?.current;
        videoElement?.addEventListener('canplay', clearConnectionTimeout);
        videoElement?.addEventListener('timeupdate', clearConnectionTimeout);

        return () => {
          videoElement?.removeEventListener('canplay', clearConnectionTimeout);
          videoElement?.removeEventListener(
            'timeupdate',
            clearConnectionTimeout,
          );
        };
      }
    }, [clearConnectionTimeout, ref, startNoVideoDetectedTimeout]);

    return (
      config && (
        <LiveKitRoom
          room={room}
          serverUrl={undefined}
          token={undefined}
          connect={false}
          onError={() => {
            console.error(
              'Error connecting to remote video server, please check for downtime at https://status.openpath.com or reach out to support to check your firewall',
            );
          }}
        >
          <LiveKitCallManager
            connectionState={connectionState}
            callState={callState}
            setIsLoading={setIsLoading}
            setShouldStartLive={setShouldStartLive}
            setErrorProps={setErrorProps}
            setCallState={setCallState}
            setConnectionState={setConnectionState}
            isMicrophoneEnabled={isMicrophoneEnabled}
            ref={ref}
          />
        </LiveKitRoom>
      )
    );
  },
);

export default memo(LiveKitVideoPlayer);
