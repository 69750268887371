import difference from 'lodash/difference';
import { ProfileFormValues } from '../components/types';

interface NicknamesPayload {
  add?: { nickname: string }[];
  remove?: { nickname: string }[];
}

export const createProfileInfoPayloadsFromValues = (
  {
    nicknames, // For updateIdentityNicknames
    ...updateIdentitiesPayload // For updateIdentity
  }: Partial<ProfileFormValues>,
  initialValues: ProfileFormValues,
) => {
  const updateIdentityNicknamesPayload = {} as NicknamesPayload;

  // If initial and current nicknames are different, add the diffs to the payload
  if (
    nicknames &&
    JSON.stringify(initialValues.nicknames) !== JSON.stringify(nicknames)
  ) {
    // Use lodash difference to check if any initial nicknames are not in the current nicknames (https://lodash.com/docs/4.17.15#difference)
    const nicknamesToRemove = difference(
      initialValues.nicknames,
      nicknames,
    ).map((nickname) => ({ nickname }));

    // Use lodash difference to check if any current nicknames are not in the initial nicknames (https://lodash.com/docs/4.17.15#difference)
    const nicknamesToAdd = difference(
      nicknames,
      initialValues.nicknames || [],
    ).map((nickname) => ({ nickname }));

    // Only update nicknames to remove if there are any
    if (nicknamesToRemove.length) {
      updateIdentityNicknamesPayload.remove = nicknamesToRemove;
    }

    // Only update nicknames to add if there are any
    if (nicknamesToAdd.length) {
      updateIdentityNicknamesPayload.add = nicknamesToAdd;
    }
  }

  return {
    updateIdentitiesPayload,
    updateIdentityNicknamesPayload,
  } satisfies {
    updateIdentitiesPayload: Partial<ProfileFormValues>;
    updateIdentityNicknamesPayload: NicknamesPayload;
  };
};
