// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.op-user-avatar{color:var(--colorWhite);object-position:center;object-fit:cover;border-radius:var(--borderRadius);background-color:var(--colorHeaderButtonBg) !important}.op-user-avatar__placeholder{width:100%;height:100%;color:var(--colorTextBase);display:flex;justify-content:center;align-items:center}img.op-user-avatar:not([src]){content:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}`, "",{"version":3,"sources":["webpack://./app/new-components/OpUserAvatar/OpUserAvatar.scss"],"names":[],"mappings":"AAAA,gBACE,uBAAA,CACA,sBAAA,CACA,gBAAA,CACA,iCAAA,CACA,sDAAA,CAEA,6BACE,UAAA,CACA,WAAA,CACA,0BAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAIJ,8BACE,+CAAA","sourcesContent":[".op-user-avatar {\n  color: var(--colorWhite);\n  object-position: center;\n  object-fit: cover;\n  border-radius: var(--borderRadius);\n  background-color: var(--colorHeaderButtonBg) !important;\n\n  &__placeholder {\n    width: 100%;\n    height: 100%;\n    color: var(--colorTextBase);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\nimg.op-user-avatar:not([src]) {\n  content: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
