import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpTextButton } from 'new-components/DLS/OpTextButton/OpTextButton';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormTextButton.scss';

interface OpFormTextButtonProps extends ComponentProps<typeof OpButton> {
  formItemClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export const OpFormTextButton = ({
  // Form Item props
  formItemClassName,

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  className,

  disabled,
  ...elementProps
}: OpFormTextButtonProps) => {
  /** The Form is wrapped in a provider that passes the loading and readOnly
   * contexts to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item className={clsx('op-form-text-button', formItemClassName)}>
      {isDataLoading || isLoading ? (
        /** A placeholder in the shape of the component
         * so we can immediately render the structure of the form while
         * data is loading */
        <OpSkeleton.Button active block={elementProps.block} />
      ) : (
        /** The actual component that is rendered when we have the data */
        <OpTextButton
          className={clsx('op-form-text-button__button', className)}
          disabled={isReadOnly || disabled}
          {...elementProps}
        />
      )}
    </OpForm.Item>
  );
};
