import { fromJS } from 'immutable';
import { SET_ACTIVITY_RANGE_HOURS } from './constants';

// Initial access token state
const activityLogsInitialState = fromJS({
  activityRangeHours: 1,
});

function bannerReducer(state = activityLogsInitialState, action) {
  switch (action?.type) {
    case SET_ACTIVITY_RANGE_HOURS:
      return state.set('activityRangeHours', action.activityRangeHours);
    default:
      return state;
  }
}

export default bannerReducer;
