import React from 'react';
import { getRawMenuItems, getItemConfig } from 'config/utils';
import { alarmEditRoute as route } from 'routes/constants';
import { PAGE, TABLE, USERS_OMNI } from './constants';

export const scope = getItemConfig(getRawMenuItems(), route).scope;
const RouteComponent = React.lazy(() => import('.'));

export default {
  RouteComponent,
  RouteImportPath: 'AlarmDashboardPage/AlarmEditPage',
  path: route,
  exact: true,
  name: PAGE,
  scope,
  reducers: [
    {
      key: PAGE,
      reducer: require('./reducer').default,
    },
  ],
  sagas: [
    {
      key: PAGE,
      saga: require('./sagas').default,
    },
    {
      key: USERS_OMNI,
      saga: require('containers/OmniSelect/sagas').default,
    },
    {
      key: TABLE,
      saga: require('containers/Table/sagas').default,
    },
  ],
};
