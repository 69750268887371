import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';

interface NavContextProps {
  hasUserSelectedNewNav?: boolean;
  setHasUserSelectedNewNav: (value: boolean) => void;
  hasNewNavFeature: boolean | null | undefined;
}

const NavContext = createContext<NavContextProps | undefined>(undefined);

export const NavProvider = ({ children }: { children: ReactNode }) => {
  const hasNewNavFeature = useSelector(selectFeatureFlag('IS_USING_NEW_NAV'));

  const [hasUserSelectedNewNav, setHasUserSelectedNewNav] =
    useLocalStorage<boolean>('hasUserSelectedNewNav', false);
  const contextValue = useMemo(
    () => ({
      hasUserSelectedNewNav,
      setHasUserSelectedNewNav,
      hasNewNavFeature,
    }),
    [hasUserSelectedNewNav, hasNewNavFeature, setHasUserSelectedNewNav],
  );

  return (
    <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
  );
};

export const useNav = (): NavContextProps => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error('useNav must be used within a NavProvider');
  }
  return context;
};
