import { CsvExportButton } from 'new-components/DLS/OpTableCore/components';
import { HELIUM_LIMIT_MAX } from 'utils/constants';
import { useOpQueries } from 'utils/customHooks/useOpQueries';
import { UseOpQueryOptions } from 'utils/customHooks/useOpQuery';
import { TableState } from 'new-components/DLS/OpTable/OpTable';
import {
  OpTableColumn,
  OpTableRecordType,
} from 'new-components/DLS/OpTableCore/OpTableCore';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { ensurePayloadAndQuery } from 'utils/ensurePayloadAndQuery';
import { createQueryStringParamsFromTableState } from '../createQueryStringParamsFromTableState';

interface DataFetchCsvExportButtonProps<T extends keyof Api.ClientSpec> {
  opQueryProps: UseOpQueryOptions<T>;
  debouncedTableState: TableState;
  filteredCount: number;
  columnsFilterCallback?: (column: OpTableColumn) => boolean;
  filename?: string;
}

// Specific to non-cursor based data fetch tables
export const DataFetchCsvExportButton = <T extends keyof Api.ClientSpec>({
  opQueryProps,
  debouncedTableState,
  filteredCount,
  columnsFilterCallback,
  filename,
}: DataFetchCsvExportButtonProps<T>) => {
  let recordsRemainingToFetch = filteredCount;
  let offset = 0;
  const queries = [];

  const { parametersArray, withQuery } = ensurePayloadAndQuery(
    opQueryProps.apiEndpointName,
    opQueryProps.parameters,
  );

  // Find the index of the queries (last object)
  const queriesIndex = parametersArray.findIndex(
    (param?: number | string | {} | null) => typeof param === 'object',
  );

  if (withQuery && queriesIndex === -1) {
    // ensurePayloadAndQuery guarantees the presence of a queries object, so throw since something is way off...
    throw new Error(
      `Critical: ensurePayloadAndQuery did not ensure a query object for API endpoint ${opQueryProps.apiEndpointName}`,
    );
  }

  while (recordsRemainingToFetch > 0) {
    if (withQuery) {
      // Add queryStringParams to their correct spot in parameters array
      parametersArray[queriesIndex] = {
        ...(parametersArray[queriesIndex] as Record<string, any>),
        ...createQueryStringParamsFromTableState({
          tableState: debouncedTableState,
          offsetForCsv: offset,
        }),
      };
    }

    queries.push({
      ...opQueryProps,
      parameters: [...parametersArray],
      enabled: false,
    });

    offset += HELIUM_LIMIT_MAX;
    recordsRemainingToFetch -= HELIUM_LIMIT_MAX;
  }

  const queryResults = useOpQueries(queries as never);

  const queriesAreFetching = queryResults.some(({ isFetching }) => isFetching);

  const formattedData = queryResults.reduce((acc, { data }) => {
    // @ts-expect-error Not sure if this is inferrable nor if it matters since this is a generic component
    return acc.concat(data?.json?.data || []);
  }, []);

  const clonedFormattedData = cloneDeep(formattedData);

  // Can filter columns with createColumns
  const computedColumns = debouncedTableState.columns.filter(
    columnsFilterCallback || (() => true),
  );

  computedColumns.forEach(({ key, createCsvExportCellValue }) => {
    if (createCsvExportCellValue) {
      clonedFormattedData.forEach(
        (record: OpTableRecordType, index: number) => {
          /** We are updating the record in clonedFormattedData so that the original
           * data is not changed. We must do this as React-query stores the reference
           * to the same api calls in cache and even though it is a different call and
           * seemingly a different object, if we don't update a copy, it will actually
           * update the ref to all data made from the same API call. */
          set(
            record,
            String(key),
            createCsvExportCellValue(formattedData[index]),
          );
        },
      );
    }
  });

  // Manually fetches each query on export button click
  const onExportClick = () => {
    queryResults.forEach((result: any) => result.refetch());
  };

  return (
    <CsvExportButton
      isLoading={queriesAreFetching}
      onClick={onExportClick}
      data={clonedFormattedData}
      columns={computedColumns}
      filename={filename}
    />
  );
};
