import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';
import { VideoRegionSelect } from './VideoRegionSelect';

import './OpFormVideoRegionSelect.scss';

interface OpFormVideoRegionSelectProps
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      'name' | 'label' | 'rules' | 'tooltip'
    >,
    Omit<ComponentProps<typeof VideoRegionSelect>, 'name' | 'tooltip'> {
  formItemClassName?: string;
  isLoading?: boolean;
  showAnnotation?: boolean;
  disabled?: boolean;
}

export const OpFormVideoRegionSelect = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,
  rules = [],

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  ...elementProps
}: OpFormVideoRegionSelectProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx('op-form-video-region-select', formItemClassName)}
      name={name}
      label={label}
      tooltip={tooltip}
      validateTrigger="onSubmit"
      rules={rules}
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Node
          active
          className="op-form-video-region-select__skeleton"
        >
          <VideoCameraOutlined className="op-form-video-region-select__skeleton-icon" />
        </OpSkeleton.Node>
      ) : (
        <VideoRegionSelect
          disabled={Boolean(isReadOnly || elementProps.disabled)}
          {...elementProps}
        />
      )}
    </OpForm.Item>
  );
};
