import { useEffect, useState } from 'react';

function useIsSlideOutChild(selfQueryAll) {
  const [isInSideBar, setIsInSideBar] = useState(false);
  useEffect(() => {
    // We check to see if the direct parent of the Container is a SlideOut and if so change the styles accordingly
    const thisContentContainer = document.querySelectorAll(selfQueryAll);
    const sidebar = document.querySelectorAll('.sidebar');
    const drawer = document.querySelectorAll('.op-drawer');

    const hasUserSelectedSuirRemovedUi =
      localStorage.getItem('hasUserSelectedSuirRemovedUi') === 'true';

    const slideout = hasUserSelectedSuirRemovedUi ? drawer : sidebar;

    let test = false;
    if (slideout && thisContentContainer[thisContentContainer.length - 1]) {
      slideout.forEach((sb) => {
        test =
          test ||
          sb.contains(thisContentContainer[thisContentContainer.length - 1]);
      });
    }
    setIsInSideBar(test);
  }, [selfQueryAll]);
  return isInSideBar;
}

export default useIsSlideOutChild;
