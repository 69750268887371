import { ModalFunc } from 'antd/es/modal/confirm';
import { OpApp } from 'new-components/DLS/OpApp/OpApp';

export const useConfirmModal: () => ModalFunc = () => {
  /** Need to wrap in antd App so modal confirm works with dark mode theme
   * https://github.com/ant-design/ant-design/issues/40576 */
  const { modal } = OpApp.useApp();

  return (modalFuncProps) =>
    modal.confirm({
      centered: true,
      ...modalFuncProps,
    });
};
