import {
  OnSubmitArgs,
  OpForm,
  OpFormInstance,
} from 'new-components/DLS/OpForm/OpForm';
import { useTranslation } from 'react-i18next';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { sortOptionsAlphabetically } from 'utils/sortOptionsAlphabetically';
import { OpRow } from 'new-components/DLS/OpRow/OpRow';
import { OpCol } from 'new-components/DLS/OpCol/OpCol';
import ImagePlaceholderDark from 'assets/img/image-placeholder-dark.png';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { useValidateScopes } from 'utils/customHooks/useValidateScopes';
import { OpCarousel } from 'new-components/DLS/OpCarousel/OpCarousel';
import { useShowSitePartitioningItems } from 'utils/customHooks/useShowSitePartitioningItems';
import {
  parcelStatusCodeToId,
  getParcelStatusOptions,
  parcelStatusCodes,
} from './helpers/constants';
import { ParcelHistoryTable } from './ParcelHistoryTable';
import { ParcelMessageModal } from './ParcelMessageModal';

import './ParcelEditForm.scss';

interface ParcelEditFormValues {
  recipientId: string;
  recipientEmail: string;
  recipientNicknames: string[] | undefined;
  mailroomId: string;
  parcelStatusCode: string;
  trackingNumber: string;
  carrier: string;
  sender: string;
}

export const ParcelEditForm = ({
  form,
  className,
  orgId,
  parcelIdExt,
  ...opFormProps
}: {
  form?: OpFormInstance<ParcelEditFormValues>;
  className?: string;
  orgId: number;
  parcelIdExt: string;
}) => {
  const { t } = useTranslation();
  const { isValid: hasWriteAccess } = useValidateScopes({
    allowedScopes: ['o{orgId}-parcelMgmtParcels:w', 'o:w', 's-o:w'],
  });

  const [openModal, setOpenModal] = useState<{
    modal: null | string;
    recipientId: string;
    extraInfo: string;
  }>({
    modal: null,
    recipientId: '',
    extraInfo: '',
  });

  const { isShowingSitePartitioningItems } =
    useShowSitePartitioningItems(orgId);

  // Keep recipient fields in sync
  const recipientId = OpForm.useWatch('recipientId', form);

  const { data: recipientData } = useOpQuery({
    apiEndpointName: 'describeUser',
    parameters: [orgId, Number(recipientId)],
    select: (data) => data.json.data,
  });

  // Update the recipient fields when the selected recipient changes
  useEffect(() => {
    form?.setFieldsValue({
      recipientEmail: recipientData?.identity?.email || '',
      recipientNicknames: recipientData?.identity?.nicknames?.map(
        ({ nickname }) => nickname,
      ) as string[] | undefined,
    });
  }, [form, recipientData]);

  // Update the recipience when the status field changes to Unidentified recipience
  const parcelStatus = OpForm.useWatch('parcelStatusCode', form);
  useEffect(() => {
    if (parcelStatus === parcelStatusCodes.unidentifiedRecipient) {
      form?.setFieldsValue({
        recipientId: '',
        recipientEmail: '',
        recipientNicknames: [],
      });
    }
  }, [parcelStatus, form]);

  const {
    data: parcel,
    isPending,
    isError,
  } = useOpQuery({
    apiEndpointName: 'describeParcel',
    parameters: [orgId, parcelIdExt],
    enabled: Boolean(parcelIdExt),
    select: (data) => data.json.data,
  });

  const updateParcelMutation = useOpMutation({
    apiEndpointName: 'updateParcel',
    onSuccessMessage: 'Successfully updated package',
    queryKeysToInvalidate: [['describeParcel'], ['listParcels']],
  });

  const handleMessageRecipient = () => {
    setOpenModal({
      modal: 'messageRecipients',
      recipientId: parcelIdExt,
      extraInfo: recipientData?.identity?.email || '',
    });
  };

  const handleModalUpdateSuccess = () => {
    setOpenModal({ modal: null, recipientId: '', extraInfo: '' });
  };

  const handleDisableMessageRecipient = () => {
    return !!(form?.isFieldTouched('recipientId') || !recipientId);
  };

  const handleSubmit = async ({ touchedValues }: OnSubmitArgs) => {
    const { parcelStatusCode, ...rest } = touchedValues;
    if (typeof rest.recipientEmail !== 'undefined') {
      delete rest.recipientEmail;
    }
    if (typeof rest.recipientNicknames !== 'undefined') {
      delete rest.recipientNicknames;
    }
    const payload = {
      ...rest,
      ...(parcelStatusCode && {
        parcelStatusId: parcelStatusCodeToId[parcelStatusCode],
      }),
    };

    updateParcelMutation.mutate({
      apiEndpointRequirements: [orgId, parcelIdExt],
      payload,
    });
  };

  const initialValues = useMemo(
    () => ({
      recipientId: parcel?.recipient?.id,
      recipientEmail: parcel?.recipient?.identity.email,
      recipientNicknames:
        parcel?.recipient?.identity?.nicknames?.map(
          ({ nickname }) => nickname,
        ) ?? [],
      mailroomId: parcel?.mailroom?.id,
      parcelStatusCode: parcel?.parcelStatus?.code,
      trackingNumber: parcel?.trackingNumber,
      carrier: parcel?.carrier,
      sender: parcel?.sender,
    }),
    [parcel],
  );

  // TODO - https://openpath.atlassian.net/browse/OPAC-10669 -
  // base this on light/dark mode when implemented
  const imagePlaceholder = ImagePlaceholderDark;

  const carouselPhotos = [...(parcel?.parcelPictures ?? [])];
  carouselPhotos.sort(
    (picA, picB) => picB.parcelPictureType!.id - picA.parcelPictureType!.id,
  );
  const parcelStatusOptions = useMemo(() => getParcelStatusOptions(), []);

  return (
    <>
      <OpForm
        form={form}
        className={clsx('parcel-edit-form', className)}
        initialValues={initialValues}
        isReadOnly={!hasWriteAccess}
        testId="parcel-edit-form"
        isLoading={isPending}
        onSubmit={handleSubmit}
        hasError={isError}
        {...opFormProps}
        defaultButtons={false}
      >
        <OpRow gutter={[32, 32]}>
          <OpCol span={16}>
            <OpForm.DataFetchSelect
              testId="parcel-recipient"
              label={t('Recipient')}
              name="recipientId"
              pathToValue="id"
              pathToLabel="identity.fullName"
              queryOptions={{
                apiEndpointName: 'listUsers',
                parameters: [
                  orgId,
                  {
                    filter: 'status:(!=I)',
                    ...(isShowingSitePartitioningItems && {
                      options: 'withUserSites',
                    }),
                  },
                ],
              }}
              filterSort={sortOptionsAlphabetically}
            />
            <OpForm.Select
              disabled
              testId="parcel-recipient-nicknames"
              mode="multiple"
              label={t('Nicknames')}
              name="recipientNicknames"
            />
            <OpForm.Input
              testId="parcel-recipient-email"
              disabled
              label={t('Email')}
              name="recipientEmail"
            />
          </OpCol>
          <OpCol span={8}>
            {carouselPhotos.length ? (
              <OpCarousel arrows>
                {carouselPhotos.map((pic) => (
                  <img
                    className="parcel-edit-form__photo"
                    alt={t('package {{typeName}}', {
                      typeName: pic.parcelPictureType?.name,
                    })}
                    src={pic.url ?? imagePlaceholder}
                  />
                ))}
              </OpCarousel>
            ) : (
              <img
                className="parcel-edit-form__photo"
                alt={t('no image')}
                src={imagePlaceholder}
              />
            )}
          </OpCol>
        </OpRow>
        <OpForm.Button
          testId="parcel-message-recipient"
          onClick={() => handleMessageRecipient()}
          disabled={handleDisableMessageRecipient()}
        >
          {t('Message recipient')}
        </OpForm.Button>
        <OpForm.Select
          testId="parcel-status"
          rules={[
            {
              required: true,
              message: t('Package status is required'),
            },
          ]}
          label={t('Status')}
          name="parcelStatusCode"
          options={parcelStatusOptions}
        />
        <OpForm.Input
          testId="parcel-tracking-number"
          label={t('Tracking number')}
          name="trackingNumber"
        />
        <OpForm.Input
          testId="parcel-carrier"
          label={t('Carrier')}
          name="carrier"
        />
        <OpForm.Input
          testId="parcel-sender"
          label={t('Sender')}
          name="sender"
        />
        <OpForm.DataFetchSelect
          testId="parcel-mailroom"
          label={t('Mailroom')}
          name="mailroomId"
          pathToValue="id"
          pathToLabel="name"
          rules={[
            {
              required: true,
              message: t('Mailroom is required'),
            },
          ]}
          queryOptions={{
            apiEndpointName: 'listMailrooms',
            parameters: [orgId],
          }}
          filterSort={sortOptionsAlphabetically}
        />
      </OpForm>
      <ParcelMessageModal
        isOpen={openModal.modal === 'messageRecipients'}
        onClose={() =>
          setOpenModal({ modal: null, recipientId: '', extraInfo: '' })
        }
        onUpdate={handleModalUpdateSuccess}
        orgId={orgId}
        selectedIds={[openModal.recipientId]}
        extraInfo={[openModal.extraInfo]}
      />
      <ParcelHistoryTable orgId={orgId} parcelIdExt={parcelIdExt} />
    </>
  );
};
