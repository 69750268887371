import { fromJS } from 'immutable';

import {
  SET_TABLE_HAS_BEEN_UPDATED,
  SET_TABLE_LOADING,
  SET_TABLE_DATA_PERCENT_LOADED,
  SET_COLUMN_WIDTHS,
  SET_PAGE_COUNT,
  SET_CSV_DATA_LOADING,
  SET_CSV_DATA_PERCENT_LOADED,
  LOADING,
  PERCENT_LOADED,
  COLUMN_WIDTHS,
  PAGE_COUNT,
  CSV_DATA,
  CSV_DATA_LOADING,
  CSV_DATA_PERCENT_LOADED,
  SET_TABLE_STATE,
  TABLE_HAS_BEEN_UPDATED,
  TABLE_STATE,
  SET_CSV_DATA,
} from './constants';

const initialState = fromJS({
  // Loaders
  [LOADING]: false,
  [PERCENT_LOADED]: 0,

  // Table state
  [TABLE_HAS_BEEN_UPDATED]: false,
  [COLUMN_WIDTHS]: [],
  [TABLE_STATE]: {},
  [PAGE_COUNT]: 0, // Controlled table pagination

  // CSV
  [CSV_DATA]: [],
  [CSV_DATA_LOADING]: false,
  [CSV_DATA_PERCENT_LOADED]: 0,
});

function tableReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TABLE_HAS_BEEN_UPDATED:
      return state.set(TABLE_HAS_BEEN_UPDATED, action[TABLE_HAS_BEEN_UPDATED]);

    // Loaders
    case SET_TABLE_LOADING:
      return state.set(LOADING, action[LOADING]);
    case SET_TABLE_DATA_PERCENT_LOADED:
      return state.set(PERCENT_LOADED, action[PERCENT_LOADED]);

    // Table state
    case SET_TABLE_STATE:
      return state.set(TABLE_STATE, fromJS(action.tableState));
    case SET_COLUMN_WIDTHS:
      return state.set(COLUMN_WIDTHS, fromJS(action[COLUMN_WIDTHS]));

    // Always set page count on page
    case SET_PAGE_COUNT:
      return state.set(PAGE_COUNT, action[PAGE_COUNT]);

    // CSV
    case SET_CSV_DATA:
      return state.set(CSV_DATA, fromJS(action[CSV_DATA]));
    case SET_CSV_DATA_LOADING:
      return state.set(CSV_DATA_LOADING, action[CSV_DATA_LOADING]);
    case SET_CSV_DATA_PERCENT_LOADED:
      return state.set(
        CSV_DATA_PERCENT_LOADED,
        action[CSV_DATA_PERCENT_LOADED],
      );
    default:
      return state;
  }
}

export default tableReducer;
