import { getAltaDomain } from 'utils/domains';

/**
 * @description gets the routing basename for the app in question. It takes the app name
 * to use as part of the base name for localhost (http://localhost/appName). Otherwise the
 * basename is just ''
 * @returns {string} the proper routing basename based on the current environment
 */
export const getBasenameForApp = (appName: string) =>
  getAltaDomain() ? '' : `/${appName}`;
