import { ComponentProps, ReactNode } from 'react';
import Switch from 'antd/es/switch';
import clsx from 'clsx';
import { OpInfoTooltip } from '../OpInfoTooltip/OpInfoTooltip';

import './OpSwitch.scss';

interface OpSwitchProps extends ComponentProps<typeof Switch> {
  testId?: string;
  label?: string;
  tooltip?: ReactNode;
  gtm?: string;
}

export const OpSwitch = ({
  className,
  testId = 'op-switch',
  gtm,
  label,
  tooltip,
  ...switchProps
}: OpSwitchProps) => {
  // When a label is passed we generate an id for the switch to be used in the aria-labelledby attribute
  const id = label ? label.replace(/\s+/g, '-').toLowerCase() : undefined;

  return (
    <div className="op-switch-container">
      <Switch
        className={clsx('op-switch', className)}
        data-testid={testId}
        data-gtm={gtm}
        {...(label && { 'aria-labelledby': id })} // For accessibility and tests
        {...switchProps}
      />
      {label && (
        <span id={id} className="op-switch-container__label">
          {label}
        </span>
      )}
      {tooltip && (
        <OpInfoTooltip
          className="op-switch-container__tooltip"
          title={tooltip}
        />
      )}
    </div>
  );
};
