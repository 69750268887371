import { OpTableRawColumnType } from '../OpTableCore/OpTableCore';
import { TableState } from './OpTable';

export const opTablePersistedUiVersion = 1;

export interface OpTableCorePersistedState {
  version: number;
  columns: OpTableRawColumnType[]; // TODO: FIX THIS TYPE
  filters: TableState['filters'];
  sorter: TableState['sorter'];
}

export const getStateToPersist = (
  tableState: Pick<TableState, 'columns' | 'filters' | 'sorter'>,
) => {
  return {
    version: opTablePersistedUiVersion,
    columns: tableState.columns,
    filters: tableState.filters,
    sorter: tableState.sorter,
  } as OpTableCorePersistedState;
};
