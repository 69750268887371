import clsx from 'clsx';
import { CustomIconComponentProps, OpIcon } from 'new-components/OpIcon/OpIcon';
import { SignalQualityDisconnected } from 'new-components/svgs/SignalQualityDisconnected';
import { SignalQualityHigh } from 'new-components/svgs/SignalQualityHigh';
import { SignalQualityLow } from 'new-components/svgs/SignalQualityLow';
import { SignalQualityMedium } from 'new-components/svgs/SignalQualityMed';

// These values comes from shadow state
export type SignalQuality = 'high' | 'med' | 'low' | 'unknown';

interface OpSignalQualityIconProps extends CustomIconComponentProps {
  variant: SignalQuality;
}

const iconMap = {
  high: SignalQualityHigh,
  med: SignalQualityMedium,
  low: SignalQualityLow,
  unknown: SignalQualityDisconnected,
};

export const OpSignalQualityIcon = ({
  className,
  variant,
}: OpSignalQualityIconProps) => {
  const iconComponent = iconMap[variant];

  return (
    <OpIcon
      component={iconComponent}
      className={clsx(
        'op-signal-quality-icon',
        `op-signal-quality-icon--${variant}`,
        className,
      )}
    />
  );
};
