import { HELIUM_LIMIT_MAX } from 'utils/constants';
import { TableState } from '../OpTable/OpTable';

// TODO - add the pre chars to the filters
export const createQueryStringParamsFromTableState = ({
  tableState,
  offsetForCsv,
}: {
  tableState: TableState;
  offsetForCsv?: number;
}) => {
  // Create the final filter string
  const finalFilter = Object.entries(tableState.filters)
    // Filter out any falsy values
    // Type is string so don't need to worry about 0
    .filter(([, value]) => value)
    // Values getting = prefix when created so don't need to add here
    .map(([key, value]) => `${key}:(${value})`)
    // Join each filter to make the filter string
    .join(' ');

  return {
    /** For CSV export we need to loop through to get all records needed
     * so we set the limit to the Helium max and then pass in increasing
     * offsets until we have all the records */
    ...(typeof offsetForCsv === 'number'
      ? {
          limit: HELIUM_LIMIT_MAX,
          offset: offsetForCsv,
        }
      : {
          limit: tableState.pagination.pageSize,
          offset:
            (tableState.pagination.current - 1) *
            tableState.pagination.pageSize,
        }),

    // Only add filter if the final filter string has a value
    ...(finalFilter && { filter: finalFilter }),

    // sorter can optionally be an array, so we need to type narrow
    ...(!Array.isArray(tableState.sorter) &&
      // Only add sort and order if the sorter object contain an order value
      tableState.sorter?.order && {
        sort: tableState.sorter.columnKey,
        order: tableState.sorter.order === 'ascend' ? 'asc' : 'desc',
      }),

    // Only add q if the param has a value
    ...(tableState.q && { q: tableState.q }),
  };
};
