import isArray from 'lodash/isArray';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOpQuery } from 'utils/customHooks/useOpQuery';

export const ALL_MAILROOMS_VALUE = 'all-mailrooms';

/**
 * @description hook to handle mailroom filter
 * @param {number} orgId - orgId to retrieve the mailrooms for
 * @returns {object}
 *    mailroomOptions - mailroom options for a select, including an All mailrooms option
 *    selectedMailroom - the currently selected mailroom. Defaults to all mailrooms
 *    handleMailroomChange - updates the selectedMailroom
 *    isPending - flag for useOpQuery isPending for listMailrooms call
 */
export const useMailroomSelect = ({
  orgId,
  onMailroomChange,
}: {
  orgId: number;
  onMailroomChange?(): void;
}) => {
  const { t } = useTranslation();
  const [selectedMailroom, setSelectedMailroom] = useState(ALL_MAILROOMS_VALUE);
  const { data: mailroomData, isPending } = useOpQuery({
    apiEndpointName: 'listMailrooms',
    parameters: [orgId],
    select: (data) => data.json.data ?? [],
  });

  const mailroomOptions = isPending
    ? []
    : [
        {
          label: t('All mailrooms'),
          value: ALL_MAILROOMS_VALUE,
        },
        ...(mailroomData?.map((mailroom) => ({
          label: mailroom.name,
          value: String(mailroom.id),
        })) ?? []),
      ];

  const handleMailroomChange = (mailroom: string | string[]) => {
    // It should always be a string here
    if (!isArray(mailroom)) {
      setSelectedMailroom(mailroom);
      onMailroomChange?.();
    }
  };

  return {
    selectedMailroom,
    handleMailroomChange,
    mailroomOptions,
    isPending,
  };
};
