import { ComponentProps, ReactNode } from 'react';
import Checkbox from 'antd/es/checkbox';
import clsx from 'clsx';
import { OpInfoTooltip } from '../OpInfoTooltip/OpInfoTooltip';

import './OpCheckbox.scss';

interface OpCheckboxGroupProps extends ComponentProps<typeof Checkbox.Group> {
  testId?: string;
}

const OpCheckboxGroup = ({
  className,
  testId = 'op-checkbox-group',
  ...checkboxGroupProps
}: OpCheckboxGroupProps) => {
  return (
    <Checkbox.Group
      className={clsx('op-checkbox-group', className)}
      data-testid={testId}
      {...checkboxGroupProps}
    />
  );
};

interface OpCheckboxProps extends ComponentProps<typeof Checkbox> {
  gtm?: string;
  testId?: string;
  tooltip?: ReactNode;
}

export const OpCheckbox = ({
  gtm,
  testId = 'op-checkbox',
  children,
  className,
  tooltip,
  ...checkboxProps
}: OpCheckboxProps) => {
  return (
    <Checkbox
      data-gtm={gtm}
      data-testid={testId}
      className={clsx('op-checkbox', className)}
      {...checkboxProps}
    >
      {children}
      {tooltip && <OpInfoTooltip title={tooltip} />}
    </Checkbox>
  );
};

// Add sub-components from the antd component to our version
OpCheckbox.Group = OpCheckboxGroup;
