import { useTranslation } from 'react-i18next';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { FormOnSubmitType, OpForm } from 'new-components/DLS/OpForm/OpForm';
import { useState } from 'react';
import { OpTextButton } from 'new-components/DLS/OpTextButton/OpTextButton';
import { sortOptionsAlphabetically } from 'utils/sortOptionsAlphabetically';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { createProfileInfoPayloadsFromValues } from '../utils/createProfileInfoPayloadsFromValues';
import { useProfileInfoMutation } from '../hooks/useProfileInfoMutation';
import { ProfileFormValues } from './types';

const ProfileInfoTabContent = () => {
  const { t } = useTranslation();
  const { identityId } = useIdentityAndUser();
  const emailConfirmModal = useConfirmModal();

  const [isChangingEmail, setIsChangingEmail] = useState(false);

  const profileInfoMutation = useProfileInfoMutation();
  const { data, isLoading } = useOpQuery({
    apiEndpointName: 'describeIdentity',
    parameters: [identityId!],
  });

  const identity = data?.json?.data;

  const initialValues = {
    email: identity?.email || '',
    firstName: identity?.firstName || '',
    middleName: identity?.middleName || '',
    lastName: identity?.lastName || '',
    nicknames: (identity?.nicknames || []).map(
      ({ nickname }) => nickname || '',
    ),
    language: (identity?.language as ProfileFormValues['language']) || 'en',
  } satisfies ProfileFormValues;

  const onSubmit: FormOnSubmitType = ({ touchedValues }) => {
    if (touchedValues.email && touchedValues.email !== initialValues.email) {
      emailConfirmModal({
        title: t('Change email?'),
        content: t(
          'Are you sure you want to change your email? This will change the email used to login.',
        ),
        cancelText: t('No'),
        okText: t('Yes'),
        onOk: () => {
          profileInfoMutation.mutate(
            createProfileInfoPayloadsFromValues(touchedValues, initialValues),
          );
        },
      });
    } else {
      profileInfoMutation.mutate(
        createProfileInfoPayloadsFromValues(touchedValues, initialValues),
      );
    }
  };

  return (
    <OpForm
      hasError={false}
      isLoading={isLoading}
      initialValues={initialValues}
      onSubmit={onSubmit}
      testId="profile-info-form"
    >
      <OpForm.Input
        autoFocus
        id="profileEmail"
        name="email"
        label={t('Email')}
        rules={[{ required: true }]}
        testId="profile-email"
        disabled={!isChangingEmail}
        suffix={
          <OpTextButton
            size="small"
            onClick={() => setIsChangingEmail((prev) => !prev)}
          >
            {isChangingEmail ? t('Keep') : t('Change')}
          </OpTextButton>
        }
      />
      {isChangingEmail && (
        <OpForm.PasswordInput
          name="oldPassword"
          label={t('Current password')}
          rules={[{ required: true }]}
          testId="profile-password"
        />
      )}
      <OpForm.Input
        name="firstName"
        label={t('First Name')}
        testId="profile-firstname"
      />
      <OpForm.Input
        name="middleName"
        label={t('Middle Name')}
        testId="profile-middle-name"
      />
      <OpForm.Input
        name="lastName"
        label={t('Last Name')}
        testId="profile-lastname"
      />
      <OpForm.TagInput
        name="nicknames"
        label={t('Nicknames')}
        rules={[
          {
            validator: async (_: any, nicknames: string[]) => {
              if (nicknames.length > 3) {
                return Promise.reject(
                  new Error(t('Exceeded maximum nicknames (max is 3)')),
                );
              }
            },
          },
        ]}
      />
      <OpForm.DataFetchSelect
        label={t('Language')}
        name="language"
        queryOptions={{
          apiEndpointName: 'globalListSupportedLanguages',
          parameters: [],
        }}
        pathToData="json.data.supportedLanguages"
        pathToLabel="nativeName"
        pathToValue="code"
        filterSort={sortOptionsAlphabetically}
      />
    </OpForm>
  );
};

export default ProfileInfoTabContent;
