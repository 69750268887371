import React from 'react';
import { TrashSvg } from 'components/svgs/TrashSvg';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { PlusSvg } from 'components/svgs/PlusSvg';
import { PhoneExternalSvg } from 'components/svgs/PhoneExternalSvg';
import { PencilSvg } from 'components/svgs/PencilSvg';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import CloudUploadOutlined from '@ant-design/icons/CloudUploadOutlined';
import ArrowUpOutlined from '@ant-design/icons/ArrowUpOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import PrinterOutlined from '@ant-design/icons/PrinterOutlined';
import ExportOutlined from '@ant-design/icons/ExportOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import PauseOutlined from '@ant-design/icons/PauseOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import BellOutlined from '@ant-design/icons/BellOutlined';
import UndoOutlined from '@ant-design/icons/UndoOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';

const sizeMap = {
  mini: 'small',
  tiny: 'small',
  small: 'small',
  medium: 'middle',
  large: 'large',
  big: 'large',
  huge: 'large',
  massive: 'large',
};

export function convertSemanticToAntdSize(semanticSize: keyof typeof sizeMap) {
  return sizeMap[semanticSize] || 'middle'; // Default to "middle" if size is not found
}

export const iconMap = {
  'trash alternate': <TrashSvg />,
  close: <CloseOutlined />,
  add: <PlusSvg />,
  'arrow left': <LeftOutlined />,
  'arrow right': <RightOutlined />,
  'cloud upload': <CloudUploadOutlined />,
  'arrow circle up': <ArrowUpOutlined />,
  'exclamation triangle': <WarningOutlined />,
  phone: <PhoneExternalSvg />,
  print: <PrinterOutlined />,
  'external alternate': <ExportOutlined />,
  cog: <SettingOutlined />,
  check: <CheckOutlined />,
  'caret down': <DownOutlined />,
  disk: <SaveOutlined />,
  'left arrow': <LeftOutlined />,
  play: <CaretRightOutlined />,
  pause: <PauseOutlined />,
  'right arrow': <RightOutlined />,
  plus: <PlusOutlined />,
  bell: <BellOutlined />,
  undo: <UndoOutlined />,
  edit: <PencilSvg />,
  search: <SearchOutlined />,
};

export const colorMap = {
  red: 'danger',
  blue: 'primary',
};
