import mergeWith from 'lodash/mergeWith';
import { createSelector } from 'reselect';
import {
  selectCurrentOrgId,
  selectRemoteFeatureFlags,
} from 'global/accessToken/selectors';

const openpathConfig = require('openpathConfig');

function customizer(objValue, srcValue) {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
}

const selectConfig = () => () => {
  return openpathConfig;
};

export const selectMergedConfig = () =>
  createSelector(
    selectConfig(),
    selectRemoteFeatureFlags(),
    selectCurrentOrgId(),
    (opconfig, remoteFlags, orgId) => {
      const remoteFlagsJS = remoteFlags?.toJS();

      // start with opconfig
      let merged = { ...opconfig };

      // merge in any org specific flags in the config
      if (opconfig.orgSpecificConfig) {
        merged = mergeWith(
          merged,
          opconfig.orgSpecificConfig[String(orgId)],
          customizer,
        );
      }

      // merge in any remote global flags
      if (remoteFlagsJS?.null) {
        merged = mergeWith(merged, remoteFlagsJS.null, customizer);
      }

      // merge in remote org specific flags
      if (remoteFlagsJS && remoteFlagsJS[String(orgId)]) {
        merged = mergeWith(merged, remoteFlagsJS[String(orgId)], customizer);
      }
      return merged;
    },
  );

export const selectFeatureFlag = (flag, fallback = null) =>
  createSelector(selectMergedConfig(), (mergedConfig) =>
    mergedConfig[flag] === undefined ? fallback : mergedConfig[flag],
  );

// TODO: Remove this once we refactor Core in DB
export const selectEnabledAcuModelIds = createSelector(
  selectFeatureFlag('ENABLED_ACU_MODEL_IDS'),

  // If 4/8 port enabled then we add Core as an option
  // Core (id 8) is hardcoded in Platinum currently and
  // will be refactored in Helium shortly
  (enabledAcuModelIds) =>
    enabledAcuModelIds.includes(6) || enabledAcuModelIds.includes(7)
      ? enabledAcuModelIds.concat(8)
      : enabledAcuModelIds,
);
