import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const ChevronUpSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 10 10" {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.12038 6.32539C1.30009 6.53506 1.61574 6.55934 1.8254 6.37963L5.00001 3.65854L8.17461 6.37963C8.38428 6.55934 8.69993 6.53506 8.87964 6.3254C9.05935 6.11573 9.03507 5.80008 8.8254 5.62037L5.32541 2.62037C5.13816 2.45988 4.86186 2.45988 4.67461 2.62037L1.17461 5.62037C0.96495 5.80008 0.94067 6.11573 1.12038 6.32539Z"
    />
  </SvgWrapper>
);
