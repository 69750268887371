import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dimmer from 'semantic-ui-react/dist/es/modules/Dimmer/Dimmer';
import SemanticLoader from 'semantic-ui-react/dist/es/elements/Loader/Loader';
import { useTranslation } from 'react-i18next';

export const LoaderWithSuir = memo(
  ({
    dimmerStyle,
    inverted = true,
    size,
    inline,
    loaderOnly,
    loaderContentOnly,
    content: _content,
    timeout = 0,
    timeoutMessage = '',
  }) => {
    const { t } = useTranslation();
    const content = _content || t('Loading');
    const loaderTimeout = useRef(null);
    if (timeout && !loaderOnly) {
      throw new Error('if using timeout, must set loaderOnly!');
    }
    const [timerExpired, setTimerExpired] = useState(!timeout);

    useEffect(() => {
      loaderTimeout.current = setTimeout(() => setTimerExpired(true), timeout);
      return () => clearTimeout(loaderTimeout.current);
    }, [timeout]);

    if (timeout && timerExpired) return <span>{timeoutMessage}</span>;

    return loaderOnly ? (
      <SemanticLoader
        active
        inverted={inverted}
        size={size}
        inline={inline}
        data-testid="loader"
      />
    ) : loaderContentOnly ? (
      <SemanticLoader
        active
        inverted={inverted}
        size={size}
        inline={inline}
        data-testid="loader"
      >
        {content}
      </SemanticLoader>
    ) : (
      <Dimmer active inverted={inverted} style={dimmerStyle}>
        <SemanticLoader
          inverted={inverted}
          size={size}
          inline={inline}
          data-testid="loader"
        >
          {content}
        </SemanticLoader>
      </Dimmer>
    );
  },
);

LoaderWithSuir.propTypes = {
  dimmerStyle: PropTypes.object,
  size: PropTypes.string,
  inline: PropTypes.bool,
  loaderOnly: PropTypes.bool,
  loaderContentOnly: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inverted: PropTypes.bool,
  timeout: PropTypes.number,
  timeoutMessage: PropTypes.string,
};

LoaderWithSuir.displayName = 'LoaderWithSuir';
