import { memo } from 'react';
import PauseOutlined from '@ant-design/icons/PauseOutlined';
import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import clsx from 'clsx';

import './BigPlayButton.scss';

const BigPlayButton = memo(({ isPaused }: { isPaused: boolean }) => {
  return (
    <div className="big-play-button">
      <OpButton
        className={clsx('big-play-button__pause-button', {
          'big-play-button__pause-button--deactivated': !isPaused,
          'big-play-button__pause-button--activated': isPaused,
        })}
        icon={<PauseOutlined />}
        shape="circle"
      />
      <OpButton
        className={clsx('big-play-button__play-button', {
          'big-play-button__play-button--activated': !isPaused,
          'big-play-button__play-button--deactivated': isPaused,
        })}
        icon={<CaretRightOutlined />}
        shape="circle"
      />
    </div>
  );
});

export default BigPlayButton;
