import clsx from 'clsx';
import { CustomIconComponentProps, OpIcon } from 'new-components/OpIcon/OpIcon';
import { ExclamationIcon } from 'new-components/svgs/ExclamationIcon';

import './OpExclamationIcon.scss';

export const OpExclamationIcon = ({ className }: CustomIconComponentProps) => {
  return (
    <OpIcon
      component={ExclamationIcon}
      className={clsx('op-exclamation-icon', className)}
    />
  );
};
