import { Map } from 'immutable';

export function parseIdentityProvider(identity) {
  if (Map.isMap(identity)) {
    switch (identity.getIn(['namespace', 'namespaceType', 'modelName'])) {
      case 'identityProvider':
        return identity.getIn([
          'namespace',
          'identityProvider',
          'identityProviderType',
          'name',
        ]);
      case 'org':
        return `Avigilon Alta / ${identity.getIn([
          'namespace',
          'org',
          'name',
        ])}`;
      default:
        return 'Avigilon Alta / Global';
    }
  } else if (identity) {
    switch (identity?.namespace?.namespaceType?.modelName) {
      case 'identityProvider':
        return identity?.namespace?.identityProvider?.identityProviderType
          ?.name;
      case 'org':
        return `Avigilon Alta / ${identity?.namespace?.org?.name}`;
      default:
        return 'Avigilon Alta / Global';
    }
  } else {
    return 'Avigilon Alta / Global';
  }
}
