import { opTime } from 'utils/dates';

export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_YYYY_MM_DD_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'h:mm a';
export const TIME_WITH_SECONDS_FORMAT = 'h:mm:ss a';
export const TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT = `${TIME_WITH_SECONDS_FORMAT} z`;
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_TIME_WITH_SECONDS_FORMAT = `${DATE_FORMAT} ${TIME_WITH_SECONDS_FORMAT}`;
export const DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT = `${DATE_FORMAT} ${TIME_WITH_SECONDS_FORMAT} z`;
export const ZULU_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const DATE_SHORT_MONTH_FORMAT = 'MMM';
export const DATE_LONG_MONTH_FORMAT = 'MMMM';

export const DATE_LONG_YEAR_FORMAT = 'YYYY';
export const DATE_SHORT_MONTH_DAY_FORMAT = `${DATE_SHORT_MONTH_FORMAT} DD`;
export const DATE_FORMAT_LONG_MONTH_LONG_YEAR = `${DATE_LONG_MONTH_FORMAT} ${DATE_LONG_YEAR_FORMAT}`;
export const DATE_FORMAT_SHORT_MONTH_LONG_YEAR = `${DATE_SHORT_MONTH_FORMAT} ${DATE_LONG_YEAR_FORMAT}`;

/* MINIMAL_DATE_FORMAT and MINIMAL_TIME_FORMAT are used inside the <Scheduler>'s formValues */
export const MINIMAL_DATE_FORMAT = 'YYYY-MM-DD';
export const MINIMAL_TIME_FORMAT = 'HH:mm:ss';

export const DateAndTimeTypes = {
  MINIMAL_DATE: 'minimaldate',
  MINIMAL_TIME: 'minimaltime',
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'datetime',
} as const;

/**
 * Generates a Zulu string from a local Zulu string based on the guessed time zone.
 *
 * This is a helper function that takes the form value of a date picker which is a zulu string
 * that is automatically offset based on the computer/browser locale (e.g. a value of midnight
 * on 12/5/2023 in the PST time zone will have a form value of "2023-12-05T08:00:00.000Z" as
 * PST has a UTC offset of -8hrs), and converts it to the correct Zulu string that will be
 * stored in the db. In order to do this, we must first remove the automatic offset added to
 * the date shown in the date picker, add the offset of the desired time zone, and then return
 * the Zulu string (e.g. if the desired outcome should be to show midnight on 12/5/2023 in the
 * EST time zone, the value stored should be "2023-12-05T05:00:00.000Z" as the UTC offset for
 * EST is -5hrs).
 *
 * @param {string} zuluString - The local Zulu string to convert.
 * @param {string} timeZoneId - The ID of the time zone to convert to.
 * @return {string} The converted Zulu string.
 */
export const createZuluStringFromLocalZuluString = (
  zuluString: string,
  timeZoneId?: string,
) =>
  opTime
    // Convert to UTC dayjs object
    .utc(zuluString)
    // Add UTC offset minutes based on guessed time zone to counter the guessed time zone
    // offset applied by dayjs creating the dayjs object
    .add(opTime().utcOffset(), 'minutes')
    // Subtract time zone UTC offset minutes of desired time zone so you are left with the
    // correct UTC date/time to be stored in the db
    .subtract(opTime().tz(timeZoneId).utcOffset(), 'minutes')
    // Return string in Zulu format
    .format(ZULU_FORMAT);

/**
 * Generates a local Zulu string from a Zulu string in a specified time zone.
 *
 * The inverse of the function above. This takes a value stored in the db and converts it to
 * a local Zulu string based on the desired time zone.
 *
 * @param {string} zuluString - The Zulu string to convert.
 * @param {string} timeZoneId - The ID of the time zone to use for the conversion.
 * @return {string} The local Zulu string.
 */
export const createLocalZuluStringFromZuluString = (
  zuluString: string,
  timeZoneId: string,
) =>
  opTime(zuluString)
    // Adjusts the dayjs object to the desired time zone
    .tz(timeZoneId)
    // Subtracts the UTC offset minutes of the guessed time zone so you are left with the
    // correct local date/time to be shown in the date picker
    .subtract(opTime().utcOffset(), 'minutes')
    // Return the string in Zulu format
    .format(ZULU_FORMAT);

/*
Helper function to get the 3 letter month abbreviation of the dateTime passed in
Mostly takes the abbreviation and adds a period at the end (Jan.)
For May and Jun(e) we want to display the full month
*/
export function getMonthAbbreviation(dateTime?: string) {
  const month = opTime(dateTime).format('MMM');

  switch (month) {
    case 'May':
      return 'May'; // Keep as is

    case 'Jun':
      return 'June';

    default:
      return `${month}.`;
  }
}

/**
 * Ex)
 * @param {String} dateTime represented as a string
 *    If dateTime is undefined opTime defaults to using the current date and time
 * @param {Bool} allowShortcuts
 *    If allowShortcuts is True, returns:
 *      'Today'
 *      'Yesterday'
 *    If allowShortcuts is False, returns:
 *      'June 6, 2018'
 * @param {Bool} addTime
 * @returns {String}
 *   Ex) 'Today' or 'June 6, 2018'
 */
export function getFormattedDate(
  dateTime: string | undefined,
  allowShortcuts: boolean,
  addTime: boolean,
): string {
  const today = opTime().toDate().toDateString();
  const yesterday = opTime().subtract(1, 'days').toDate().toDateString();

  const currentDateString = opTime(dateTime).toDate().toDateString();

  if (allowShortcuts) {
    if (today === currentDateString) {
      return addTime ? 'Today at' : 'Today';
    }
    if (yesterday === currentDateString) {
      return addTime ? 'Yesterday at' : 'Yesterday';
    }
  }

  return `${getMonthAbbreviation(dateTime)} ${opTime(dateTime).format(
    'D, YYYY',
  )}`;
}

export function getFormattedDateAndTime(
  dateTime: string | undefined,
  allowShortcuts: boolean,
) {
  // if this returns Jan 1, 1970 - dateTime is likely in seconds. Multiply dateTime by 1000 to get ms.
  return `${getFormattedDate(dateTime, allowShortcuts, true)} ${opTime(
    dateTime,
  ).format(TIME_WITH_SECONDS_FORMAT)}`;
}
