// THESE SHOULD BE REFACTORED INTO A "REDUCERS" FOLDER (OR SOMETHING SIMILAR)
export const SET_ACU = 'app/OrgContainer/SET_ACU';
export const SET_ACU_CONFIG = 'app/OrgContainer/SET_ACU_CONFIG';
export const SET_ACU_LOCATION_MEASUREMENTS =
  'app/OrgContainer/SET_ACU_LOCATION_MEASUREMENTS';
export const SET_ACU_LOCATION_RESTRICTION =
  'app/OrgContainer/SET_ACU_LOCATION_RESTRICTION';
export const SET_ACU_MODELS = 'app/OrgContainer/SET_ACU_MODELS';
export const SET_ACU_EXPANSION_BOARDS =
  'app/OrgContainer/SET_ACU_EXPANSION_BOARDS';
export const SET_ACU_PORTS = 'app/OrgContainer/SET_ACU_PORTS';
export const SET_ACUS = 'app/OrgContainer/SET_ACUS';
export const SET_ACTIVITY = 'app/OrgContainer/SET_ACTIVITY';
export const SET_ALARM_ACTIVITY = 'app/OrgContainer/SET_ALARM_ACTIVITY';
export const SET_ALARM_CONFIGURATIONS =
  'app/OrgContainer/SET_ALARM_CONFIGURATIONS';
export const SET_ALL_ACU_ENTRIES_USERS =
  'app/OrgContainer/SET_ALL_ACU_ENTRIES_USERS';
export const SET_ALL_ACU_PORTS = 'app/OrgContainer/SET_ALL_ACU_PORTS';
export const SET_ALLEGION_OPERATORS = 'app/OrgContainer/SET_ALLEGION_OPERATORS';
export const SET_ALLEGION_INVITE_STATE =
  'app/OrgContainer/SET_ALLEGION_INVITE_STATE';
export const SET_APPS = 'app/OrgContainer/SET_APPS';
export const SET_AUDIT_LOGS = 'app/OrgContainer/SET_AUDIT_LOGS';
export const SET_AUDIT_LOGS_UI = 'app/OrgContainer/SET_AUDIT_LOGS_UI';
export const SET_BADGE_CONFIGS = 'app/OrgContainer/SET_BADGE_CONFIGS';
export const SET_CAMERA_ENTRIES = 'app/OrgContainer/SET_CAMERA_ENTRIES';
export const SET_CAMERA_STALE_SNAPSHOT =
  'app/OrgContainer/SET_CAMERA_STALE_SNAPSHOT';
export const SET_CARD_FORMATS = 'app/OrgContainer/SET_CARD_FORMATS';
export const SET_CONTACT_SENSORS = 'app/OrgContainer/SET_CONTACT_SENSORS';
export const SET_CREDENTIAL_TYPES = 'app/OrgContainer/SET_CREDENTIAL_TYPES';
export const SET_CREDENTIALS = 'app/OrgContainer/SET_CREDENTIALS';
export const SET_CUSTOM_FIELD_DROPDOWN_ITEMS =
  'app/OrgContainer/SET_CUSTOM_FIELD_DROPDOWN_ITEMS';
export const SET_CUSTOM_FIELD = 'app/OrgContainer/SET_CUSTOM_FIELD';
export const SET_CUSTOM_FIELD_TYPES = 'app/OrgContainer/SET_CUSTOM_FIELD_TYPES';
export const SET_CUSTOM_FIELDS = 'app/OrgContainer/SET_CUSTOM_FIELDS';
export const SET_DASHBOARDS = 'app/OrgContainer/SET_DASHBOARDS';
export const SET_EMAIL_ALERTS = 'app/OrgContainer/SET_EMAIL_ALERTS';
export const SET_ENTERPRISE_VPN_CONFIG =
  'app/OrgContainer/SET_ENTERPRISE_VPN_CONFIG';
export const SET_ENTRIES = 'app/OrgContainer/SET_ENTRIES';
export const SET_ENTRY = 'app/OrgContainer/SET_ENTRY';
export const SET_ENTRY_CAMERAS = 'app/OrgContainer/SET_ENTRY_CAMERAS';
export const SET_ENTRY_CONTACT_SENSORS =
  'app/OrgContainer/SET_ENTRY_CONTACT_SENSORS';
export const SET_ENTRY_STATES = 'app/OrgContainer/SET_ENTRY_STATES';
export const SET_ENTRY_READERS = 'app/OrgContainer/SET_ENTRY_READERS';
export const SET_ENTRY_RELAYS = 'app/OrgContainer/SET_ENTRY_RELAYS';
export const SET_ENTRY_REXS = 'app/OrgContainer/SET_ENTRY_REXS';
export const SET_ENTRY_USER_SCHEDULES =
  'app/OrgContainer/SET_ENTRY_USER_SCHEDULES';
export const SET_ENTRY_WIEGANDS = 'app/OrgContainer/SET_ENTRY_WIEGANDS';
export const SET_FILTERED_USERS_COUNT =
  'app/OrgContainer/SET_FILTERED_USERS_COUNT';
export const SET_GENERIC_INPUTS = 'app/OrgContainer/SET_GENERIC_INPUTS';
export const SET_GLOBAL_ACUS = 'app/OrgContainer/SET_GLOBAL_ACUS';
export const SET_GLOBAL_IDENTITIES = 'app/OrgContainer/SET_GLOBAL_IDENTITIES';
export const SET_GLOBAL_ORGS = 'app/OrgContainer/SET_GLOBAL_ORGS';
export const SET_GLOBAL_ENTRIES = 'app/OrgContainer/SET_GLOBAL_ENTRIES';
export const SET_GLOBAL_HOOK_EVENTS = 'app/OrgContainer/SET_GLOBAL_HOOK_EVENTS';
export const SET_GROUP = 'app/OrgContainer/SET_GROUP';
export const SET_GROUP_SCHEDULES = 'app/OrgContainer/SET_GROUP_SCHEDULES';
export const SET_GROUPS = 'app/OrgContainer/SET_GROUPS';
export const SET_HLS_STREAM_IS_LOADING =
  'app/OrgContainer/SET_HLS_STREAM_IS_LOADING';
export const SET_HOOK_ACTIONS = 'app/OrgContainer/SET_HOOK_ACTIONS';
export const SET_HOOK_EVENTS = 'app/OrgContainer/SET_HOOK_EVENTS';
export const SET_IDENTITY_PROVIDERS = 'app/OrgContainer/SET_IDENTITY_PROVIDERS';
export const SET_IDENTITIES = 'SET_IDENTITIES';
export const SET_IDENTITY_PROVIDER_TYPES =
  'app/OrgContainer/SET_IDENTITY_PROVIDER_TYPES';
export const SET_IDENTITY_PROVIDER_GROUP_RELATIONS =
  'app/OrgContainer/SET_IDENTITY_PROVIDER_GROUP_RELATIONS';
export const SET_IDENTITY_PROVIDER_GROUPS =
  'app/OrgContainer/SET_IDENTITY_PROVIDER_GROUPS';
export const SET_IDENTITY_ROLES = 'app/OrgContainer/SET_IDENTITY_ROLES';
export const SET_INVOICES = 'app/OrgContainer/SET_INVOICES';
export const SET_IOS = 'app/OrgContainer/SET_IOS';
export const SET_LOCKDOWN_PLANS = 'app/OrgContainer/SET_LOCKDOWN_PLANS';
export const SET_MOBILE_APP_CONFIG = 'app/OrgContainer/SET_MOBILE_APP_CONFIG';
export const SET_NAMESPACES = 'app/OrgContainer/SET_NAMESPACES';
export const SET_OPVIDEO_CAMERA_STREAMING_PROFILES =
  'app/OrgContainer/SET_OPVIDEO_CAMERA_STREAMING_PROFILES';
export const SET_OPVIDEO_DEVICE = 'app/OrgContainer/SET_OPVIDEO_DEVICE';
export const SET_OPVIDEO_DEVICE_ACTIVITY =
  'app/OrgContainer/SET_OPVIDEO_DEVICE_ACTIVITY';
export const SET_OPVIDEO_DEVICE_FRAGMENTS =
  'app/OrgContainer/SET_OPVIDEO_DEVICE_FRAGMENTS';
export const SET_OPVIDEO_DEVICE_HLS_PLAYBACK_URL =
  'app/OrgContainer/SET_OPVIDEO_DEVICE_HLS_PLAYBACK_URL';
export const SET_OPVIDEO_DEVICES = 'app/OrgContainer/SET_OPVIDEO_DEVICES';
export const SET_ORG = 'app/OrgContainer/SET_ORG';
export const SET_ORG_CAMERAS = 'app/OrgContainer/SET_ORG_CAMERAS';
export const SET_ORG_PACKAGE_PLANS_REDUCER =
  'app/OrgContainer/SET_ORG_PACKAGE_PLANS_REDUCER';
export const SET_NETSUITE_PACKAGE_PLANS =
  'app/OrgContainer/SET_NETSUITE_PACKAGE_PLANS';
export const SET_PROTON_STATUSES = 'app/OrgContainer/SET_PROTON_STATUSES';
export const SET_PRESENCE_BUCKETS = 'app/OrgContainer/SET_PRESENCE_BUCKETS';
export const SET_READERS = 'app/OrgContainer/SET_READERS';
export const SET_RELAYS = 'app/OrgContainer/SET_RELAYS';
export const SET_REXS = 'app/OrgContainer/SET_REXS';
export const SET_REQUEST_TYPES = 'app/OrgContainer/SET_REQUEST_TYPES';
export const SET_ROLE = 'app/OrgContainer/SET_ROLE';
export const SET_ROLES = 'app/OrgContainer/SET_ROLES';
export const SET_SCHEDULE_ACCESS = 'app/OrgContainer/SET_SCHEDULE_ACCESS';
export const SET_SCHEDULE_EVENTS = 'app/OrgContainer/SET_SCHEDULE_EVENTS';
export const SET_SCHEDULE_TYPES = 'app/OrgContainer/SET_SCHEDULE_TYPES';
export const SET_SCHEDULES = 'app/OrgContainer/SET_SCHEDULES';
export const SET_SCOPE_RESOURCES = 'app/OrgContainer/SET_SCOPE_RESOURCES';
export const SET_SHARED_USERS = 'app/OrgContainer/SET_SHARED_USERS';
export const SET_SITE = 'app/OrgContainer/SET_SITE';
export const SET_SITES = 'app/OrgContainer/SET_SITES';
export const SET_SUBSCRIPTION = 'app/OrgContainer/SET_SUBSCRIPTION';
export const SET_SUBSCRIPTIONS = 'app/OrgContainer/SET_SUBSCRIPTIONS';
export const SET_THIRD_PARTY_DEVICES =
  'app/OrgContainer/SET_THIRD_PARTY_DEVICES';
export const SET_TOTAL_USERS_COUNT = 'app/OrgContainer/SET_TOTAL_USERS_COUNT';
export const SET_TRIGGER_METHODS = 'app/OrgContainer/SET_TRIGGER_METHODS';
export const SET_THIRD_PARTY_READERS =
  'app/OrgContainer/SET_THIRD_PARTY_READERS';
export const SET_USER = 'app/OrgContainer/SET_USER';
export const SET_USER_ACTIVITY = 'app/OrgContainer/SET_USER_ACTIVITY';
export const SET_USER_BADGE = 'app/OrgContainer/SET_USER_BADGE';
export const SET_USER_CREDENTIALS = 'app/OrgContainer/SET_USER_CREDENTIALS';
export const SET_USER_CUSTOM_FIELDS = 'app/OrgContainer/SET_USER_CUSTOM_FIELDS';
export const SET_USER_ENTRIES = 'app/OrgContainer/SET_USER_ENTRIES';
export const SET_USER_GROUPS = 'app/OrgContainer/SET_USER_GROUPS';
export const SET_USERS = 'app/OrgContainer/SET_USERS';
export const SET_VIDEO_PROVIDER_TYPES =
  'app/OrgContainer/SET_VIDEO_PROVIDER_TYPES';
export const SET_VIDEO_PROVIDERS = 'app/OrgContainer/SET_VIDEO_PROVIDERS';
export const SET_VIDEO_READER = 'app/OrgContainer/SET_VIDEO_READER';
export const SET_VIDEO_READERS = 'app/OrgContainer/SET_VIDEO_READERS';
export const SET_VIDEO_INTERCOM_READERS =
  'app/OrgContainer/SET_VIDEO_INTERCOM_READERS';
export const SET_VISUAL_ACTIVITY = 'app/OrgContainer/SET_VISUAL_ACTIVITY';
export const SET_WIDGETS = 'app/OrgContainer/SET_WIDGETS';
export const SET_WIDGET_TYPES = 'app/OrgContainer/SET_WIDGET_TYPES';
export const SET_WIRELESS_LOCKS = 'app/OrgContainer/SET_WIRELESS_LOCKS';
export const SET_WIRELESS_LOCK_GATEWAYS =
  'app/OrgContainer/SET_WIRELESS_LOCK_GATEWAYS';
export const SET_WIRELESS_LOCK_PROVIDERS =
  'app/OrgContainer/SET_WIRELESS_LOCK_PROVIDERS';
export const SET_ZONE = 'app/OrgContainer/SET_ZONE';
export const SET_ZONE_GROUPS = 'app/OrgContainer/SET_ZONE_GROUPS';
export const SET_ZONE_ZONE_USERS = 'app/OrgContainer/SET_ZONE_ZONE_USERS';
export const SET_ZONE_ZONE_GROUPS = 'app/OrgContainer/SET_ZONE_ZONE_GROUPS';
export const SET_ZONES = 'app/OrgContainer/SET_ZONES';

// Reducer names
export const ACTIVITY_REDUCER = 'activity';
export const ACU_EXPANSION_BOARDS_REDUCER = 'acuExpansionBoards';
export const ACU_CONFIG_REDUCER = 'acuConfig';
export const ACU_LOCATION_MEASUREMENTS_REDUCER = 'acuLocationMeasurements';
export const ACU_LOCATION_RESTRICTION_REDUCER = 'acuLocationRestriction';
export const ACU_MODELS_REDUCER = 'acuModels';
export const ACU_PORTS_REDUCER = 'acuPorts';
export const ACU_REDUCER = 'acu';
export const ACUS_REDUCER = 'acus';
export const ALARM_ACTIVITY_REDUCER = 'alarmActivity';
export const ALL_ACU_PORTS_REDUCER = 'allAcuPorts';
export const ALARM_CONFIGURATIONS_REDUCER = 'alarmConfigurations';
export const ALLEGION_INVITER_REDUCER = 'allegionInviter';
export const ALLEGION_OPERATORS_REDUCER = 'allegionOperators';
export const APPS_REDUCER = 'apps';
export const AUDIT_LOGS_UI_REDUCER = 'auditLogsUi';
export const BADGE_CONFIGS_REDUCER = 'badgeConfigs';
export const CAMERA_ENTRIES_REDUCER = 'cameraEntries';
export const CAMERA_STALE_SNAPSHOT_REDUCER = 'cameraStaleSnapshot';
export const CARD_FORMATS_REDUCER = 'cardFormats';
export const CONTACT_SENSORS_REDUCER = 'contactSensors';
export const CREDENTIAL_TYPES_REDUCER = 'credentialTypes';
export const CREDENTIALS_REDUCER = 'credentials';
export const CUSTOM_FIELD_REDUCER = 'customField';
export const CUSTOM_FIELD_DROPDOWN_ITEMS_REDUCER = 'customFieldDropdownItems';
export const CUSTOM_FIELD_TYPES_REDUCER = 'customFieldTypes';
export const CUSTOM_FIELDS_REDUCER = 'customFields';
export const DASHBOARDS_REDUCER = 'dashboards';
export const EMAIL_ALERTS_REDUCER = 'emailAlerts';
export const ENTERPRISE_VPN_CONFIG_REDUCER = 'enterpriseVpnConfig';
export const ENTRIES_REDUCER = 'entries';
export const ENTRY_CAMERAS_REDUCER = 'entryCameras';
export const ENTRY_CONTACT_SENSORS_REDUCER = 'entryContactSensors';
export const ENTRY_READERS_REDUCER = 'entryReaders';
export const ENTRY_REDUCER = 'entry';
export const ENTRY_RELAYS_REDUCER = 'entryRelays';
export const ENTRY_REXS_REDUCER = 'entryRexs';
export const ENTRY_STATES_REDUCER = 'entryStates';
export const ENTRY_WIEGANDS_REDUCER = 'entryWiegands';
export const ENTRY_USER_SCHEDULES_REDUCER = 'entryUserSchedules';
export const GENERIC_INPUTS_REDUCER = 'genericInputs';
export const GLOBAL_ACUS_REDUCER = 'globalAcus';
export const GLOBAL_IDENTITIES_REDUCER = 'globalIdentities';
export const GLOBAL_ORGS_REDUCER = 'globalOrgs';
export const GLOBAL_ENTRIES_REDUCER = 'globalEntries';
export const GLOBAL_HOOK_EVENTS_REDUCER = 'globalHookEvents';
export const GROUP_REDUCER = 'group';
export const GROUP_SCHEDULES_REDUCER = 'groupSchedules';
export const GROUPS_REDUCER = 'groups';
export const HLS_STREAM_IS_LOADING_REDUCER = 'hlsStreamIsLoading';
export const HOOK_ACTIONS_REDUCER = 'hookActions';
export const HOOK_EVENTS_REDUCER = 'hookEvents';
export const IDENTITY_PROVIDERS_REDUCER = 'identityProviders';
export const IDENTITY_PROVIDER_TYPES_REDUCER = 'identityProviderTypes';
export const IDENTITY_ROLES_REDUCER = 'identityRoles';
export const IDENTITIES_REDUCER = 'identities';
export const INVOICES_REDUCER = 'invoices';
export const IOS_REDUCER = 'ios';
export const LOCKDOWN_PLANS_REDUCER = 'lockdownPlans';
export const MOBILE_APP_CONFIG_REDUCER = 'mobileAppConfig';
export const OPVIDEO_CAMERA_STREAMING_PROFILES_REDUCER =
  'opvideoCameraStreamingProfiles';
export const OPVIDEO_DEVICE_REDUCER = 'opvideoDevice';
export const OPVIDEO_DEVICE_ACTIVITY_REDUCER = 'opvideoDeviceActivity';
export const OPVIDEO_DEVICE_FRAGMENTS_REDUCER = 'opvideoDeviceFragments';
export const OPVIDEO_DEVICE_HLS_PLAYBACK_URL_REDUCER =
  'opvideoDeviceHlsPlayback';
export const OPVIDEO_DEVICES_REDUCER = 'opvideoDevices';
export const ORG_CAMERAS_REDUCER = 'orgCameras';
export const ORG_PACKAGE_PLANS_REDUCER = 'orgPackagePlans';
export const PRESENCE_BUCKETS_REDUCER = 'presenceBuckets';
export const READERS_REDUCER = 'readers';
export const RELAYS_REDUCER = 'relays';
export const REXS_REDUCER = 'rexes';
export const ROLES_REDUCER = 'roles';
export const SCHEDULE_ACCESS_REDUCER = 'scheduleAccess';
export const SCHEDULE_EVENTS_REDUCER = 'scheduleEvents';
export const SCHEDULES_REDUCER = 'schedules';
export const SHARED_USERS_REDUCER = 'sharedUsers';
export const SITE_REDUCER = 'site';
export const SITES_REDUCER = 'sites';
export const TRIGGER_METHODS = 'triggerMethods';
export const SUBSCRIPTION_REDUCER = 'subscription';
export const SUBSCRIPTIONS_REDUCER = 'subscriptions';
export const THIRD_PARTY_DEVICES_REDUCER = 'thirdPartyDevices';
export const THIRD_PARTY_READERS_REDUCER = 'thirdPartyReaders';
export const USER_ACTIVITY_REDUCER = 'userActivity';
export const USER_BADGE_REDUCER = 'userBadge';
export const USER_CREDENTIALS_REDUCER = 'userCredentials';
export const USER_CUSTOM_FIELDS_REDUCER = 'userCustomFields';
export const USER_ENTRIES_REDUCER = 'userEntries';
export const USER_GROUPS_REDUCER = 'userGroups';
export const USER_REDUCER = 'user';
export const USERS_REDUCER = 'users';
export const VIDEO_PROVIDER_TYPES_REDUCER = 'videoProviders';
export const VIDEO_PROVIDERS_REDUCER = 'videoProviders';
export const VIDEO_READER_REDUCER = 'videoReader';
export const VIDEO_READERS_REDUCER = 'videoReaders';
export const VIDEO_INTERCOM_READERS_REDUCER = 'videoIntercomReaders';
export const VISUAL_ACTIVITY_REDUCER = 'visualActivity';
export const WIDGETS_REDUCER = 'widgets';
export const WIDGET_TYPES_REDUCER = 'widgetTypes';
export const WIRELESS_LOCKS_REDUCER = 'wirelessLocks';
export const WIRELESS_LOCK_GATEWAYS_REDUCER = 'wirelessLockGateways';
export const WIRELESS_LOCK_PROVIDERS_REDUCER = 'wirelessLockProviders';
export const ZONE_GROUPS_REDUCER = 'zoneGroups';
export const ZONE_REDUCER = 'zone';
export const ZONE_ZONE_USERS_REDUCER = 'zoneZoneUsers';
export const ZONE_ZONE_GROUPS_REDUCER = 'zoneZoneGroups';
export const ZONES_REDUCER = 'zones';

export const ORG_REDUCER = 'org';

// temp, should be moved up
export const BUILDINGS_REDUCER = 'buildings';
export const BUILDING_REDUCER = 'building';
export const SET_BUILDINGS = 'app/OrgContainer/SET_BUILDINGS';
export const SET_BUILDING = 'app/OrgContainer/SET_BUILDING';
export const FLOORS_REDUCER = 'buildingFloors';
export const FLOOR_REDUCER = 'floor';
export const SET_FLOORS = 'app/OrgContainer/SET_FLOORS';
export const SET_FLOOR = 'app/OrgContainer/SET_FLOOR';
export const UNITS_REDUCER = 'buildingFloorUnits';
export const UNIT_REDUCER = 'unit';
export const SET_UNITS = 'app/OrgContainer/SET_UNITS';
export const SET_UNIT = 'app/OrgContainer/SET_UNIT';

export const SERVICE_REQUESTS_REDUCER = 'serviceRequests';
export const SET_SERVICE_REQUESTS = 'app/OrgContainer/SET_SERVICE_REQUESTS';

export const CAMERAS_REDUCER = 'cameras';
export const SET_CAMERAS = 'app/OrgContainer/SET_CAMERAS';
export const SCHEDULED_REPORTS_REDUCER = 'scheduledReports';
export const SET_SCHEDULED_REPORTS = 'app/OrgContainer/SET_SCHEDULED_REPORTS';
export const SCHEDULED_REPORT_REDUCER = 'scheduledReport';
export const SET_SCHEDULED_REPORT = 'app/OrgContainer/SET_SCHEDULED_REPORT';
export const TASK_REPORTS_REDUCER = 'taskReports';
export const SET_TASK_REPORTS = 'app/OrgContainer/SET_TASK_REPORTS';
export const ORG_SOFTWARE_LICENSES_REDUCER = 'orgSoftwareLicenses';
export const SET_ORG_SOFTWARE_LICENSES =
  'app/OrgContainer/SET_ORG_SOFTWARE_LICENSES';

// with slideouts, we often need to store table data while the slideout is open
export const SAVE_TABLE_STATE = 'app/OrgContainer/SAVE_TABLE_STATE';
export const TABLE_STATE_REDUCER = 'tableState';

// BELOW THIS LINE ARE ONES THAT SHOULD LIVE HERE
export const REQUEST_GET_CURRENT_ORG_DATA =
  'app/OrgContainer/REQUEST_GET_CURRENT_ORG_DATA';
export const REQUEST_PAGE_DATA_GENERIC =
  'app/OrgContainer/REQUEST_PAGE_DATA_GENERIC';
export const REQUEST_HELIUM_DATA_GENERIC =
  'app/OrgContainer/REQUEST_HELIUM_DATA_GENERIC';
export const RESET_HLS_URL = 'app/OrgContainer/RESET_HLS_URL';
export const RESET_POSTER_URL = 'app/OrgContainer/RESET_POSTER_URL';
export const REQUEST_AND_SET_GENERAL_WRAPPER =
  'app/OrgContainer/REQUEST_AND_SET_GENERAL_WRAPPER';
export const SLIDE_OUT_SUCCESSFUL = 'app/OrgContainer/SLIDE_OUT_SUCCESSFUL';
export const UPDATE_OPTIONS = 'app/OrgContainer/UPDATE_OPTIONS';
