import { useCallback, useMemo } from 'react';

import {
  SET_ENTRIES,
  SET_SITES,
  SET_ZONES,
  SET_USERS,
  SET_ROLES,
  SET_ACUS,
  SET_READERS,
} from 'routes/OrgContainer/constants';
import {
  selectOptionsForEntriesMultiSelect,
  selectSitesForMultiSelect,
  selectZonesForMultiSelect,
  selectUserOptionsForDropdown,
  selectRolesForMultiSelect,
  selectAllAcuOptions,
  selectReaderOptions,
} from 'routes/OrgContainer/selectors';

import { requestAndSetGeneralWrapper } from 'routes/OrgContainer/actions';

import { useSelectorJs } from '.';
import { useShowSitePartitioningItems } from './useShowSitePartitioningItems';

const useFiltersForAlarmReport = (PAGE, route, dispatch, orgId) => {
  // filter options
  const userOptions = useSelectorJs(selectUserOptionsForDropdown, { route });
  const siteOptions = useSelectorJs(selectSitesForMultiSelect(), { route });
  const zoneOptions = useSelectorJs(selectZonesForMultiSelect(), { route });
  const entryOptions = useSelectorJs(selectOptionsForEntriesMultiSelect(), {
    route,
  });
  const roleOptions = useSelectorJs(selectRolesForMultiSelect, { route });
  const acuOptions = useSelectorJs(selectAllAcuOptions(), {
    route,
  });
  const readerOptions = useSelectorJs(selectReaderOptions, { route });

  const filterOptions = useMemo(
    () => ({
      userId: userOptions,
      assignedUserId: userOptions,
      assignedRoleId: roleOptions,
      'entries.site.id': siteOptions,
      'entries.zone.id': zoneOptions,
      'entries.id': entryOptions,
      acuId: acuOptions,
      readerId: readerOptions,
    }),
    [
      userOptions,
      roleOptions,
      siteOptions,
      zoneOptions,
      entryOptions,
      acuOptions,
      readerOptions,
    ],
  );

  const { isShowingSitePartitioningItems } =
    useShowSitePartitioningItems(orgId);

  const filterOptionsUpdate = useCallback(
    (filterType, q) => {
      let actionInfo = null;
      switch (filterType) {
        case 'userId':
        case 'assignedUserId':
          actionInfo = {
            heliumApi: 'listUsers',
            queryStringParams: {
              q,
              ...(isShowingSitePartitioningItems && {
                options: 'withUserSites',
              }),
            },
            setterActionType: SET_USERS,
            page: PAGE,
          };
          break;
        case 'assignedRoleId':
          actionInfo = {
            heliumApi: 'listRoles',
            queryStringParams: { q },
            setterActionType: SET_ROLES,
            page: PAGE,
          };
          break;
        case 'entries.site.id':
          actionInfo = {
            heliumApi: 'listSites',
            queryStringParams: { q },
            setterActionType: SET_SITES,
            page: PAGE,
          };
          break;
        case 'entries.zone.id':
          actionInfo = {
            heliumApi: 'listZones',
            queryStringParams: { q },
            setterActionType: SET_ZONES,
            page: PAGE,
          };
          break;
        case 'entries.id':
          actionInfo = {
            heliumApi: 'listEntries',
            queryStringParams: { q },
            setterActionType: SET_ENTRIES,
            page: PAGE,
          };
          break;
        case 'acuId':
          actionInfo = {
            heliumApi: 'listAcus',
            queryStringParams: { q },
            setterActionType: SET_ACUS,
            page: PAGE,
          };
          break;
        case 'readerId':
          actionInfo = {
            heliumApi: 'listReaders',
            queryStringParams: { q },
            setterActionType: SET_READERS,
            page: PAGE,
          };
          break;
        default:
        // do nothing, user hasn't selected anything yet
      }

      if (actionInfo) {
        // API doesn't like empty q's
        if (!actionInfo.queryStringParams.q?.length) {
          delete actionInfo.queryStringParams.q;
        }
        dispatch(requestAndSetGeneralWrapper(actionInfo));
      }
    },
    [isShowingSitePartitioningItems, PAGE, dispatch],
  );

  return [filterOptions, filterOptionsUpdate];
};

export default useFiltersForAlarmReport;
