import { Key, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { OpInput } from 'new-components/DLS/OpInput/OpInput';
import { SelectFilter } from './SelectFilter';
import { OpTableRawColumnType } from '../OpTableCore';

export type OnFilterUpdateType = (
  filterKey: Key,
  filterValue: string[] | null,
) => void;

export interface ColumnHeaderFilterProps
  extends Pick<OpTableRawColumnType, 'filter' | 'defaultFilteredValue'> {
  filterKey: Key;
  testId?: string;
  onFilterUpdate?: OnFilterUpdateType;
  resetRowSelections?: () => void;
  gtm?: string;
  isUsingSubstringMatch?: boolean;
}

export const ColumnHeaderFilter = ({
  filterKey,
  onFilterUpdate,
  resetRowSelections = () => {},
  filter,
  testId,
  defaultFilteredValue,
  gtm,
  isUsingSubstringMatch = false,
}: ColumnHeaderFilterProps) => {
  const { t } = useTranslation();

  // Disallows using whitespace-only filters on this input
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space' && e.currentTarget.value.trim() === '') {
      e.preventDefault();
    }
  };

  const createChangeHandler = (filterValue?: string | string[]) => {
    let finalFilterValue: string[] | null;
    /**
     * Helium uses default match logic which is “if it looks like a number then do a number
     * match, otherwise do a string match". Since we explicitly want a string match, we
     * should be sending an explicit substring-match operator ~ to helium so it will always
     * use string matching, not number matching.
     */
    if (Array.isArray(filterValue)) {
      if (filterValue.length === 0) {
        // If no filters are selected, we shouldn't filter at al
        finalFilterValue = null;
      } else {
        finalFilterValue = isUsingSubstringMatch
          ? filterValue.map((v) => `~${v}`)
          : filterValue;
      }
    } else if (typeof filterValue === 'string' && filterValue !== '') {
      finalFilterValue = [
        isUsingSubstringMatch ? `~${filterValue}` : filterValue,
      ];
    } else {
      finalFilterValue = null;
    }

    if (onFilterUpdate) {
      onFilterUpdate(filterKey, finalFilterValue);
    }

    // Reset row selections on filter
    resetRowSelections();
  };

  if (filter?.type === 'select' || filter?.type === 'multiSelect') {
    const defaultValue =
      defaultFilteredValue === null
        ? undefined
        : defaultFilteredValue?.map((v) =>
            v.startsWith('~') ? v.slice(1) : v,
          );

    return (
      <SelectFilter
        /* This will allow the select to get the proper defaultFilteredValue on initial load - making sure to display the final default value */
        key={defaultFilteredValue?.toString()}
        gtm={gtm}
        testId={testId}
        allowMultiple={filter.type === 'multiSelect'}
        options={filter?.options || []}
        defaultValue={defaultValue}
        onChange={(value) => {
          createChangeHandler(value);
        }}
      />
    );
  }

  if (filter?.type === 'input') {
    /**
     * Antd defaultFilteredValue is an array but we only want to use the first value
     * in the array for inputs
     *
     * For data fetching tables we prefix filters with a `~` to indicate a substring match
     * We passing in defaultFilteredValues we want to make sure to remove the `~` so the
     * user doesn't see it
     */
    const defaultValue = defaultFilteredValue?.[0]
      ? defaultFilteredValue[0].startsWith('~')
        ? defaultFilteredValue[0].slice(1)
        : defaultFilteredValue[0]
      : undefined;

    return (
      <OpInput
        /* This will allow input to get the proper defaultFilteredValue on initial load -  making sure to display the final default value */
        key={defaultFilteredValue?.toString()}
        gtm={gtm && `${gtm}-input-filter`}
        size="small"
        placeholder={t('Filter')}
        testId={testId}
        allowClear
        data-lpignore // Prevents autofill
        defaultValue={defaultValue}
        onChange={({ target: { value } }) => {
          createChangeHandler(value);
        }}
        onKeyDown={handleKeyDown}
      />
    );
  }

  return <OpInput size="small" placeholder={t('Unfilterable')} disabled />;
};
