import { countries } from 'constants/countries';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

export const phoneUtils = {
  isValidNumber(phone: string) {
    return parsePhoneNumber(phone)?.isValid();
  },

  getCountryCode(
    phone: string,
    defaultCountryCode: CountryCode = 'US',
  ): CountryCode {
    return parsePhoneNumber(phone)?.country || defaultCountryCode;
  },

  getFormattedE164CountryCode(shortCode: CountryCode): string {
    const countryObj = countries.find((country) => country.short === shortCode);
    return `+${countryObj?.phoneCode}` || '+1';
  },
};
