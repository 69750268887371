import { useEffect, forwardRef, useState, useRef, useCallback } from 'react';

import {
  DisconnectReason,
  Participant,
  RemoteParticipant,
  RemoteTrackPublication,
  RoomEvent,
  Track,
} from 'livekit-client';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAlert } from 'routes/AppContainer/actions';
import { useRoomContext, useTracks } from '@livekit/components-react';

import {
  connectionStates,
  callStates,
} from 'containers/OpVideoPlayer/constants';
import { useVideoState, VideoEvents } from '@openpathsec/opvideo-state-machine';
import { useSelectorJs } from 'utils/customHooks';
import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import { timeoutStates, timeoutMs, disconnectReasonStatus } from '../constants';

// todo: reference this from other file instead of copy-pasting
interface LiveKitVideoPlayerProps {
  callState: string;
  connectionState: string;
  isMicrophoneEnabled: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setCallState: (state: string) => void;
  setConnectionState: (state: string) => void;
  setErrorProps: (props: object | null) => void;
}

interface LiveKitVideoPlayerStageProps extends LiveKitVideoPlayerProps {
  setShouldStartLive: (state: boolean) => void;
}

interface TimeoutState {
  previewTimeout: ReturnType<typeof setTimeout> | null;
  callTimeout: ReturnType<typeof setTimeout> | null;
  disconnectIfNoInteractionTimeout: ReturnType<typeof setTimeout> | null;
  type: string;
}

interface Metadata {
  isOpvideoDevice?: boolean;
  isIntercom?: boolean;
  isUser?: boolean;
}

export const LiveKitCallManager = forwardRef<
  HTMLVideoElement,
  LiveKitVideoPlayerStageProps
>(
  (
    {
      callState,
      connectionState,
      isMicrophoneEnabled,
      setCallState,
      setConnectionState,
      setIsLoading,
      setShouldStartLive,
      setErrorProps,
    },
    ref,
  ) => {
    const currentTracks = useRef<string[]>([]);
    const roomContext = useRoomContext();
    const tracksTracker = useTracks(
      [Track.Source.Camera, Track.Source.Microphone],
      {
        updateOnlyOn: [
          RoomEvent.TrackPublished,
          RoomEvent.TrackSubscribed,
          RoomEvent.TrackSubscriptionPermissionChanged,
          RoomEvent.TrackSubscriptionStatusChanged,
        ],
      },
    );

    const participantsUnmuted = useRef<string[]>([]);
    const [monitoringParticipantMicTracks, setMonitoringParticipantMicTracks] =
      useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const timeoutState = useRef<TimeoutState>({
      callTimeout: null,
      previewTimeout: null,
      disconnectIfNoInteractionTimeout: null,
      type: timeoutStates.NO_TIMEOUT,
    });
    const [shouldResetTimeout, setShouldResetTimeout] = useState<boolean>(true);

    const isVideoStateMachineEnabled = useSelectorJs(
      selectFeatureFlag('IS_VIDEO_STATE_MACHINE_ENABLED'),
    );

    const { sendEvent } = useVideoState();
    /* 
      no-await-in-loop rule was disabled since we need to await the result of each attempt 
      before proceeding to the next iteration of the loop.
    */
    const enableMicrophone = useCallback(
      async (newState: boolean) => {
        let retries = 0;
        let err = null;
        const maxRetries = 3;
        while (retries < maxRetries) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await roomContext?.localParticipant?.setMicrophoneEnabled(newState);

            if (isVideoStateMachineEnabled) {
              sendEvent({
                type: newState
                  ? VideoEvents.MicEnabled
                  : VideoEvents.MicDisabled,
              });
            }
            return;
          } catch (error) {
            err = error;
            retries++;
            if (retries < maxRetries) {
              console.error(
                `Failed setting microphone state on attempt ${retries}. Error details:` +
                  error,
              );
              // eslint-disable-next-line no-await-in-loop
              await new Promise((resolve) => {
                setTimeout(resolve, 200);
              });
            }
          }
        }
        if (isVideoStateMachineEnabled) {
          sendEvent({ type: VideoEvents.MicDisabled });
        }

        dispatch(setAlert('error', t(`Failed to enable microphone: ${err}`)));
        console.error(
          `Maximum retries on setMicrophoneEnabled exceeded. Unable to set microphone state: ${err}`,
        );
      },
      [
        isVideoStateMachineEnabled,
        dispatch,
        t,
        roomContext?.localParticipant,
        sendEvent,
      ],
    );

    const manageAnswerOrMic = useCallback(() => {
      /*
        Enable/disable mic whenever the mic toggle state changes and the session is in call state
        On Preview Mode, the mic should always be turned off
      */
      if (connectionState === connectionStates.CONNECTED) {
        enableMicrophone(callState === callStates.CALL && isMicrophoneEnabled);
      }
    }, [isMicrophoneEnabled, callState, connectionState, enableMicrophone]);

    useEffect(() => {
      manageAnswerOrMic();
    }, [manageAnswerOrMic, callState, isMicrophoneEnabled]);

    const cleanUpTracks = useCallback(() => {
      if (
        tracksTracker?.length === 0 &&
        ref !== null &&
        typeof ref !== 'function'
      ) {
        // Clean up if there were no tracks before
        const currentStream = ref?.current?.srcObject;
        if (currentStream instanceof MediaStream) {
          const previousTracks = currentStream.getTracks();
          previousTracks?.forEach((track) => {
            currentStream.removeTrack(track);
          });
        }
      }
    }, [ref, tracksTracker?.length]);

    const updateTracks = useCallback(() => {
      if (
        tracksTracker?.length > 0 &&
        ref !== null &&
        typeof ref !== 'function'
      ) {
        let shouldUpdate = false;
        let stream = ref?.current?.srcObject;
        if (!stream) {
          stream = new MediaStream();
        }
        tracksTracker?.forEach((track) => {
          if (
            !track.participant.isLocal &&
            track.publication.track?.mediaStreamTrack &&
            stream !== null &&
            stream !== undefined
          ) {
            if (
              !currentTracks.current.includes(track.publication.trackSid) &&
              stream instanceof MediaStream
            ) {
              stream.addTrack(track.publication.track?.mediaStreamTrack);
              currentTracks.current.push(track.publication.trackSid);
              shouldUpdate = true;
              if (connectionState !== connectionStates.CONNECTED) {
                // Connection state should be connected only once we add tracks to the video element
                setConnectionState(connectionStates.CONNECTED);
              }
            }
          }
        });
        if (
          shouldUpdate &&
          ref != null &&
          typeof ref !== 'function' &&
          stream instanceof MediaStream &&
          ref.current !== null
        ) {
          // eslint-disable-next-line no-param-reassign
          ref.current.srcObject = stream;
        }
      }
    }, [connectionState, ref, setConnectionState, tracksTracker]);

    useEffect(() => {
      cleanUpTracks();
      updateTracks();
    }, [cleanUpTracks, updateTracks]);

    const startTeardown = useCallback(async () => {
      if (roomContext) {
        setConnectionState(connectionStates.DISCONNECTED);
        await roomContext.disconnect();

        if (isVideoStateMachineEnabled) {
          sendEvent({ type: VideoEvents.EndCall });
          sendEvent({ type: VideoEvents.TeardownCompleted });
        }
      }
    }, [
      isVideoStateMachineEnabled,
      roomContext,
      sendEvent,
      setConnectionState,
    ]);

    const getUnmutedParticipants = useCallback(() => {
      if (roomContext && !monitoringParticipantMicTracks) {
        setMonitoringParticipantMicTracks(true);

        participantsUnmuted.current.push(
          roomContext.localParticipant.name + '',
        );

        roomContext.on('trackUnmuted', (_, participant: Participant) => {
          participantsUnmuted.current.push('' + participant.name);
        });

        roomContext.on('trackMuted', (_, participant: Participant) => {
          participantsUnmuted.current = participantsUnmuted.current.filter(
            (p) => p !== participant.name,
          );
        });

        roomContext.on(
          'trackPublished',
          (track: RemoteTrackPublication, participant: RemoteParticipant) => {
            let metadata: Metadata = {};
            try {
              metadata = JSON.parse(participant.metadata || '{}');
            } catch {
              // do nothing
            }

            if (metadata?.isOpvideoDevice) {
              participantsUnmuted.current.push('' + participant.name);
            }
          },
        );
      }
    }, [monitoringParticipantMicTracks, roomContext]);

    useEffect(() => {
      getUnmutedParticipants();
    }, [getUnmutedParticipants]);

    useEffect(() => {
      roomContext?.on('reconnecting', () => {
        if (isVideoStateMachineEnabled) {
          sendEvent({ type: VideoEvents.ShouldReconnect });
        }
      });
      roomContext?.on('reconnected', () => {
        if (isVideoStateMachineEnabled) {
          sendEvent({ type: VideoEvents.WebRTCReconnected });
        }
      });
      roomContext?.on(
        'disconnected',
        (reason: DisconnectReason | undefined) => {
          if (isVideoStateMachineEnabled) {
            if (
              reason !== disconnectReasonStatus.CLIENT_INITIATED &&
              reason !== disconnectReasonStatus.PARTICIPANT_REMOVED &&
              reason !== disconnectReasonStatus.SIGNAL_CLOSE
            ) {
              sendEvent({ type: VideoEvents.ConnectionFailed });
            }
            sendEvent({ type: VideoEvents.StartTeardown });
            sendEvent({ type: VideoEvents.TeardownCompleted });
          }
        },
      );
    }, [isVideoStateMachineEnabled, roomContext, sendEvent]);

    /*
      Preview and call timeouts: The call should be dropped after 1 hour (in preview mode) or 5 minutes (in call mode)
      A 1 minute time window is provided for the user to click "Continue" and reset the timeout
    */

    function clearPreviewTimeout(): void {
      if (timeoutState.current.previewTimeout) {
        clearTimeout(timeoutState.current.previewTimeout);
        timeoutState.current.previewTimeout = null;
        timeoutState.current.type = timeoutStates.NO_TIMEOUT;
      }
    }

    function clearCallTimeout(): void {
      if (timeoutState.current.callTimeout) {
        clearTimeout(timeoutState.current.callTimeout);
        timeoutState.current.callTimeout = null;
        timeoutState.current.type = timeoutStates.NO_TIMEOUT;
      }
    }

    function clearDisconnectIfNoInteractionTimeout(): void {
      if (timeoutState.current.disconnectIfNoInteractionTimeout) {
        clearTimeout(timeoutState.current.disconnectIfNoInteractionTimeout);
        timeoutState.current.disconnectIfNoInteractionTimeout = null;
        timeoutState.current.type = timeoutStates.NO_TIMEOUT;
      }
    }

    const clearTimeouts = useCallback(() => {
      clearPreviewTimeout();
      clearCallTimeout();
      clearDisconnectIfNoInteractionTimeout();
    }, []);

    const reconnectToPreview = useCallback(async () => {
      await startTeardown();
      setErrorProps(null);
      setIsLoading(true);
      setConnectionState(connectionStates.DISCONNECTED);
      setCallState(callStates.PREVIEW);
      setShouldStartLive(true);
      clearTimeouts();
      setShouldResetTimeout(true);
      sendEvent({ type: VideoEvents.ConnectionStart });
    }, [
      startTeardown,
      setErrorProps,
      setIsLoading,
      setConnectionState,
      setCallState,
      setShouldStartLive,
      clearTimeouts,
      sendEvent,
    ]);

    const startDisconnectIfNoInteractionTimeout = useCallback(() => {
      if (
        timeoutState.current.type === timeoutStates.PREVIEW ||
        timeoutState.current.type === timeoutStates.CALL
      ) {
        timeoutState.current.type = timeoutStates.DISCONNECT_WINDOW;
        timeoutState.current.disconnectIfNoInteractionTimeout = setTimeout(
          async () => {
            setConnectionState(connectionStates.DISCONNECTED);
            timeoutState.current.type =
              timeoutStates.DISCONNECT_WINDOW_EXCEEDED;
            startTeardown();
            setErrorProps({
              errorMessage: t('Call ended due to inactivity.'),
              buttonContent: t('Reload'),
              handleRetryClick: async () => {
                await reconnectToPreview();
              },
            });
          },
          timeoutMs.DISCONNECT,
        );
      }
    }, [
      startTeardown,
      setConnectionState,
      setErrorProps,
      reconnectToPreview,
      t,
    ]);

    const startTimeout = useCallback(() => {
      timeoutState.current.type =
        callState === callStates.PREVIEW
          ? timeoutStates.PREVIEW
          : timeoutStates.CALL;
      const callOrPreviewTimeout: number =
        callState === callStates.PREVIEW ? timeoutMs.PREVIEW : timeoutMs.CALL;

      return setTimeout(() => {
        startDisconnectIfNoInteractionTimeout();
        setErrorProps({
          errorMessage: t('Are you still watching?'),
          iconName: 'question circle outline',
          buttonContent: t('Continue'),
          handleRetryClick: () => {
            setErrorProps(null);
            setIsLoading(false);
            clearTimeouts();
            setShouldResetTimeout(true);
          },
        });
      }, callOrPreviewTimeout);
    }, [
      setErrorProps,
      setIsLoading,
      setShouldResetTimeout,
      t,
      callState,
      clearTimeouts,
      startDisconnectIfNoInteractionTimeout,
    ]);

    const setPreviewOrCallTimeout = useCallback(() => {
      if (
        timeoutState.current.type !== timeoutStates.DISCONNECT_WINDOW &&
        callState === callStates.PREVIEW &&
        !timeoutState.current.previewTimeout
      ) {
        clearTimeouts();
        timeoutState.current.previewTimeout = startTimeout();
        setShouldResetTimeout(false);
      } else if (
        timeoutState.current.type !== timeoutStates.DISCONNECT_WINDOW &&
        !(callState === callStates.PREVIEW) &&
        !timeoutState.current.callTimeout
      ) {
        clearTimeouts();
        timeoutState.current.callTimeout = startTimeout();
        setShouldResetTimeout(false);
      }
    }, [clearTimeouts, callState, startTimeout]);

    useEffect(() => {
      if (
        shouldResetTimeout ||
        connectionState === connectionStates.CONNECTED
      ) {
        setPreviewOrCallTimeout();
      }
    }, [shouldResetTimeout, setPreviewOrCallTimeout, connectionState]);

    // On unmount disconnect from room (if connected)
    useEffect(() => {
      return () => {
        clearTimeouts();
        startTeardown();
        setErrorProps(null);
      };
    }, [clearTimeouts, startTeardown, setErrorProps]);

    return null;
  },
);
