import { ChangeEvent } from 'react';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { useTranslation } from 'react-i18next';
import { OpInput } from 'new-components/DLS/OpInput/OpInput';

import './GlobalSearch.scss';

interface GlobalSearch {
  onGlobalSearchChange(event: ChangeEvent<HTMLInputElement>): void;
  gtm?: string;
}

export const GlobalSearch = ({ onGlobalSearchChange, gtm }: GlobalSearch) => {
  const { t } = useTranslation();

  return (
    <div className="global-search">
      <OpInput
        gtm={gtm && `${gtm}-search`}
        testId="op-table-search"
        className="global-search__input"
        allowClear
        size="large"
        placeholder={t('Search')}
        suffix={<SearchOutlined />}
        onChange={onGlobalSearchChange}
      />
    </div>
  );
};
