export const BatteryLow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9C2 7.89543 2.89543 7 4 7H18C19.1046 7 20 7.89543 20 9V9.5H21C21.5523 9.5 22 9.94772 22 10.5V13.5C22 14.0523 21.5523 14.5 21 14.5H20V15C20 16.1046 19.1046 17 18 17H4C2.89543 17 2 16.1046 2 15V9ZM10 8.5H18C18.2761 8.5 18.5 8.72386 18.5 9V15C18.5 15.2761 18.2761 15.5 18 15.5H7L10 8.5Z"
    />
  </svg>
);
