import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const PartnerSvg = ({ ...svgProps }) => (
  <SvgWrapper {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.03333 3C6.91127 3 8.43333 4.52207 8.43333 6.4C8.43333 7.454 7.94373 8.3822 7.19233 9.00667C8.58747 9.62207 9.56667 11.0127 9.56667 12.6333V13.7667C9.56667 14.0795 9.3128 14.3333 9 14.3333H1.06667C0.753867 14.3333 0.5 14.0795 0.5 13.7667V12.6333C0.5 11.0127 1.4792 9.62207 2.87433 9.00667C2.12293 8.3822 1.63333 7.454 1.63333 6.4C1.63333 4.52207 3.1554 3 5.03333 3ZM12.9667 3C14.8446 3 16.3667 4.52207 16.3667 6.4C16.3667 7.454 15.8771 8.3822 15.1257 9.00667C16.5208 9.62207 17.5 11.0127 17.5 12.6333V13.7667C17.5 14.0795 17.2461 14.3333 16.9333 14.3333H10.5957C10.6592 14.1554 10.7 13.9661 10.7 13.7667V13.2H16.3667V12.6333C16.3667 11.0705 15.0962 9.8 13.5333 9.8H12.4C11.5761 9.8 10.8394 10.1593 10.3215 10.7225C10.173 10.3576 9.98147 10.0153 9.75593 9.698C10.0699 9.4158 10.4314 9.19027 10.819 9.01687C10.0608 8.3924 9.56667 7.45967 9.56667 6.4C9.56667 4.52207 11.0887 3 12.9667 3ZM5.6 9.8H4.46667C2.90493 9.8 1.63333 11.0705 1.63333 12.6333V13.2H8.43333V12.6333C8.43333 11.0705 7.16173 9.8 5.6 9.8ZM5.03333 4.13333C3.78327 4.13333 2.76667 5.14993 2.76667 6.4C2.76667 7.65007 3.78327 8.66667 5.03333 8.66667C6.2834 8.66667 7.3 7.65007 7.3 6.4C7.3 5.14993 6.2834 4.13333 5.03333 4.13333ZM12.9667 4.13333C11.7166 4.13333 10.7 5.14993 10.7 6.4C10.7 7.65007 11.7166 8.66667 12.9667 8.66667C14.2167 8.66667 15.2333 7.65007 15.2333 6.4C15.2333 5.14993 14.2167 4.13333 12.9667 4.13333Z"
    />
  </SvgWrapper>
);
