import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'semantic-ui-react/dist/es/modules/Modal/Modal';
import { stringify } from 'query-string';
import { useSelectorJs, useFeatureGate } from 'utils/customHooks';
import { requestPackageUpgradeWithCode } from 'routes/AuthenticatedContainer/actions';
import { selectBillingMode } from 'global/billing/selectors';
import { selectAllFeatures } from 'global/features/selectors';
import { Segment } from 'components/Segment';
import { Button } from 'components/Button';
import { Loader } from 'components/Loader';
import { MessageTypes } from 'utils/iframeMessaging';
import { selectCurrentOrgId } from 'global/accessToken/selectors';
import {
  selectCurrentIdentityInfo,
  selectCurrentOrgName,
} from 'routes/AppContainer/selectors';
import { useTranslation } from 'react-i18next';
import { OpDivider } from 'new-components/DLS/OpDivider/OpDivider';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { appColorThemeManager } from 'utils/appColorThemeManager';
import featureGateUrlDictionary from './featureGateUrlDictionary';

const openpathConfig = require('openpathConfig');

const styles = {
  overlay: {
    background: 'var(--colorBgMask)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    borderRadius: 4,
    // paddingTop: '60px', // For upgrade prompt
  },
  upgradeCtaSectionContainer: {
    height: '50%',
    maxHeight: 300,
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const FeatureGate = ({
  featureCode,
  children,
  style,
  loader = true,
  activateOnClick = false,
  onModalButtonPress,
}) => {
  const { t } = useTranslation();
  // If hasFeature is null it most likely means we didn't pass a featureCode
  const hasFeature = useFeatureGate(featureCode);
  // Disabled when there is no feature code or the feature is not enabled
  const disabled = Boolean(featureCode) && !hasFeature;

  // For actions
  const dispatch = useDispatch();

  // reference to the iframe
  const iframeRef = useRef();

  // Selectors
  const billingMode = useSelectorJs(selectBillingMode());
  const allFeatures = useSelectorJs(selectAllFeatures());
  const currentIdentity = useSelectorJs(selectCurrentIdentityInfo());
  const currentOrgName = useSelectorJs(selectCurrentOrgName());
  const currentOrgId = useSelectorJs(selectCurrentOrgId());

  // State
  const [isHovered, setIsHovered] = useState(false);
  const [isMarketingUpgradeOpen, setIsMarketingUpgradeOpen] = useState(false);
  const [showMarketingLoader, setShowMarketingLoader] = useState(true);

  // dictionary
  // const featureInfo = useMemo(() => featureGateUrlDictionary[featureCode][billingMode === 'channel' ? 'channel' : 'revshare'], [featureCode, billingMode])

  const featureInfo = useRef({});

  useEffect(() => {
    // We only want to run the code if we have a featureCode
    // (featureCode will be null if it is not passed to FeatureGate due to default props)
    if (featureCode) {
      // NOTE - this might be bad, but we don't want to render while we're waiting for the selector
      if (billingMode === undefined) return;

      try {
        featureInfo.current =
          featureGateUrlDictionary[featureCode][
            billingMode === 'channel' ? 'channel' : 'revshare'
          ];
      } catch {
        const msg = t(
          `Unable to find upgrade URL for featureCode: {{featureCode}} with billing mode: {{billingMode}}.`,
          {
            featureCode,
            billingMode,
          },
        );
        featureInfo.current =
          featureGateUrlDictionary.generic[
            billingMode === 'channel' ? 'channel' : 'revshare'
          ];
        console.error(msg);
      }
    }
  }, [t, featureCode, billingMode, dispatch]);

  // Get the color theme from the identity preferences
  const { data: colorTheme } = useOpQuery({
    apiEndpointName: 'describeIdentityPreferenceSet',
    parameters: [currentIdentity?.id],
    select: (heliumResponse) => heliumResponse?.json?.data?.colorTheme,
  });

  // Handlers
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    // don't let the upgrade flow close if they mouse out of the window
    if (isMarketingUpgradeOpen) return;
    setIsHovered(false);
  };

  const urlParams = useMemo(
    () =>
      stringify(
        {
          firstname: currentIdentity?.firstName,
          email: currentIdentity?.email,
          company: currentOrgName,
          orgid: currentOrgId,
          featureCode,
          billingMode,
          darkMode:
            appColorThemeManager.getColorThemeForProvider(colorTheme) ===
            'dark', // To show the iFrame in dark mode or not
        },
        { strict: false },
      ),
    [
      billingMode,
      colorTheme,
      currentIdentity?.email,
      currentIdentity?.firstName,
      currentOrgId,
      currentOrgName,
      featureCode,
    ],
  );

  let envUrl = 'https://modal.prod.control.openpath.com';
  if (
    !openpathConfig.ENV ||
    ['sandbox', 'mine', 'dev'].includes(openpathConfig.ENV)
  ) {
    envUrl = 'https://modal.dev.control.openpath.com';
  }

  useEffect(() => {
    const messageHandler = (e) => {
      // ignore messages coming from any other origin
      if (![envUrl].includes(e.origin)) return;
      // ignore messages we don't care about
      if (!Object.keys(MessageTypes).includes(e.data.type)) return;
      switch (e.data.type) {
        case 'PLATINUM_MESSAGE_TEST':
          console.log(`MESSAGE FROM IFRAME ${JSON.stringify(e.data)}`); // eslint-disable-line no-console
          break;
        case 'PARENT_REDIRECT':
          dispatch(
            requestPackageUpgradeWithCode(
              featureCode,
              billingMode === 'channel',
            ),
          );
          break;
        case 'DESTROY_CHILD':
          if (e.data.data) {
            setIsHovered(false);
          }
          break;
        case 'CHILD_CONTENT_READY':
          if (e.data.data) {
            setShowMarketingLoader(false);
          }
          break;
        default:
          console.error(`UNHANDLED MESSAGE TYPE FROM IFRAME ${e.data.type}`);
          break;
      }
    };
    // listen for messages from the iFrame
    window.addEventListener('message', messageHandler);
    // send a test message every 5 seconds into the iframe
    // setInterval(() => sendMessageToIframe(MessageTypes.PLATINUM_MESSAGE_TEST, `Hello ${Date.now()}`), 5000)
    return () => window.removeEventListener('message', messageHandler);
  }, [dispatch, featureCode, billingMode, envUrl]);

  const onOpenCallback = useCallback(() => {
    setIsMarketingUpgradeOpen(true);
    setTimeout(() => setShowMarketingLoader(false), 10000);
  }, []);

  const onCloseCallback = useCallback(() => {
    setIsMarketingUpgradeOpen(false);
    setShowMarketingLoader(true);
  }, []);

  const renderUpgradeText = () => {
    const codeName =
      allFeatures?.find(({ code }) => code === featureCode)?.name ||
      featureCode;

    return (
      <h4>
        {featureInfo?.current?.popUpCopy
          ? featureInfo.current.popUpCopy.replace('FEATURECODE', codeName)
          : t(`This is a Premium Feature`)}
      </h4>
    );
  };

  const renderUpgradeButton = (customTrigger) => {
    /**
     * I am using a new prop onModalButtonPress
     * for now to be safe as I have no idea what
     * customTrigger was / is being used for. We
     * are revisiting the feature gate so this should
     * be sufficient for now.
     */
    if (onModalButtonPress) {
      return (
        <Button
          id={`${featureCode}-upgrade-button`}
          data-gtm={`${featureCode}-feature-gate-button`}
          primary
          fluid
          icon="arrow circle up"
          content={featureInfo?.current?.buttonCta || t('More information')}
          labelPosition="left"
          onClick={onModalButtonPress}
        />
      );
    }

    return (
      <Modal
        onOpen={onOpenCallback}
        onClose={onCloseCallback}
        closeIcon
        trigger={
          customTrigger || (
            <Button
              id={`${featureCode}-upgrade-button`}
              data-gtm={`${featureCode}-feature-gate-button`}
              primary
              fluid
              icon="arrow circle up"
              content={featureInfo?.current?.buttonCta || t('More information')}
              labelPosition="left"
            />
          )
        }
        content={
          <>
            {showMarketingLoader && <Loader active />}
            <iframe
              style={{ border: 'none' }}
              ref={iframeRef}
              width="100%"
              height="600"
              id="featureCodeUpgradeFrame"
              title="featureCodeUpgradeForm"
              src={`${envUrl}${featureInfo.current.url}?${urlParams}`}
            />
          </>
        }
      />
    );
  };

  // ** new simple version for things like buttons and not sections...
  if (activateOnClick) {
    return disabled
      ? renderUpgradeButton(children({ disabled, enabled: !disabled }))
      : children({ disabled, enabled: !disabled });
  }

  // we we didn't pass a feature code, just ignore everything
  if (!featureCode) return children({ disabled: false, enabled: true });

  return (
    <div style={{ position: 'relative', ...style }} data-testid="feature-gate">
      {/* Children (need position property in order for overlay to work properly */}
      {children({ disabled, enabled: !disabled })}

      {/* Gate (only show when the feature is disabled */}
      {disabled &&
        (allFeatures?.length > 0 ? (
          <div
            style={{ ...styles.overlay, opacity: isHovered ? 1 : 0 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-testid="feature-gate-overlay-container"
          >
            {isHovered && (
              <div
                style={styles.upgradeCtaSectionContainer}
                data-testid="feature-gate-overlay"
              >
                <Segment>
                  <div style={styles.message}>
                    {renderUpgradeText()}
                    <OpDivider />
                    {renderUpgradeButton()}
                  </div>
                </Segment>
              </div>
            )}
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{loader && <Loader content={t(`Loading features...`)} />}</>
        ))}
    </div>
  );
};

FeatureGate.propTypes = {
  children: PropTypes.func.isRequired,
  featureCode: PropTypes.string,
  style: PropTypes.object,
  loader: PropTypes.bool,
  activateOnClick: PropTypes.bool,
  onModalButtonPress: PropTypes.func,
};

export default FeatureGate;
