export const connectionStates = {
  DISCONNECTED: 'DISCONNECTED',
  CONNECTING: 'CONNECTING',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  CONNECTED: 'CONNECTED',
  SHOULD_RECONNECT: 'SHOULD_RECONNECT',
};

export const callStates = {
  PREVIEW: 'PREVIEW',
  CALL: 'CALL',
  DISCONNECTED: 'DISCONNECTED',
};
