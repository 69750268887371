import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { OpModal } from 'new-components/DLS/OpModal/OpModal';
import { OnSubmitArgs, OpForm } from 'new-components/DLS/OpForm/OpForm';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  parcelStatusCodeToId,
  getParcelStatusOptions,
} from './helpers/constants';

interface ParcelStatusModalProps {
  isOpen: boolean;
  onClose(): void;
  onUpdate(): void;
  selectedIds: string[];
  orgId: number;
}

/**
 * @component
 * @param {Boolen} isOpen - is the modal open or closed
 * @param {Function} onClose - callback to close the modal
 * @param {Function} onUpdate - callback to handle update success
 * @param {string[]} selectedIds - ids of the selected parcels
 * @param {number} orgId - orgId number
 * @returns {JSX.Element} - modal containing a form to update parcel status of the selected parcels
 */
export const ParcelStatusModal = ({
  isOpen,
  onClose,
  onUpdate,
  selectedIds,
  orgId,
}: ParcelStatusModalProps) => {
  const { t } = useTranslation();
  const [form] = OpForm.useForm();
  const updateParcelsMutation = useOpMutation({
    apiEndpointName: 'updateParcels',
    onSuccessMessage: t(
      'Successfully updated the status of {{count}} packages',
      {
        count: selectedIds.length,
      },
    ),
    queryKeysToInvalidate: [['describeParcel'], ['listParcels']],
  });

  const parcelStatusOptions = useMemo(() => getParcelStatusOptions(), []);

  const handleSubmit = async ({ touchedValues }: OnSubmitArgs) => {
    const { parcelStatusCode } = touchedValues;

    const payload = selectedIds?.map((id) => ({
      idExt: id,
      parcelStatusId: parcelStatusCodeToId[parcelStatusCode],
    }));

    try {
      await updateParcelsMutation.mutateAsync({
        apiEndpointRequirements: [orgId],
        payload,
      });

      onUpdate();
    } catch {
      // mutateAsync shows the error already
      onClose();
    }
  };

  return (
    <OpModal
      onCancel={onClose}
      open={isOpen}
      title={t('Change package status')}
      okText={t('Update')}
      onOk={form.submit}
      closeIcon={<CloseOutlined data-gtm={`parcel-status-modal-close-icon`} />}
    >
      <OpForm
        className="parcel-modal-form"
        form={form}
        testId="modal-parcel-status-form"
        defaultButtons={false}
        hasError={false}
        onSubmit={handleSubmit}
      >
        <OpForm.Select
          rules={[
            {
              required: true,
              message: t('Package status is required'),
            },
          ]}
          testId="modal-parcel-status"
          label={t('Status')}
          name="parcelStatusCode"
          options={parcelStatusOptions}
        />
      </OpForm>
    </OpModal>
  );
};
