import { OpUserAvatar } from 'new-components/OpUserAvatar/OpUserAvatar';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';

export const OpIdentityUserAvatar = ({ size }: { size: number }) => {
  const { user, identity } = useIdentityAndUser();

  return (
    <OpUserAvatar
      userId={user?.id}
      initials={identity?.initials ?? undefined}
      size={size}
    />
  );
};
