import React from 'react';
import { ReactReduxContext } from 'react-redux';

const useInjectReducers = (reducers = [], readyCallback = null) => {
  const context = React.useContext(ReactReduxContext);

  React.useEffect(() => {
    if (!reducers.length) {
      if (readyCallback) {
        readyCallback();
      }
      return;
    }

    context.store.reducerManager.add(reducers, readyCallback);

    return () => {
      context.store.reducerManager.remove(reducers);
    };
  }, [context.store, readyCallback, reducers]);
};

export { useInjectReducers };
