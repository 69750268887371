import { ComponentProps } from 'react';
import Menu from 'antd/es/menu';
import clsx from 'clsx';

interface OpMenuProps extends Omit<ComponentProps<typeof Menu>, 'data-testid'> {
  testId?: string;
}

export const OpMenu = ({
  className,
  testId = 'op-menu',
  ...menuProps
}: OpMenuProps) => {
  return (
    <Menu
      className={clsx('op-menu', className)}
      {...menuProps}
      data-testid={testId}
    />
  );
};
