import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { OpIdentityUserAvatar } from 'new-components/OpIdentityUserAvatar/OpIdentityUserAvatar';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';

export default () => {
  const { identity } = useIdentityAndUser();

  const fullName = identity?.fullName
    ? identity?.fullName
    : `${identity?.firstName || ''} ${identity?.lastName || ''}`.trim();

  return (
    <OpSpace>
      <OpIdentityUserAvatar size={64} />
      <div className="user-menu__header-text">
        {fullName && (
          <OpText className="user-menu__header-name">{fullName}</OpText>
        )}
        <OpText className="user-menu__header-email">{identity?.email}</OpText>
        <OpText className="user-menu__header-namespace-nickname">
          {identity?.namespace?.nickname}
        </OpText>
      </div>
    </OpSpace>
  );
};
