import clsx from 'clsx';
import { ComponentProps } from 'react';
import { OpHeader } from 'new-components/OpHeader.tsx/OpHeader';
import { UserMenu } from 'new-components/UserMenu/UserMenu';
import { OrgSwitcher } from 'new-components/OrgSwitcher/OrgSwitcher';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { HamburgerMenuSvg } from 'components/svgs/HamburgerMenuSvg';

import './OpAppHeader.scss';

interface OpAppHeaderProps extends ComponentProps<typeof OpHeader> {
  homePath: string;
  onHamburgerClick?: () => void;
  showOrgSwitcher?: boolean;
  showHamburger?: boolean;
}

export const OpAppHeader = ({
  className,
  homePath,
  onHamburgerClick,
  showHamburger,
  showOrgSwitcher = true,
  ...headerProps
}: OpAppHeaderProps) => {
  return (
    <OpHeader className={clsx('op-app-header', className)} {...headerProps}>
      <div className="op-app-header__left-content">
        {showHamburger && (
          <OpButton
            gtm="op-app-header-hamburger"
            data-testid="op-app-header-hamburger"
            className="op-app-header__hamburger"
            onClick={onHamburgerClick}
            icon={<HamburgerMenuSvg />}
          />
        )}
      </div>
      <div className="op-app-header__right-content">
        {showOrgSwitcher && <OrgSwitcher homePath={homePath} />}
        <UserMenu />
      </div>
    </OpHeader>
  );
};
