import { ComponentProps, forwardRef } from 'react';
import { OpRadio } from 'new-components/DLS/OpRadio/OpRadio';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';

interface RadioListProps {
  options: { label: string; value: string | number }[];
  onChange: (value: string[]) => void;
  selectValue: string[];
  setSelectValue: (value: string[]) => void;
}

type RadioGroupProps = ComponentProps<(typeof OpRadio)['Group']>;

/* Without forwardRef we see an error in the console as most likely a ref is 
being passed through. Could investigate more if needed */
export const RadioList = forwardRef(
  (
    { options = [], selectValue, setSelectValue, onChange }: RadioListProps,
    _ref, // This is not used directly
  ) => {
    const handleRadioChange: RadioGroupProps['onChange'] = ({
      target: { value },
    }) => {
      // Set the new select value
      setSelectValue([value]);

      // Set the table state filter with the new value
      onChange([value]);
    };

    return (
      <div className="select-filter__radio-list">
        <OpRadio.Group onChange={handleRadioChange} value={selectValue[0]}>
          <OpSpace direction="vertical">
            {options.map(({ label, value }) => (
              <OpRadio key={value} value={value}>
                {label}
              </OpRadio>
            ))}
          </OpSpace>
        </OpRadio.Group>
      </div>
    );
  },
);
