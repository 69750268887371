import { useTranslation } from 'react-i18next';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { OpTable } from 'new-components/DLS/OpTable/OpTable';
import { useMemo } from 'react';
import { processParcelHistory } from './helpers/processParcelHistory';

export const ParcelHistoryTable = ({
  orgId,
  parcelIdExt,
}: {
  orgId: number;
  parcelIdExt: string;
}) => {
  const { t } = useTranslation();

  const { data: parcelHistory } = useOpQuery({
    apiEndpointName: 'listParcelHistory',
    parameters: [orgId, parcelIdExt],
    select: (data) => data.json.data ?? [],
  });

  const parcelHistoryRows = processParcelHistory(parcelHistory);

  const columns = useMemo(
    () => [
      {
        label: t('Event'),
        dataIndex: ['event'],
      },
      {
        label: t('Mailroom time'),
        dataIndex: ['time'],
      },
    ],
    [t],
  );

  return (
    <OpTable
      uiStateKey="ParcelHistoryTable"
      columns={columns}
      gtm="parcel-history-table"
      rowKey={(record) => record.index}
      dataSource={parcelHistoryRows}
      label={t('History')}
      allowShowHideColumns={false}
      allowGlobalSearch={false}
      allowExport={false}
    />
  );
};
