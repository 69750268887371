import { ComponentProps, useState } from 'react';
import AceEditor, { IAnnotation } from 'react-ace';
import clsx from 'clsx';
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-clouds';
import 'ace-builds/src-noconflict/theme-cloud9_night';
import 'ace-builds/webpack-resolver';
import { type Schema } from 'joi';
import { validateJSON } from './helpers';

import './OpCodeEditor.scss';

ace.config.set('loadWorkerFromBlob', false); // Disable worker loading from blob

interface OpCodeEditorProps extends ComponentProps<typeof AceEditor> {
  testId?: string;
  showAnnotation?: boolean;
  disabled?: boolean;
  id?: string;
  schema?: Schema;
}

export const OpCodeEditor = ({
  testId = 'op-code-editor',
  className,
  showAnnotation = true,
  disabled,
  schema,
  ...elementProps
}: OpCodeEditorProps) => {
  const [annotations, setAnnotations] = useState<IAnnotation[] | undefined>([]);

  const onChange = async (newValue: string) => {
    const errors = await validateJSON(newValue, schema);

    setAnnotations(errors);

    elementProps.onChange?.(newValue);
  };

  const theme = document.documentElement.classList.contains('dark')
    ? 'cloud9_night'
    : 'clouds';

  return (
    <AceEditor
      data-testid={testId}
      className={clsx('op-code-editor', 'op-code-editor--error', className, {
        'op-code-editor--disabled': disabled,
      })}
      mode="json"
      readOnly={disabled}
      editorProps={{ $blockScrolling: true }}
      tabSize={2}
      highlightActiveLine={!disabled}
      {...elementProps}
      onChange={onChange} // If we ever need to customize the onChange through a prop, we can move this up
      theme={theme}
      annotations={showAnnotation ? annotations : undefined}
      /**
       * Disable the built-in JSON linting by setting `useWorker: false`. The
       * built-in JSON linting prevents creating custom annotations based on
       * JSON & schema validation failures.
       */
      setOptions={{ useWorker: false }}
    />
  );
};
