// internally, this file is only used for testing but the code is
// used on the marketing page. This is the "master copy" of the source
// until we are ready to make a shared repo between platinum/marketing

// Only these message types will be received
const MessageTypes = {
  PLATINUM_MESSAGE_TEST: 'PLATINUM_MESSAGE_TEST', // unused/testing
  CHILD_CONTENT_READY: 'CHILD_CONTENT_READY', // when dom is ready inside iframe
  DESTROY_CHILD: 'DESTROY_CHILD', // when iframe is ready to be closed
  PARENT_REDIRECT: 'PARENT_REDIRECT', // when iframe wants parent to redirect (iframe will be closed, send `billing` as data)
};

// callback for receiving messages from Platinum
function platinumMessageHandler(e) {
  // filter out messages from bad origins
  if (!['http://localhost:3000'].includes(e.origin)) return;
  // filter out types we don't want
  if (!Object.keys(MessageTypes).includes(e.data.type)) return;
  // handle message coming in
  const log = document.createElement('div');
  log.innerHTML = `(${e.data.type}) ${e.data.data}`;
  document.getElementById('content').append(log); //! no content id anywhere, so this won't work
}

// Helper to send a message to Platinum
function sendMessageToPlatinum(type, data) {
  if (!parent) return;
  parent.postMessage({ type, data });
}

// Setup message receiving
function setupPlatinumMessaging() {
  window.addEventListener('message', platinumMessageHandler, false);
}

// Tear down message receiving
function tearDownPlatinumMessaging() {
  window.removeEventListener('message', platinumMessageHandler);
}

export {
  MessageTypes,
  tearDownPlatinumMessaging,
  setupPlatinumMessaging,
  sendMessageToPlatinum,
  platinumMessageHandler,
};
