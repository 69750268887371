import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { t as translate } from 'i18next';
import clsx from 'clsx';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { Store } from 'antd/es/form/interface';
import { OpButton } from '../OpButton/OpButton';
import { OpSpace } from '../OpSpace/OpSpace';
import { OpForm } from './OpForm';
import { OpTooltip } from '../OpTooltip/OpTooltip';

import './FormButtons.scss';

interface FormButtonsProps<T extends Store> {
  className?: string;
  form: ComponentProps<typeof OpForm<T>>['form'];
  isFormLoading?: boolean;
  isSubmitButtonLoading?: boolean;
  submitButtonLabel?: string;
  isReadOnly?: boolean;
  tooltip?: string;
  gtm?: string;
}

export const FormButtons = <T extends Store = Store>({
  className,
  form,
  isFormLoading = false,
  isSubmitButtonLoading = false,
  submitButtonLabel = translate('Save'),
  isReadOnly = false,
  tooltip,
  gtm,
}: FormButtonsProps<T>) => {
  const confirmModal = useConfirmModal();
  const { t } = useTranslation();

  const onResetClick = () => {
    if (form?.isFieldsTouched()) {
      confirmModal({
        title: t('Confirm discard?'),
        content: t(
          'You have unsaved changes. Are you sure you wish to discard them?',
        ),
        cancelText: t('No'),
        closable: true,
        okText: t('Yes'),
        onOk: () => {
          form.resetFields();
        },
      });
    }
  };

  const onSubmitClick = () => {
    form?.submit();
  };

  return (
    <OpTooltip title={tooltip} placement="topRight">
      <OpSpace className={clsx('form-buttons', className)} size="middle">
        <OpButton
          gtm={gtm && `${gtm}-reset-form-button`}
          testId="reset-form-button"
          onClick={onResetClick}
          disabled={isReadOnly || isFormLoading}
        >
          {t('Reset')}
        </OpButton>
        <OpButton
          gtm={gtm && `${gtm}-submit-form-button`}
          testId="submit-form-button"
          type="primary"
          onClick={onSubmitClick}
          disabled={isReadOnly || isFormLoading}
          loading={isSubmitButtonLoading}
        >
          {submitButtonLabel}
        </OpButton>
      </OpSpace>
    </OpTooltip>
  );
};
