import { ComponentProps } from 'react';
import clsx from 'clsx';
import Transfer from 'antd/es/transfer';

import './OpTransfer.scss';

interface OpTransferProps extends ComponentProps<typeof Transfer> {
  testId?: string;
}

export const OpTransfer = ({
  className,
  testId = 'op-transfer',
  ...elementProps
}: OpTransferProps) => {
  return (
    // Currently antd isnt supporting data-testid being directly added to the component
    <div data-testid={testId}>
      <Transfer className={clsx('op-transfer', className)} {...elementProps} />
    </div>
  );
};
