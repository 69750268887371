export const CLEAR_AUTHENTICATED_CONTAINER_STORE_DATA =
  'app/Authenticated/CLEAR_AUTHENTICATED_CONTAINER_STORE_DATA';
export const CHANGE_ROUTE = 'app/Authenticated/CHANGE_ROUTE';

export const ROOT_CONTAINER_MOUNTED =
  'app/Authenticated/ROOT_CONTAINER_MOUNTED';
export const CLEAR_ORG_SPECIFIC_STORE_DATA =
  'app/Authenticated/CLEAR_ORG_SPECIFIC_STORE_DATA';
export const FIRE_AUTH_REDIRECT_CHECK =
  'app/Authenticated/FIRE_AUTH_REDIRECT_CHECK';
export const FIRE_SCOPE_CHECK = 'app/Authenticated/FIRE_SCOPE_CHECK';
export const REQUEST_AND_SET_IDENTITY =
  'app/Authenticated/REQUEST_AND_SET_IDENTITY';
export const REQUEST_PACKAGE_UPGRADE =
  'app/Authenticated/REQUEST_PACKAGE_UPGRADE';
