import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const FilterSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 12 12" {...svgProps}>
    <path d="M0 2.5C0 2.22386 0.223858 2 0.5 2H11.5C11.7761 2 12 2.22386 12 2.5C12 2.77614 11.7761 3 11.5 3H0.5C0.223858 3 0 2.77614 0 2.5Z" />
    <path d="M2 6.5C2 6.22386 2.22386 6 2.5 6H9.5C9.77614 6 10 6.22386 10 6.5C10 6.77614 9.77614 7 9.5 7H2.5C2.22386 7 2 6.77614 2 6.5Z" />
    <path d="M4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H7.5C7.77614 11 8 10.7761 8 10.5C8 10.2239 7.77614 10 7.5 10H4.5Z" />
  </SvgWrapper>
);
