import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpDataFetchTransfer } from 'new-components/DLS/OpDataFetchTransfer/OpDataFetchTransfer';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormDataFetchTransfer.scss';

interface OpFormDataFetchTransferProps<
  T extends keyof Api.ClientSpec,
  U extends keyof Api.ClientSpec,
> extends Pick<
      ComponentProps<typeof OpForm.Item>,
      | 'name'
      | 'label'
      | 'tooltip'
      | 'rules'
      | 'validateTrigger'
      | 'validateStatus' // Temp: For SUIR wrapped version
      | 'help' // Temp: For SUIR wrapped version
      | 'required' // Temp: For SUIR wrapped version
    >,
    ComponentProps<typeof OpDataFetchTransfer<T, U>> {
  formItemClassName?: string;
  isLoading?: boolean;
}

export const OpFormDataFetchTransfer = <
  T extends keyof Api.ClientSpec,
  U extends keyof Api.ClientSpec,
>({
  // Form Item props
  name,
  label,
  tooltip,
  rules,
  validateTrigger,
  formItemClassName,
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  disabled,

  ...transferProps
}: OpFormDataFetchTransferProps<T, U>) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx('op-form-data-fetch-transfer', formItemClassName)}
      name={name}
      label={label}
      tooltip={tooltip}
      rules={rules}
      validateTrigger={validateTrigger}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Input active block />
      ) : (
        <OpDataFetchTransfer<T, U>
          disabled={isReadOnly || disabled}
          {...transferProps}
        />
      )}
    </OpForm.Item>
  );
};
