import { fromJS } from 'immutable';
import { CLEAR_ORG_SPECIFIC_STORE_DATA } from 'routes/AuthenticatedContainer/constants';
import { LOGOUT_SUCCESS } from 'routes/AppContainer/constants';
import { SET_ORG_PACKAGE_PLANS } from './constants';

function orgPackagePlansReducer(state = fromJS([]), action) {
  switch (action.type) {
    case SET_ORG_PACKAGE_PLANS:
      return fromJS(action.orgPackagePlans);
    case CLEAR_ORG_SPECIFIC_STORE_DATA:
    case LOGOUT_SUCCESS:
      return fromJS([]);
    default:
      return state;
  }
}

export default orgPackagePlansReducer;
