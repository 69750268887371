import { Header } from 'antd/es/layout/layout';
import clsx from 'clsx';
import { ComponentProps } from 'react';

interface OpHeaderProps extends ComponentProps<typeof Header> {
  testId?: string;
  gtm?: string;
}

export const OpHeader = ({
  className,
  testId = 'op-menu',
  children,
  ...headerProps
}: OpHeaderProps) => {
  return (
    <Header
      className={clsx('op-header', className)}
      {...headerProps}
      data-testid={testId}
    >
      {children}
    </Header>
  );
};
