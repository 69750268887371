import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/svgs/SvgWrapper';

interface FileOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const FileOutlinedSvg = ({ ...svgProps }: FileOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M12.5 16H5.5C4.673 16 4 15.327 4 14.5V3.5C4 2.673 4.673 2 5.5 2H9V4.5C9 5.878 10.121 7 11.5 7H14V14.5C14 15.327 13.327 16 12.5 16ZM13.293 6H11.5C10.673 6 10 5.327 10 4.5V2.707L13.293 6ZM14.561 5.854L10.146 1.439C9.863 1.156 9.486 1 9.086 1H5.5C4.122 1 3 2.122 3 3.5V14.5C3 15.879 4.122 17 5.5 17H12.5C13.879 17 15 15.879 15 14.5V6.914C15 6.514 14.844 6.137 14.561 5.854Z" />
  </SvgWrapper>
);
