import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { formatSecondsToClock } from 'utils/dates';
import { useHoverDirty, useMouseHovered } from 'react-use';
import { OpSlider } from 'new-components/DLS/OpSlider/OpSlider';

const SeekBar = memo(({ currentTime, duration, handleSeek }) => {
  const sliderContainerRef = useRef(null);
  const { elX, elW } = useMouseHovered(sliderContainerRef, {
    bound: true,
    whenHovered: true,
  });
  const isHovering = useHoverDirty(sliderContainerRef);

  const hoverTimeStyles = {
    position: 'absolute',
    left: elX - 10,
    color: 'var(--colorWhite)',
    bottom: 20,
    pointerEvents: 'none',
  };

  const handleStyle = {
    display: isHovering ? 'block' : 'none',
  };

  return (
    <div className="seek-bar">
      <div ref={sliderContainerRef} className="op-position-relative">
        {isHovering && !!duration && elX !== undefined && !!elW && (
          <div style={hoverTimeStyles}>
            {formatSecondsToClock((duration * elX) / elW)}
          </div>
        )}
        <OpSlider
          value={(currentTime / duration) * 100}
          onChange={handleSeek}
          tooltip={{ open: false }}
          handleStyle={handleStyle}
        />
      </div>
    </div>
  );
});

SeekBar.displayName = 'SeekBar';

SeekBar.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  handleSeek: PropTypes.func.isRequired,
};

export default SeekBar;
