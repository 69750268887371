import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import invariant from 'invariant';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import { LOGOUT_SUCCESS } from 'routes/AppContainer/constants';
import { CLEAR_ORG_SPECIFIC_STORE_DATA } from 'routes/AuthenticatedContainer/constants';
import { reducer as accessTokenReducer } from 'global/accessToken/'; // this is named export. How is this working?
import { reducer as billingReducer } from 'global/billing/';
import { reducer as bannerReducer } from 'global/banner/';
import { reducer as activityLogsReducer } from 'global/activityLogs/';
import { reducer as lockdownPlansReducer } from 'global/lockdownPlans/';
import { reducer as cloudKeyReducer } from 'global/cloudKey/';
import modalDeluxeReducer from 'global/modalDeluxe/reducer';
import orgFeaturesReducer from 'global/orgFeatures/reducer';
import orgPackagePlansReducer from 'global/orgPackagePlans/reducer';
import featuresReducer from 'global/features/reducer';
import packagesReducer from 'global/packages/reducer';
import slideOutsReducer from 'global/slideOuts/reducer';
import history from './history';

export const staticReducers = {
  global: combineReducers({
    billing: billingReducer,
    modal: modalDeluxeReducer,
    banners: bannerReducer,
    activityLogs: activityLogsReducer,
    lockdownPlans: lockdownPlansReducer,
    cloudKeys: cloudKeyReducer,
    orgFeatures: orgFeaturesReducer,
    orgPackagePlans: orgPackagePlansReducer,
    packages: packagesReducer,
    slideOuts: slideOutsReducer,
    features: featuresReducer,
  }),
  accessToken: accessTokenReducer,
  router: connectRouter(history),
};

export const createReducerManager = (additionalReducers = {}) => {
  // Create an object which maps keys to reducers
  const reducers = { ...staticReducers, ...additionalReducers };
  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = [];

  // The root reducer function exposed by this object
  // This will be passed to the store
  const generateReduce = () => (state, action) => {
    // If any reducers have been removed, clean up their state first
    if (keysToRemove.length > 0) {
      const cleanedState = state.withMutations((temporaryState) => {
        keysToRemove.forEach((key) => {
          temporaryState.delete(key);
        });
      });
      keysToRemove = [];
      return combinedReducer(cleanedState, action);
    }

    return combinedReducer(state, action);
  };

  const reducerManager = {
    getReducerMap: () => reducers,
    reloadReducers: (initialReducers) => {
      Object.assign(reducers, initialReducers);

      combinedReducer = combineReducers(reducers);

      // Fire the INIT action to initialize the newly added state slices!
      reducerManager.store.replaceReducer(generateReduce());
    },
    reduce: generateReduce(),
    add: (injectedReducers, readyCallback) => {
      const changedReducers = injectedReducers.filter(({ key, reducer }) => {
        invariant(
          isString(key) && !isEmpty(key) && isFunction(reducer),
          '(app/utils...) injectReducers: Expected `reducer` to be a reducer function',
        );

        // Check `reducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
        if (Reflect.has(reducers, key) && reducers[key] === reducer) {
          return false;
        }

        return true;
      });

      changedReducers.forEach(({ key, reducer }) => {
        const injectedReducerWrapper = (state, action) => {
          if (
            action.type === LOGOUT_SUCCESS ||
            action.type === CLEAR_ORG_SPECIFIC_STORE_DATA
          ) {
            state = undefined; // eslint-disable-line no-param-reassign
          }
          return reducer(state, action);
        };

        reducers[key] = injectedReducerWrapper;
      });

      combinedReducer = combineReducers(reducers);

      // Fire the INIT action to initialize the newly added state slices!
      reducerManager.store.replaceReducer(generateReduce());

      if (readyCallback) {
        readyCallback();
      }
    },

    remove: (injectedReducers) => {
      injectedReducers.forEach(({ key }) => {
        delete reducers[key];
        keysToRemove.push(key);
      });

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },
  };

  return reducerManager;
};
