import { ComponentProps } from 'react';
import Steps from 'antd/es/steps';
import clsx from 'clsx';

export const OpSteps = ({
  children,
  className,
  ...stepsProps
}: ComponentProps<typeof Steps>) => (
  <Steps className={clsx('op-steps', className)} {...stepsProps} />
);
