import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'react-use';
import { AlertBox } from 'components/AlertBox';
import { P1Svg } from 'components/svgs/P1Svg';
import { P2Svg } from 'components/svgs/P2Svg';
import { P3Svg } from 'components/svgs/P3Svg';
import { P4Svg } from 'components/svgs/P4Svg';
import { P5Svg } from 'components/svgs/P5Svg';
import { useMount, useSelectorJs } from 'utils/customHooks';
import { SoundEffects, Layers, WebAudio } from 'utils/audio';
import { selectPlayAlarmNotificationSoundPreference } from 'routes/AppContainer/selectors';

const iconLookup = {
  success: 'checkmark box',
  info: 'info circle',
  warning: 'exclamation circle',
  error: 'exclamation triangle',
  intercom: {
    name: 'phone square',
    flipped: 'horizontally',
    className: 'animate-flicker',
  },
  p1: (
    <P1Svg
      className="icon"
      opacity="1"
      width="44.470588235294116"
      height="42"
      stroke="var(--colorAlarmHighText)"
    />
  ),
  p2: (
    <P2Svg
      className="icon"
      opacity="1"
      width="44.470588235294116"
      height="42"
      stroke="var(--colorAlarmMediumBg)"
    />
  ),
  p3: (
    <P3Svg
      className="icon"
      opacity="1"
      width="44.470588235294116"
      height="42"
      stroke="var(--colorAlarmMediumBg)"
    />
  ),
  p4: (
    <P4Svg
      className="icon"
      opacity="1"
      width="44.470588235294116"
      height="42"
      stroke="var(--colorAlarmLowBg)"
    />
  ),
  p5: (
    <P5Svg
      className="icon"
      opacity="1"
      width="44.470588235294116"
      height="42"
      stroke="var(--colorAlarmLowBg)"
    />
  ),
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 22,
    marginRight: 11,
  },
};

const playOptions = {
  skipIfAlreadyPlaying: true,
};

export const Alert = ({
  id,
  alertType,
  alertIcon,
  alertButtons,
  onAlertClosed,
  children,
  header,
}) => {
  const onClose = useCallback(() => {
    if (alertType === 'intercom') {
      WebAudio.stop(
        SoundEffects.INTERCOM_NOTIFICATION,
        Layers.INTERCOM_NOTIFICATIONS,
      );
    }

    onAlertClosed(id);
  }, [onAlertClosed, alertType, id]);

  const playAlarmNotificationSound = useSelectorJs(
    selectPlayAlarmNotificationSoundPreference(),
  );

  const [intercomNotificationsPermission] = useLocalStorage(
    'intercomNotificationsPermission',
    'granted',
  );

  const canPlayIntercomNotificationSound =
    intercomNotificationsPermission === 'granted';

  useMount(() => {
    switch (alertType) {
      case 'alarmHigh':
      case 'alarmMedium':
      case 'alarmLow':
        if (playAlarmNotificationSound) {
          WebAudio.play(
            SoundEffects.ALARM_NOTIFICATION,
            Layers.ALARM_NOTIFICATIONS,
            playOptions,
          );
        }
        break;
      case 'intercom': {
        if (canPlayIntercomNotificationSound) {
          WebAudio.play(
            SoundEffects.INTERCOM_NOTIFICATION,
            Layers.INTERCOM_NOTIFICATIONS,
            playOptions,
          );
        }
        break;
      }
      default:
        break;
    }
  });

  return (
    <div id={id} style={styles.container}>
      <AlertBox
        icon={iconLookup[alertIcon || alertType]}
        type={alertType}
        onClose={onClose}
        header={header || alertType}
        buttons={alertButtons}
      >
        {children}
      </AlertBox>
    </div>
  );
};

Alert.propTypes = {
  id: PropTypes.string,
  alertType: PropTypes.string,
  alertIcon: PropTypes.string,
  alertButtons: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.shape({
        resourceType: PropTypes.string,
        resourceUid: PropTypes.string,
      }),
      onClick: PropTypes.func,
    }),
  ),
  onAlertClosed: PropTypes.func,
  children: PropTypes.any,
  header: PropTypes.string,
};
