/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import { createReducerManager } from '../reducers';
import history from '../history';

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line import/no-mutable-exports
let store = {};
// eslint-disable-next-line import/no-mutable-exports
let isStoreConfigured = false;

function configureStore(initialState = {}, additionalReducers = {}) {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  const reducerManager = createReducerManager(additionalReducers);

  store = createStore(
    reducerManager.reduce,
    fromJS(initialState),
    compose(...enhancers),
  );

  // The manager needs to be able to call store.replaceReducers!
  reducerManager.store = store;

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.reducerManager = reducerManager;
  store.injectedSagas = {};

  isStoreConfigured = true;
  return store;
}

export {
  configureStore,
  // DONT USE THIS UNLESS YOU HAVE TO
  store,
  isStoreConfigured,
};
