export const ExclamationIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41683 1.16663H7.5835C7.90566 1.16663 8.16683 1.42779 8.16683 1.74996V8.74996C8.16683 9.07213 7.90566 9.33329 7.5835 9.33329H6.41683C6.09466 9.33329 5.8335 9.07213 5.8335 8.74996V1.74996C5.8335 1.42779 6.09466 1.16663 6.41683 1.16663ZM6.41683 10.5H7.5835C7.90566 10.5 8.16683 10.7611 8.16683 11.0833V12.25C8.16683 12.5721 7.90566 12.8333 7.5835 12.8333H6.41683C6.09466 12.8333 5.8335 12.5721 5.8335 12.25V11.0833C5.8335 10.7611 6.09466 10.5 6.41683 10.5Z"
    />
  </svg>
);
