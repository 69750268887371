import { useCallback, useEffect, useMemo, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { tableStateKeys } from 'new-components/DLS/OpTableCore/constants/tableStateKeys';

import debounce from 'lodash/debounce';
import { useQueryClient } from '@tanstack/react-query';
import { getOpParams } from '../getOpParams';
import { useOpQuery } from './useOpQuery';
import { MutationArgs, useOpMutation } from './useOpMutation';
import { useIdentityAndUser } from './useIdentityAndUser';

type DefaultUiState =
  | {
      version: number; // explicitly add when needed
      [key: string]: any;
    }
  | undefined;

// uiComponentKey should be extended as we add more ui state (for now its just tables)
interface UsePersistentUiState<UiState extends DefaultUiState> {
  uiComponentKey: Utils.ValueOf<typeof tableStateKeys>;
  setStateDebounceTimeout?: number;
  defaultState?: UiState;
  persistedUiVersion: number;
}

/**
 *
 * @description Hook form encapsulating persistent ui state calls. We keep track of the uiKey that can be used for
 * forcing component rerenders when necessary to indicate when the query cache has been cleared (generally during a
 * reset)
 * @param {string} uiComponentKey - unique component key for component to persist in uiState table in Helium
 * @param {number} setStateDebounceTimeout - custom value for debounce updates
 * @returns
 */
export const usePersistentUiState = <
  UiState extends DefaultUiState, // Intentionally not assigning a default
>({
  uiComponentKey,
  setStateDebounceTimeout = 300,
  // This should be the current static version of the component defined in Platinum
  persistedUiVersion,
}: UsePersistentUiState<UiState>) => {
  const queryClient = useQueryClient();

  const { identityId, isLoading: isLoadingIdentity } = useIdentityAndUser();

  const { orgId } = getOpParams();
  const finalOrgId = orgId || null;

  const uiKey = useRef(0);
  const rowId = useRef<number>();

  const {
    data: uiComponentStates,
    isSuccess,
    isPending: isFetchingUiState,
  } = useOpQuery({
    apiEndpointName: 'listIdentityUiStates',
    queryKey: ['listIdentityUiStates', identityId, uiComponentKey],
    parameters: [
      identityId!,
      {
        filter: `orgId:(${finalOrgId}) uiComponentKey:(=${uiComponentKey})`,
        limit: 1,
      },
    ],
    gcTime: 0,
    staleTime: 0,
    select: (data) => data.json.data,
  });

  /**
   * Increment the uiKey every time isFetchingUiState changes to false
   * This will only happen when we explicitly invalidate the listIdentityUiStates
   * query (when deleting a row)and therefore can be used to create a new
   * instance of a table using the key prop
   */
  useEffect(() => {
    if (!isFetchingUiState) {
      uiKey.current += 1;
    }
  }, [isFetchingUiState]);

  const { mutateAsync: setIdentityUiState } = useOpMutation<
    'setIdentityUiState',
    Api.Response['setIdentityUiState'],
    MutationArgs<'setIdentityUiState'>
  >({
    apiEndpointName: 'setIdentityUiState',
    // All this should be happening with no visual knowledge (yes even an error)
    shouldSuppressErrorMessage: true,
    onSuccessCallback: (data) => {
      /**
       * We need to set the rowId here as we are not invalidating the
       * listIdentityUiStates query and we want to store the rowId in
       * order to use it when deleting a row
       */
      rowId.current = data?.id;
    },
    // Optimistic updates to keep persisted state up to date
    onMutate: async ({ payload }) => {
      // Optimistically update to the new value
      queryClient.setQueryData(
        ['listIdentityUiStates', identityId, uiComponentKey],
        (old?: { json?: { data?: Api.Response['listIdentityUiStates'] } }) => {
          // initialize json.data to empty array if currently no data in cache for listIdentityUiStates
          const updated = old?.json?.data
            ? { ...old }
            : { json: { data: [] as Api.Response['listIdentityUiStates'] } };

          const prevData = updated.json!.data![0] ?? { identityId }; // payload doesn't include identityId or id, can default identityId.
          updated.json!.data![0] = {
            ...prevData,
            ...payload,
          };

          return updated;
        },
      );
    },
    onError: (err) => {
      console.error(
        `An error occurred persisting your UI state ${err.message}`,
      );

      Sentry.captureException(err);
    },
  });

  // Do we keep state sorta nebulous for the nature of the feature being flexible or try to type it on the client?
  const setPersistedUiState = useCallback(
    async (state: Utils.NonUndefined<UiState>) => {
      if (!identityId) return; // dont do anything if no identity id

      try {
        await setIdentityUiState({
          apiEndpointRequirements: [identityId],
          payload: {
            orgId: orgId || null, // Null for master mode orgId defaults to 0 (it probably shouldn't)
            uiComponentKey,
            state,
          },
        });
      } catch (err) {
        const typedError = err as unknown as Error;
        // Lets log this for debugging in the wild. We should make a Logger class...
        console.error(
          `An error occurred while persistent ui state ${typedError.message}`,
        );
        Sentry.captureException(err);
      }
    },
    [setIdentityUiState, identityId, orgId, uiComponentKey],
  );

  const debouncedSetPersistedUiState = useMemo(
    () => debounce(setPersistedUiState, setStateDebounceTimeout),
    [setPersistedUiState, setStateDebounceTimeout],
  );

  const { mutateAsync: deleteIdentityUiState } = useOpMutation({
    apiEndpointName: 'deleteIdentityUiState',
    onSuccessMessage: null,
    shouldSuppressErrorMessage: true,
    queryKeysToInvalidate: [
      ['listIdentityUiStates', identityId!, uiComponentKey],
    ],
  });

  const resetPersistedUiState = useCallback(async () => {
    try {
      const finalRowId = uiComponentStates?.[0]?.id || rowId.current;

      if (finalRowId && identityId) {
        await deleteIdentityUiState({
          apiEndpointRequirements: [identityId, finalRowId],
        });
      }
    } catch (err) {
      /**
       * We don't know this is a true error so we will capture it in Sentry for investigation if needed. For example
       * its possible someone has 2 tabs open on the same table and resets the table on 1 tab but the other
       * tab still thinks theres a uiState id and if they try to reset the table it would result in an error
       * since the row in the db no longer exists (currently we don't do realtime websocket pushes to keep data in sync cross tabs)
       */
      queryClient.invalidateQueries({
        queryKey: ['listIdentityUiStates', identityId, uiComponentKey],
      });
      Sentry.captureException(err);
    }
  }, [
    uiComponentStates,
    deleteIdentityUiState,
    identityId,
    queryClient,
    uiComponentKey,
  ]);

  const persistedUiState = uiComponentStates?.[0]?.state as UiState;

  /**
   * We shouldn't map an old version of persisted state if versions mismatch.
   * In the future (probably when we have a v2) we can create some kind of conversion
   * function if necessary. A version update should happen if ANY of the values being
   * stored in our database change or are removed. For example, if we remove a column
   * that still exists in the db it will be remapped below. Currently we said we are
   * fine with the table resetting but if we are inclined the first time this happens
   * we can start working on a conversion function. This is why we are storing the
   * version number so we can keep track
   */
  const isPersistedStateExpectedVersion =
    persistedUiState?.version === persistedUiVersion;

  const canUsePersistedUiState = isSuccess && isPersistedStateExpectedVersion;

  return {
    uiKey: uiKey.current,
    uiStateId: uiComponentStates?.[0]?.id || rowId.current,
    persistedUiState,
    setPersistedUiState: debouncedSetPersistedUiState,
    resetPersistedUiState,
    canUsePersistedUiState,
    isPersistedUiStateFetching: isFetchingUiState || isLoadingIdentity,
  };
};
