import {
  CLEAR_AUTHENTICATED_CONTAINER_STORE_DATA,
  CHANGE_ROUTE,
  ROOT_CONTAINER_MOUNTED,
  CLEAR_ORG_SPECIFIC_STORE_DATA,
  FIRE_AUTH_REDIRECT_CHECK,
  FIRE_SCOPE_CHECK,
  REQUEST_AND_SET_IDENTITY,
  REQUEST_PACKAGE_UPGRADE,
} from './constants';

export function fireScopeCheck(scope) {
  return {
    type: FIRE_SCOPE_CHECK,
    scope,
  };
}

export function fireAuthRedirect(attemptedRoute) {
  return {
    type: FIRE_AUTH_REDIRECT_CHECK,
    attemptedRoute,
  };
}
// There may be a cleaner way to do this but I haven't come up with it yet -justin
export function clearOrgSpecificStoreData() {
  return {
    type: CLEAR_ORG_SPECIFIC_STORE_DATA,
  };
}
export function clearAuthenticatedContainerStoreData(callback) {
  return {
    type: CLEAR_AUTHENTICATED_CONTAINER_STORE_DATA,
    callback,
  };
}

// rootLevel, masterMode
// options: root
export function changeRoute(route, options) {
  return {
    type: CHANGE_ROUTE,
    route,
    options,
  };
}

export function rootContainerMounted(route) {
  return {
    type: ROOT_CONTAINER_MOUNTED,
    route,
  };
}

export function requestAndSetIdentity() {
  return {
    type: REQUEST_AND_SET_IDENTITY,
  };
}

export function requestPackageUpgradeWithCode(featureCode, isChannel) {
  return {
    type: REQUEST_PACKAGE_UPGRADE,
    featureCode,
    isChannel,
  };
}
