import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { getAccessToken } from 'utils/accessToken';
import { useOxygenSocket } from 'utils/customHooks';
import { useOpQuery } from 'utils/customHooks/useOpQuery';

// Active parcel table only cares about these 3 hookEvent types
// Does not care about `parcelMessage.sent` as that is not shown in the table.
const parcelTableHookEventTypes = [
  'parcel.created',
  'parcel.changed',
  'parcel.deleted',
];

const debounceTimeout = 1000;
const trigger = {
  properties: {
    required: ['data', 'event'],
  },
};

export const useActiveParcels = ({
  orgId,
  mailroomId,
}: {
  orgId?: number;
  mailroomId?: string;
}) => {
  const accessToken = useMemo(() => getAccessToken(), []);
  const queryClient = useQueryClient();

  const subscriptionData = useMemo(
    () =>
      parcelTableHookEventTypes.map((hookEventType) => ({
        preFilter: {
          hookEventType,
        },
        trigger,
        clientData: {},
      })),
    [],
  );

  const { data: parcelData, isFetching: isFetchingParcelData } = useOpQuery({
    apiEndpointName: 'listParcels',
    parameters: [
      orgId!,
      {
        ...(mailroomId && { preFilter: `mailroom.id:(=${mailroomId})` }),
      },
    ],
    select: (data) => data?.json?.data ?? [],
  });

  const invalidateActiveParcels = useCallback(
    (event: MessageEvent) => {
      if (!event || !event.data) return;

      // Invalidate listParcels query.
      // We dont care what the data is, just want to refresh the table.
      // Current data should be returned from `listParcels`.
      queryClient.invalidateQueries({
        queryKey: ['listParcels'],
      });
    },
    [queryClient],
  );

  // debouncing in case multiple parcels were updated at once (batch actions for example).
  const debouncedHandleMessage = useMemo(
    () => debounce(invalidateActiveParcels, debounceTimeout),
    [invalidateActiveParcels],
  );

  const onConnectionHandler = useCallback(
    (socket: WebSocket | null) => {
      socket?.addEventListener('message', debouncedHandleMessage);
    },
    [debouncedHandleMessage],
  );

  const { connect, disconnect } = useOxygenSocket({
    clientRequestId: 'activeParcelsDashboard',
    onConnectionHandler,
    subscriptionData,
    orgId: orgId!,
    token: accessToken,
  });

  // connect
  useEffect(() => {
    connect();
  }, [connect]);

  // Disconnect from oxygen websocket when the component unmounts
  useEffect(
    () => () => {
      disconnect();
    },
    [disconnect],
  );

  return {
    parcelData,
    isLoading: isFetchingParcelData,
  };
};
