import AlertWrapperWithSuir from './AlertWrapperWithSuir';
import AlertWrapperWithoutSuir from './AlertWrapperWithoutSuir';

const AlertWrapper = () => {
  const hasUserSelectedSuirRemovedUi =
    localStorage.getItem('hasUserSelectedSuirRemovedUi') === 'true';

  return hasUserSelectedSuirRemovedUi ? (
    <AlertWrapperWithoutSuir />
  ) : (
    <AlertWrapperWithSuir />
  );
};

export default AlertWrapper;
