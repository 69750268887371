import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpSwitch } from 'new-components/DLS/OpSwitch/OpSwitch';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormSwitch.scss';

interface OpFormSwitchProps
  extends Pick<ComponentProps<typeof OpForm.Item>, 'name'>,
    Omit<ComponentProps<typeof OpSwitch>, 'name'> {
  formItemClassName?: string;
  isLoading?: boolean;
}

export const OpFormSwitch = ({
  // Form Item props
  formItemClassName,
  name,
  tooltip,

  // For skeleton loading state
  isLoading = false,

  // Wrapped element props
  disabled,

  ...elementProps
}: OpFormSwitchProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx('op-form-switch', formItemClassName)}
      name={name}
      valuePropName="checked"
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Button active size="small" shape="circle" />
      ) : (
        <OpSwitch
          disabled={isReadOnly || disabled}
          tooltip={tooltip}
          {...elementProps}
        />
      )}
    </OpForm.Item>
  );
};
