export function createNamedWrapperReducer(
  reducerFunction,
  page,
  subFilterId,
  strictMode = false,
) {
  return (state, action) => {
    const isInitializationCall = state === undefined;
    if (!isInitializationCall) {
      if (page !== action.page) return state;
      if (subFilterId) {
        // @HACK - this is how we filter down to multiple named reducers inside a named reducer (table + omnibox)
        // We should start using subFilterId universally, right? - justin
        if (
          ![action.tableId, action.omniId, action.subFilterId].includes(
            subFilterId,
          )
        ) {
          return state;
        }
      }
    }
    // its possible to have a reducer initialized without a subFilter, but
    // the action has a subfilter... so we don't need to run the reducer function!
    if (
      strictMode &&
      !subFilterId &&
      (action.subFilterId || action.tableId || action.omniId)
    ) {
      return state;
    }

    return reducerFunction(state, action);
  };
}
