import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/svgs/SvgWrapper';

interface AvigilonLogoMarkSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const AvigilonLogoMarkSvg = ({
  ...svgProps
}: AvigilonLogoMarkSvgProps) => (
  <SvgWrapper width="22" height="22" viewBox="0 0 22 22" {...svgProps}>
    <path d="M15.1966 21.5542H6.77787C6.50874 21.5542 6.35922 21.2446 6.45948 21.0441L7.57295 18.6676C7.64507 18.49 7.80515 18.3756 7.98105 18.3756H14.0198C14.1957 18.3756 14.354 18.49 14.4279 18.6676L15.5414 21.0459C15.6399 21.2745 15.4833 21.5542 15.1966 21.5542ZM12.4455 0.843313C12.3294 0.600566 12.0849 0.44577 11.8175 0.44577H10.1851C9.91599 0.44577 9.67325 0.600566 9.55891 0.843313L0.0354789 21.0353C-0.0788586 21.2763 0.0970452 21.5542 0.364419 21.5542H3.62567C3.79982 21.5542 3.95813 21.4522 4.03025 21.2939L10.6865 7.01226C10.7938 6.77127 11.1403 6.76775 11.2529 7.01226L17.9724 21.2939C18.0445 21.4522 18.2028 21.5542 18.377 21.5542H21.6365C21.9021 21.5542 22.078 21.2763 21.9654 21.0353L12.4455 0.843313Z" />
  </SvgWrapper>
);
