import { forwardRef } from 'react';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { OpCheckbox } from 'new-components/DLS/OpCheckbox/OpCheckbox';
import { OpMenu } from 'new-components/DLS/OpMenu/OpMenu';

interface CheckboxListProps {
  options: { label: string; value: string | number }[];
  onChange: (value: string[]) => void;
  selectValue: string[];
  setSelectValue: (value: string[]) => void;
}

/* Without forwardRef we see an error in the console as most likely a ref is 
being passed through. Could investigate more if needed */
export const CheckboxList = forwardRef(
  (
    { options = [], selectValue, setSelectValue, onChange }: CheckboxListProps,
    _ref, // This is not used directly
  ) => {
    const handleMenuClick: MenuClickEventHandler = ({ key }) => {
      // Copy the current select value so we can mutate it
      const newSelectValue = [...selectValue];

      // Add the key if not already present or else remove it
      if (newSelectValue.indexOf(key) === -1) {
        newSelectValue.push(key);
      } else {
        newSelectValue.splice(newSelectValue.indexOf(key), 1);
      }

      // Set the new select value
      setSelectValue(newSelectValue);

      // Set the table state filter with the new value
      onChange(newSelectValue);
    };

    // Create the checkboxes
    const menuItems = options.map(({ label, value }) => {
      return {
        key: value,
        label,
        icon: <OpCheckbox checked={selectValue.includes(String(value))} />,
      };
    });

    return <OpMenu onClick={handleMenuClick} items={menuItems} />;
  },
);
