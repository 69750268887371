import {
  ComponentPropsWithoutRef,
  Key,
  Ref,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { UseOpQueryOptions } from 'utils/customHooks/useOpQuery';
import { removeHiddenColumnsFromFilters } from 'utils/removeHiddenColumnsFromFilters';
import { useDataFetchTable } from './useDataFetchTable';
import { OpTableCore, OpTableRecordType } from '../OpTableCore/OpTableCore';
import {
  AddButton,
  BatchActions,
  GlobalSearch,
  ShowHideColumnsButton,
  TableHeader,
} from '../OpTableCore/components';
import { DataFetchCsvExportButton } from './components/DataFetchCsvExportButton';
import { RowActions } from './components/RowActions';
import { TablePagination } from '../OpTableCore/components/TablePagination';
import { createTableWidth } from '../OpTableCore/helpers';
import { OpTable } from '../OpTable/OpTable';
import { getStateToPersist } from '../OpTable/uiPersistence';
import { OnShowHideColumns } from '../OpTableCore/components/ShowHideColumnsButton';

import './OpDataFetchTable.scss';

export type OpDataFetchTableRefType = { resetRowSelections(): void };

type OpDataFetchTableProps<T extends keyof Api.ClientSpec> =
  ComponentPropsWithoutRef<typeof OpTable> & {
    opQueryProps: UseOpQueryOptions<T>;
    allowShowHideColumns?: boolean;
    /* forwardRef and generic components do not work together simply. Using a prop ref instead as the simplest workaround.
    This prop ref cannot be named just 'ref' or useImperativeHandle won't work. */
    tableRef?: Ref<OpDataFetchTableRefType>;
  };

export const OpDataFetchTable = <T extends keyof Api.ClientSpec>({
  // Data derived from API queries
  opQueryProps,

  rowKey = 'id',
  columns: rawColumns,
  testId = 'op-data-fetch-table',
  height,
  rowActions,
  batchActionMenuItems,
  label,
  allowGlobalSearch = true,
  allowAddition = false,
  allowExport = true,
  allowShowHideColumns = true,
  uiStateKey,
  tableRef,
  ...tableProps
}: OpDataFetchTableProps<T>) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<OpTableRecordType[]>([]);

  // Ref used to calculate if a row has been clicked, dragged, highlighted, etc
  const rowMouseDownTimestamp = useRef(0);

  // Need to memoize so the useEffect doesn't run repeately in useDataFetchTable
  const resetRowSelections = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  useImperativeHandle(
    tableRef,
    () => ({
      // Allow parent to reset selected rows
      resetRowSelections,
    }),
    [resetRowSelections],
  );

  const {
    data,
    isFetching,
    onChange,
    state,
    debouncedState,
    setState,
    resetState,
    uiKey,
    setPersistedUiState,
  } = useDataFetchTable({
    rawColumns,
    opQueryProps,
    resetRowSelections,
    uiStateKey,
  });

  const {
    filteredCount = 0,
    totalCount = 0,
    data: jsonData = [],
  } = data?.json || {};

  /** Create dataSource separate from helium data so we can manipulate
   * without mutating the original data */
  let dataSource = [];

  if (Array.isArray(jsonData)) {
    dataSource = jsonData;
  } else {
    console.error('Data response needs to be an array for OpDataFetchSelect');
  }

  const onShowHideColumns: OnShowHideColumns = (newColumns) => {
    setState((prevState) => {
      const newFilters = removeHiddenColumnsFromFilters(
        newColumns,
        prevState.filters,
      );

      const newState = {
        ...prevState,
        default: false,
        columns: newColumns,
        filters: newFilters,
      };

      setPersistedUiState(
        getStateToPersist({
          columns: newState.columns,
          filters: newState.filters,
          sorter: newState.sorter,
        }),
      );

      return newState;
    });
  };

  const tableTitle = () => (
    <TableHeader
      batchActions={
        batchActionMenuItems && (
          <BatchActions
            menuItems={batchActionMenuItems}
            selectedRowKeys={selectedRowKeys}
            selectedRows={selectedRows}
            resetRowSelections={resetRowSelections}
          />
        )
      }
      tableLabel={label}
      globalSearch={
        allowGlobalSearch && (
          <GlobalSearch
            onGlobalSearchChange={({ target: { value } }) => {
              setState((currentState) => ({
                ...currentState,
                pagination: {
                  ...currentState.pagination,
                  current: 1, // Reset to page 1 on filter
                },
                q: value,
              }));

              // Reset row selections on filter
              resetRowSelections();
            }}
          />
        )
      }
      addButton={
        allowAddition && (
          <AddButton
            {...(typeof allowAddition === 'object' && allowAddition)}
          />
        )
      }
      csvExportButton={
        allowExport && (
          <DataFetchCsvExportButton
            opQueryProps={opQueryProps}
            debouncedTableState={debouncedState}
            filteredCount={filteredCount}
            {...(typeof allowExport === 'object' && allowExport)}
          />
        )
      }
      showHideColumnsButton={
        allowShowHideColumns && (
          <ShowHideColumnsButton
            tableState={state}
            onShowHideColumns={onShowHideColumns}
            onResetTable={resetState}
          />
        )
      }
    />
  );

  /** Width calculated based on the width of each column (default is 180px)
   * plus the width of the selection column (if there is one). We define the
   * width so that we can constrain the table width to the container width
   * and scroll horizontally */
  const tableWidth = createTableWidth(
    state.columns,
    batchActionMenuItems ? 52 : 0,
  );

  const finalColumns = [...state.columns];

  // Add an action column if rowActions are passed
  /** This is added as the last thing so that it persists and is
   * not part of the show/hide columns logic */
  if (rowActions) {
    let shouldIncludeWidth = true;

    Object.entries(tableProps).forEach(([propName, propValue]) => {
      /**
       * Explicitly passing scroll as undefined or null to turn off scroll thus we dont need to add a width.
       * This width was needed as the scroll prop adds `table-layout: fixed` which we then seemingly needed this for.
       * We should probably take another look at why this is needed at all
       */
      if (propName === 'scroll' && !propValue) {
        shouldIncludeWidth = false;
      }
    });

    // Ensure width only grows for items that will be displayed as buttons
    // If the display condition (see RowActions) is true, Number(true) === 1
    const numActionButtons =
      (rowActions.additionalActions?.length ?? 0) +
      (rowActions.onEditClick ? 1 : 0) +
      (rowActions.onDeleteClick ? 1 : 0);

    finalColumns.push({
      key: 'op-actions',
      render: (_, record) => (
        <RowActions
          {...{ rowActions, record, numRecords: dataSource.length }}
        />
      ),
      ...(shouldIncludeWidth && {
        width: 32 + 32 * numActionButtons, // 32 px as a base (16px horizontal padding) and 32 px per action button width looks nice for however many buttons are used.
      }),
      fixed: 'right',
    });
  }

  return (
    <OpTableCore
      key={uiKey}
      uiStateKey={uiStateKey}
      loading={isFetching}
      data-testid={testId}
      dataSource={dataSource}
      onChange={onChange}
      columns={finalColumns}
      rowKey={rowKey}
      scroll={{
        y: height,
        x: tableWidth,
      }}
      // Only show table title if there is content to show
      {...((batchActionMenuItems ||
        allowGlobalSearch ||
        allowAddition ||
        allowExport ||
        allowShowHideColumns) && {
        title: tableTitle,
      })}
      // Allow row selection if batchActionOptions passed
      {...(batchActionMenuItems && {
        rowSelection: {
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        },
      })}
      pagination={false} // We are using custom pagination
      // Only show pagination if there is more than one page
      {...(filteredCount && {
        // eslint-disable-next-line react/no-unstable-nested-components
        footer: () => (
          <TablePagination
            currentPage={state.pagination.current}
            pageSize={state.pagination.pageSize}
            filteredCount={filteredCount}
            totalCount={totalCount}
            setTableState={setState}
          />
        ),
      })}
      // Allow row to be clicked to edit
      onRow={(record) => {
        return {
          onMouseDown: () => {
            rowMouseDownTimestamp.current = Date.now();
          },
          onClick: () => {
            /**
             * If user clicks a row and doesn't immediately trigger a mouseup
             * don't invoke any of the rowAction callbacks
             */
            if (Date.now() - rowMouseDownTimestamp.current > 150) {
              return;
            }

            if (rowActions?.onRowClick) {
              rowActions.onRowClick(record);
              return;
            }

            if (rowActions?.onEditClick) {
              rowActions.onEditClick(record);
            }
          },
        };
      }}
      // Pass other props to the table
      {...tableProps}
    />
  );
};
