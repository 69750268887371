/**
 * Create a standard hashing of the token. This can be used safely in logs.
 */
export async function getTokenHash(jwt?: `${string}.${string}.${string}`) {
  // quick-exit for invalid jwt
  if (!jwt) return null;

  const parts = jwt.split('.');
  // exclude the jwt signature from the hash input
  const prefix = `${parts[0]}.${parts[1]}`;

  const encoder = new TextEncoder();
  const data = encoder.encode(prefix);

  try {
    // encrypt the data
    const hash = await window.crypto.subtle.digest('SHA-256', data);
    // convert the data back into a string for encoding
    const hashStr = String.fromCharCode(...new Uint8Array(hash));
    // base64 encode the encrypted string
    return btoa(hashStr);
  } catch {
    // there shouldn't be an error here, but if there is, it should not break execution
    return null;
  }
}

export function getTokenPayload(jwt?: `${string}.${string}.${string}`) {
  // quick-exit for invalid jwt
  if (!jwt) return null;

  const base64Url = jwt.split('.')[1];

  // additional processing is needed since JWTs are encoded via base64url, not base64
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonString = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((char) => {
        return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  try {
    const payload = JSON.parse(jsonString);
    payload.ttl = payload.exp - Math.floor(Date.now() / 1000);
    return payload;
  } catch {
    // there shouldn't be a parse error, but if there is, it should not break execution
    return null;
  }
}
