import {
  ADD_SLIDE_OUT,
  DELETE_SLIDE_OUT,
  REPLACE_SLIDE_OUT,
} from './constants';

export function addSlideOut(slideOutData) {
  return {
    type: ADD_SLIDE_OUT,
    slideOutData,
  };
}

export function deleteSlideOut(slideOutData = null) {
  return {
    type: DELETE_SLIDE_OUT,
    slideOutData,
  };
}

export function replaceSlideOut(slideOutData) {
  return {
    type: REPLACE_SLIDE_OUT,
    slideOutData,
  };
}
