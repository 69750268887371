import React from 'react';
import clsx from 'clsx';
import { CustomIconComponentProps, OpIcon } from 'new-components/OpIcon/OpIcon';
import { DonutIcon } from 'new-components/svgs/DonutIcon';

import './OpDonutIcon.scss';

export const OpDonutIcon = ({ className }: CustomIconComponentProps) => {
  return (
    <OpIcon
      component={DonutIcon}
      className={clsx('op-donut-icon', className)}
    />
  );
};
