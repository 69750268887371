import { useEffect, useRef, useState, useCallback } from 'react';
import Hls from 'hls.js';
// import * as Sentry from '@sentry/react'
import { heliumReceive } from 'utils/helpers';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { useSelectorJs } from 'utils/customHooks';
import { useTranslation } from 'react-i18next';

export const useHls = ({
  playerRef,
  autoPlay,
  startDate,
  endDate,
  errorProps,
  setErrorProps,
  setIsLoading,
  orgId,
  opvideoDeviceId,
  shouldRun,
}) => {
  const hls = useRef(null);
  const noFragTimeout = useRef(null);
  const addSecondsIntervalRef = useRef(null);
  const { t } = useTranslation();

  const [programDateTime, setProgramDateTime] = useState(null);
  const currentIdentityLanguage = useSelectorJs(
    selectCurrentIdentityLanguage(),
  );

  const setupHls = useCallback(async () => {
    // Get hlsPlaybackUrl
    const {
      data: opvideoDeviceHlsPlaybackUrlData,
      localizedErrorMessage: opvideoDeviceHlsPlaybackUrlErrorMessage,
    } = await heliumReceive(
      'opvideoDeviceHlsPlaybackUrl',
      [orgId, opvideoDeviceId],
      {
        queryStringParams: { startDate, endDate }, // TODO - test with bad dates
        headers: {
          'Accept-Language': currentIdentityLanguage,
        },
      },
    );

    if (
      opvideoDeviceHlsPlaybackUrlErrorMessage ||
      !opvideoDeviceHlsPlaybackUrlData?.clipFound
    ) {
      const errorMessage =
        opvideoDeviceHlsPlaybackUrlErrorMessage ||
        // These are the cases where the HLS url is returned from Helium as `null`
        opvideoDeviceHlsPlaybackUrlData?.cloudRetentionHours
          ? t('No fragments found in the stream for the streaming request.')
          : t('The stream data retention is set to 0 hours.');

      setErrorProps({ errorMessage });
      return;
    }

    const { url } = opvideoDeviceHlsPlaybackUrlData;

    setIsLoading(false);

    hls.current = new Hls({
      // hls.js config - https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning
      enableWorker: false,
    });

    hls.current.attachMedia(playerRef.current);

    hls.current.on(Hls.Events.MEDIA_ATTACHED, (event, { media }) => {
      hls.current.loadSource(url);

      hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          const playPromise = media.play();
          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                media.focus();
              })
              .catch(() => null);
          }
        }
      });

      // Watch change in frags so we can get the programDateTime (capture time of hls stream)
      hls.current.on(Hls.Events.FRAG_CHANGED, (evt, { frag }) => {
        // Clear noFragTimeout if there is one
        if (noFragTimeout.current) {
          clearTimeout(noFragTimeout.current);
          noFragTimeout.current = null;
        }

        // Clear addSecondsIntervalRef if there is one
        if (addSecondsIntervalRef.current) {
          clearTimeout(addSecondsIntervalRef.current);
          addSecondsIntervalRef.current = null;
        }

        setProgramDateTime(frag.programDateTime);

        // We can change the interval to a lower value to have a more accurate timestamp, but this comes with the cost of performance
        const INTERVAL = 1000;
        addSecondsIntervalRef.current = setInterval(() => {
          // If the player is paused at timeout time then don't do anything
          if (playerRef.current.paused) {
            return;
          }

          setProgramDateTime((prev) => prev + INTERVAL);
        }, INTERVAL);

        // If we don't get a changed frag within a threshold then we reset the player
        noFragTimeout.current = setTimeout(() => {
          // If the player is paused at timeout time then don't do anything
          if (playerRef.current.paused) {
            return;
          }

          // If a fragment has an issue and prevents the HLS stream from continuing, this
          // should skip to the next fragment (assuming fragment length of 2 seconds).
          console.error('Fragment issue, continuing to next fragment');
          // eslint-disable-next-line no-param-reassign
          playerRef.current.currentTime += 2;
        }, 3000);
      });

      // // Log all events and their corresponding data
      // allEvents.forEach(item => hls.current.on(item, (event, data) => console.log(event, data)))
    });

    // Handle errors
    hls.current.on(Hls.Events.ERROR, (event, error) => {
      // eslint-disable-next-line no-console
      console.log({ 'HLS ERROR': error });

      // // Send error to Sentry
      // Sentry.captureException(error)
      // Sentry.captureMessage(`HLS ERROR: ${JSON.stringify(error)}`)

      if (error.fatal) {
        switch (error.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // eslint-disable-next-line no-console
            console.log('fatal network error encountered, try to recover');
            hls.current.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            // eslint-disable-next-line no-console
            console.log('fatal media error encountered, try to recover');
            hls.current.recoverMediaError();
            break;
          default:
            // eslint-disable-next-line no-console
            console.log('fatal network error encountered, restarting');
            setupHls();
            break;
        }
      }
    });
  }, [
    t,
    autoPlay,
    currentIdentityLanguage,
    endDate,
    opvideoDeviceId,
    orgId,
    playerRef,
    setErrorProps,
    setIsLoading,
    startDate,
  ]);

  useEffect(() => {
    if (shouldRun && !hls.current) {
      setupHls();
    }
  }, [setupHls, shouldRun]);
  // }, [endDate, setupHls, shouldRun, startDate]);

  useEffect(
    () => () => {
      // If hls
      if (hls.current) {
        hls.current.destroy();
        hls.current = null;
      }

      // Make sure we clear the timeout so we don't reset the player when remounting
      if (noFragTimeout.current) {
        clearTimeout(noFragTimeout.current);
        noFragTimeout.current = null;
      }

      if (addSecondsIntervalRef.current) {
        clearTimeout(addSecondsIntervalRef.current);
        addSecondsIntervalRef.current = null;
      }
    },
    [errorProps],
  );

  return { programDateTime };
};
