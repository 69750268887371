import { MailroomSvg } from 'components/svgs/MailroomSvg/MailroomSvg';
import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { HomeOutlinedSvg } from 'components/svgs/HomeOutlinedSvg/HomeOutlinedSvg';
import { HomeFilledSvg } from 'components/svgs/HomeFilledSvg/HomeFilledSvg';

import { mailroomDashboardRoute, mailroomListRoute } from './routes';

export const dashboardMenuItem = (): OpRawMenuItemType => ({
  key: mailroomDashboardRoute,
  title: 'Home',
  route: mailroomDashboardRoute,
  icon: <HomeOutlinedSvg />,
  selectedIcon: <HomeFilledSvg />,
  scope: [
    'o{orgId}-parcelMgmtParcels:r',
    'o{orgId}-parcelMgmtParcels:w',
    'o:w',
    'o:r',
    's-o:r',
    's-o:w',
  ],
});

export const mailroomListMenuItem = (): OpRawMenuItemType => ({
  key: mailroomListRoute,
  title: 'Mailroom',
  route: mailroomListRoute,
  icon: <MailroomSvg />,
  selectedIcon: <MailroomSvg />,
  scope: [
    'o{orgId}-parcelMgmtParcels:r',
    'o{orgId}-parcelMgmtParcels:w',
    'o:w',
    'o:r',
    's-o:r',
    's-o:w',
  ],
});

export const sidebarItems = (): OpRawMenuItemType[] => [
  dashboardMenuItem(),
  mailroomListMenuItem(),
];
