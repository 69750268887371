import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import HeliumDictionary from 'heliumDictionary';
import { useInjectReducers } from 'utils/injectReducer';
// import { useInjectSagas } from 'utils/injectSaga'
import { selectOrgContainerReducers } from 'routes/OrgContainer/selectors';
import { requestPageDataGeneric } from 'routes/OrgContainer/actions';
import { useSelectorJs } from 'utils/customHooks';
import { useDeepCompareEffect } from 'react-use';
import { combineReducers } from 'redux-immutable';
import { createNamedWrapperReducer } from 'utils/reducers';
import { createOrgContainerReducer } from 'routes/OrgContainer/reducer';
import formReducer from 'global/formWrapper/reducer';

// import { HeliumClient } from '@openpathsec/helium-client'
// console.debug(`[HeliumClient.getDefaultSpec()] - ${JSON.stringify(HeliumClient.getDefaultSpec(), null, 2)}`)
//
// TODO - I think we can convert this to use the spec.json file instead of helium dictionary (for less complexity)
// 1 - we will pass in an API like `acus` or `acu` and this hook will:
// 1.1 - if singular, add `describe` to the beginning and check spec for `describeAcu`, error if not found
// 1.2 - if plural, check spec for `listAcus`, error if not found
// 2 - the reducer and action don't really matter, as long as they're unique so..
// 2.1 - we can use the `acus` or `acu` as the reducer name
// 2.2 - and the action we can define as toUpperCase(`SET_${acus}`)
//
// this will leads us to be able to do simpler `useHeliumData(['acus', { key: 'acu', initOnly: true}])`
// PS - possibly make a long-hand shortcut to directly type the API to avoid the pattern matching, and if
// a new API is added that doesn't follow spec exactly, the user can still do `useHeliumData([{spec: 'someCrazyShit'}])`

// THIS HOOK IS UNDER DEVELOPMENT, TALK  TO JUSTIN <3
const useHeliumData = (
  page,
  injections = [],
  options = {},
  additionalReducers = null,
) => {
  // @TODO error if additionalReducers is an object

  // validate the options
  const VALID_OPTIONS = ['includeVideoPlayer', 'includeForm'];
  if (!Object.keys(options).every((el) => VALID_OPTIONS.includes(el))) {
    throw new Error(
      `useHeliumData() - Invalid options passed to . (valid options: ${JSON.stringify(
        VALID_OPTIONS,
      )})`,
    );
  }

  // validate the injection format!
  injections.forEach((i) => {
    switch (typeof i) {
      case 'string':
        // we're using super simple shorthand default like "users"
        // so make sure its in the dictionary!
        if (!Object.keys(HeliumDictionary).includes(i)) {
          throw new Error(
            `useHeliumData() - Trying to inject ${i} shorthand, which does not exist in HeliumDictionary!`,
          );
        }
        break;
      case 'object':
        {
          // in this case, we're customizing the defaults
          const VALID_INJECTION_OBJECT_KEYS = [
            'key',
            'name',
            'filter',
            'requisites',
            'initialValue',
            'initOnly',
            'onError',
            'suppressErrorMessage',
            'defaultQueryParams',
            'loopToGetAll',
          ];
          if (
            !Object.keys(i).every((v) =>
              VALID_INJECTION_OBJECT_KEYS.includes(v),
            )
          ) {
            throw new Error(
              `useHeliumData() - Trying to inject an object with invalid settings! Must be one of ${JSON.stringify(
                VALID_INJECTION_OBJECT_KEYS,
              )}. Received ${JSON.stringify(Object.keys(i))}`,
            );
          }
        }
        break;
      default:
        throw new Error(
          `useHeliumData() - Unrecognized data type passed as injections param (${typeof i})`,
        );
    }
  });

  const dispatch = useDispatch();
  const injectedReducers = useMemo(
    () =>
      injections.reduce(
        (acc, key) => {
          // this is the key we'll use to look up data in heliumDictionary.js
          let effectiveKey = key;

          // this is the name of the reducer in redux, often the PAGE variable
          let effectiveName = null;

          // this is the default value for the reducer
          let effectiveInitialValue = [];

          if (typeof key === 'object') {
            const { key: objKey, name = null, initialValue = [] } = key;
            if (!objKey) {
              throw new Error(
                'If passing object to useHeliumData, must include key param!',
              );
            }
            // we could receive more complicated options like:
            // - name: we want our reducer to be named something else
            effectiveKey = objKey;
            effectiveName = name;
            effectiveInitialValue = initialValue;
          }
          const heliumData = HeliumDictionary[effectiveKey];

          if (!heliumData) {
            throw new Error(`Missing from HeliumDictionary ${effectiveKey}`);
          }

          const { reducer, action } = heliumData;

          // We're going to dynamically inject the reducers so we don't need to define the boilerplate for our route
          // eslint-disable-next-line no-param-reassign
          acc.reducers[effectiveName || reducer] = createNamedWrapperReducer(
            createOrgContainerReducer(effectiveInitialValue, action),
            page,
            effectiveName,
            true,
          );
          acc.keys.push(reducer);
          return acc;
        },
        {
          // this is the default reducer state that will be injected when using this hook...
          reducers: {
            // all reducers that use this hook will have a loading boolean, can be used to know when injection is finished
            // at the very least, also used whenever we are talking to Helium
            loading: createNamedWrapperReducer(
              createOrgContainerReducer(true, 'TOGGLE_DATA_LOADER'),
              page,
            ), // TODO - constant

            // // if we want to include the video player...
            // ...(options.includeVideoPlayer && {
            // }),

            // if we want to include the form reducer...
            ...(options.includeForm && {
              form: createNamedWrapperReducer(formReducer, page),
            }),

            // we are able to add additional reducers here...
            // something like  `  const reducers = useMemo(() => ([{ key: 'widgetData', reducer: occupancyReducer }]), [])`
            ...(additionalReducers || []).reduce((acc, curr) => {
              // eslint-disable-next-line no-param-reassign
              acc[curr.key] = createNamedWrapperReducer(curr.reducer, page);
              return acc;
            }, {}),
          },
          keys: [],
        },
      ),
    [injections, page, additionalReducers, options.includeForm],
  );

  const memoizedReducers = useMemo(
    () => [{ key: page, reducer: combineReducers(injectedReducers.reducers) }],
    [injectedReducers.reducers, page],
  );
  useInjectReducers(memoizedReducers);

  // request the data from helium!
  useDeepCompareEffect(() => {
    dispatch(requestPageDataGeneric(page, injections));
  }, [dispatch, injections, page]);

  // TODO PERHAPS define the skeleton of the data to return?
  // TODO clean this up if we keep it
  const defaultReturn = injections.reduce(
    (acc, curr) => {
      const key = typeof curr === 'object' ? curr.key : curr;
      const retVal =
        typeof curr === 'object' && curr.initialValue ? curr.initialValue : [];
      // eslint-disable-next-line no-param-reassign
      acc[key] = retVal;
      return acc;
    },
    {
      form: {},
      loading: true,
    },
  );

  const data =
    useSelectorJs(selectOrgContainerReducers(injectedReducers.keys)(), {
      route: { name: page },
    }) || defaultReturn;
  return Object.keys(data).length ? data : defaultReturn;
};

export default useHeliumData;
