import React, { useState } from 'react';
import { appColorThemeManager } from 'utils/appColorThemeManager';
import { OpApp } from 'new-components/DLS/OpApp/OpApp';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import { useDispatch } from 'react-redux';
import { setAlert } from 'routes/AppContainer/actions';
import { OpAppScaffold } from 'new-components/OpAppScaffold/OpAppScaffold';
import { getBasenameForApp } from 'utils/getBasenameForApp';
import { Route } from 'react-router-dom-v5-compat';
import { OpAuthenticatedRoute } from 'new-components/OpAppScaffold/OpAuthenticatedRoute';
import { OpScopeGuard } from 'new-components/OpScopeGuard/OpScopeGuard';
import { OpPageNotFound } from 'new-components/OpAppScaffold/OpPageNotFound';
import { TanstackDevtools } from 'new-components/TanstackDevtools/TanstackDevtools';
import AlertWrapper from 'containers/AlertWrapper';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { OrgIdOutlet } from '../app/new-components/OpAppScaffold/OrgIdOutlet';
import Mailrooms from './pages/Mailrooms/Mailrooms';
import Packages from './pages/Packages/Packages';
import {
  dashboardMenuItem,
  mailroomListMenuItem,
  sidebarItems,
} from './constants/sidebarItems';

import {
  baseRoute,
  mailroomDashboardRoute,
  mailroomListRoute,
} from './constants/routes';

import './App.scss';

/**
 * @component
 * @description Component that wraps the mailroom app and holds the top level functionality.
 */
export const App = () => {
  const dispatch = useDispatch();

  // Create a react-query client
  const [queryClient] = useState(
    new QueryClient({
      queryCache: new QueryCache({
        // Catch any react-query useQuery error globally and display the alert (this will not catch useMutation errors)
        onError: (error) => {
          const typedError = error as unknown as Error & {
            suppressErrorMessage: boolean;
          };
          if (!typedError.suppressErrorMessage) {
            dispatch(setAlert('error', error.message));
          }
        },
      }),
    }),
  );

  // Gets the correct basename for the app based on the current environment
  const basename = getBasenameForApp('mailroom');

  // TODO Base this on their control center profile setting
  // https://openpath.atlassian.net/browse/OPAC-10669 - mailroom use local storage
  appColorThemeManager.handleThemeToggle('dark');

  return (
    <OpApp className="op-mailroom-app">
      <QueryClientProvider client={queryClient}>
        <AlertWrapper />

        <OpAppScaffold
          appScopePrefix="parcelMgmt"
          basename={basename}
          homePath={`${baseRoute}/${mailroomDashboardRoute}`}
          sidebarItems={sidebarItems()}
          noLayoutRoutes={<Route path="packages" element={<Packages />} />}
          title="Mailroom"
        >
          {/* Routes requiring an orgId should be placed inside this route */}
          <Route
            path={`${baseRoute}/`}
            element={<OrgIdOutlet homePage={mailroomDashboardRoute} />}
          >
            {/* All Routes that require login should be inside here */}
            <Route element={<OpAuthenticatedRoute />}>
              <Route
                path={mailroomDashboardRoute}
                element={
                  <OpScopeGuard
                    allowedScopes={dashboardMenuItem().scope ?? []}
                    element={<Dashboard />}
                    fallback={<OpPageNotFound />}
                  />
                }
              />
              <Route
                path={mailroomListRoute}
                element={
                  <OpScopeGuard
                    allowedScopes={mailroomListMenuItem().scope ?? []}
                    element={<Mailrooms />}
                    fallback={<OpPageNotFound />}
                  />
                }
              />
            </Route>
          </Route>
        </OpAppScaffold>
        <TanstackDevtools />
      </QueryClientProvider>
    </OpApp>
  );
};
