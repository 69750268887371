import { ComponentProps } from 'react';
import Dropdown from 'antd/es/dropdown';

interface OpDropdownProps extends ComponentProps<typeof Dropdown> {
  onClick?(): void;
  testId?: string;
}

export const OpDropdown = ({
  children,
  className = '',
  testId = 'op-dropdown',
  ...dropdownProps
}: OpDropdownProps) => {
  return (
    <Dropdown
      data-testid={testId}
      className={`op-dropdown ${className}`.trim()}
      rootClassName={'table-filter-dropdown'}
      {...dropdownProps}
    >
      {children}
    </Dropdown>
  );
};
