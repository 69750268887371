import React, { memo, useRef, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'semantic-ui-react/dist/es/elements/Icon/Icon';
import { useHoverDirty } from 'react-use';
import { OpSlider } from 'new-components/DLS/OpSlider/OpSlider';
import { Clickable } from 'components/Clickable/Clickable';

const VolumeButton = memo(
  ({ handleMuteToggleClick, handleVolumeSliderChange, volume }) => {
    const volumeContainerRef = useRef(null);
    const isHovering = useHoverDirty(volumeContainerRef);
    const iconName = volume
      ? volume > 0.5
        ? 'volume up'
        : 'volume down'
      : 'volume off';
    const styles = useMemo(
      () => ({
        container: { paddingRight: isHovering ? 15 : 8 },
        icon: {
          height: 'auto',
        },
        sliderContainer: {
          width: isHovering ? 60 : 0,
          transition: 'width 0.3s',
          alignItems: 'center',
          cursor: 'pointer',
        },
        slider: {
          cursor: 'pointer',
          padding: 0,
          width: '100%',
          margin: 0,
        },
        handle: {
          top: -4,
          display: isHovering ? 'block' : 'none',
        },
        rail: {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
      }),
      [isHovering],
    );

    // Hacky solution to override the transform css property of rc-slider
    // This is used as the prop is !important in their class and React
    // doesn't provide a way to override !important in classes inline
    useLayoutEffect(() => {
      volumeContainerRef.current?.children?.[1]?.children[0].children[3]?.style.setProperty(
        'transform',
        `translateX(-${volume * 100}%)`,
        'important',
      );
    }, [volume]);

    return (
      <div
        ref={volumeContainerRef}
        className="volume-button op-justify-content-center"
        style={styles.container}
      >
        {/** Mute Button */}
        <Clickable
          className="player-controls-button op-justify-content-center op-cursor-pointer"
          onClick={handleMuteToggleClick}
        >
          <Icon
            name={iconName}
            fitted
            inverted
            size="large"
            style={styles.icon}
          />
        </Clickable>

        {/** Volume Slider */}
        <div
          className="op-justify-content-center"
          style={styles.sliderContainer}
          data-testid="volume-slider"
        >
          <OpSlider
            className="video-player-volume-slider"
            tooltip={{ formatter: null }} // Don't show tooltip
            onChange={handleVolumeSliderChange}
            value={volume * 100}
            style={styles.slider}
            handleStyle={styles.handle}
            railStyle={styles.rail}
          />
        </div>
      </div>
    );
  },
);

VolumeButton.displayName = 'VolumeButton';

VolumeButton.propTypes = {
  handleMuteToggleClick: PropTypes.func.isRequired,
  handleVolumeSliderChange: PropTypes.func.isRequired,
  volume: PropTypes.number.isRequired,
};

export default VolumeButton;
