import React, { ComponentProps } from 'react';
import Typography from 'antd/es/typography';

import clsx from 'clsx';

import './OpText.scss';

const { Text } = Typography;

interface OpTextProps extends ComponentProps<typeof Text> {
  children?: React.ReactNode | React.ReactNode[];
  inline?: boolean;
  size?: 'large' | 'regular' | 'small' | 'tiny';
  ellipsis?: boolean;
  className?: string;
  fontWeight?: '300' | '400' | '500' | '600' | '700';
  testId?: string;
}

export const OpText = ({
  children,
  inline = false,
  size = 'regular',
  ellipsis = false,
  fontWeight = '400',
  testId = 'op-text',
  className,
}: OpTextProps) => {
  const classes = clsx(
    'op-text',
    `op-text--${fontWeight}`,
    { 'op-text--large': size === 'large' },
    { 'op-text--regular': size === 'regular' },
    { 'op-text--small': size === 'small' },
    { 'op-text--tiny': size === 'tiny' },
    { 'op-text--inline': inline },
    { 'op-text--ellipsis': ellipsis },
    className,
  );

  return (
    <Text className={classes} ellipsis={ellipsis} data-testid={testId}>
      {children}
    </Text>
  );
};
