// Loaders
export const SET_TABLE_LOADING = 'app/table/SET_TABLE_LOADING';
export const SET_TABLE_DATA_PERCENT_LOADED =
  'app/table/SET_TABLE_DATA_PERCENT_LOADED';

// Table state
export const SET_TABLE_HAS_BEEN_UPDATED =
  'app/table/SET_TABLE_HAS_BEEN_UPDATED';
export const REQUEST_UPDATE_TABLE = 'app/table/REQUEST_UPDATE_TABLE';
export const REQUEST_UPDATE_DATA_ORDER = 'app/table/REQUEST_UPDATE_DATA_ORDER';
export const SET_TABLE_STATE = 'app/table/SET_TABLE_STATE';
export const SET_FILTERS = 'app/table/SET_FILTERS';
export const SET_GLOBAL_FILTER = 'app/table/SET_GLOBAL_FILTER';
export const SET_SEARCH_QUERY = 'app/table/SET_SEARCH_QUERY';
export const SET_SORT_BY = 'app/table/SET_SORT_BY';
export const SET_COLUMN_WIDTHS = 'app/table/SET_COLUMN_WIDTHS';
export const SET_EXPANDED = 'app/table/SET_EXPANDED';
export const SET_SELECTED_ROWS = 'app/table/SET_SELECTED_ROWS';
export const SET_HIDDEN_COLUMNS = 'app/table/SET_HIDDEN_COLUMNS';

// Pagination
export const SET_PAGE_INDEX = 'app/table/SET_PAGE_INDEX';
export const SET_PAGE_COUNT = 'app/table/SET_PAGE_COUNT';
export const SET_PAGE_SIZE = 'app/table/SET_PAGE_SIZE';

// CSV
export const REQUEST_AND_SET_CSV_DATA = 'app/table/REQUEST_AND_SET_CSV_DATA';
export const SET_CSV_DATA = 'app/table/SET_CSV_DATA';
export const SET_CSV_DATA_LOADING = 'app/table/SET_CSV_DATA_LOADING';
export const SET_CSV_DATA_PERCENT_LOADED =
  'app/table/SET_CSV_DATA_PERCENT_LOADED';

// State item names
export const TABLE_HAS_BEEN_UPDATED = 'tableHasBeenUpdated';
export const TABLE_STATE = 'tableState';
export const LOADING = 'loading';
export const PERCENT_LOADED = 'tableDataPercentLoaded';
export const FILTERS = 'filters';
export const GLOBAL_FILTER = 'globalFilter';
export const SORT_BY = 'sortBy';
export const SEARCH_QUERY = 'searchQuery';
export const COLUMN_WIDTHS = 'columnWidths';
export const EXPANDED = 'expanded';
export const SELECTED_ROW_IDS = 'selectedRowIds';
export const HIDDEN_COLUMNS = 'hiddenColumns';
export const PAGE_INDEX = 'pageIndex';
export const PAGE_COUNT = 'pageCount';
export const PAGE_SIZE = 'pageSize';
export const CSV_DATA = 'csvData';
export const CSV_DATA_LOADING = 'csvDataLoading';
export const CSV_DATA_PERCENT_LOADED = 'csvDataPercentLoaded';
