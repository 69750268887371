import { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { selectOrgFeatures } from 'global/orgFeatures/selectors';
import { opMoment } from 'utils/dates';
import { selectCloudKeys } from 'global/cloudKey/selectors';
import {
  selectCurrentOrgId,
  selectActiveScopes,
} from 'global/accessToken/selectors';
import { verifyApiScope } from 'utils/redirects';

export { default as useHeliumData } from './useHeliumData';
export { default as useOxygenSocket } from './useOxygenSocket';
export { default as useIsSlideOutChild } from './useIsSlideOutChild';
export { default as useVideoClipPolling } from './useVideoClipPolling';
export { default as useSlideOut } from './useSlideOut';
export { default as useFiltersForReport } from './useFiltersForReport';
export { default as useFiltersForAlarmReport } from './useFiltersForAlarmReport';
export { default as useIntercomNotifications } from './useIntercomNotifications';
export { default as useUiNotifications } from './useUiNotifications';
export { default as useBroadcastChannel } from './useBroadcastChannel';

const cookies = new Cookies();

export const useValidCloudKeys = () => {
  const cloudKeys = useSelectorJs(selectCloudKeys(), {});

  const validCloudKeys = cloudKeys.filter(
    ({ startDate, endDate }) =>
      (startDate === null || opMoment(startDate).isBefore(opMoment())) &&
      (endDate === null || opMoment(endDate).isAfter(opMoment())),
  );

  return validCloudKeys;
};

export const useHasPermissionForApi = (operationId) => {
  const currentOrgId = useSelectorJs(selectCurrentOrgId(), {});
  const activeScopes = useSelectorJs(selectActiveScopes(), {});

  const scopeCheck = verifyApiScope(activeScopes, operationId, currentOrgId);
  return scopeCheck;
};

// Uses createPropsSelector to return a memoized version of the selector in plain js
export const useSelectorJs = (selector, props, shouldRun = true) => {
  const value = useSelector(
    (state) => (shouldRun ? selector(state, props) : null),
    (a, b) => (a?.toJS ? a.equals(b) : a === b),
  );
  return useMemo(() => (value?.toJS ? value.toJS() : value), [value]);
};

// Used to get the last instance of the prop (like prevProps in ComponentDidUpdate)
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// Can be used to debounce API calls
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

// Can be used to check feature gate in sagas
export const selectOrgHasFeature = (featureCode) => () => {
  const orgFeatures = useSelectorJs(selectOrgFeatures());

  if (!orgFeatures) {
    return false;
  }

  const hasFeature = orgFeatures.some(({ code }) => {
    return Array.isArray(featureCode)
      ? featureCode.includes(code)
      : featureCode === code;
  });

  return hasFeature;
};

// Can be used to feature gate elements
export const useFeatureGate = (featureCode) => {
  // Selectors
  const orgFeatures = useSelectorJs(selectOrgFeatures());

  // Prevent initial flash of upgrade message when the component first loads
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && orgFeatures && orgFeatures.length > 0) {
      setLoaded(true);
    }
  }, [orgFeatures, loaded]);

  if (!loaded || !featureCode || !orgFeatures) {
    return null;
  }

  const hasFeature = orgFeatures.some(({ code }) => {
    return Array.isArray(featureCode)
      ? featureCode.includes(code)
      : featureCode === code;
  });

  return hasFeature;
};

export const useIsInMobileWebView = () =>
  /*
   * Tungsten displays Platinum in a webview. To prevent the user from navigating on their own, Tungsten adds a cookie with
   * key 'isInMobileWebView' and value 'true' which tells Platinum
   * to not show Navigation, HeaderWrapper, or SmallBreadcrumbs
   * It also hides the icon in <PageHeader />
   * And it reduces margins on the side of the table and content boxes
   */
  cookies.get('isInMobileWebView') === 'true';

// this is considered bad practice, I believe, but sometimes it's necessary
export const useMount = (func) => {
  useEffect(func, []); // eslint-disable-line react-hooks/exhaustive-deps
};

// To get the hovering state of an element
export const useHover = (ref) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);
  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [ref]);
  return isHovering;
};

// potential values are 'granted' | 'denied' | 'unknown'
// all browsers except Chrome will always return 'unknown'
export const useMicrophonePermission = () => {
  const [permissionStatus, setPermissionStatus] = useState('unknown');

  useEffect(() => {
    const checkMicrophonePermission = async () => {
      if (navigator.permissions && navigator.permissions.query) {
        try {
          const result = await navigator.permissions.query({
            name: 'microphone',
          });
          setPermissionStatus(result.state);

          result.onchange = () => {
            setPermissionStatus(result.state);
          };
        } catch (error) {
          console.error('Error checking microphone permission:', error);
        }
      } else {
        console.warn('Permissions API is not supported in this browser.');
      }
    };

    checkMicrophonePermission();
  }, []);

  return permissionStatus;
};
