import { useOpQuery } from './useOpQuery';

export const useHasFeatures = (
  orgId: number,
  featureCodes: string | string[] = [],
) => {
  const { data: org, isLoading } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    select: (heliumResponse) => heliumResponse?.json?.data,
  });

  const featureCodesArray = Array.isArray(featureCodes)
    ? featureCodes
    : [featureCodes];

  return {
    hasFeatures: org?.enabledFeatures?.some((feature) =>
      featureCodesArray.includes(feature?.code || ''),
    ),
    isLoading,
  };
};
