import intersection from 'lodash/intersection';
import { useCallback } from 'react';
import { useOpQuery } from './useOpQuery';
import { useHasFeatures } from './useHasFeatures';

export const useIsRestrictedBySiteSpecificRole = (orgId: number) => {
  const { hasFeatures: orgHasUserSitePartitioningFeature } = useHasFeatures(
    orgId,
    ['userSitePartitioning'],
  );

  // Determine the sites the logged in user has access to
  const { data: orgSites } = useOpQuery({
    apiEndpointName: 'listSites',
    parameters: [orgId],
    select: (heliumResponse) => heliumResponse.json.data,
    enabled: Boolean(orgId && orgHasUserSitePartitioningFeature),
  });

  // Need to wrap in useCallback to avoid rerenders (specifically Building > Units > edit)
  return useCallback(
    (siteIds?: number[]) => {
      const orgSiteIds = (orgSites || []).map(({ id }: { id: number }) => id);

      // If the org doesn't have the userSitePartitioning feature, the user is not restricted
      if (!orgHasUserSitePartitioningFeature) return false;

      // If the org has the feature but doesn't have access to any sites, the user is restricted
      if (!orgSiteIds.length) return true;

      // If the user has no site associations, the user is not restricted
      if (!siteIds?.length) return false;

      // If the user has site associations but none match the org's sites, the user is restricted
      return intersection(siteIds, orgSiteIds).length === 0;
    },
    [orgHasUserSitePartitioningFeature, orgSites],
  );
};
