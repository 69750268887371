import React, {
  memo,
  createRef,
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { useHoverDirty, useMeasure } from 'react-use';
import Icon from 'semantic-ui-react/dist/es/elements/Icon/Icon';
import { Button } from 'components/Button';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { OpSpin } from 'new-components/DLS/OpSpin/OpSpin';
import {
  PlayButton,
  SeekBar,
  LiveButton,
  TimeDisplay,
  VolumeButton,
  FullscreenButton,
  BigPlayButton,
  UpperControls,
} from '.';

import './VideoPlayer.scss';

const VideoPlayer = memo(
  forwardRef(
    (
      {
        width = '100%',
        height = 'auto',
        url = '',
        preventedHotkeys = [],
        hiddenElements = [],
        preventVideoClick,
        isLoading,
        aspectRatio = 4 / 3,
        controls,

        // Populates the upper content area
        upperControlsContent,

        // For error state
        errorProps,

        // Used so we can force rerenders on external volume changes
        initialVolume = 0,

        // Passed through to <video>
        ...videoProps
      },
      ref,
    ) => {
      const { t } = useTranslation();
      /** State */
      const [isPaused, setIsPaused] = useState(true);
      const [volume, setVolume] = useState(initialVolume);
      const [currentTime, setCurrentTime] = useState(0);
      const [duration, setDuration] = useState(0.1);

      /** Refs */
      const cachedVolume = useRef(1);
      const fullscreenContainerRef = useRef(null);
      const controlsRef = useRef(null);
      const [playerContainerRef, { width: playerContainerWidth }] =
        useMeasure();

      // In the case of using the video player stand alone we must give it a ref
      if (!ref) {
        // FIXME: DEF DONT DO THIS THIS IS A PROP BEING PASSED
        // eslint-disable-next-line no-param-reassign
        ref = createRef(null);
      }

      /** Utils */
      const isHoveringVideo = useHoverDirty(ref);
      const isHoveringControls = useHoverDirty(controlsRef);
      const isNotFullscreen =
        !document.isFullScreen &&
        !document.fullscreenElement &&
        !document.webkitFullscreenElement &&
        !document.mozFullScreenElement &&
        !document.msFullscreenElement;

      /** Styles */
      const styles = useMemo(() => {
        const playerHeight =
          playerContainerWidth && aspectRatio
            ? playerContainerWidth / aspectRatio
            : height;

        return {
          streamTimeout: {
            lineHeight: '22px',
            color: 'var(--colorWhite)',
            padding: 33,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            flexDirection: 'column',
          },
          playerContainer: {
            width,
            height: isNotFullscreen ? playerHeight : '100%',
          },
          fullscreenContainer: {
            position: 'relative',
            height: playerHeight,
          },
          controls: {
            opacity: isHoveringVideo || isHoveringControls ? 1 : 0,
            transition: 'opacity 0.3s',
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.4))',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            height: 100,
            justifyContent: 'flex-end',
          },
          bigPauseButton: {
            position: 'absolute',
            opacity: isPaused ? 0 : 1,
            fontSize: isPaused ? 24 : 0,
            ...(isPaused && { transition: 'opacity 1s, font-size 1s' }),
          },
          bigPlayButton: {
            position: 'absolute',
            opacity: !isPaused ? 0 : 1,
            fontSize: !isPaused ? 24 : 0,
            ...(!isPaused && { transition: 'opacity 1s, font-size 1s' }),
          },
          fixedLiveButton: {
            position: 'absolute',
            bottom: 0,
            height: 30,
          },
        };
      }, [
        isHoveringControls,
        isHoveringVideo,
        isPaused,
        width,
        aspectRatio,
        height,
        playerContainerWidth,
        isNotFullscreen,
      ]);

      // Focus video and unmute on mount
      useEffect(() => {
        if (ref.current) {
          ref.current.focus();
          // eslint-disable-next-line no-param-reassign
          ref.current.muted = false;
        }
      }, [ref]);

      // Watch for changes in initialVolume
      useEffect(() => {
        setVolume(initialVolume);
      }, [initialVolume]);

      // Set the ref (actual player volume) every time volume state changes
      useEffect(() => {
        if (ref.current) {
          // eslint-disable-next-line no-param-reassign
          ref.current.volume = volume;
        }
      }, [ref, volume]);

      /** Volume button props */
      const handleMuteToggleClick = useCallback(() => {
        if (ref.current.volume > 0) {
          cachedVolume.current = ref.current.volume;
          setVolume(0);
        } else {
          setVolume(cachedVolume.current);
        }
      }, [ref]);
      const handleVolumeSliderChange = useCallback((value) => {
        const volumeToSet = value / 100;
        setVolume(volumeToSet);
      }, []);

      /** Fullscreen button props */
      const requestFullscreen = useCallback(() => {
        fullscreenContainerRef.current?.requestFullscreen?.();
        fullscreenContainerRef.current?.webkitRequestFullscreen?.();
        fullscreenContainerRef.current?.mozRequestFullScreen?.();
        fullscreenContainerRef.current?.msRequestFullscreen?.();
      }, []);
      const exitFullscreen = useCallback(() => {
        document.exitFullscreen?.();
        document.webkitExitFullscreen?.();
        document.mozCancelFullScreen?.();
        document.msExitFullscreen?.();
      }, []);
      const handleFullscreenToggleClick = useCallback(
        () => (isNotFullscreen ? requestFullscreen() : exitFullscreen()),
        [isNotFullscreen, requestFullscreen, exitFullscreen],
      );

      /** Play button props */
      const handlePlayToggleClick = useCallback(() => {
        if (isPaused) {
          ref.current.play();
        } else {
          ref.current.pause();
        }
      }, [ref, isPaused]);
      const iconName = isPaused
        ? currentTime / duration === 1
          ? 'redo'
          : 'play'
        : 'pause';

      /** Seek bar props */
      const handleSeek = useCallback(
        (value) => {
          const newCurrentTime = (duration * value) / 100;
          setCurrentTime(newCurrentTime);
          // eslint-disable-next-line no-param-reassign
          ref.current.currentTime = newCurrentTime;
        },
        [duration, ref],
      );

      /** Video props */
      const handleVideoClick = useCallback(() => {
        if (isPaused) {
          ref.current.play();
        } else {
          ref.current.pause();
        }
      }, [ref, isPaused]);
      const handleTimeUpdate = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        ({ target: { currentTime, duration, paused } }) => {
          setCurrentTime(currentTime);
          setDuration(duration);
          setIsPaused(paused);
        },
        [],
      );
      const handleKeyDown = useCallback(
        (e) => {
          if (preventedHotkeys.includes(e.key)) return;

          switch (e.key) {
            // Spacebar (play/pause)
            case ' ': {
              // Prevents jumping down page
              e?.preventDefault?.();

              if (isPaused) {
                ref.current.play();
              } else {
                ref.current.pause();
              }

              break;
            }
            // Left arrow (jump 5 sec back)
            case 'ArrowLeft': {
              // eslint-disable-next-line no-param-reassign
              ref.current.currentTime -= 5;
              break;
            }
            // Right arrow (jump 5 sec forward)
            case 'ArrowRight': {
              // eslint-disable-next-line no-param-reassign
              ref.current.currentTime += 5;
              break;
            }
            // down arrow (volume down 5%)
            case 'ArrowDown': {
              // Prevents jumping down page
              e?.preventDefault?.();
              // If adjusting the volume would put it below 0 we set to 0
              setVolume((prevVolume) => Math.max(0, prevVolume - 0.05));
              break;
            }
            // up arrow (volume up 5%)
            case 'ArrowUp': {
              // Prevents jumping down page
              e?.preventDefault?.();
              // If adjusting the volume would put it above 1 we set to 1
              setVolume((prevVolume) => Math.min(1, prevVolume + 0.05));
              break;
            }
            // Toggle mute
            case 'm': {
              handleMuteToggleClick();
              break;
            }
            // Toggle fullscreen
            case 'f': {
              handleFullscreenToggleClick();
              break;
            }
          }
        },
        [
          handleFullscreenToggleClick,
          handleMuteToggleClick,
          isPaused,
          preventedHotkeys,
          ref,
        ],
      );

      // Used to gain player focus so hotkeys can be used
      const handlePlayerClick = useCallback(() => {
        ref.current?.focus();
      }, [ref]);

      const onError = (error) => {
        // Send error to Sentry
        Sentry.captureException(error);
        Sentry.captureMessage(`HTML5 <video /> error: ${error.message}`);
      };

      return (
        <div
          className="video-player"
          ref={playerContainerRef}
          style={styles.playerContainer}
        >
          <OpSpin
            className="video-player__loader"
            tip={t('Loading')}
            spinning={isLoading && !url}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              ref={fullscreenContainerRef}
              style={styles.fullscreenContainer}
              onClick={handlePlayerClick}
              role="button"
              tabIndex={0}
            >
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                onError={onError}
                tabIndex={0}
                className="op-display-flex op-outline-none" // Prevents extra padding at bottom of video and prevents blue outline on focus
                ref={ref}
                style={styles.playerContainer}
                onKeyDown={handleKeyDown}
                data-testid="html5-video-element"
                {...(!preventVideoClick && { onClick: handleVideoClick })}
                // Don't run time update when there is no SeekBar
                {...(!hiddenElements.includes('SeekBar') && {
                  onTimeUpdate: handleTimeUpdate,
                })}
                {...videoProps}
              />

              {!hiddenElements.includes('BigPlayButton') && (
                <BigPlayButton isPaused={isPaused} />
              )}

              {/** Controls */}
              {controls && (
                <div ref={controlsRef}>
                  <UpperControls
                    isHovering={isHoveringVideo || isHoveringControls}
                    content={upperControlsContent}
                  />

                  {!isLoading && (
                    <>
                      <div style={styles.controls}>
                        {!hiddenElements.includes('SeekBar') && (
                          <SeekBar {...{ currentTime, duration, handleSeek }} />
                        )}
                        <div className="op-justify-content-space-between op-horizontal-padding-11">
                          <div className="op-display-flex">
                            {!hiddenElements.includes('PlayButton') && (
                              <PlayButton
                                {...{ handlePlayToggleClick, iconName }}
                              />
                            )}
                            {!hiddenElements.includes('LiveButton') && (
                              <LiveButton {...{ currentTime, duration }} />
                            )}
                            {!hiddenElements.includes('TimeDisplay') && (
                              <TimeDisplay {...{ currentTime, duration }} />
                            )}
                          </div>
                          <div className="op-display-flex">
                            {!hiddenElements.includes('VolumeButton') && (
                              <VolumeButton
                                {...{
                                  handleMuteToggleClick,
                                  handleVolumeSliderChange,
                                  volume,
                                }}
                              />
                            )}
                            {!hiddenElements.includes('FullscreenButton') && (
                              <FullscreenButton
                                {...{
                                  handleFullscreenToggleClick,
                                  isNotFullscreen,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {/** Fixed Live Indicator */}
                      {!hiddenElements.includes('LiveButton') && (
                        <div
                          className="op-justify-content-space-between op-horizontal-padding-11"
                          style={styles.fixedLiveButton}
                        >
                          <div className="op-display-flex">
                            <LiveButton {...{ currentTime, duration }} />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {/** URL error retry state */}
              {errorProps && (
                <div
                  className="op-cover op-fully-centered-content"
                  style={styles.streamTimeout}
                >
                  <Icon
                    className="op-font-size-48"
                    name={errorProps.iconName || 'exclamation triangle'}
                  />
                  <div className="op-margin-bottom-22">
                    {errorProps.errorMessage}
                  </div>
                  {errorProps.handleRetryClick && (
                    <Button
                      basic
                      content={errorProps.buttonContent || t('Retry')}
                      onClick={errorProps.handleRetryClick}
                    />
                  )}
                </div>
              )}
            </div>
          </OpSpin>
        </div>
      );
    },
  ),
);

VideoPlayer.propTypes = {
  isLoading: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string,
  aspectRatio: PropTypes.number,
  // This gets ...spread I think so not sure why this is erroring
  // eslint-disable-next-line react/no-unused-prop-types
  muted: PropTypes.bool,
  preventedHotkeys: PropTypes.arrayOf(PropTypes.string),
  preventVideoClick: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  isLive: PropTypes.bool,
  controls: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  upperControls: PropTypes.element,
  errorProps: PropTypes.shape({
    buttonContent: PropTypes.string,
    errorMessage: PropTypes.any,
    handleRetryClick: PropTypes.func,
    iconName: PropTypes.string,
  }),
  hiddenElements: PropTypes.arrayOf(PropTypes.string),
  upperControlsContent: PropTypes.shape({
    left: PropTypes.shape({
      fadeIn: PropTypes.bool,
      content: PropTypes.any,
    }),
    center: PropTypes.shape({
      fadeIn: PropTypes.bool,
      content: PropTypes.any,
    }),
    right: PropTypes.shape({
      fadeIn: PropTypes.bool,
      content: PropTypes.any,
    }),
  }),
  videoDownloadButtonProps: PropTypes.shape({
    opvideoDeviceId: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  initialVolume: PropTypes.number,
};

VideoPlayer.displayName = 'VideoPlayer';

export default VideoPlayer;
