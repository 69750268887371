import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  getUserConfirmation(symbolKey, callback) {
    const trigger = window[Symbol.for(symbolKey)];
    if (trigger) {
      // if we've registered a callback to handle navigation, lets pass it
      return trigger(callback);
    }
    // otherwise just let navigation happen
    return callback(true);
  },
});

export default history;
