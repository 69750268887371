import { useState, useRef, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import clsx from 'clsx';
import { LoaderProps } from './types';

import './Loader.scss';

export const LoaderWithoutSuir: FC<LoaderProps> = ({
  dimmerStyle,
  inverted = true,
  size = 'large' as const,
  inline,
  loaderOnly,
  loaderContentOnly,
  content: _content,
  timeout = 0,
  timeoutMessage = '',
}) => {
  const { t } = useTranslation();
  const content = _content || t('Loading');
  const loaderTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [timerExpired, setTimerExpired] = useState(!timeout);

  if (timeout && !loaderOnly) {
    throw new Error('If using timeout, must set loaderOnly!');
  }

  useEffect(() => {
    if (timeout) {
      loaderTimeout.current = setTimeout(() => setTimerExpired(true), timeout);
      return () => clearTimeout(loaderTimeout.current);
    }
  }, [timeout]);

  if (timeout && timerExpired) {
    return <span>{timeoutMessage}</span>;
  }

  const tip = !loaderOnly ? content : undefined;

  const baseWrapperClass = inline
    ? 'op-antd-loader-wrapper-inline'
    : 'op-antd-loader-wrapper';

  const wrapperClassName = clsx(baseWrapperClass, {
    [`${baseWrapperClass}--no-mask`]: loaderOnly || loaderContentOnly,
    [`${baseWrapperClass}--inverted`]: !inverted,
  });

  const baseClass = 'op-antd-loader';

  const className = clsx(baseClass, {
    [`${baseClass}--no-mask`]: loaderOnly || loaderContentOnly,
    [`${baseClass}--cover`]: !inline,
    [`${baseClass}--inverted`]: loaderOnly && !inverted, // Top level when no content
  });

  return loaderOnly ? (
    <Spin indicator={<LoadingOutlined />} size={size} className={className} />
  ) : loaderContentOnly ? (
    <Spin
      indicator={<LoadingOutlined />}
      size={size}
      tip={tip}
      className={className}
      wrapperClassName={wrapperClassName}
    >
      {!loaderOnly && tip && <div style={{ color: 'transparent' }}>{tip}</div>}
    </Spin>
  ) : (
    <Spin
      indicator={<LoadingOutlined />}
      size={size}
      tip={tip}
      className={className}
      wrapperClassName={wrapperClassName}
      style={dimmerStyle}
    >
      {!loaderOnly && tip && <div style={{ color: 'transparent' }}>{tip}</div>}
    </Spin>
  );
};
