import { memo } from 'react';
import PropTypes from 'prop-types';
import { opMoment } from 'utils/dates';
import { DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT } from 'constants/Dates';

const RelativeTime = memo(
  ({
    millisecondsSinceEpoch,
    timezone,
    format = DATE_TIME_WITH_SECONDS_AND_TIMEZONE_FORMAT,
  }) =>
    millisecondsSinceEpoch && timezone
      ? opMoment
          .unix(millisecondsSinceEpoch / 1000)
          .tz(timezone)
          .format(format)
      : null,
);

RelativeTime.propTypes = {
  millisecondsSinceEpoch: PropTypes.number.isRequired,
  timezone: PropTypes.string,
  format: PropTypes.string,
};

RelativeTime.displayName = 'RelativeTime';

export default RelativeTime;
