import { memo, useEffect, useRef, useState } from 'react';
import { OpDrawer } from 'new-components/DLS/OpDrawer/OpDrawer';
import { OpMenu } from 'new-components/DLS/OpMenu/OpMenu';
import clsx from 'clsx';
import {
  OpNavMenuDrawerContent,
  OpNavMenuItem,
  OpNavMenuKey,
  OpNavMenuProps,
} from './types';

import './OpNavMenu.scss';

// const drawerCloseTimeoutMs = 0;
const drawerCloseTimeoutMs = 100;

export const OpNavMenu = memo(
  ({
    items = [],
    defaultPrimaryKey = null,
    defaultSecondaryKey = null,
  }: OpNavMenuProps) => {
    const [drawerContent, setDrawerContent] =
      useState<OpNavMenuDrawerContent>(null);
    const [selectedPrimaryMenuKey, setSelectedPrimaryMenuKey] =
      useState<OpNavMenuKey>(defaultPrimaryKey);
    const [selectedSecondaryMenuKey, setSelectedSecondaryMenuKey] =
      useState<OpNavMenuKey>(defaultSecondaryKey);
    const [highlightedPrimaryMenuKey, setHighlightedPrimaryMenuKey] =
      useState<OpNavMenuKey>(null);

    const drawerCloseTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
      null,
    );
    const primaryItemSelectionDelayTimeoutRef = useRef<ReturnType<
      typeof setTimeout
    > | null>(null);
    const drawerContentRef = useRef<OpNavMenuDrawerContent>(null);
    const highlightedPrimaryMenuKeyRef = useRef<OpNavMenuKey>(null);

    const clearTimeouts = () => {
      if (drawerCloseTimeoutRef.current) {
        clearTimeout(drawerCloseTimeoutRef.current);
        drawerCloseTimeoutRef.current = null;
      }
      if (primaryItemSelectionDelayTimeoutRef.current) {
        clearTimeout(primaryItemSelectionDelayTimeoutRef.current);
        primaryItemSelectionDelayTimeoutRef.current = null;
      }
    };

    // Clear timeouts on unmount
    useEffect(() => {
      return () => {
        clearTimeouts();
      };
    }, []);

    // Ensure the selected keys are updated when the default keys change (e.g. when default keys are calculated using async data)
    useEffect(() => {
      setSelectedPrimaryMenuKey(defaultPrimaryKey);
      setSelectedSecondaryMenuKey(defaultSecondaryKey);
    }, [defaultPrimaryKey, defaultSecondaryKey]);

    const createTestId = (item: OpNavMenuItem) =>
      item['data-testid'] || `nav-${item.key}`;

    const menuItems = items.map((item) => {
      const handleClick = () => {
        if (!item.preventKeySelection) {
          if (item.children) {
            const firstChild = item.children[0];

            if (firstChild?.type === 'group') {
              const firstGroupChild = firstChild.children?.[0];

              if (firstGroupChild && !firstGroupChild?.preventKeySelection) {
                // Select the first child of the group
                setSelectedPrimaryMenuKey(item.key);
                setSelectedSecondaryMenuKey(firstGroupChild?.key);
                firstGroupChild?.onClick?.();
              }
            } else if (!firstChild?.preventKeySelection) {
              // Select the first child
              setSelectedPrimaryMenuKey(item.key);
              setSelectedSecondaryMenuKey(firstChild?.key);
              firstChild?.onClick?.();
            }
          } else {
            setSelectedPrimaryMenuKey(item.key);
            setSelectedSecondaryMenuKey(null);
          }
        }

        item.onClick?.();
      };

      const handleMouseEnter = () => {
        clearTimeouts();

        // Set the content to the refs immediately, but set the state after a timeout
        if (item.children) {
          drawerContentRef.current = {
            title: item?.label || '',
            items: item.children.map(
              ({
                preventKeySelection = false,
                type,
                onClick,
                children,
                ...secondaryItem
              }) => {
                const createOnClickHandler =
                  (originalOnClick?: () => void) => () => {
                    /**
                     * Need to add this timeout so that the drawer closes immediately after clicking an item
                     * Otherwise, the drawer close can be delayed if the onClick handler does something
                     * expensive that blocks the main thread (e.g. users page with many users causes the drawer
                     * to close slowly)
                     *
                     * Understood this is a potential memory leak, but as the timeout is set to 100ms,
                     * it should not be a big issue, and no alternative has been found.
                     */
                    setTimeout(() => {
                      originalOnClick?.();
                    }, drawerCloseTimeoutMs);
                    setDrawerContent(null);
                    setHighlightedPrimaryMenuKey(null);

                    if (!preventKeySelection) {
                      setSelectedPrimaryMenuKey(item.key);
                      setSelectedSecondaryMenuKey(secondaryItem.key);
                    }
                  };

                const secondaryItemBase = {
                  ...secondaryItem,
                  'data-testid': createTestId(secondaryItem),
                };

                if (type === 'group' && children) {
                  return {
                    type: 'group',
                    ...secondaryItemBase,
                    children: children.map((groupItem) => ({
                      ...groupItem,
                      onClick: createOnClickHandler(groupItem.onClick),
                    })),
                  };
                }

                return {
                  ...secondaryItemBase,
                  onClick: createOnClickHandler(onClick),
                };
              },
            ),
          };
          highlightedPrimaryMenuKeyRef.current = item.key;
        } else {
          drawerContentRef.current = null;
          highlightedPrimaryMenuKeyRef.current = null;
        }

        primaryItemSelectionDelayTimeoutRef.current = setTimeout(() => {
          setDrawerContent(drawerContentRef.current);
          setHighlightedPrimaryMenuKey(highlightedPrimaryMenuKeyRef.current);
        }, drawerCloseTimeoutMs);
      };

      const handleMouseLeave = () => {
        drawerCloseTimeoutRef.current = setTimeout(() => {
          setDrawerContent(null);
          setHighlightedPrimaryMenuKey(null);
        }, drawerCloseTimeoutMs);
      };

      return {
        key: item.key,
        icon: item.icon,
        label: item.label,
        onClick: handleClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        className: clsx('op-nav-menu__primary-menu-item', {
          'op-nav-menu__primary-menu-item--highlighted':
            highlightedPrimaryMenuKey === item.key,
        }),
        'data-testid': createTestId(item),
      };
    });

    return (
      <div id="op-nav-menu" className="op-nav-menu" data-testid="op-nav-menu">
        <OpMenu
          className="op-nav-menu__primary-menu"
          selectedKeys={selectedPrimaryMenuKey ? [selectedPrimaryMenuKey] : []}
          items={menuItems}
        />
        <OpDrawer
          getContainer={document.getElementById('op-nav') || document.body}
          autoFocus={false}
          rootClassName="op-nav-menu__drawer-root"
          className="op-nav-menu__drawer"
          width={200}
          placement="left"
          closable={false}
          open={Boolean(drawerContent)}
          mask={false}
          onMouseEnter={() => {
            clearTimeouts();
          }}
          onMouseLeave={() => {
            drawerCloseTimeoutRef.current = setTimeout(() => {
              setDrawerContent(null);
              setHighlightedPrimaryMenuKey(null);
            }, drawerCloseTimeoutMs);
          }}
        >
          {drawerContent && (
            <div className="op-nav-menu__drawer-content">
              <div className="op-nav-menu__drawer-title">
                {drawerContent.title}
              </div>
              <OpMenu
                selectedKeys={
                  selectedSecondaryMenuKey ? [selectedSecondaryMenuKey] : []
                }
                className="op-nav-menu__drawer-menu"
                // @ts-expect-error - need to adjust OpNavMenuItem
                items={drawerContent.items}
              />
            </div>
          )}
        </OpDrawer>
      </div>
    );
  },
);
