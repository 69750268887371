import Layout from 'antd/es/layout';
import clsx from 'clsx';
import { ComponentProps } from 'react';

export const OpLayout = ({
  className,
  ...layoutProps
}: ComponentProps<typeof Layout>) => (
  <Layout className={clsx('op-layout', className)} {...layoutProps} />
);
