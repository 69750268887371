import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'components/Popup';
import { TooltipSvg } from 'components/svgs/TooltipSvg/TooltipSvg';

import './InfoButton.scss';

export const InfoButton = ({
  tooltipOrientation = 'top center',
  tooltipContent,
  styles = {
    popup: {},
    icon: {},
  },
  offset,
  additionalPopupProps,
}) => {
  const style = useMemo(
    () => ({
      pointerEvents: typeof tooltipContent === 'string' ? 'none' : 'auto',
      ...styles.popup,
    }),
    [tooltipContent, styles.popup],
  );

  return (
    <Popup
      className="op-info-button"
      trigger={
        <div className="op-info-button__icon-wrapper">
          <TooltipSvg
            className="op-info-button__icon"
            fill="var(--colorTextBase)"
          />
        </div>
      }
      style={style}
      position={tooltipOrientation}
      hoverable
      offset={offset}
      {...additionalPopupProps}
    >
      {tooltipContent}
    </Popup>
  );
};

InfoButton.displayName = 'InfoButton';

InfoButton.propTypes = {
  tooltipOrientation: PropTypes.oneOf([
    'top left',
    'top center',
    'top right',
    'right top',
    'right center',
    'right bottom',
    'bottom left',
    'bottom center',
    'bottom right',
    'left top',
    'left center',
    'left bottom',
  ]),
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  styles: PropTypes.shape({
    popup: PropTypes.object,
    icon: PropTypes.object,
  }),
  offset: PropTypes.arrayOf(PropTypes.number),
  additionalPopupProps: PropTypes.object,
};
