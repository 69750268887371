import clsx from 'clsx';
import { CustomIconComponentProps, OpIcon } from 'new-components/OpIcon/OpIcon';
import { XMarkIcon } from 'new-components/svgs/XMarkIcon';

import './OpXMarkIcon.scss';

export const OpXMarkIcon = ({ className }: CustomIconComponentProps) => {
  return (
    <OpIcon
      component={XMarkIcon}
      className={clsx('op-x-mark-icon', className)}
    />
  );
};
