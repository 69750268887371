import { Content } from 'antd/es/layout/layout';
import clsx from 'clsx';
import { ComponentProps } from 'react';

export const OpContent = ({
  className,
  ...contentProps
}: ComponentProps<typeof Content>) => {
  return (
    <Content className={clsx('op-content', className)} {...contentProps} />
  );
};
