import Helium from './helium';

const ensureObject = (value: any) => {
  // If value is not an object or is null, return an empty object
  if (typeof value !== 'object' || !value) {
    console.error('ensureObject: Expected object, got', value);
    return {};
  }

  return value;
};

/**
 * @param apiEndpointName
 * @param parameters
 * @returns An object containing the following keys:
 *
 *  - parametersArray: parameters with payload and query objects added if needed
 *  - withPayload: 0 if no payload, 1 if has a JSON payload, 2 if has a multipart/form-data payload
 *  - withQuery: 0 if no query, 1 if has query
 */
export const ensurePayloadAndQuery = <T extends keyof Api.ClientSpec>(
  apiEndpointName: T,
  parameters?: Parameters<Api.Client[T]>,
): {
  parametersArray: Parameters<Api.Client[T]>;
  withPayload: 0 | 1 | 2;
  withQuery: 0 | 1;
} => {
  const api = Helium.client[apiEndpointName] satisfies Api.Client[T];

  // Throw an error if an invalid api endpoint name
  if (!api) {
    throw new Error(`Invalid Helium client endpoint: \`${apiEndpointName}\``);
  }

  const parametersArray = parameters ? [...parameters] : [];

  const [, , pathParams, , withPayload, withQuery] =
    Helium.client.spec[apiEndpointName];
  const expectedParametersLength =
    pathParams.length +
    Number(Boolean(withPayload)) +
    Number(Boolean(withQuery));

  // Throw an error if too many parameters are passed in
  if (parametersArray.length > expectedParametersLength) {
    throw new Error(
      `Critical: ensurePayloadAndQuery received too many parameters for API endpoint ${apiEndpointName}`,
    );
  }

  if (parametersArray.length < pathParams.length) {
    // We don't even have all the path params (and we should)
    console.error(
      `ensurePayloadAndQuery: Missing path parameters for API endpoint ${apiEndpointName}`,
    );

    // Path param placeholders become undefined
    parametersArray.push(
      ...Array(pathParams.length - parametersArray.length).fill(undefined),
    );

    // Payload and query placeholders become empty objects
    if (withPayload) {
      parametersArray.push({});
    }

    if (withQuery) {
      parametersArray.push({});
    }

    return {
      parametersArray: parametersArray as Parameters<Api.Client[T]>,
      withPayload,
      withQuery,
    };
  }

  if (parametersArray.length < expectedParametersLength) {
    // We have at least the path params, add empty objects for payload and query as necessary

    // Payload and query placeholders become empty objects
    parametersArray.push(
      ...Array(expectedParametersLength - parametersArray.length).fill({}),
    );
  }

  // At this point, we have the right number of parameters...

  // Ensure payload and query are not null or undefined
  if (withPayload && withQuery) {
    // Needs both
    parametersArray[parametersArray.length - 2] = ensureObject(
      parametersArray[parametersArray.length - 2],
    );
    parametersArray[parametersArray.length - 1] = ensureObject(
      parametersArray[parametersArray.length - 1],
    );
  } else if (withPayload || withQuery) {
    // Just needs one
    parametersArray[parametersArray.length - 1] = ensureObject(
      parametersArray[parametersArray.length - 1],
    );
  }

  return {
    parametersArray: parametersArray as Parameters<Api.Client[T]>,
    withPayload,
    withQuery,
  };
};
