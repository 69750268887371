import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpLink } from 'new-components/DLS/OpLink/OpLink';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormLink.scss';

interface OpFormLinkProps extends ComponentProps<typeof OpLink> {
  formItemClassName?: string;
  isLoading?: boolean;
}

export const OpFormLink = ({
  // Form Item props
  formItemClassName,

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  className,

  disabled,
  ...elementProps
}: OpFormLinkProps) => {
  /** The Form is wrapped in a provider that passes the loading and readOnly
   * contexts to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item className={clsx('op-form-link', formItemClassName)}>
      {isDataLoading || isLoading ? (
        /** A placeholder in the shape of the component
         * so we can immediately render the structure of the form while
         * data is loading */
        <OpSkeleton.Button active block={elementProps.block} />
      ) : (
        /** The actual component that is rendered when we have the data */
        <OpLink
          className={clsx('op-form-link__button', className)}
          disabled={isReadOnly || disabled}
          {...elementProps}
        />
      )}
    </OpForm.Item>
  );
};
