import { ORG_ID_SCOPE_TEMPLATE } from 'new-components/OpAppScaffold/constants';

export const SUB_ORG_SCOPES = ['s-o:r', 's-o:w', 's-o-video:r', 's-o-video:w'];
export const SUB_ORG_FEATURE_CODES = ['subOrgs', 'subOrgsVideo'];

/**
 * @description Get the user's suborgs scopes when not referring to a specific org.
 *  EX: the org switcher for partner users
 * @returns {array} returns all the user's scopes in the parent org.
 */
export const getAnySubOrgScopes = ({
  tokenScopeList,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
}) => {
  const subOrgsScopes = new Set();

  tokenScopeList?.forEach((tokenScope) => {
    // Check if this org has the suborgs feature
    const hasSubOrg = tokenScope?.org?.packagePlans?.find((packagePlan) => {
      return packagePlan.package?.packageFeatures?.some((packageFeature) =>
        SUB_ORG_FEATURE_CODES.includes(packageFeature.feature?.code ?? ''),
      );
    });

    if (hasSubOrg) {
      (
        tokenScope?.scope?.filter((scope) => SUB_ORG_SCOPES.includes(scope)) ??
        []
      ).forEach((scope) => subOrgsScopes.add(scope));
    }
  });

  return [...subOrgsScopes] as string[];
};

/**
 * @description Get the user's applicable parent org scopes. Scopes are applicable
 * if the org has a parent, that parent has a subOrgs license, and the user has subOrg
 * related scopes in that org.
 * @returns {array} returns all the user's scopes in the parent org.
 */
export const getParentSubOrgScopes = ({
  tokenScopeList,
  parentOrgId,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
  parentOrgId?: number;
}) => {
  if (!parentOrgId) return [];

  // Get the parent org's TokenScope
  const parentOrgTokenScope = tokenScopeList?.find(
    (tokenScope) => tokenScope.org?.id === parentOrgId,
  );

  // Check if the parent has the suborgs feature
  const hasSubOrg = parentOrgTokenScope?.org?.packagePlans?.find(
    (packagePlan) => {
      return packagePlan.package?.packageFeatures?.some((packageFeature) =>
        SUB_ORG_FEATURE_CODES.includes(packageFeature.feature?.code ?? ''),
      );
    },
  );

  return hasSubOrg
    ? // If hasSubOrg is true, these must be defined
      parentOrgTokenScope!.scope!.filter((scope) =>
        SUB_ORG_SCOPES.includes(scope),
      )
    : [];
};

/**
 * @description Get the user's current identity scopes.
 * @returns {array} the user's current identity scopes.
 */
export const getActiveIdentityScopes = ({
  tokenScopeList,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
}) => {
  if (!tokenScopeList) return []; // user has no scopes

  return tokenScopeList.find((tokenScope) => !tokenScope.org?.id)?.scope ?? [];
};

/**
 * @description Get the user's current applicable scopes.
 * @param tokenScopeList - list of user's token scopes
 * @param orgId - org you want applicable scopes for - if no orgId
 *  get only identity level and partner scopes
 * @param parentOrgId - parent of org you want applicable scopes for
 * @returns {array} a combination of the User's scopes in the current org,
 * the parent org, and their identity level scopes
 */
export const getActiveScopes = ({
  tokenScopeList,
  orgId,
  parentOrgId,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
  orgId?: number;
  parentOrgId?: number;
}) => {
  if (!tokenScopeList) return []; // user has no scopes

  // Get the current org's tokenScope from the tokenScopeLIst
  const currentOrgTokenScope = tokenScopeList.find(
    (tokenScope) => tokenScope.org?.id === orgId,
  );

  // Check if there are parent org scopes where the user has sub orgs access
  // or if only looking for identity/partner scopes check for any sub-orgs scopes
  const parentOrgScopes = orgId
    ? getParentSubOrgScopes({
        tokenScopeList,
        parentOrgId,
      })
    : getAnySubOrgScopes({ tokenScopeList });

  /*
    Filter sub org scopes out of the current org's scopes. They should be retrieved
    from the parent org if one exists. The parent org must have a sub-orgs license
    and the user must have s-o scopes in that parent org

    It's important to get the right list here since
    various places (e.g. BillingSubscriptionWidget) are doing
    client-side visibility gating based on the presence of s-o:r or
    s-o:w, but that produces unintended behavior if the org doesn't
    also have the subOrgs feature.
  */
  const orgScopes = (currentOrgTokenScope?.scope ?? []).filter(
    (scope) => !SUB_ORG_SCOPES.includes(scope),
  );

  // grab identity scopes
  const identityScopes =
    tokenScopeList.find((tokenScope) => !tokenScope.org?.id)?.scope ?? [];

  return [...orgScopes, ...identityScopes, ...parentOrgScopes];
};

/**
 * @description inject orgId into org scopes
 * @returns {object} a copy of scopes where {orgId} is replaced with the org id
 */
export const injectOrgIdIntoOrgScopes = ({
  scopes,
  orgId,
}: {
  scopes: string[];
  orgId: string;
}) => scopes.map((scope) => scope.replace(ORG_ID_SCOPE_TEMPLATE, orgId));

/**
 * @description validates the user has proper scopes
 * @returns {boolean} true if the user has atleaset 1 allowed scope
 * and false if not
 */
export const validateScopes = ({
  activeScopes,
  rawAllowedScopes,
  orgId,
}: {
  activeScopes?: string[] | null;
  rawAllowedScopes: string[];
  orgId?: string;
}) => {
  if (!activeScopes) return false;

  // replace {orgId} with orgId when passed
  const allowedScopes = orgId
    ? injectOrgIdIntoOrgScopes({ scopes: rawAllowedScopes, orgId })
    : rawAllowedScopes;

  return allowedScopes.some((requiredScope) =>
    activeScopes.includes(requiredScope),
  );
};
