import { Key } from 'react';
import { MenuItemType } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { OpDropdown } from 'new-components/DLS/OpDropdown/OpDropdown';
import { OpSelect } from 'new-components/DLS/OpSelect/OpSelect';
import { OpTextButton } from 'new-components/DLS/OpTextButton/OpTextButton';
import { OpTableRecordType } from '../OpTableCore';

import './BatchActions.scss';

export interface BatchActionsMenuItem extends Omit<MenuItemType, 'onClick'> {
  testId?: string;
  onClick(selectedRowKeys: Key[], selectedRows?: OpTableRecordType[]): void;
}

interface OpDataFetchTableProps {
  menuItems: BatchActionsMenuItem[];
  selectedRowKeys: Key[];
  selectedRows?: OpTableRecordType[];
  resetRowSelections(): void;
}

export const BatchActions = ({
  menuItems,
  selectedRowKeys,
  selectedRows,
  resetRowSelections,
}: OpDataFetchTableProps) => {
  const { t } = useTranslation();

  return (
    <div className="batch-actions">
      <OpDropdown
        testId="batch-actions-dropdown"
        menu={{
          items: menuItems.map(({ testId, ...item }) => ({
            ...item,
            'data-testid': testId,
            // Overwriting the onClick prop so we can pass selectedRowKeys
            onClick: () => item.onClick?.(selectedRowKeys, selectedRows),
          })),
          className: 'batch-actions__dropdown-menu',
        }}
        trigger={['click']}
        disabled={!selectedRowKeys.length}
      >
        {/** Using this as child so we get the consistent dropdown caret */}
        <OpSelect
          className="batch-actions__select"
          size="large"
          value={t('Batch actions')}
          notFoundContent={null}
          testId="batch-actions-select"
        />
      </OpDropdown>

      {/** Shows selected row count */}
      {selectedRowKeys.length > 0 && (
        <div className="batch-actions__text-container">
          <span className="batch-actions__selected-count">
            {selectedRowKeys.length} selected
          </span>
          <OpTextButton
            className="batch-actions__clear-button"
            onClick={resetRowSelections}
          >
            {t('Clear')}
          </OpTextButton>
        </div>
      )}
    </div>
  );
};
